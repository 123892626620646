import { Link, useNavigate } from 'react-router-dom';
import SellerProfile from 'assets/images/listing-description-image.png';
import Rating from 'react-rating';
import starEmpty from 'assets/images/icons/icon-star-empty.svg';
import starFilled from 'assets/images/icons/icon-star-2.svg';
import {
    addServiceWishlist, removeServiceWishlist, addToCart, cleanAddToCart
} from 'store/action/ServiceDetailAction'
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { chatWithSel } from 'store/action/chatStartAction';

const ReviewCard = ({ serviceDetail }) => {
    let cart = useSelector(store => store.sellerServiceDetail);
    let login = useSelector(store => store.login);
    let dispatch = useDispatch()
    let navigate = useNavigate();

    const getDaysOpen = (item) => {
        if (item.daysOpened.length === 1) {
            return item.daysOpened[0].day
        } else {
            return item.daysOpened[0].day + "-" + item.daysOpened[item?.daysOpened?.length - 1].day
        }
    }

    const getDaysClose = (item) => {
        let dayOptions = [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday"
        ];
        let closedDays = dayOptions.filter((ditem, i) => i > item.daysOpened.length - 1);
        if (closedDays.length === 1) {
            return closedDays[0]
        } else {
            return closedDays[0] + "-" + closedDays[closedDays.length - 1]
        }
    }

    const addToCard = () => {
        if (login.islogin) {
            let payload = {
                serviceId: serviceDetail.data._id,
                quantity: 1
            }
            addToCart(payload)(dispatch);
        } else {
            let cart = [];
            if (localStorage.getItem("ala_cart")) {
                cart = JSON.parse(localStorage.getItem("ala_cart"));
            }

            let payload = {
                serviceTitle: serviceDetail.data.title,
                serviceCover: serviceDetail.data.serviceCover,
                path: serviceDetail.data.path,
                categoryName: serviceDetail.data.categoryName,
                serviceId: serviceDetail.data._id,
                price: serviceDetail.data.price,
                sellerData: serviceDetail.data.sellerData,
                quantity: 1
            }
            cart.push(payload);
            localStorage.setItem("ala_cart", JSON.stringify(cart))
        }
    }

    useEffect(() => {
        if (cart?.addtocart?.status === "success") {
            setTimeout(() => {
                navigate('/user/cart')
            }, 1000)
            cleanAddToCart()(dispatch)
        }
    }, [cart]);


    const addToWishlist = () => {
        if (serviceDetail.data.wishlist) {
            removeServiceWishlist({ serviceId: serviceDetail.data._id }, 'service')(dispatch)
        } else {
            addServiceWishlist({ serviceId: serviceDetail.data._id }, 'service')(dispatch)
        }
    }

    const handleChat = (e) => {
        e.preventDefault()
        let payload = {
            sellerId: serviceDetail?.data?.sellerId,
            serviceId: serviceDetail?.data?.serviceId
        }
        chatWithSel(payload, callback => {
            setTimeout(() => navigate('/user/messages/opened/' + callback), 1000)
        })(dispatch);
        // console.log(chatWithSel.data.response._id)
        // window.location.href = `/user/messages/opened/${item._id}`

    }

    const isCart = () => {
        let isCartVal = false;
        if (localStorage.getItem("ala_cart")) {
            cart = JSON.parse(localStorage.getItem("ala_cart"));
            cart.forEach(item => {
                if (item.serviceId === serviceDetail?.data?._id) {
                    isCartVal = true
                }
            })
        } else {
            isCartVal = serviceDetail?.data?.cart;
        }
        return isCartVal;
    }

    return (
        <div className='description-wrap listing'>
            <div className='image-block'>
                <div className='image-wrap'>
                    {
                        serviceDetail?.data?.serviceCover === "" ?
                            <img src={SellerProfile} alt='profile-image' />
                            : <img src={serviceDetail?.data?.path + serviceDetail?.data?.serviceCover} alt='profile-image' />
                    }
                </div>
                {
                    serviceDetail?.data?.forTim !== "" ?
                        isCart() ?
                            <Link to='/user/cart' className='btn'>Go to Cart</Link>
                            : <Link to='#' onClick={addToCard} className='btn'>Add to Cart</Link>
                        : null
                }
                <Link to="#" onClick={handleChat} className='white-btn'>Chat</Link>
            </div>
            <div className='seller-details'>
                <div className='details-head'>
                    <div className='main-head'>
                        <div className='seller-btns'>
                            <button className='btn-wrap' onClick={addToWishlist}>
                                <i className={serviceDetail?.data?.wishlist ? 'icon-heart add' : 'icon-heart'}></i>
                                Add to Wishlist
                            </button>
                            <button className='btn-wrap'>
                                <i className='icon-share'></i>
                                Share This Service
                            </button>
                        </div>
                        <h2>{serviceDetail?.data?.title}</h2>
                        {serviceDetail?.data?.sellerData.length > 0 ?
                            serviceDetail?.data?.sellerData[0].type === "Company" ?
                                <h3>{serviceDetail?.data?.sellerData[0].businessName}</h3>
                                : <h3>{serviceDetail?.data?.sellerData[0].firstName + " " + serviceDetail?.data?.sellerData[0].lastName}</h3>
                            : null
                        }

                    </div>
                    <div className='pricing-wrap'>${serviceDetail?.data?.price}<span className='timer'>{serviceDetail?.data?.forTim !== "" && serviceDetail?.data?.forTim}</span></div>
                </div>
                <div className='seller-rating'>
                    <div className='rating-wrap'>
                        <div className='rating-image'>
                            <Rating
                                readonly
                                placeholderRating={serviceDetail?.data?.averageRating ? serviceDetail?.data?.averageRating : 0}
                                emptySymbol={<img src={starEmpty} className="icon" />}
                                placeholderSymbol={<img src={starFilled} className="icon" />}
                                fullSymbol={<img src={starFilled} className="icon" />}
                            />
                        </div>
                        <div className='rating-number'>{serviceDetail?.data?.totalReview} reviews</div>
                    </div>
                </div>
                <div className='details-wrap'>
                    <div className='detail-list'>
                        <h3>Address:</h3>
                        {
                            serviceDetail?.data?.addressData.length > 0 ?
                                <ul className='details-values'>
                                    <li>
                                        <div className='detail-text'>{serviceDetail?.data?.addressData[0].countryName + " " + serviceDetail?.data?.addressData[0].stateName}</div>
                                    </li>
                                    <li>
                                        <div className='detail-text'>
                                            {
                                                serviceDetail?.data?.addressData[0].addressLine1
                                            }
                                            <>
                                                {
                                                    serviceDetail?.data?.addressData[0].addressLine2 !== "" &&
                                                    + ", " +
                                                    serviceDetail?.data?.addressData[0].addressLine2
                                                }

                                            </>

                                        </div>
                                    </li>
                                </ul>
                                : null
                        }

                    </div>
                    {
                        serviceDetail?.data?.addressData.length > 0 ?
                            <div className='detail-list'>
                                <h3>Days Opened</h3>
                                <ul className='details-values'>
                                    <li>
                                        <div className='detail-text'>
                                            <span className='day-name'>{getDaysOpen(serviceDetail?.data?.addressData[0])}</span>
                                            <span className='timing'>9:00 a.m. — 6:00 p.m.</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='detail-text'><span className='day-name'>{getDaysClose(serviceDetail?.data?.addressData[0])}</span><span className='timing'>Closed</span></div>
                                    </li>
                                </ul>
                            </div>
                            : null
                    }
                </div>
                <p>{serviceDetail?.data?.description}</p>
            </div>
        </div>
    )
}

export default ReviewCard;
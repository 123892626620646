import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Tabs, Tab } from 'react-bootstrap';
import Company from 'components/BecomeSeller/Company';
import Freelancer from 'components/BecomeSeller/Freelancer';
import LabForm from 'components/BecomeSeller/LabForm';

const BecomeSeller = () => {

	useEffect(() => {
		let ele = document.getElementById('root');
		ele.scrollIntoView()
	}, [])


	return (
		<section className='become-seller'>
			<div className="container">
				<Breadcrumb>
					<Breadcrumb.Item href="/">Home</Breadcrumb.Item>
					<Breadcrumb.Item active>Register as a Lab</Breadcrumb.Item>
				</Breadcrumb>
				<div className="section-header">
					<h1>Register as a Lab</h1>
					{/* <div className='btn-wrap'><Link className='btn' to="/seller-sign">Lab panel login</Link></div> */}
					<div className='btn-wrap'><Link className='btn' to="/lab-sign">Lab panel login</Link></div>
				</div>

				{/* <Tabs defaultActiveKey="company" id="uncontrolled-tab-example" className="mb-3">
					<Tab eventKey="company" title="Company">
						<Company />
					</Tab>
					<Tab eventKey="freelancer" title="Freelancer">
						<Freelancer />
					</Tab>
				</Tabs> */}

				<LabForm />

			</div>
		</section>
	)
}

export default BecomeSeller

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'react-slick';
import service1 from 'assets/images/service-5.jpg';
import service6 from 'assets/images/service-9.png';
import { homePageData } from 'store/action/homePageAction';

const Homepage = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const homeInfo = useSelector(state => state.homePage);

	let settings = {
		infinite: false,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 4,
				}
			},
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 2,
				}
			},
			{
				breakpoint: 576,
				settings: {
					slidesToShow: 1,
				}
			}
		]
	};

	let adSettings = {
		infinite: false,
		speed: 500,
		slidesToShow: 2,
		slidesToScroll: 2,
		arrows: false,
		dots: true,
		responsive: [
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 2,
				}
			},
			{
				breakpoint: 576,
				settings: {
					slidesToShow: 1,
				}
			}
		]
	};

	let bannerSettings = {
		infinite: false,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		dots: true,
		responsive: [
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 2,
				}
			},
			{
				breakpoint: 576,
				settings: {
					slidesToShow: 1,
				}
			}
		]
	};

	useEffect(() => {
		homePageData()(dispatch)
	}, [])

	return (
		<React.Fragment>
			<section className="banner">
				<Slider {...bannerSettings}>
					{
						homeInfo?.data?.bannerData?.map(item =>
							<div className='slide'>
								<div className="container">
									<div className="banner-wrap">
										{/* <div className="banner-left">
							<h1>COVID-19 Test</h1>
							<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
							<div className="btn-wrap">
								<Link to='/' className="btn">See More</Link>
							</div>
						</div> */}
										<div className="banner-right">
											<div className="img-wrap">
												<img src={homeInfo?.data?.bannerImagePath + item.webImage} alt="banner" />
											</div>
										</div>
									</div>
								</div>
							</div>
						)
					}
				</Slider>
			</section>
			<section className="trending-services">
				<div className="container">
					<h2>Popular Test</h2>
					<Slider {...settings}>
						{
							homeInfo?.data?.subcategoryData?.map(item =>
								<div className='slide'>
									<div className="card">
										<a href={`/category/particular/${item.categoryId}/${item._id}/${item.name}`}>
											<div className="service-img">
												{item.subcategoryIcon ?
													<img src={homeInfo?.data?.subcategoryImagePath + item.subcategoryIcon} alt="service" />
													:
													<img src={service1} alt="service" />
												}
											</div>
											<div className="service-name">
												<h3>{item.name}</h3>
											</div>
										</a>
									</div>
								</div>
							)}
					</Slider>
				</div>
			</section>
			<aside className='ad-banners'>
				<div className="container">
					<Slider {...adSettings}>
						{
							homeInfo?.data?.topAdvertiserBannerData?.map(item =>
								<div className='slide'>
									<div className="card">
										{/* <div className="ad-info">
									<span className="info-wrap">Discover All Hair Solutions</span>
									<div className="btn-wrap">
										<Link to='/' className="btn">Click Here</Link>
									</div>
								</div> */}
										<div className="ad-img">
											<img src={homeInfo?.data?.bannerImagePath + item.webImage} alt="ad" />
										</div>
									</div>
								</div>
							)
						}
					</Slider>
				</div>
			</aside>
			<section className="core-services">
				<div className="container">
					<h2>Categories</h2>
					<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
					<div className="services-block">
						{
							homeInfo?.data?.categoryData?.map(item =>
								<div className="service-wrap">
									<a href={`/category/${item._id}/${item.name}`}>
										<div className='img-wrap'>
											{
												item.categoryIcon ?
													<img src={homeInfo?.data?.categoryImagePath + item.categoryIcon} alt="service" />
													:
													<img src={service1} alt="service" />
											}
										</div>
										<div className="service-info">{item.name}</div>
									</a>
								</div>
							)
						}
					</div>
				</div>
			</section>
			<aside className='ad-banners last'>
				<div className="container">
					<Slider {...adSettings}>
						{
							homeInfo?.data?.bottomAdvertiserBannerData?.map(item =>
								<div className='slide'>
									<div className="card">
										{/* <div className="ad-info">
									<span className="info-wrap">Discover All Hair Solutions</span>
									<div className="btn-wrap">
										<Link to='/' className="btn">Click Here</Link>
									</div>
								</div> */}
										<div className="ad-img">
											<img src={homeInfo?.data?.bannerImagePath + item.webImage} alt="ad" />
										</div>
									</div>
								</div>
							)
						}
					</Slider>
				</div>
			</aside>
		</React.Fragment>
	)
}

export default Homepage;

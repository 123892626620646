import * as actiontype from "../actionType";
import { endPoints } from "../../utils/endPoints";
import { axiosInstanceWithUserToken } from "../../utils/Axios";
import { useNotify } from "../../components/Alert/Alert";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const summaryOrder = (data) => (dispatch) => {

    let token = localStorage.getItem("user_token");
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken
        .get(endPoints.ORDER_SUMMARY, {
            headers: {
                Authorization: token,
            },
        })
        .then(async (response) => {
            dispatch({ type: "LOADERSTOP" })
            if (response?.data?.status === "success") {
                // toast.success(response?.data?.message)
                dispatch({
                    type: actiontype.ORDERS_SUMMARY,
                    payload: {
                        ...response.data
                    },
                });
            } else {
                // toast.error(response?.data?.message);
            }
            // loadingAction(false)(dispatch);
        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
            // console.log('error', errResponse?.response?.data)
            if (errResponse?.response?.data?.status === "error") {
                // toast.error(errResponse?.response?.data?.message);
            }
        });
};
import React, { useState, useEffect, useRef } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { sellerFormAction } from 'store/action/becomeSellerFromAction'
import { useDispatch, useSelector } from 'react-redux'
import { countryList } from 'store/action/countryList';
import { stateList } from 'store/action/stateList';
import { cityList } from 'store/action/cityListAction';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

const schema = yup.object().shape({
    firstName: yup
        .string()
        .required("Please enter First Name")
        .min(2, "First name must be at least 2 characters")
        .max(20, "First name must be at most 20 characters")
        .matches(/^[A-Za-z ]+$/i, "Please enter valid first name"),
    lastName: yup
        .string()
        .required("Please enter Last Name")
        .min(2, "Last name must be at least 2 characters")
        .max(20, "Last name must be at most 20 characters")
        .matches(/^[A-Za-z ]+$/i, "Please enter valid last name"),
    email: yup
        .string()
        .required("Please enter your email address")
        .matches(
            /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            "Please use the correct email"
        ),
    dob: yup
        .string()
        .required("Please select your Birthday"),
    addressLine1: yup
        .string()
        .required("Please enter Address")
        .min(2, "Address must be at least 2 characters")
        .max(60, "Address must be at most 60 characters")
        .matches(/^[a-zA-Z0-9\s.,'-]*$/, "Please enter valid Address"),
    addressLine2: yup
        .string(),
    city: yup
        .string(),
    stateId: yup
        .string()
        .required("Please enter state"),
    proofOfIdentity: yup
        .string(),
    countryId: yup
        .string()
        .required("Please enter country"),
    phone: yup
        .string()
        .required("Please enter mobile number")
        .min(7)
        .max(14),
    postcode: yup
        .string()
        .required("Please enter Zip")
        .min(3)
        .matches(/^[0-9a-zA-Z]+$/, "Zip code is not valid"),
    comment: yup
        .string()
        .required("Please enter Comment")
        .min(2)
        .max(300),
});

const Freelancer = () => {
    const navigate = useNavigate();
    let attachmentName = useRef(null);
    const [img, setImg] = useState("");
    const [fileAttach, setFileAttach] = useState(null)
    const getCountry = useSelector(state => state.countryData)
    const getState = useSelector(state => state.stateData)
    const getCity = useSelector(state => state.cityData)
    const dispatch = useDispatch()
    const [file, setFile] = React.useState("");
    const [countryname, setCountryname] = useState("");



    const { register, formState: { errors }, handleSubmit, reset } = useForm({
        mode: "onSubmit",
        resolver: yupResolver(schema),
    });

    const imgHandler = (e) => {
        setFileAttach(e.target.files[0])
        let src_ = URL.createObjectURL(e.target.files[0]);
        setImg(src_)
        console.log('newwwwww', e.target.files)
    }

    useEffect(() => {
        countryList()(dispatch);
    }, []);

    function handleUpload(event) {
        setFile(event.target.files[0]);
    }



    const onSubmit = (data) => {
        console.log('dataaaaaaaaaa', data)

        let payload = new FormData();
        payload.append('type', "Freelancer");
        payload.append('firstName', data.firstName);
        payload.append('lastName', data.lastName);
        payload.append("cityId", data.cityId);
        payload.append('stateId', data.stateId);
        payload.append('comment', data.comment);
        payload.append("countryId", data.countryId);
        payload.append("addressLine1", data.addressLine1);
        payload.append("addressLine2", data.addressLine2);
        payload.append("email", data.email);
        payload.append("postcode", data.postcode);
        payload.append("phone", data.phone);
        payload.append("proofOfIdentity", fileAttach);
        payload.append("dob", data.dob.split("T")[0]);

        sellerFormAction(payload, callback => {
            setTimeout(() => navigate("/become-seller/success"), 1000)
        })(dispatch)


    };

    const getStateHandler = (e) => {
        let val = e.target.value;
        stateList({ countryId: val })(dispatch);
    }

    const getCityHandler = (e) => {
        cityList({ countryId: countryname, stateId: e.target.value })(dispatch);
    }

    return (
        <div className='freelancer-tab'>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
            />
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="card inputs-wrapper">
                    {/* <h4>Verification</h4> */}
                    <div className="row input-block">
                        <div className="col-md-6 col-lg-4 input-wrapper required">
                            <label className="input-label">first name</label>
                            <div className={`input-wrap ${errors.firstName ? 'has-error' : ''}`}>
                                <input
                                    type="text"
                                    className="form-control"
                                    {...register("firstName")}
                                    placeholder='FirstName'
                                />
                            </div>
                            {errors.firstName && (
                                <span className="error">
                                    {errors.firstName.message}
                                </span>
                            )}
                        </div>
                        <div className="col-md-6 col-lg-4 input-wrapper required">
                            <label className="input-label">Last name</label>
                            <div className={`input-wrap ${errors.lastName ? 'has-error' : ''}`}>
                                <input
                                    type="text"
                                    {...register("lastName")}
                                    className="form-control"
                                    placeholder='LastName'
                                />
                            </div>
                            {errors.lastName && (
                                <span className="error">
                                    {errors.lastName.message}
                                </span>
                            )}
                        </div>
                        <div className="col-md-6 col-lg-4 input-wrapper required">
                            <label className="input-label">Email address</label>
                            <div className={`input-wrap ${errors.email ? 'has-error' : ''}`}>
                                <input
                                    type="email"
                                    {...register("email")}
                                    className="form-control"
                                    autoComplete="off"
                                    placeholder='example@aladyyn.pro'
                                />
                            </div>
                            {errors.email && (
                                <span className="error">
                                    {errors.email.message}
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="inputs-heading">Freelancer Information</div>
                    <div className="row input-block">
                        <div className="col-md-6 col-lg-4 input-wrapper required text-fix">
                            <label className="input-label">Date of Birth</label>
                            {/* <DatePicker className="input-wrap"
                                onChange={onChange}
                                value={value}
                                type="date"
                                    className="form-control"
                                    {...register("date")}
									placeholder='Select date'
                            /> */}
                            <div className={`input-wrap ${errors.dob ? 'has-error' : ''}`}>
                                <input
                                    type='date'
                                    className="form-control date-input"
                                    {...register("dob")}
                                    placeholder='Select date'
                                />
                            </div>
                            {errors.dob && (
                                <span className="error">
                                    {errors.dob.message}
                                </span>
                            )}
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="input-wrapper required">
                                <label className="input-label">Phone Number</label>
                                <div className={`input-wrap ${errors.phone ? 'has-error' : ''}`}>
                                    <input
                                        type="number"
                                        {...register("phone")}
                                        className="form-control"
                                        placeholder='Mobile'
                                    />
                                </div>
                                {errors.phone && (
                                    <span className="error">
                                        {errors.phone.message}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="input-wrapper img-input-fix has-input-file">
                        <label className="">Proof of Identify</label>
                        <div className="row input-block">
                            <div className="col-lg-8 input-wrapper">
                                <div className="img-input-wrapper">
                                    <div className="img-input">
                                        <i className="icon-plus"></i>
                                        Upload
                                        <input
                                            {...register("proofOfIdentity")}
                                            type="file"
                                            accept="image/*"
                                            ref={attachmentName}
                                            onChange={imgHandler}
                                        />
                                    </div>
                                    <span className="img-info">Upload scanned copy of passport, nationalID, driver’s license etc.</span>
                                </div>
                                {
                                    img !== "" ?
                                        <div className="img-thumbnail-wrapper attachment-name image">
                                            <img src={img} />
                                        </div>
                                        :
                                        ''
                                }
                            </div>
                        </div>
                        {file && <ImageThumb image={file} />}
                    </div>
                    <div className="inputs-heading">Business Address</div>
                    <div className="row input-block">
                        <div className="col-md-6 input-wrapper required">
                            <label className="input-label">address line 1</label>
                            <div className={`input-wrap ${errors.addressLine1 ? 'has-error' : ''}`}>
                                <input
                                    type="text"
                                    className="form-control"
                                    {...register("addressLine1")}
                                    placeholder='Address'
                                />
                                <span className="input-info">Building number and Street</span>
                            </div>
                            {errors.addressLine1 && (
                                <span className="error">
                                    {errors.addressLine1.message}
                                </span>
                            )}
                        </div>
                        <div className="col-md-6 input-wrapper">
                            <label className="input-label">address line 2</label>
                            <div className={`input-wrap ${errors.addressLine2 ? 'has-error' : ''}`}>
                                <input
                                    type="text"
                                    {...register("addressLine2")}
                                    className="form-control"
                                    placeholder='Address'
                                />
                                <span className="input-info">Room/Block/Apartments</span>
                            </div>
                        </div>
                        <div className="col-md-6 input-wrapper required">
                            <label className="input-label">Country</label>
                            <div className={`input-wrap ${errors.countryId ? 'has-error' : ''}`}>
                                <select {...register("countryId")} name="countryId" className="form-control" id="countryId" onBlur={getStateHandler}>
                                    <option value={""}>Please select country</option>
                                    {
                                        getCountry?.map(item =>
                                            <option value={item._id}>{item.name}</option>
                                        )
                                    }

                                </select>
                            </div>
                            {errors.countryId && (
                                <span className="error">
                                    {errors.countryId.message}
                                </span>
                            )}
                        </div>
                        <div className="col-md-6 input-wrapper required">
                            <label className="input-label">State / Region</label>
                            <div className="input-wrap">
                                <select className="form-control" {...register("stateId")} name="stateId" onChange={getCityHandler}>
                                    <option value={""}>Please select state</option>
                                    {
                                        getState?.map(item =>
                                            <option value={item._id}>{item.name}</option>
                                        )
                                    }

                                </select>
                            </div>
                            {errors.stateId && (
                                <span className="error">
                                    {errors.stateId.message}
                                </span>
                            )}
                        </div>
                        <div className="col-md-6 input-wrapper">
                            <label className="input-label">City</label>
                            <div className="input-wrap">
                                <select className="form-control" {...register("cityId")} name="cityId">
                                    <option value={""}>Please select city</option>
                                    {
                                        getCity?.map(item =>
                                            <option value={item._id}>{item.name}</option>
                                        )
                                    }

                                </select>
                            </div>
                            {errors.city && (
                                <span className="error">
                                    {errors.city.message}
                                </span>
                            )}
                        </div>
                        <div className="col-md-6 input-wrapper required">
                            <label className="input-label">ZIP / Postal Code</label>
                            <div className={`input-wrap ${errors.postcode ? 'has-error' : ''}`}>
                                <input
                                    type="text"
                                    {...register("postcode")}
                                    className="form-control"
                                    autoComplete="off"
                                    placeholder='Zip Code'
                                />
                            </div>
                            {errors.postcode && (
                                <span className="error">
                                    {errors.postcode.message}
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="row input-block">
                        <div className="col-12 input-wrapper required mb-0">
                            <label className="input-label">Comment</label>
                            <div className={`input-wrap ${errors.comment ? 'has-error' : ''}`}>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    {...register("comment")}
                                    placeholder='Please enter your comment'
                                />
                            </div>
                            <label className="input-label input-info position-static">Comment should not exceed 300 character.</label>
                            {errors.comment && (
                                <span className="error">
                                    {errors.comment.message}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
                <div className="btn-wrap">
                    <input className="btn" type="submit" />
                </div>
            </form>
        </div>
    )
}

const ImageThumb = ({ image }) => {
    return <div className='thumbnail-wrap'>
        <img src={URL.createObjectURL(image)} alt={image.name} />
    </div>
};

export default Freelancer;
import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { editOptionalInfo, optionalinfoDetail, uploadFiles } from '../../store/action/optionalinfo'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
// import Loader from '../Loader/Loader';
import Loader from 'components/Loader/Loader';

const EditInfo = ({ sellerInformationId }) => {
    let dispatch = useDispatch();
    const [data, setData] = useState({})
    let history = useNavigate();
    const [isloader, setIsLoader] = useState(false);

    useEffect(() => {
        document.getElementById("sDate_0").max = new Date(
            new Date().getTime() - new Date().getTimezoneOffset() * 60000
        ).toISOString().split("T")[0];


        optionalinfoDetail({ sellerInformationId }, callback => {
            setData(callback)
        })(dispatch)
    }, [])



    const [state, setState] = useState([
        { name: '', startDate: null, endDate: null, currentlyWorking: false, workDescription: '' }
    ])

    const [portfolioState, setPortfolioState] = useState([
        { portfolioDescription: '', media: '', mediaSrc: "", fileType: '' }
    ])


    const [certificationState, setCertificationState] = useState([
        { certificateDescription: '', image: '', mediaSrc: "", fileType: '' }
    ])


    useEffect(async () => {
        if (data?.sellerWorkExperienceData?.length > 0) {
            let newData = [];
            data?.sellerWorkExperienceData.forEach((item) => {
                newData.push({
                    name: item.name,
                    startDate: item.startDate ? item.startDate.split("T")[0] : null,
                    endDate: item.endDate ? item.endDate.split("T")[0] : null,
                    currentlyWorking: item.currentlyWorking,
                    workDescription: item.description
                })
            })
            setState(newData)
        }
        if (data?.sellerPortfolioData?.length > 0) {
            let newData = [];
            data?.sellerPortfolioData.forEach((item) => {
                let mediaSrc = data.path + item.media;
                newData.push({ portfolioDescription: item.description, media: item.media, mediaSrc, fileType: item.fileType })
            })
            setPortfolioState(newData)
        }
        if (data?.sellerCertificateData?.length > 0) {
            let newData = [];
            data?.sellerCertificateData.forEach((item) => {
                let mediaSrc = data.path + item.image;
                newData.push({ certificateDescription: item.description, image: item.image, mediaSrc, fileType: "image/sdda" })
            })
            setCertificationState(newData);
        }
    }, [data])


    console.log('portfolioState', portfolioState)


    const { register, formState: { errors }, handleSubmit, reset } = useForm({
        reValidateMode: "onChange"
    });

    const handlerChange = (e, i, name) => {
        let newState = [...state];
        if (name === "startDate") {
            document.getElementById("sDate_0").min = e.target.value;
        }
        name === "currentlyWorking" ? newState[i][name] = e.target.checked : newState[i][name] = e.target.value;
        setState(newState);
    }


    const portfolioHandlerChange = (e, i, name) => {
        let newState = [...portfolioState];
        if (name === "media") {
            if (Math.round(e.target.files[0].size / 1024) < 25000) {
                setIsLoader(true)
                let formData = new FormData();
                formData.append('media', e.target.files[0]);
                formData.append('path', "optionalInformation");
                uploadFiles(formData, callback => {
                    newState[i][name] = callback;
                    newState[i].fileType = e.target.files[0].type;
                    newState[i].mediaSrc = data.path + callback;
                    setPortfolioState(newState);
                    setIsLoader(false)
                })(dispatch);
            } else {
                alert("The file size should not exceed 25 MB");
            }
        } else {
            newState[i][name] = e.target.value;
            setPortfolioState(newState);
        }
    }



    const centificationHandlerChange = (e, i, name) => {
        let newState = [...certificationState];
        if (name === "image") {
            let formData = new FormData();
            formData.append('media', e.target.files[0]);
            formData.append('path', "optionalInformation");
            uploadFiles(formData, callback => {
                newState[i][name] = callback;
                newState[i].mediaSrc = data.path + callback;
                newState[i].fileType = e.target.files[0].type;
                setCertificationState(newState);
            })(dispatch);
        } else {
            newState[i][name] = e.target.value;
            setCertificationState(newState);
        }
    }
    // console.log('certificationState', certificationState)

    const addMore = (e) => {
        e.preventDefault();
        let newState = [...state];
        newState.push({ name: '', startDate: null, endDate: null, currentlyWorking: false, workDescription: '' })
        setState(newState);
    }

    const portfolioAddMore = (e) => {
        e.preventDefault();
        let newState = [...portfolioState];
        newState.push({ portfolioDescription: '', media: '', mediaSrc: "", type: '' })
        setPortfolioState(newState);
    }

    const certificationAddMore = (e) => {
        e.preventDefault();
        let newState = [...certificationState];
        newState.push({ certificateDescription: '', image: '', mediaSrc: "", type: '' })
        setCertificationState(newState);
    }


    const onSubmit = (data) => {
        let name = [];
        let startDate = [];
        let endDate = [];
        let currentlyWorking = [];
        let workDescription = [];
        let portfolioDescription = [];
        let certificateDescription = [];
        let media = [];
        let image = [];
        let fileType = [];

        state.forEach(item => {
            name.push(item.name)
            if (item.startDate) {
                startDate.push(item.startDate.split("-")[0] + "-" + item.startDate.split("-")[1] + "-" + item.startDate.split("-")[2])
            } else {
                startDate.push("")
            }
            if (item.endDate) {
                endDate.push(item.endDate.split("-")[0] + "-" + item.endDate.split("-")[1] + "-" + item.endDate.split("-")[2])
            } else {
                endDate.push("")
            }
            currentlyWorking.push(item.currentlyWorking)
            workDescription.push(item.workDescription)
        })


        portfolioState.forEach(item => {
            portfolioDescription.push(item.portfolioDescription)
            media.push(item.media)
            fileType.push(item.fileType)
        })


        certificationState.forEach(item => {
            certificateDescription.push(item.certificateDescription)
            image.push(item.image)
        })

        let formData = {
            sellerInformationId,
            name,
            startDate,
            endDate,
            currentlyWorking,
            workDescription,
            portfolioDescription,
            certificateDescription,
            media,
            image,
            fileType
        }
        editOptionalInfo(formData, callback => {
            if (callback) {
                setTimeout(() => history('/seller/optional-info'), 2000)
            }
        })(dispatch);

    };

    const removeFilePort = (index) => {
        let newState = [...portfolioState];
        newState = newState.filter((item, i) => i !== index);
        setPortfolioState(newState);
    }

    const removeFileCerti = (index) => {
        let newState = [...certificationState];
        newState = newState.filter((item, i) => i !== index);
        setCertificationState(newState);
    }

    return (
        <div className='edit-info'>
            {
                // true ?
                isloader ?
                    <Loader />
                    :
                    null
            }
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="card">

                    {
                        state.map((item, i) =>
                            <>
                                <h4>Project/Work Experience</h4>
                                <div className="row input-block">
                                    <div className="col-md-6 col-lg-6-fix input-wrapper">
                                        <label className="input-label">Name of the Place of Work or Project</label>
                                        <div className="input-wrap">
                                            <input
                                                type="text"
                                                className="form-control"
                                                {...register("place_" + i, { required: false })}
                                                onChange={(e) => handlerChange(e, i, "name")}
                                                value={item.name}
                                                placeholder='Name of the Place of Work or Project'
                                            />
                                        </div>
                                        {errors["place_" + i] && (
                                            <span className="error-input">
                                                Please enter name of the place of work or project
                                            </span>
                                        )}
                                    </div>
                                    <div className="col-md-6 col-lg-2-fix input-wrapper">
                                        <label className="input-label">Start Date</label>
                                        <div className="input-wrap">
                                            <input
                                                id={`sDate_` + i}
                                                type='date'
                                                className="form-control date-input"
                                                {...register("sDate" + i, { required: false })}
                                                placeholder='Select date'
                                                onChange={(e) => handlerChange(e, i, "startDate")}
                                                value={item.startDate}
                                            />
                                        </div>
                                        {errors["sDate" + i] && (
                                            <span className="error-input">
                                                Please choose start date
                                            </span>
                                        )}
                                    </div>
                                    <div className="col-md-6 col-lg-2-fix input-wrapper">
                                        <label className="input-label">End Date</label>
                                        <div className="input-wrap">
                                            <input
                                                id={`eDate_` + i}
                                                type='date'
                                                className="form-control date-input"
                                                {...register("eDate" + i, { required: false })}
                                                placeholder='Select date'
                                                onChange={(e) => handlerChange(e, i, "endDate")}
                                                value={!item.currentlyWorking ? item.endDate : ''}
                                                disabled={item.currentlyWorking ? true : false}
                                            />
                                        </div>
                                        {errors["eDate" + i] && (
                                            <span className="error-input">
                                                Please choose end date
                                            </span>
                                        )}
                                    </div>
                                    <div className="col-md-6 col-lg-3-fix input-wrapper">
                                        <div className="input-wrap">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    checked={item.currentlyWorking}
                                                    onChange={(e) => handlerChange(e, i, 'currentlyWorking')}
                                                    id="flexCheckChecked"
                                                />
                                                <label className="form-check-label" htmlFor="flexCheckChecked">
                                                    I am currently working in this role
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row input-block position-relative">
                                    <div className="col-md-12">
                                        <label className="input-label">Description</label>
                                        <div className="input-wrap">
                                            <textarea
                                                type="text"
                                                className="form-control"
                                                {...register("desc_" + i, { required: false })}
                                                onChange={(e) => handlerChange(e, i, 'workDescription')}
                                                value={item.workDescription ? item.workDescription : item.description}
                                                maxLength={500}
                                                placeholder='Brief description of your role, responsibilities & accomplishments for this project/work experience'
                                            />
                                            <label className="input-label required input-info position-static label-fix">Comment should not exceed 500 characters.</label>
                                        </div>
                                        {errors["desc_" + i] && (
                                            <span className="error-input">
                                                Please enter description
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </>
                        )
                    }

                    <div className="add-more">
                        <a onClick={addMore}><span className="text">Add another place of work or project</span></a>
                    </div>
                </div>
                <div className="card">
                    <h4>Portfolio</h4>
                    {
                        portfolioState.map((item, i) =>
                            <>
                                <div className="input-block margin-fix">
                                    <div className="input-wrapper required img-input-fix has-input-file">
                                        <label className="">Upload Photo or Video</label>
                                        <div className="row">
                                            <div className="col-lg-9 input-wrapper">
                                                <div className="img-input-wrapper">
                                                    <div className="img-input">
                                                        <i className="icon-plus"></i>
                                                        Upload
                                                        <input
                                                            type="file"
                                                            accept="image/*,video/*"
                                                            onChange={(e) => portfolioHandlerChange(e, i, "media")}
                                                        />
                                                    </div>
                                                    <span className="img-info">Supported file formats: .jpg, .png, .mp4, .avi, .mov (918 x 425)<br />The file size should not exceed 25 MB</span>
                                                </div>
                                            </div>
                                        </div>
                                        {item.media !== "" ?
                                            <div className='img-thumbnail'>
                                                <div className="img-wrap">
                                                    <ImageThumb image={item.mediaSrc} type={item.fileType} />
                                                </div>
                                                <div className="remove-btn">
                                                    <button type='button' onClick={() => removeFilePort(i)}>
                                                        <i className="icon-remove"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            :
                                            <div className='img-thumbnail'>
                                                {/* <div className="img-wrap">
                                                <ImageThumb image={item.mediaSrc} type={item.fileType} />
                                            </div> */}
                                                {/* <div className="remove-btn">
                                                <button type='button' onClick={() => removeFilePort(i)}>
                                                    <i className="icon-remove"></i>
                                                </button>
                                            </div> */}
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="row input-block position-relative">
                                    <div className="col-md-12">
                                        <label className="input-label">Description</label>
                                        <div className="input-wrap">
                                            <textarea
                                                type="text"
                                                className="form-control"
                                                // {...register("portfolioDescription" + i, { required: false })}
                                                placeholder='Brief description of the portfolio content'
                                                maxLength={500}
                                                value={item.portfolioDescription ? item.portfolioDescription : item.description}
                                                onChange={(e) => portfolioHandlerChange(e, i, "portfolioDescription")}
                                            />
                                            <label className="input-label required input-info position-static label-fix">Comment should not exceed 500 characters.</label>
                                        </div>
                                        {errors["portfolioDescription" + i] && (
                                            <span className="error-input">
                                                Please enter description
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </>
                        )
                    }

                    <div className="add-more">
                        <a onClick={portfolioAddMore}><span className="text">Add another media</span></a>
                    </div>
                </div>
                <div className="card">
                    <h4>Certifications & Licenses</h4>
                    {
                        certificationState.map((item, i) =>
                            <>
                                <div className="input-block margin-fix">
                                    <div className="input-wrapper required img-input-fix has-input-file">
                                        <label className="">Upload Certification or License</label>
                                        <div className="row">
                                            <div className="col-lg-9 input-wrapper">
                                                <div className="img-input-wrapper">
                                                    <div className="img-input">
                                                        <i className="icon-plus"></i>
                                                        Upload
                                                        <input
                                                            type="file"
                                                            accept="image/*"
                                                            onChange={(e) => centificationHandlerChange(e, i, "image")}
                                                        />
                                                    </div>
                                                    <span className="img-info">Supported file formats: .jpg, .png (918 x 425), .pdf<br />The file size should not exceed 25 MB</span>
                                                </div>
                                            </div>
                                        </div>
                                        {item.image &&
                                            <div className='img-thumbnail'>
                                                <div className="img-wrap">
                                                    <ImageThumb image={item.mediaSrc} type={item.fileType} />
                                                </div>
                                                <div className="remove-btn">
                                                    <button type='button' onClick={() => removeFileCerti(i)}>
                                                        <i className="icon-remove"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="row input-block position-relative">
                                    <div className="col-md-12">
                                        <label className="input-label">Description</label>
                                        <div className="input-wrap">
                                            <textarea
                                                type="text"
                                                className="form-control"
                                                // {...register("place")}
                                                value={item.certificateDescription ? item.certificateDescription : item.description}
                                                placeholder='Brief description of the Certification or License'
                                                maxLength={500}
                                                onChange={(e) => centificationHandlerChange(e, i, "certificateDescription")}
                                            />
                                            <label className="input-label required input-info position-static label-fix">Comment should not exceed 500 characters.</label>
                                        </div>

                                        {errors.profileDescription && (
                                            <span className="error">
                                                {errors.profileDescription.message}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </>
                        )
                    }

                    <div className="add-more">
                        <a onClick={certificationAddMore}><span className="text">Add another certification or license</span></a>
                    </div>
                </div>
                <div className="btn-wrap">
                    <input className="secondary-btn" type='button' onClick={() => history('/seller/optional-info')} value="cancel" />
                    <input className="btn" type="submit" value="save" />
                </div>
            </form>
        </div>
    )
}

const ImageThumb = ({ image, type }) => {

    let newType = type.split("/")[0];
    return <div className='thumbnail-wrap'>
        {
            newType === "image" ?
                <img src={image} alt={`img`} />
                : <video src={image} controls />
        }
    </div>
};

export default EditInfo

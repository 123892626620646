import React, { useState, useEffect } from 'react';

const PortfolioSec = ({ item, data, index, openModel }) => {


    const [showPlay, setShowPlay] = useState(false);
    const [isPlay, setIsPlay] = useState(true);
    const [stateFile, setStateFile] = useState(false);


    const videoHandler = (e, idname) => {
        e.preventDefault()
        console.log('eee', idname)
        let vid = document.getElementById(idname);
        if (vid.paused) {
            vid.play()
            setIsPlay(false)
        } else {
            vid.pause();
            setIsPlay(true)
        }
        setShowPlay(false);
    }

    const mouseEnter = () => {
        setShowPlay(true);
    }

    const mouseLeave = () => {
        setShowPlay(false);
    }

    return (
        <React.Fragment>
            <div className='slide'>
                <div className="img-wrap contains-video">
                    {
                        item.fileType.split("/")[0] === "image" ?
                            <a className={showPlay ? "active" : ""} onClick={() => openModel(item, index)} >
                                <img src={data.path + item.media} alt="service" />
                                <div className={isPlay ? "video-wrapper play" : "video-wrapper pause"}>
                                </div>
                            </a>
                            : <a className={showPlay ? "active" : ""} onClick={() => openModel(item, index)} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
                                <video id={"videoContainer" + index} src={data.path + item.media} alt="service" />
                                <div className={isPlay ? "video-wrapper play" : "video-wrapper pause"}>
                                </div>
                            </a>
                    }

                </div>
                <div className="service-info">
                    <p>{item.description.length > 60 ? item.description = item.description.substring(0, 60) + "...." : item.description}</p>
                </div>
            </div>
        </React.Fragment>
    )
}

export default PortfolioSec
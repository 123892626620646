import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { loginAction, sellerLoginAction } from "store/action/userLoginAction";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login";
import { Alert } from "components/Alert/Alert";

const schema = yup.object().shape({
  email: yup
    .string()
    .required("Please enter your email address")
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Za-z]{2,}$/i,
      "Please use the correct email"
    ),
  password: yup
    .string()
    .required("Please enter the password")
    .min(8, "Password should be equal or greater that 8 characters"),
});

const SignIn = () => {
  const navigate = useNavigate();
  let location = useLocation()

  // console.log('location', location.pathname === "/lab-sign")

  const [isShowPassword, setIsShowPassword] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [rememberMe, setRememberMe] = useState(false);
  const login = useSelector((state) => state.login);


  const dispatch = useDispatch();
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const togglePasswordHandler = () => {
    setIsShowPassword(!isShowPassword);
  };

  const inputType = isShowPassword ? "text" : "password";

  let token = localStorage.getItem("user_token");

  useEffect(() => {
    // console.log('check login', login)
    if (login.islogin) {
      if (login.type === "Lab") {
        // setTimeout(() => (window.location.href = '/seller-dashboard-company'), 3000);
        setTimeout(() => (window.location.href = '/lab-dashboard'), 3000);
        // return
      } else if (login.type === "Advertiser") {
        setTimeout(() => (window.location.href = '/user/advertiser-dashboard'), 3000);
        // return
      } else if (login.type === "User") {
        setTimeout(() => (window.location.href = '/user/dashboard'), 3000);
        // return
      }
      else if (login.type === "Freelancer") {
        setTimeout(() => (window.location.href = '/seller-dashboard-freelancer'), 3000);
        return
      }
      // reset();
    }
  }, [login]);

  const responseGoogle = (response) => {
    console.log(response);
  };

  const responseFacebook = (response) => {
    console.log(response);
  };

  const togglePasswordBtnClasses = isShowPassword ? 'toggle-btn show toggle-btn-wrap' : 'toggle-btn hide sign-in-btn';

  const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked);
  }

  const onSubmit = (data, e) => {
    e.preventDefault();
    
  //  const time= localStorage.setItem('lastLoginTime', Date.now().toString())
  //  conesole.log("time11111",time)
   

    let payload = {
      email: data.email,
      password: data.password,
      keep_me_signed_in: rememberMe
    };
    // loginAction(payload)(dispatch);

    sellerLoginAction(payload)(dispatch)


    // const lastLoginTimeInMillis = Date.now();
    // localStorage.setItem('lastLoginTime', lastLoginTimeInMillis.toString());

    // const lastLoginTime = localStorage.getItem('lastLoginTime');
    // const formattedLastLoginTime = new Date(parseInt(lastLoginTime));
    // const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
    // const loginTime = formattedLastLoginTime.toLocaleDateString(undefined, options);
    // console.log("Formatted Last Login Time:", loginTime);

    // localStorage.setItem("loginTimeSet", loginTime);


    // if (token && time) {
    //   const expirationTime = new Date().getTime() + 1 * 60 * 1000;
    //   localStorage.setItem("tokenExpiration", expirationTime);
    //   setTimeout(() => {
    //      localStorage.removeItem("user_token");
    //      localStorage.removeItem("lastLoginTime");
    //      localStorage.removeItem("tokenExpiration");
    //      window.location.reload('/');
         
    //   }, 1 * 60 * 1000);
    //  }

  };

  return (
    <section className='sign-in'>
      {/* <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      /> */}
      <Alert />
      <div className="container">
        <div className="card">
          <h2>Sign In</h2>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="input-block">
              <label htmlFor="user-id">Email</label>
              <div className="input-wrap">
                <input
                  type="text"
                  {...register("email")}
                  className="form-control"
                  placeholder="Email"
                />
              </div>
              {errors.email && (
                <span className="error">
                  {errors.email.message}
                </span>
              )}
            </div>
            <div className="input-block">
              <label htmlFor="password">Password</label>
              <div className="input-wrap">
                <input
                  type={inputType}
                  className="form-control"
                  {...register("password")}
                  placeholder="***********"
                />
                <div className="toggle-wrap">
                  <button className={togglePasswordBtnClasses} type="button" onClick={togglePasswordHandler}>
                    <i className="icon-eye"></i>
                  </button>
                </div>
              </div>
              {errors.password && (
                <span className="error">
                  {errors.password.message}
                </span>
              )}
            </div>
            <div className="row">
              <div className="col-md-6 mb-2 mb-md-0">
                <div className="form-check">
                  <input className="form-check-input" type="checkbox"
                    onChange={handleRememberMeChange}
                    checked={rememberMe}
                  />
                  <label className="form-check-label" >Keep me signed in</label>
                </div>
              </div>
              <div className="col-md-6 mb-2 mb-md-0 text-md-end">
                <Link to='/forgot-password' className='forgot-link'>Forgot Password?</Link>
              </div>
            </div>
            <div className="btn-wrap">
              <button type='submit' className='btn w-100'>Sign In</button>
            </div>
          </form>
          {/* {
            location.pathname !== "/seller-sign" ?
              <>
                <div className="signin-with">or continue with</div>
                <div className="signin-options text-center">
                  <Link to='/' className="google-login">
                    <i className="icon-google"></i>
                    <span className="text">Google</span>
                  </Link> */}

          {/* Not me============= GoogleLogin*/}
          {/* <GoogleLogin
                        clientId="useful-maxim-341310"
                        buttonText="Login"
                        onSuccess={responseGoogle}
                        onFailure={responseGoogle}
                        cookiePolicy={'single_host_origin'}
						      />, */}
          {/* Not me============= GoogleLogin*/}


          {/* <Link to="/" className="fb-login">
                    <i className="icon-fb"></i>
                    <span className="text">Facebook</span>
                  </Link> */}


          {/* Not me============= FacebookLogin*/}
          {/* <FacebookLogin
                    appId="1088597931155576"
                    autoLoad={true}
                    fields="name,email,picture"
                    // onClick={componentClicked}
                    callback={responseFacebook}
                  />, */}
          {/* Not me============= FacebookLogin*/}



          {/* </div>
              </>
              : null
          } */}

        </div>
        <div className="card text-center">
          {
            location.pathname === "/lab-sign" ?
              <div className="signup-wrap">
                Don’t have panel account?
                <Link to='/register-Lab' className="signup-link">
                  Sign Up
                </Link>
              </div>
              :
              <div className="signup-wrap">
                Don’t have an account?
                <Link to='/create-account' className="signup-link">
                  Sign Up
                </Link>
              </div>
          }

        </div>
      </div>
    </section>
  );
};

export default SignIn;

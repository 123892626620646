
import React from 'react';
import { Button } from 'reactstrap';

const CustomPagination = ({ count, page = 1, rowsPerPage = 5, setPage }) => {
  // Calculate the total number of pages
  const totalPages = Math.ceil(count.length / rowsPerPage);

  const handlePreviousClick = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNextClick = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  return (
    count ? (
      <div className="d-flex justify-content-between align-items-center mt-4">
        <Button
          className="btn-icon btn-3 py-1 px-2 "
          color="primary"
          type="button"
          onClick={handlePreviousClick}
          disabled={page === 1}
        >
          <span className="btn-inner--icon">
            <i className="fas fa-angle-left" />
          </span>
          <span className="btn-inner--text">Previous</span>
        </Button>
        <div>
          {Math.min(rowsPerPage * page, count.length)} of {count.length} Entries
        </div>
        <Button
          className="btn-icon btn-3 py-1 px-2 "
          color="primary"
          type="button"
          onClick={handleNextClick}
          disabled={page >= totalPages}
        >
          <span className="btn-inner--text">Next</span>
          <span className="btn-inner--icon">
            <i className="fas fa-angle-right" />
          </span>
        </Button>
      </div>
    ) : null
  );
};

export default CustomPagination;



import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import Select from 'react-select';
import invoiceThumbnail from 'assets/images/invoice-thumbnail.png'


const RaiseInvoice = () => {
	const categoryOptions = [
		{ value: '', label: 'Select Categories' },
		{ value: 'entertainment', label: 'entertainment' },
		{ value: 'wedding services', label: 'wedding services' },
	]
	const subCategoryoptions = [
		{ value: '', label: 'SelectSub-Category' },
		{ value: 'photographers', label: 'photographers' },
		{ value: 'videographers', label: 'videographers' },
	]
	const countryOptions = [
		{ value: 'Singapore', label: 'Singapore' },
		{ value: 'Option 2', label: 'Option 2' },
	]
	const cityOptions = [
		{ value: 'Ubin', label: 'Ubin' },
		{ value: 'Option 2', label: 'Option 2' },
	]
	const stateOptions = [
		{ value: 'State', label: 'State' },
		{ value: 'Option 2', label: 'Option 2' },
	]
	return (
		<section className='raise-invoice'>
			<div className="container">
				<Breadcrumb>
					<Breadcrumb.Item href="#">Home</Breadcrumb.Item>
					<Breadcrumb.Item active>Raise Invoie</Breadcrumb.Item>
				</Breadcrumb>
				<div className="section-head">
					<h1>Ralse Invoie</h1>
					<div className="head-right">
						<div className="select-wrap">
                            <Select defaultValue={categoryOptions[0]} classNamePrefix="react-select" className='react-select-container' options={categoryOptions} />
                        </div>
						<div className="select-wrap">
                            <Select defaultValue={subCategoryoptions[0]} classNamePrefix="react-select" className='react-select-container' options={subCategoryoptions} />
                        </div>
					</div>
				</div>
				<div className='services-inputs'>
                    <form>
                        <div className='form-wrap'>
                            <div className='inputs-wrap'>
                                <div className='input-wrap'>
                                    <input type="text" className='input' placeholder="Search a specific service" />
                                    <button className='search-btn'>
                                        <i className='icon-search'></i>
                                    </button>
                                </div>
                                <div className='input-wrap'>
                                    <input type="text" className='input' placeholder="Search a specific address" />
                                    <button className='search-btn'>
                                        <i className='icon-search'></i>
                                    </button>
                                </div>
                            </div>
                            <div className='selects-wrap'>
                                <div className="select-wrap bigger">
                                    <Select defaultValue={countryOptions[0]} classNamePrefix="react-select" className='react-select-container' options={countryOptions} />
                                </div>
                                <div className="select-wrap">
                                    <Select defaultValue={cityOptions[0]} classNamePrefix="react-select" className='react-select-container' options={cityOptions} />
                                </div>
                                <div className="select-wrap">
                                    <Select defaultValue={stateOptions[0]} classNamePrefix="react-select" className='react-select-container' options={stateOptions} />
                                </div>
                            </div>
						</div>
                    </form>
                </div>
				<div className="invoice-form">
					<form>
						<div className="invoice-wrap">
							<div className="left-block">
								<div className="invoice-thumbnail">
									<img src={invoiceThumbnail} alt="thumbnail" />
								</div>
								<div className="invoice-name">Haircut Session Lorem ipsum dolor sit amet, </div>
							</div>
							<div className="right-block">
								<div className="price-wrap">$100</div>
								<div className="checkbox-wrap">
									<div className="form-check">
										<input className="form-check-input" type="checkbox" value="" />
									</div>
								</div>
							</div>
						</div>
						<div className="invoice-wrap">
							<div className="left-block">
								<div className="invoice-thumbnail">
									<img src={invoiceThumbnail} alt="thumbnail" />
								</div>
								<div className="invoice-name">Nail Session Lorem ipsum dolor sit amet, </div>
							</div>
							<div className="right-block">
								<div className="price-wrap">$100</div>
								<div className="checkbox-wrap">
									<div className="form-check">
										<input className="form-check-input" type="checkbox" value="" />
									</div>
								</div>
							</div>
						</div>
						<div className="invoice-wrap">
							<div className="left-block">
								<div className="invoice-thumbnail">
									<img src={invoiceThumbnail} alt="thumbnail" />
								</div>
								<div className="invoice-name">Photo Session Lorem ipsum dolor sit amet, </div>
							</div>
							<div className="right-block">
								<div className="price-wrap">$100</div>
								<div className="checkbox-wrap">
									<div className="form-check">
										<input className="form-check-input" type="checkbox" value="" />
									</div>
								</div>
							</div>
						</div>
						<div className="btns-wrap">
							<div className="total-price">Total Price: <span className="price-wrap">$400</span></div>
							<div className="btn-wrap">
								<button type='submit' className='btn'>Raise Invoice</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</section>
	)
}

export default RaiseInvoice

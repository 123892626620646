import { combineReducers } from 'redux';
import Users from './User'
import { countryData, stateData, cityData, countryDataBank, stateDataBank, cityDataBank } from './countryList.reducer'
import login from './login.reducer';
import { profileView, advertiserView, advertiserEdit, userView } from './profile.reducer';
// import categoryData from './category.reducer';
import { sellerBillingMethod } from './sellingBillingMethod.reducer';
import { sellerServiceAdd } from './sellerServiceAdd.reducer';
import { sellerServiceCategory } from './sellerServiceAdd.reducer';
import { sellerServiceSubCategory } from './sellerServiceAdd.reducer';
import { categoryData, subCategoryData, finalCategoryData, homeSubCat, homeService } from './category.reducer';
import { languageDrop } from './language.reducer';
import { sellerUploadMedia } from './sellerServiceAdd.reducer';
import { newAddress } from './addAddress.reducer';
import { sellerData } from './sellerInfo.reduer';
import { forgetPass } from './forgetPassword.reducer';
import { newPassword } from './resetPas.reducer';
import { billingView } from './billing.reducer';
import { sellerServiceList } from './serviceList.reducer';
import { faqRedu } from './faqReducer'
import bannerShow from './showBanners.reducers';
import { homePage } from './home.reducer';
import { category } from './newcategory.reducer';
import { sellerServiceDetail } from './ServiceDetailReducer'
import { sellerProfileRedu } from './sellerProfileRedu'
import { userNotify, sellerNotify } from './notification.reducer';
import { cartDetail } from './cartDetails.reducer';
import { summary } from './ordersSummary.reducer';
import { orderPlaced } from './orderPlaced.reducer';
import { userToSeller } from './sellerChat.reducer';
import { userToSellerConversation, userToSellerConversationList } from './conversationSeller.reduer';
import { userAllMessage } from './userAllCount.reducer';
import { sellerToUserConversation, sellerToUserConversationList } from './conversationOfSeller.reducer';
import { userAllName } from './userAllCount.reducer';
import {loader} from './loader';

const rootReducer = combineReducers({
    sellerProfileRedu,
    sellerServiceDetail,
    countryData,
    stateData,
    cityData,
    users: Users,
    login,
    profileView,
    categoryData,
    sellerBillingMethod,
    sellerServiceAdd,
    sellerServiceCategory,
    sellerServiceSubCategory,
    newPassword,
    subCategoryData,
    finalCategoryData,
    languageDrop,
    sellerUploadMedia,
    newAddress,
    sellerData,
    forgetPass,
    countryDataBank,
    stateDataBank,
    cityDataBank,
    billingView,
    sellerServiceList,
    faqRedu,
    advertiserView,
    advertiserEdit,
    bannerShow,
    userView,
    homePage,
    category,
    homeSubCat,
    homeService,
    userNotify,
    sellerNotify,
    cartDetail,
    summary,
    orderPlaced,
    userToSeller,
    userToSellerConversation,
    userToSellerConversationList,
    userAllMessage,
    sellerToUserConversation,
    sellerToUserConversationList,
    userAllName,
    loader
});

export default rootReducer
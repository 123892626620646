import React, { useState, useEffect } from 'react';
import Warning from 'components/Warning/Warning';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";

const SellerDashboardFreelancer = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch()
	const [isProfileCompleted, setIsProfileCompleted] = useState(false);
	const user = useSelector((state) => state.profileView);

	console.log('user', user?.data)

	// useEffect(() => {
	// 	viewProfile()(dispatch);
	// 	console.log('dasdsdsdaa', getProfileInfo)
	// }, []);

	const handleLink = () => {
		// console.log('dataaaaa', getProfileInfo)
		if (user?.data?.[0]?.type == "Freelancer") {
			setTimeout(() => navigate({ pathname: '/seller-verification' }), 500);
		}
		else {
			setTimeout(() => navigate({ pathname: '/seller/company/seller-verification' }), 500);
		}
	}

	console.log('test', user?.data?.[0]?.postcode)

	const dashboardCardClasses = isProfileCompleted ?
		'dashboard-card card' :
		'dashboard-card card faded'
	return (
		<section className="seller-dashboard">
			{!user?.data?.[0]?.isApproved && <Warning />}
			<div className="container">
				<h2>Lab Account</h2>
				<div className="dashboard-wrapper">
					<div className="dashboard-card card">
						<Link to='/lab-information'>
							<div className="wrap-icon">
								<i className="icon-person"></i>
							</div>
							<h4 className="service-name">Lab Information</h4>
							<div className="service-details">Edit or add lab information and operating addresses & hours</div>
						</Link>
					</div>
					<div className={user?.data?.[0]?.isApproved ? "dashboard-card card" : "dashboard-card card faded"}>
						<Link to='#'>
							<div className="wrap-icon">
								<i className="icon-comment"></i>
							</div>
							<h4 className="service-name">Seller Messages</h4>
							<div className="service-details">View your seller messages</div>
						</Link>
					</div>
					<div className={user?.data?.[0]?.isApproved ? "dashboard-card card" : "dashboard-card card faded"}>
						<Link to='#'>
							<div className="wrap-icon">
								<i className="icon-bag"></i>
							</div>
							<h4 className="service-name">Seller Orders</h4>
							<div className="service-details">View order status and check payment and statistics</div>
						</Link>
					</div>
					<div className={user?.data?.[0]?.isApproved ? "dashboard-card card" : "dashboard-card card faded"}>

						<Link to={user?.data?.[0]?.isApproved ? "/seller/company/service-list" : "#"}>
							<div className="wrap-icon">
								<i className="icon-plus"></i>
							</div>
							<h4 className="service-name">Edit Services</h4>
							<div className="service-details">Create new and manage listings</div>
						</Link>
					</div>
					<div className="dashboard-card card">
						<Link to='/seller-verification'>
							<div className="wrap-icon">
								<i className="icon-lock"></i>
							</div>
							<h4 onClick={handleLink} className="service-name">Seller Verification & Security</h4>
							<div className="service-details">Edit or add verification information</div>
						</Link>
					</div>
					<div className="dashboard-card card">
						<Link to='/seller/billing-method'>
							<div className="wrap-icon">
								<i className="icon-card"></i>
							</div>
							<h4 className="service-name">Billing Method</h4>
							<div className="service-details">Edit or add billing methods</div>
						</Link>
					</div>
					<div className="dashboard-card card">
						<Link to='/seller/optional-info'>
							<div className="wrap-icon">
								<i className="icon-snippet"></i>
							</div>
							<h4 className="service-name">Optional Information</h4>
							<div className="service-details">Edit or add seller certifications & licenses and portfolio</div>
						</Link>
					</div>
					<div className="dashboard-card card">
						<Link to='/faq'>
							<div className="wrap-icon">
								<i className="icon-question"></i>
							</div>
							<h4 className="service-name">Help (FAQ)</h4>
							<div className="service-details">Browse questions and help topics</div>
						</Link>
					</div>
				</div>
			</div>
		</section>
	)
}

export default SellerDashboardFreelancer

import React, { useState, useEffect, useRef } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { advertiserAction } from 'store/action/editAdvertiserAction';
import { useDispatch, useSelector } from 'react-redux';
import { viewProfile } from 'store/action/profileAction';
import { advertiserProfile } from 'store/action/advertiserProfileAction';
import { Alert } from 'components/Alert/Alert';
import { useNavigate } from "react-router-dom";

const schema = yup.object().shape({
	firstName: yup
		.string()
		.required("Please enter First Name")
		.min(2, "First name must be at least 2 characters")
		.max(20, "First name must be at most 20 characters")
		.matches(/^[A-Za-z ]+$/i, "Please enter valid first name"),
	lastName: yup
		.string()
		.required("Please enter Last Name")
		.min(2, "Last name must be at least 2 characters")
		.max(20, "Last name must be at most 20 characters")
		.matches(/^[A-Za-z ]+$/i, "Please enter valid last name"),
	phone: yup
		.string()
		.required("Please enter mobile number")
		.min(7)
		.max(14),
	email: yup
		.string()
		.required("Please enter your email address")
		.matches(
			/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
			"Please use the correct email"
		),
	password: yup
		.string(),
	// .required("Please enter the password")
	// .min(8, "Password must be 8 characters long"),
	re_password: yup
		.string()
		// .required("Please enter the password")
		// .min(8, "Password must be 8 characters long")
		.oneOf([yup.ref('password'), null], 'Confirm Password must be same as New Password'),
});

const EditAdvertiserDashboard = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch()
	let attachmentName = useRef(null);
	const [showThumbnail, setShowThumbnail] = useState(true);
	const [isShowPassword, setIsShowPassword] = useState(false);
	const [img, setImg] = useState("");
	const [passwordType, setPasswordType] = useState("password");
	const [con_passwordType, setCon_passwordType] = useState("password");
	const [fileAttach, setFileAttach] = useState(null)
	const getProfileInfo = useSelector(state => state.profileView)
	const getAdvertiserInfo = useSelector(state => state.advertiserView)
	const [state, setState] = useState({
		image: "",
		imageSrc: ""
	})
	const { register, formState: { errors }, handleSubmit, reset, setValue } = useForm({
		mode: "onSubmit",
		resolver: yupResolver(schema),
	});

	const imgHandler = (e) => {
		setFileAttach(e.target.files[0])
		let src_ = URL.createObjectURL(e.target.files[0]);
		setImg(src_)
		console.log('newwwwww', e.target.files)
	}

	useEffect(() => {
		viewProfile()(dispatch)
		advertiserProfile()(dispatch)
	}, [])

	const togglePasswordHandler = () => {
		setIsShowPassword(!isShowPassword);
	}

	const inputType = isShowPassword ?
		'text' : 'password';
	const togglePasswordBtnClasses = isShowPassword ? 'toggle-btn show' : 'toggle-btn hide';

	useEffect(() => {
		if (getProfileInfo?.data?.length > 0) {
			setValue("firstName", getProfileInfo.data[0].firstName);
			setValue("lastName", getProfileInfo.data[0].lastName);
			setValue("email", getProfileInfo.data[0].email);
			setValue("phone", getProfileInfo.data[0].phone);
			setImg(getProfileInfo.data[0].image ? getProfileInfo.data[0].path + getProfileInfo.data[0].image : "");
		}
	}, [getProfileInfo])

	const onSubmit = (data, e) => {
		// e.preventDefault();
		let payload = new FormData();
		payload.append('firstName', data.firstName);
		payload.append('email', data.email);
		payload.append('phone', data.phone);
		payload.append('lastName', data.lastName);
		payload.append('image', fileAttach);
		advertiserAction(payload)(dispatch)
		// return
		setTimeout(
			() => (window.location.href = "/user/advertiser-dashboard"),
			4000
		);
	};

	const changeHandler = () => {
		navigate({ pathname: '/user/advertiser-dashboard' })
	}

	return <section className='advertiser-dashboard edit'>
		<Alert />
		<div className="container">
			<Breadcrumb>
				<Breadcrumb.Item onClick={changeHandler}>User Account</Breadcrumb.Item>
				<Breadcrumb.Item onClick={changeHandler}>User Profile</Breadcrumb.Item>
				<Breadcrumb.Item active>Edit User Profile</Breadcrumb.Item>
			</Breadcrumb>
			<h1>Edit User Profile</h1>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="card">
					<div className="row inputs-wrapper">
						<div className="col-md-6 pr-fix input-block">
							<div className="input-wrapper required">
								<label>First Name</label>
								<div className={`input-wrap ${errors.firstName ? 'has-error' : ''}`}>
									<input
										type="text"
										{...register("firstName")}
										className='form-control' />
								</div>
								{errors.firstName && (
									<span className="error">
										{errors.firstName.message}
									</span>
								)}
							</div>
						</div>
						<div className="col-md-6 pl-fix input-block">
							<div className="input-wrapper required">
								<label>Last Name</label>
								<div className={`input-wrap ${errors.lastName ? 'has-error' : ''}`}>
									<input
										type="text"
										{...register("lastName")}
										className='form-control' />
								</div>
								{errors.lastName && (
									<span className="error">
										{errors.lastName.message}
									</span>
								)}
							</div>
						</div>
						<div className="col-md-6 input-block">
							<div className="input-wrapper required">
								<label htmlFor="user-id">Mobile Number</label>
								<div className={`input-wrap ${errors.phone ? 'has-error' : ''}`}>
									<input
										type="number"
										{...register("phone")}
										className='form-control' />
								</div>
								{errors.phone && (
									<span className="error">
										{errors.phone.message}
									</span>
								)}
							</div>
						</div>
						<div className="col-md-6 input-block">
							<div className="input-wrapper required">
								<label htmlFor="user-id">Email Address</label>
								<div className={`input-wrap ${errors.email ? 'has-error' : ''}`}>
									<input
										type="text"
										{...register("email")}
										className='form-control' />
								</div>
								{errors.email && (
									<span className="error">
										{errors.email.message}
									</span>
								)}
							</div>
						</div>
						<div className="col-md-6 input-wrapper">
							<div className="input-wrapper">
								<label htmlFor="password">Password</label>
								<div className={`input-wrap password ${errors.password ? 'has-error' : ''}`}>
									<input type={passwordType}
										className='form-control'
										{...register("password")} />
									<div className="toggle-password">
										<i onClick={() => { setPasswordType(passwordType === "password" ? "text" : "password") }} className={`icon-eye ${passwordType === "password" ? "" : "open"}`}></i>
									</div>
								</div>
								{errors.password && (
									<span className="error">
										{errors.password.message}
									</span>
								)}
							</div>
						</div>
						<div className="col-md-6 input-wrapper">
							<div className="input-wrapper">
								<label htmlFor="password">Confirm Password</label>
								<div className={`input-wrap password ${errors.re_password ? 'has-error' : ''}`}>
									<input
										type={con_passwordType}
										className='form-control'
										{...register("re_password")} />
									<div className="toggle-password">
										<i onClick={() => { setCon_passwordType(con_passwordType === "password" ? "text" : "password") }} className={`icon-eye ${con_passwordType === "password" ? "" : "open"}`}></i>
									</div>
								</div>
								{errors.re_password && (
									<span className="error">
										{errors.re_password.message}
									</span>
								)}
							</div>
						</div>
					</div>
					<div className="input-wrapper required img-input-fix has-input-file">
						<label className="mb-2">Profile Image</label>
						<div className="row input-block">
							<div className="col-lg-8 input-wrapper">
								<div className="img-input-wrapper">
									<div className="img-input">
										<i className="icon-plus"></i>
										Upload
										<input
											{...register("image")}
											type="file"
											accept="image/*"
											ref={attachmentName}
											onChange={imgHandler}
										/>
									</div>
									<span className="img-info">Upload profile image.</span>
								</div>
								{
									img !== "" ?
										<div className="img-thumbnail-wrapper attachment-name image">
											<img src={img} />
										</div>
										:
										''
								}
							</div>
						</div>
						{/* {file && <ImageThumb image={file} />} */}
					</div>
				</div>
				<div className="btn-wrap">
					<button type='button' onClick={changeHandler} className='secondary-btn'>cancel</button>
					<button type='submit' className='btn'>Submit</button>
				</div>
			</form>
		</div>
	</section>
};

export default EditAdvertiserDashboard;

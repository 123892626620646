import * as actiontype from "../actionType";
import { endPoints } from "../../utils/endPoints";
import { axiosInstance, axiosInstanceWithUserToken } from "../../utils/Axios";
// import { useNotify } from "../../components/Alert/Alert";


// export const sellerrServiceAdd = (data, callback) => (dispatch) => {
//     // console.log("data---->",data)
//     let toast = useNotify();
//     let token = localStorage.getItem("user_token");
//     axiosInstanceWithUserToken
//         .post(endPoints.SELLER_ADD_SERVICE, data, {
//             headers: {
//                 Authorization: token,
//             },

//         })
//         .then(async (response) => {
//             if (response?.data?.status === "success") {
//                 toast.success(response?.data?.message)
//                 callback(true)
//             } else {
//                 toast.error(response?.data?.message);
//                 callback(false)
//             }

//         })
//         .catch((errResponse) => {
//             if (errResponse?.response?.data?.status === "error") {
//                 toast.error(errResponse?.response?.data?.message);
//             }

//         });

// };


export const labTestAdd = (payload, dispatch, toast, callback) => {
    let token = localStorage.getItem("user_token");
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken
        .post(endPoints.LAB_TEST_ADD, payload, {
            headers: {
                Authorization: token,
            },

        })
        .then(async (response) => {
            dispatch({ type: "LOADERSTOP" })
            if (response.data.status === "success") {
                callback(true)
                toast.success(response?.data?.message)
            } else {
                toast.error(response?.data?.message);
                callback(false)
            }
        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
            if (errResponse?.response?.data?.status === "error") {
                toast.error(errResponse?.response?.data?.message);
            }

        });
}

export const labPackageAdd = (payload, dispatch, toast, callback) => {
    let token = localStorage.getItem("user_token");
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken
        .post(endPoints.LAB_PACKAGE_ADD, payload, {
            headers: {
                Authorization: token,
            },

        })
        .then(async (response) => {
            dispatch({ type: "LOADERSTOP" })
            if (response.data.status === "success") {
                callback(true)
                toast.success(response?.data?.message)
            } else {
                toast.error(response?.data?.message);
                callback(false)
            }
        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
            if (errResponse?.response?.data?.status === "error") {
                toast.error(errResponse?.response?.data?.message);
            }

        });
}

export const packageCheckList = (toast, setPackageList) => {
    let token = localStorage.getItem("user_token");
    
    axiosInstanceWithUserToken
        .get(endPoints.LAB_PACKAGE_LIST,{
            headers: {
                Authorization: token,
            },

        })
        .then(async (response) => {
            if (response.data.status === "success") {
                // toast.success(response?.data?.message)
                setPackageList(response?.data)
            } else {
                toast.error(response?.data?.message);
            }
        })
        .catch((errResponse) => {
            if (errResponse?.response?.data?.status === "error") {
                toast.error(errResponse?.response?.data?.message);
            }

        });
}

export const getAddressPackages = (payload,setAddressList) => {
    let token = localStorage.getItem("user_token");
    axiosInstanceWithUserToken
        .post(endPoints.LAB_PACKAGE_ADDRESS,payload,{
            headers: {
                Authorization: token,
            },

        })
        .then(async (response) => {
            setAddressList(response?.data)
        })
        .catch((errResponse) => {
            if (errResponse?.response?.data?.status === "error") {
                // toast.error(errResponse?.response?.data?.message);
            }

        });
}

export const editLabTest = (dispatch, payload, setLogoAccreditation, callback) => {

    let token = localStorage.getItem("user_token");
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken
        .post(endPoints.EDIT_LAB_TEST, payload, {
            headers: {
                Authorization: token,
            },

        })
        .then((response) => {
            dispatch({ type: "LOADERSTOP" })
            if (response?.data?.status === "success") {
                callback(response?.data?.data)
                setLogoAccreditation({path:response?.data?.data?.[0]?.imagePath, imageName:response?.data?.data?.[0]?.logoImage})
            }

        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })



        });
}

export const editLabPackage = (dispatch, payload, setLogoAccreditation, callback) => {

    let token = localStorage.getItem("user_token");
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken
        .post(endPoints.EDIT_LAB_PACKAGE, payload, {
            headers: {
                Authorization: token,
            },

        })
        .then((response) => {
            dispatch({ type: "LOADERSTOP" })
            if (response?.data?.status === "success") {
                callback(response?.data?.data)
                setLogoAccreditation({path:response?.data?.data?.[0]?.imagePath, imageName:response?.data?.data?.[0]?.logoImage})
            }

        })
        .catch((errResponse) => {

            dispatch({ type: "LOADERSTOP" })

        });
}


// export const updateLabTest = (payloadId, payload,dispatch, toast, callback) => {

//     console.log("token--------->111")
   
//     const token = localStorage.getItem("user_token");
//     console.log("token--------->", token)
//     axiosInstance.post(endPoints.LAB_TEST_UPDATE, payloadId, payload,{
//         headers: {
//             Authorization: `Bearer ${token}`,

//         },
//     })
//         .then((response) => {
//             console.log("1111111111111111", response)
//             if (response.data.status === "success") {
//                 callback(true)
//                 toast.success(response?.data?.message)
//             } else {
//                 toast.error(response?.data?.message);
//                 callback(false)
//             }
//         })
//         .catch((errResponse) => {
//             console.log("222222222222222")
//             if (errResponse?.response?.data?.status === "error") {
//                 toast.error(errResponse?.response?.data?.message);
//             }

//         });

// }


export const updateLabTest = (payload,dispatch, toast, callback) => {

    console.log("ddddddddddd",payload)

    console.log("token--------->111")

    let token = localStorage.getItem("user_token");
    console.log("token--------->", token)

    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken
        .post(endPoints.LAB_TEST_UPDATE,payload,{
            headers: {
                Authorization: token,
            },

        } )
        .then((response) => {
            dispatch({ type: "LOADERSTOP" })
            console.log("1111111111111111", response)
            if (response?.data?.status === "success") {
                callback(response?.data?.data)
                toast.success(response?.data?.message)
            }

        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })



        });
}

export const updateLabPackage = (payload,dispatch, toast, callback) => {
    let token = localStorage.getItem("user_token");
    console.log("token--------->", token)

    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken
        .post(endPoints.LAB_PACKAGE_UPDATE,payload,{
            headers: {
                Authorization: token,
            },

        } )
        .then((response) => {
            dispatch({ type: "LOADERSTOP" })
            console.log("1111111111111111", response)
            if (response?.data?.status === "success") {
                callback(response?.data?.data)
            }

        })
        .catch((errResponse) => {

            dispatch({ type: "LOADERSTOP" })

        });
}


export const addLogo = (payload,setLogoAccreditation, logoAccreditation, dispatch) => {
    let token = localStorage.getItem("user_token");
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken
        .post(endPoints.LOGO_UPLOAD,payload,{
            headers: {
                Authorization: token,
            },

        } )
        .then((response) => {
            dispatch({ type: "LOADERSTOP" })
            if(logoAccreditation?.imageName?.length>0){
                setLogoAccreditation({path:response?.data?.path, imageName:[...logoAccreditation.imageName, ...response?.data?.imageName]})
            }
            else{
                setLogoAccreditation(response?.data)
            }
        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
        });
}
import Rating from 'react-rating';
import starEmpty from 'assets/images/icons/icon-star-empty.svg';
import starFilled from 'assets/images/icons/icon-star-2.svg';


const ReviewCard = ({ item }) => {


    const getTimeDate = (createAt) => {
        let date = new Date(createAt)
        let newDate = date.getDate() + "," + (date.getMonth() + 1) + "," + date.getFullYear() + " " + date.getHours() +
            ":" + date.getMinutes()
        // console.log('newDate', newDate)
        return newDate
        return "14.02.2021 9.41am"
    }

    return (
        <div className='review-box'>
            <div className='review-logo'>
                <div className="text-image">{item.firstName[0]}</div>
            </div>
            <div className='review-text'>
                <div className='review-details'>
                    <div className='review-info'>
                        <ul className='tests-wrap'>
                            <li>{item.title}</li>
                        </ul>
                        <div className='review-name'>{item.firstName + " " + item.lastName}</div>
                        <div className='review-timing'>{getTimeDate(item.createdAt)}</div>
                    </div>
                    <div className='review-rating'>
                        <Rating
                            readonly
                            placeholderRating={item?.rating ? item?.rating : 0}
                            emptySymbol={<img src={starEmpty} className="icon" />}
                            placeholderSymbol={<img src={starFilled} className="icon" />}
                            fullSymbol={<img src={starFilled} className="icon" />}
                        />
                    </div>
                </div>
                <p>{item.review}</p>
            </div>
        </div>
    )
}

export default ReviewCard;
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import userImg from 'assets/images/user-img.png';
import { conversationSeller } from 'store/action/sellerConvrstnAction';
import { sendMessageSeller } from 'store/action/sellerReadMessagesAction';
import { allMessagesSeller } from 'store/action/sellerReadMessagesAction';

const SellerDialogue = () => {
	let params = useParams()
	let dispatch = useDispatch()
	let navigate = useNavigate();
	const [showUserList, setShowUserList] = useState(false);
	const [searchVal, setSearchVal] = useState("");
	const getConvrstnInfoSeller = useSelector((state) => state.sellerToUserConversation)

	const toggleUserListHandler = () => {
		setShowUserList(!showUserList);
	}

	useEffect(() => {
		console.log('ddsdfdsfdsf', params)
		let payload = {
			conversationId: params.conid,

		}
		conversationSeller(payload)(dispatch);
	}, [])

	const handlerSendMessage = (e) => {
		e.preventDefault();
		let payload = {
			message: searchVal,
			conversationId: params.conid
		}
		console.log('searchVal', searchVal, payload)
		if (searchVal !== '') {
			sendMessageSeller(payload)(dispatch)
			setSearchVal('')
			setTimeout(() => allMessagesSeller({ conversationId: params.conid })(dispatch), 200)
			setTimeout(() => conversationSeller({ conversationId: params.conid })(dispatch), 300)
		}
		else {
			return
		}
	}

	const userListClasses = showUserList ? 'users-list active' : 'users-list'

	console.log("seller messages", getConvrstnInfoSeller)

	return (
		<section className='seller-dialogue'>
			<div className="container">
				<div className="card dialogue-wrap">
					<div className="dialogue-left">
						<div className="users-header">
							<button type="button" onClick={toggleUserListHandler}>All Chats</button>
							<button type="button" onClick={toggleUserListHandler}>Unread Messages</button>
						</div>
						<div className="users-wrap custom-scroll">
							<ul className={userListClasses}>
								<li><Link to='/'>Paul Baker</Link></li>
								<li><Link to='/' className='active'>Jeremy Chandler</Link></li>
								<li><Link to='/'>Brian Holmes</Link></li>
								<li><Link to='/'>Clara Lloyd</Link></li>
								<li><Link to='/'>Hope Mills</Link></li>
								<li><Link to='/'>Robert Nicholson</Link></li>
								<li><Link to='/'>Jeremy Chandler</Link></li>
								<li><Link to='/'>Paul Baker</Link></li>
								<li><Link to='/'>Brian Holmes</Link></li>
								<li><Link to='/'>Clara Lloyd</Link></li>
								<li><Link to='/'>Hope Mills</Link></li>
								<li><Link to='/'>Robert Nicholson</Link></li>
								<li><Link to='/'>Jeremy Chandler</Link></li>
								<li><Link to='/'>Paul Baker</Link></li>
							</ul>
						</div>
					</div>
					<div className="dialogue-right">
						<div className="user-header">
							<div className="back-wrap">
								<Link to='/seller/company/seller-messages' className='back-link'>Back</Link>
							</div>
							<div className="name-wrap">
								<span className="name">Jeremy Chandler</span>
								<span className="status">online</span>
							</div>
							<div className="more-wrap">
								<button>
									<i className="icon-dots"></i>
								</button>
							</div>
						</div>
						<div className="msg-box custom-scroll">
							<div className="msg-tags">
								<div className="tag-wrap">Entertainment</div>
								<div className="tag-wrap">Photographers</div>
								<div className="tag-wrap">Photo Session Lorem ipsum dolor sit amet</div>
							</div>
							{
								getConvrstnInfoSeller?.map(item =>
									<div className="msg-wrap">
										<div className="user-img no-img">
											{item?.userData?.[0]?.firstName[0]}
										</div>
										<div className="msg-details">
											<div className="user-name">
												<Link to='#'>
													{item?.userData?.[0]?.firstName}
												</Link>
												{/* <div className="link-wrap">
													<Link to='/' className='page-link'>Go to Service Listing Page</Link>
												</div> */}
											</div>
											<p>{item.message}</p>
										</div>
									</div>
								)
							}
						</div>
						<div className="msg-input">
							<form>
								<div className="form-wrap">
									<div className="clip-wrap">
										<button type='button'>
											<i className="icon-clip"></i>
										</button>
									</div>
									<div className="input-wrap">
										<textarea
											placeholder='Write a message...'
											type='text'
											onChange={(e) => setSearchVal(e.target.value)}
											value={searchVal}										>
										</textarea>
									</div>
									<div className="send-wrap">
										<button type='submit' onClick={handlerSendMessage}>
											<i className="icon-send"></i>
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default SellerDialogue

import React from 'react';
import { Breadcrumb, Tabs, Tab } from 'react-bootstrap';
import TrackCurrent from './Tabs/TrackCurrent'

const SellerOrders = () => {
	return (
		<section className='seller-orders'>
			<div className="container">
				<Breadcrumb>
					<Breadcrumb.Item href="#">Lab Account</Breadcrumb.Item>
					<Breadcrumb.Item active>Lab Orders</Breadcrumb.Item>
				</Breadcrumb>
				<Tabs defaultActiveKey="Track Current Orders & Mark as Complete" id="uncontrolled-tab-example" className="mb-3">
					<Tab eventKey="Track Current Orders & Mark as Complete" title="Track Current Orders & Mark as Complete">
						<TrackCurrent />
					</Tab>
					<Tab eventKey="Completed Orders" title="Completed Orders">
						tab2
					</Tab>
					<Tab eventKey="Canceled Orders" title="Canceled Orders">
						tab3
					</Tab>
					<Tab eventKey="Disputed Orders" title="Disputed Orders">
						tab4
					</Tab>
					<Tab eventKey="Statistics" title="Statistics">
						tab5
					</Tab>
				</Tabs>
			</div>
		</section>
	)
}

export default SellerOrders
import { useState } from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import service1 from 'assets/images/service-1.jpg';
import ModalImage from 'components/ModalImage';


const ReviewCard = ({ serviceDetail }) => {

    console.log('serviceDetail', serviceDetail)

    const [showModel, setShowModel] = useState(false);
    const [modelData, setModelData] = useState({});
    const [index, setIndex] = useState(0);

    const onCloseModal = () => setShowModel(false);

    let settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };


    const openModel = (val, i) => {
        setShowModel(true);
        setModelData(val)
        setIndex(i)
    }

    const handlerNext = (index, allData) => {
        if (allData.length - 1 > index) {
            let newIndex = index + 1;
            setModelData(allData[newIndex])
            setIndex(newIndex)
        }
    }

    const handlerPrev = (index, allData) => {
        if (index !== 0) {
            let newIndex = index - 1;
            setModelData(allData[newIndex])
            setIndex(newIndex)
        }
    }


    return (
        <div className='tab-wrapper'>
            {
                showModel ?
                    <ModalImage
                        modelData={modelData}
                        data={serviceDetail.data}
                        handlerNext={handlerNext}
                        handlerPrev={handlerPrev}
                        allData={serviceDetail?.data?.gallery}
                        index={index}
                        closeModel={onCloseModal}
                        isDelete={false}
                    />
                    : null
            }
            <div className='portfolio-sliders entertainment-tab'>
                <Slider {...settings}>
                    {
                        serviceDetail?.data?.gallery.map((item, i) =>
                            <div className='slide'>
                                <div className="img-wrap contains-video">
                                    <Link to='#' onClick={() => openModel(item, i)}>
                                        {
                                            item.fileType.split("/")[0] === "image" ?
                                                item.media && item.media !== "" ?
                                                    <img src={serviceDetail.data.path + item.media} alt="service" />
                                                    : <img src={service1} alt="service" />
                                                : <video src={serviceDetail.data.path + item.media} alt="service" />
                                        }

                                        <div className="video-wrapper">
                                        </div>
                                    </Link>
                                </div>
                                <div className="service-info">
                                    <p>{item.description}</p>
                                </div>
                            </div>
                        )
                    }
                </Slider>
                <div className='view-btn'>
                    <Link to='/' className='view-more'>View More</Link>
                </div>
            </div>
        </div>
    )
}

export default ReviewCard;
import React, { useEffect, useState } from "react";
import ProfileImage from "assets/images/edit-card-image.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  removeLabPackage,
  removeLabTest,
  removeSellerServiceList,
} from "store/action/serviceListAction";

const ServiceCardPackage = ({ item }) => {
  console.log("item9999999---->", item);

  const dispatch = useDispatch();
  let history = useNavigate();
  const navigate = useNavigate();

  const [show, setShow] = useState(false);

  const removeCard = (serviceId) => {
    const payload = {
      packageId: serviceId, // _id from the list_lab_test API
    };

    // console.log("serviceId",serviceId)

    if (window.confirm("Are you sure you want to delete this package ?")) {
      setShow(false);
      removeLabPackage(payload, dispatch);
    } else {
      setShow(false);
    }
  };

  useEffect(() => {}, [item]);

  const goEditPage = (id) => {
    // history(`/lab/company/service-list/add?id=${id}`)
    navigate(`/lab/package-list/edit/${id}`);
  };
  const goEditPageLogo = (id) => {
    // history(`/lab/company/service-list/add?id=${id}`)
    navigate(`/lab/package-list/edit/${id}?to=logo`);
  };

  const getDaysOpen = (item) => {
    console.log("item", item);
    if (item.daysOpened.length === 1) {
      return item.daysOpened[0].day;
    } else {
      return (
        item.daysOpened[0].day +
        "-" +
        item.daysOpened[item?.daysOpened?.length - 1].day
      );
    }
  };

  const getDaysClose = (item) => {
    let dayOptions = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    let closedDays = dayOptions.filter(
      (ditem, i) => i > item.daysOpened.length - 1
    );
    if (closedDays.length === 1) {
      return closedDays[0];
    } else {
      return closedDays[0] + "-" + closedDays[closedDays.length - 1];
    }
  };

  // console.log(item.addressData?.[0]?.daysOpened.length)

  return (
    <div className="edit-card">
      <div className="card-image">
        {item.packageCoverImage ? (
          <img
            src={
              item.imagePath +
              item.packageCoverImage
                ?.split("")
                .map((char) => (char === " " ? "%20" : char))
                .join("")
            }
            alt="profile-image"
          />
        ) : (
          <img src={ProfileImage} alt="profile-image" />
        )}
      </div>
      <div className="card-data">
        <div className="service-information flex-grow-1 service-info-custom">
          <div className="service-detail">{item.package_title}</div>
          {item?.testIds?.length > 0 ? (
            <div className="tests-number">
              {`${item.testIds.length} test${item.testIds.length>1?"s":''} included `}
            </div>
          ) : null}
          <div className="service-details">
            <div className="location">
              {item?.addressData?.countryName && (
                <div className="main-location">
                  {item?.addressData?.countryName},{" "}
                  {item?.addressData?.stateName}, {item?.addressData?.cityName}
                </div>
              )}
              {item?.addressData?.addressLine1 && (
                <div className="inner-location">
                  {item?.addressData?.addressLine1},{" "}
                  {item?.addressData?.addressLine2}
                </div>
              )}
            </div>
            {item?.addressData?.daysOpened?.[0].day ? (
              <div className="service-time">
                <div className="time-info">
                  <div className="days">{getDaysOpen(item?.addressData)}</div>
                  <div className="day-time">
                    {item?.addressData?.daysOpened?.[0]?.from} -{" "}
                    {
                      item.addressData?.daysOpened?.[
                        item.addressData?.daysOpened?.length - 1
                      ].to
                    }
                  </div>
                </div>
                <div className="time-info">
                  <div className="days">{getDaysClose(item?.addressData)}</div>
                  <div className="day-time">Closed</div>
                </div>
              </div>
            ) : null}
          </div>
          {/* <div className='inner-location'>{item.description}</div> */}

          <div className="service-price">
            {item.package_price && (
              <div className="off-price"><s>₹{item.package_price}</s></div>
            )}
            {item.package_discountedPrice ? (
              <div className="final-pricing-red">
                ₹{item.package_discountedPrice}
              </div>
            ) : null}

          </div>
        </div>
        <div className="menu-wrap with-logo">
          <button
            className="menu-btn"
            onClick={() => {
              setShow(!show);
            }}
          >
            <span className="btn-dot"></span>
            <span className="btn-dot"></span>
            <span className="btn-dot"></span>
          </button>
          {show ? (
            <div className="menu">
              <ul className="menu-items">
                <li>
                  <button
                    onClick={() => goEditPage(item._id)}
                    className="menu-option"
                  >
                    Edit
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => removeCard(item._id)}
                    className="menu-option delete"
                  >
                    Delete
                  </button>
                </li>
              </ul>
            </div>
          ) : null}
          <div className="icons-listing">
            {item?.logoImage?.map((ele, index) => {
              return index < 2 ? (
                <div className="logo-image" key={ele}>
                  <img src={item?.imagePath + ele} alt="logo" />
                </div>
              ) : null;
            })}
            {item?.logoImage?.length > 2 && (
              <h5 onClick={() => goEditPageLogo(item._id)} >+{item?.logoImage?.length - 2}</h5>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceCardPackage;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SellerProfile from "assets/images/edit-card-image.png";
import { sellerAddData, editSellerInfo } from "store/action/sellerInformationAction";
import Select from "react-select";
import { getAddressList } from 'store/action/sellerServiceAddAction';
import AddressList from "./AddressList"
import { deleteNewAddress } from "store/action/addAddressAction";
import AddAddress from "components/Seller/AddAddress";
import { countryList } from "store/action/countryList";
import { stateList } from "store/action/stateList";
import { cityList } from "store/action/cityListAction";
import { useParams, useSearchParams } from "react-router-dom";
import { getSellerDetail, labDetailsAction } from "store/action/sellerProfileAction";
import ManageDatePopup from "./ManageDatePopup";



const SellerInfoEdit = ({ item, catName }) => {
    const [searchParams, setSearchParams] = useSearchParams()

   

    

    const search = searchParams.get('to')
    useEffect(() => {
        if (search) {
            setTimeout(() => {
                let ele = document.getElementById("addresses")
                ele?.scrollIntoView()
            }, 1000)
        }
    }, [search])
    const { cat } = useParams();

    let dispatch = useDispatch();
    const getProfileInfo = useSelector(state => state.profileView)
    const getCountry = useSelector((state) => state.countryData);
    const getState = useSelector((state) => state.stateData);
    const getCity = useSelector((state) => state.cityData);
    const CategoryData = useSelector((state) => state.finalCategoryData);


    const [state, setState] = useState({});
    // console.log("state--->", state)
    const [addressList, setAddressList] = useState([]);
    const [showAddAddress, setShowAddAddress] = useState(false);
    const [showManageData, setShowManageData] = useState(false)

    const [addressId, setAddressId] = useState(null);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(5);
    const [countryId, setCountryId] = useState("");
    const [stateId, setStateId] = useState("");

    const [countryVal, setCountryVal] = useState("");
    const [stateVal, setStateVal] = useState("");
    const [cityVal, setCityVal] = useState("");
    const [searchVal, setSearchVal] = useState("");
    const [labData, setLabData] = useState()

    const [daysListShow, setDaysListShow] = useState(false)


    const indexOfLastTodo = page * limit;
    const indexOfFirstTodo = indexOfLastTodo - limit;

    useEffect(() => {
        countryList()(dispatch);
        sellerAddData({ sellerInformationId: item.sellerInformationId }, callback => {
            setState(callback[0])
        })(dispatch)
        getAddressList({ sellerInformationId: item.sellerInformationId }, callback => {
            setAddressList(callback)
        })(dispatch)
    }, [])


    const renderUi = (desc) => {
        return <div dangerouslySetInnerHTML={{ __html: desc }} />
    }


    const deleteAddressHander = (id) => {
        deleteNewAddress({ addressId: id }, callback => {
            let newState = [...addressList];
            newState = newState.filter(item => item._id !== id);
            setAddressList(newState);
        })(dispatch)
    }

    const closeAddressModel = (status) => {
        setShowAddAddress(status);
        getAddressList({ sellerInformationId: item.sellerInformationId }, callback => {
            setAddressList(callback)
        })(dispatch)
    }

    const closeManageDateModel = (status) => {
        setShowManageData(status)
    }

    const clickPagination = (index) => {
        setPage(index);
    }

    const clickPrev = (index) => {
        setPage(index - 1);
    }

    const clickNext = (index) => {
        setPage(index + 1);
    }

    const setAddressIdHandler = (id) => {
        setAddressId(id);
        setTimeout(() => setShowAddAddress(true), 500);
    }


    const countryChange = (val) => {
        setCountryVal(val)
        setStateVal("")
        setSearchVal("");
        setCityVal("");
        setCountryId(val.value)
        stateList({ countryId: val.value })(dispatch);
        getAddressList({ sellerInformationId: item.sellerInformationId, countryId: val.value }, callback => {
            setAddressList(callback)
        })(dispatch)
    }

    const stateChange = (val) => {
        setStateVal(val)
        setStateId(val.value)
        setCityVal("");
        cityList({ countryId: countryId, stateId: val.value })(dispatch);
        getAddressList({ sellerInformationId: item.sellerInformationId, countryId: "61c2fb83dc7c0d455ba5e68d", stateId: val.value }, callback => {
            setAddressList(callback)
        })(dispatch)
    }

    useEffect(() => {
        stateList({ countryId: "61c2fb83dc7c0d455ba5e68d" }, dispatch);
    }, [])

    const cityChange = val => {
        setCityVal(val);
        getAddressList({ sellerInformationId: item.sellerInformationId, countryId: countryId, stateId: stateId, cityId: val.value }, callback => {
            setAddressList(callback)
        })(dispatch)
    }

    const handlerInputChange = (e) => {
        e.preventDefault();
        setCountryVal("")
        setStateVal("")
        setCityVal("");
        setCountryId("")

        getAddressList({ sellerInformationId: item.sellerInformationId, address: searchVal }, callback => {
            setAddressList(callback)
        })(dispatch)
    }

    const getCountryData = () => {
        let countrydata = getCountry?.map(item => ({ value: item._id, label: item.name }));
        countrydata.unshift({ value: "", label: "Please select country" });
        return countrydata;
    }

    const getStateData = () => {
        let countrydata = getState?.map(item => ({ value: item._id, label: item.name }));
        countrydata.unshift({ value: "", label: "Please select state" });
        return countrydata;
    }

    const getCityData = () => {
        let countrydata = getCity?.map(item => ({ value: item._id, label: item.name }));
        countrydata.unshift({ value: "", label: "Please select city" });
        return countrydata;
    }

    const uniqueCountry = () => {
        let c = state?.sellerAddressData?.map(item => item.countryName)
        return [...new Set(c)]
    }

    useEffect(() => {
        // getSellerDetail(catName)(dispatch)

        console.log("sandeep0000",item)
        
        let payload={
            sellerId:item?.sellerInformationId,
            categoryId:item?.categoryId
        }

        labDetailsAction(payload, dispatch, (callback) => {
            console.log("callback---", callback)
        })

    }, [item?.sellerInformationId])

    const setManageDateHandler = (id) => {
        setAddressId(id);
        setTimeout(() => setShowManageData(true), 500);
    }

    return (
        <>

            <div className="description-wrap">
                <div className="image-block">
                    <div className="image-wrap">
                        {
                            state?.image ?
                                <img src={item?.imagePath + item?.image} alt="profile-image" />
                                : <img src={SellerProfile} alt="profile-image" />
                        }

                    </div>
                </div>
                <div className="seller-details">
                    <h3>{getProfileInfo?.data?.[0]?.businessName}</h3>
                    <div className="details-wrap">
                        <div className="detail-list">
                            {/* <h3>Category:</h3> */}
                            <ul className="details-values">
                                <li>
                                    {
                                        state?.subcategoryData?.map(item => <div className="detail-text">{item}</div>)
                                    }
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="details-wrap">
                        {/* <div className="detail-list">
                            <h3>Languages Spoken:</h3>
                            <ul className="details-values">
                                {
                                    state?.languages?.map((item, i) =>
                                        <li>
                                            <div className="detail-text">{state?.languageName[i]}</div>
                                            <div className="detail-text">{item.languageType}</div>
                                        </li>
                                    )
                                }
                            </ul>
                        </div> */}

                        <div className="detail-list">
                            <h3>Operating Countries:</h3>
                            <ul className="details-values">
                                {
                                    uniqueCountry()?.map(item => {
                                        console.log("country", item)
                                        return (
                                            <li>
                                                <div className="detail-text">{item}</div>
                                            </li>

                                        )
                                    }
                                    )
                                }
                            </ul>
                        </div>

                    </div>
                    <div className="details-wrap">
                        <div className="detail-list">
                            <h3>Description:</h3>
                            {
                                state?.description && <p>{renderUi(state?.description)}</p>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="slider-heading" id="addresses">
                <h2>Addresses</h2>
            </div>
            <div className="services-inputs">
                <form>
                    <div className="form-wrap">
                        <div className="inputs-wrap">
                            <div className="input-wrap">
                                <input
                                    type="text"
                                    className="input"
                                    placeholder="Search a specific address"
                                    onChange={(e) => setSearchVal(e.target.value)}
                                    value={searchVal}
                                />
                                <button className="search-btn" onClick={handlerInputChange}>
                                    <i className="icon-search"></i>
                                </button>
                            </div>
                        </div>
                        <div className="selects-wrap">
                            <div className="select-wrap bigger">
                                {/* <Select
                                    onChange={countryChange}
                                    classNamePrefix="react-select"
                                    className="react-select-container"
                                    value={countryVal}
                                    options={getCountryData()}
                                /> */}
                            </div>
                            <div className="select-wrap selecet-box-wrapper">
                                <Select
                                    onChange={stateChange}
                                    classNamePrefix="react-select"
                                    className="react-select-container"
                                    value={stateVal}
                                    options={getStateData()}
                                    placeholder="Select State"
                                />
                            </div>
                            <div className="select-wrap disabled selecet-box-wrapper">
                                <Select
                                    onChange={cityChange}
                                    value={cityVal}
                                    classNamePrefix="react-select"
                                    className="react-select-container"
                                    options={getCityData()}
                                    placeholder="Select City"
                                />

                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div className="address-table">
                <div className="table-wrap">
                   
                    {
                        addressList?.slice(indexOfFirstTodo, indexOfLastTodo).map(item =>
                            <AddressList item={item}
                                deleteAddressHander={deleteAddressHander}
                                setAddressId={setAddressIdHandler}
                                setManageDateHandler={setManageDateHandler}
                                setDaysListShow={setDaysListShow}
                                daysListShow={daysListShow} />
                        )
                    }
                    {
                        addressList.length === 0 ?
                            <div>No address found</div>
                            : null
                    }

                </div>
                {
                    addressList.length > 0 &&
                    <nav
                        className="pagination-wrap"
                        aria-label="Page navigation example"
                    >
                        <ul className="pagination">
                            <li className={page === 1 ? "page-item previous disabled" : "page-item previous"} onClick={() => clickPrev(page)}>
                                <a
                                    className="page-link"
                                    href="javascript:void(0)"
                                    tabIndex="-1"

                                >
                                    <i className="icon-arrow"></i>
                                </a>
                            </li>
                            {
                                [...Array(Math.ceil(addressList.length / 5))].map((item, i) =>
                                    <li className={i + 1 === page ? "page-item active" : "page-item"} aria-current="page">
                                        <a className="page-link" href="javascript:void(0)" onClick={() => clickPagination(i + 1)}>
                                            {i + 1}
                                        </a>
                                    </li>
                                )
                            }


                            <li className={page === Math.ceil(addressList.length / 5) ? "page-item  disabled" : "page-item "}>
                                <a className="page-link" href="javascript:void(0)" onClick={() => clickNext(page)}>
                                    <i className="icon-arrow"></i>
                                </a>
                            </li>
                        </ul>
                    </nav>
                }
            </div>
            {showAddAddress && <AddAddress
                sellerInformationId={item.sellerInformationId}
                onCloseModal={closeAddressModel}
                addressId={addressId}

            />}

            {showManageData && <ManageDatePopup
                sellerInformationId={item.sellerInformationId}
                onCloseModal={closeManageDateModel}
                addressId={addressId}
            />}
        </>
    )
}

export default SellerInfoEdit
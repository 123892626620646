import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { getCategory } from 'store/action/categoryAction';

const Categories = () => {

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const categoryUser = useSelector(state => state.category);

	useEffect(() => {
		getCategory()(dispatch)
	}, [])

	const handleCategory = () => {
		// setShowMenu(!showMenu)
		getCategory()(dispatch)
	}

	console.log("catgoryUser", categoryUser)

	return <div className='categories'>
		<ul>
			{categoryUser?.map(item =>
				<li key={item._id}>
					<a onClick={handleCategory} href={`/category/${item._id}/${item.name}`}>{item.name}</a>
				</li>

			)}
		</ul>
	</div>;
};

export default Categories
	;

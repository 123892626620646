import React, { useState, useRef, useEffect } from 'react';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from "react-router-dom";
import { categoryList } from 'store/action/categoryListAction';
import { addNewCategory, removeCategoryAction } from 'store/action/addCategoryAction';
import { categoryFinalList } from "store/action/categoryListAction";
import { useNotify } from 'components/Alert/Alert';
import { ToastContainer } from 'react-toastify';
// import addCategoryData from 'store/reducer/newCategory.reducer';

const AddCategory = (props) => {
	const dispatch = useDispatch()
	let history = useNavigate()
	let naviagte = useNavigate()
	const param = useParams()
	const toast = useNotify()
	// const addCategoryNew = useSelector(state => state.addCategoryData)
	const getCategory = useSelector(state => state.categoryData)
	const finalCategory = useSelector((state) => state.finalCategoryData);
	console.log("finalCategory", finalCategory)
	const [dropDownOpt, setDropDownOpt] = useState([]);
	const [id, setId] = useState('');
	const [name, setName] = useState('');
	const [handleError, setHandleError] = useState(false);
	const [errMsg, setErrMsg] = useState('');
	const [categoryName, setCategoryName] = useState([]);
	console.log("categoryName", categoryName)
	const [categoryNameMain, setCategoryNameMain] = useState([]);
	// console.log("categoryNameMain", categoryNameMain, categoryName)
	// console.log("sandeep222", categoryName)
	// console.log('sdkfjsd', finalCategory)

	// const [removeCat, setRemoveCat] = useState([])

	// const [newCatIds, setNewCatIds] = useState([])

	const options = [
		{ value: 'entertainment', label: 'entertainment' },
		{ value: 'wedding Service', label: 'wedding service' },
	]

	useEffect(() => {
		let a = finalCategory?.map(item => ({ value: item.categoryId, label: item.categoryName }))
		setCategoryName(a)
		setCategoryNameMain(a)
	}, [getCategory, finalCategory])

	useEffect(() => {
		categoryList(callback => {
		})(dispatch)
		categoryFinalList(callback => {

		})(dispatch)

		// console.log('check Category',getCategory)
	}, [])

	const closeModalHandler = () => {
		props.onCloseModal(false);
		categoryFinalList(callback => {

		})(dispatch)

	}

	// console.log("sandeep5555", removeCat)

	const handleCategory = (val) => {


		// setRemoveCat(val)

		// if (val.length > 5) {
		// 	setErrMsg("You have to choose only 5 categories");
		// 	setHandleError(true)
		// }

		// else {
		setCategoryName(val)
		if (val.length === 0) {

			setErrMsg("Please Choose Category")
			setHandleError(true)
		} else {
			setErrMsg('')
			setHandleError(false)
		}
		// }

	}



	// useEffect(() => {
	// 	let newArray = [];

	// 	finalCategory.forEach((category) => {
	// 		const categoryId = category.categoryId;
	// 		const isDifferent = !removeCat.some((removeCategory) => removeCategory.value === categoryId);
	// 		if (isDifferent) {
	// 			console.log("eeeeeeeeeeeeeee", categoryId)
	// 			newArray.push(categoryId);
	// 		}
	// 	});

	// 	setNewCatIds(newArray);

	// }, [removeCat])

	// console.log("finalCategory", newCatIds)



	const submitHandler = (e) => {

		e.preventDefault();
		if (categoryName.length > 0) {

			let isParamsValue = categoryName.filter(item => item.label === param.cid)


			let ids = categoryName.map(item => (item.value));

			// console.log("ids", ids)



			let x = []
			categoryName.forEach((item) => {
				x.push(item.value)
			})
			let y = []
			categoryNameMain.forEach((item) => {
				y.push(item.value)
			})
			let z = []
			y.forEach((item) => {
				if (!x.includes(item)) {
					z.push(item)
				}
			})
			console.log("yyyyyy", x, y, z)
			// categoryName.forEach((item)=>{
			// 	categoryName
			// })

			addNewCategory({ categoryIds: ids, remove_cat_ids: z }, toast)(dispatch)

			// addNewCategory({ categoryIds: ids, remove_cat_ids: newCatIds })(dispatch)


			setTimeout(() => {
				closeModalHandler();
				if (isParamsValue.length === 0) {
					history('/lab-information-edit/' + categoryName[0].label)
					// 	// window.location.reload();
					// 	naviagte('/lab-information?to=availability')

				}
			}, 100)
		} else {
			setErrMsg("Please Choose Category")
			setHandleError(true)
		}


		// removeCategoryAction(newCatIds)(dispatch)
	}

	return (
		<div className='modal-backdrop'>

			<ToastContainer
				position="top-right"
				autoClose={3000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
			/>
			<div className="add-category card">
				<form onSubmit={submitHandler}>
					<h4>Add New Categories</h4>
					<div className="inputs-wrapper category-selector-box">
						<div className="input-wrapper required">
							<label className="input-label">Categories</label>
							<div className="input-wrap">
								<div className="select-wrap multi-select">
									<Select
										classNamePrefix="react-select"
										className='react-select-container form-control'
										options={getCategory?.map(item => ({ value: item._id, label: item.name }))}
										closeMenuOnSelect={false}
										onChange={handleCategory}
										value={categoryName}

										// openOnFocus={true}
										isMulti
									/>
									{handleError ? <p className='error'>{errMsg}</p> : ''}
								</div>
							</div>
						</div>
					</div>
					<div className="btn-wrap">
						<input className="secondary-btn" type="reset" onClick={closeModalHandler} value="cancel" />
						<input className="btn" type="submit" value="submit" />
					</div>
				</form>
			</div>
		</div>
	)
}

export default AddCategory

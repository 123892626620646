import * as actiontype from "../actionType";
import { endPoints } from "../../utils/endPoints";
import { axiosInstanceWithUserToken } from "../../utils/Axios";
import { useNotify } from "../../components/Alert/Alert";





export const getSellerDetail = (data, callback) => (dispatch) => {
    let token = localStorage.getItem("user_token");
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken.get(endPoints.GET_SELLER_DETAIL + "?sellerId=" + data.sid + "&categoryId=" + data.cid, {
        headers: {
            Authorization: token,
        },
    })
        .then(response => {
            dispatch({ type: "LOADERSTOP" })
            dispatch({
                type: actiontype.SET_SELLER_DETAILS,
                payload: response.data
            })
        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
            console.log("success respoerrResponsese", errResponse)
        });
};








export const getServices = (data) => (dispatch) => {
    let token = localStorage.getItem("user_token");
    axiosInstanceWithUserToken.post(endPoints.GET_SERVICE_OF_SELLER, data, {
        headers: {
            Authorization: token,
        },
    })
        .then(response => {
            if (response.data.status === "success") {
                dispatch({
                    type: actiontype.SET_SERVICE_OF_SELLER,
                    payload: response.data
                })
            } else {
                dispatch({
                    type: actiontype.SET_SERVICE_OF_SELLER,
                    payload: {}
                })
            }
        })
        .catch((errResponse) => {

            console.log("success respoerrResponsese", errResponse)
            dispatch({
                type: actiontype.SET_SERVICE_OF_SELLER,
                payload: {}
            })
        });
};




export const getSellerReviewedDetail = (data, callback) => (dispatch) => {
    let token = localStorage.getItem("user_token");
    axiosInstanceWithUserToken.post(endPoints.GET_REVIEWLIST_SELLER, { sellerId: data.sid, pageno: data.pageno, Limit: data.Limit }, {
        headers: {
            Authorization: token,
        },
    })
        .then(response => {
            dispatch({
                type: actiontype.SET_SELLER_REVIEW_DETAILS,
                payload: response.data
            })
        })
        .catch((errResponse) => {
            console.log("success respoerrResponsese", errResponse)
        });
};


export const labDetailsAction = (payload, dispatch, callback) => {

console.log("pauload1111",payload)

    let token = localStorage.getItem("user_token");
    console.log("rokennnnnnnn", token)
    axiosInstanceWithUserToken.post(endPoints.LAB_DETAILS, payload, {
        headers: {
            Authorization: `${localStorage.getItem("user_token")}`,
        },
    })
        .then(response => {
            console.log("sandeep5555", response)
            callback(response.data)
            dispatch({
                type: actiontype.SET_SELLER_DETAILS,
                payload: response.data
            })
        })
        .catch((errResponse) => {
            console.log("sandeep5555", errResponse?.response)
            console.log("success respoerrResponsese", errResponse)
        });
};
import * as actiontype from "../actionType";
import { endPoints } from "../../utils/endPoints";
import { axiosInstanceWithUserToken } from "../../utils/Axios";
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

export const getServiceList = (cid, scid, name) => (dispatch) => {

    let token = localStorage.getItem("user_token");
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken.get(endPoints.GET_SEVICE_LIST + "?categoryId=" + cid + "&subcategoryId=" + scid, {
        headers: {
            Authorization: token,
        },
    })
        .then(async (response) => {
            dispatch({ type: "LOADERSTOP" })
            // if (response?.data?.status === "success") {
            //     window.location.href = "/page-not-found";
            // }
            dispatch({
                type: actiontype.SERVICE_LIST,
                payload: response.data.data
            })
        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
            // toast.error("error")
            console.log("success respoerrResponsese", errResponse)
        });
};


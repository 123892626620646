export const BECOME_SELLER = "BECOME_SELLER";
export const SET_COUNTRY_DATA = "SET_COUNTRY_DATA";
export const SET_STATE_DATA = "SET_STATE_DATA";
export const SET_CITY_DATA = "SET_CITY_DATA";
export const SET_COUNTRY_BANK_DATA = "SET_COUNTRY_DATA";
export const SET_STATE_BANK_DATA = "SET_STATE_DATA";
export const SET_CITY_BANK_DATA = "SET_CITY_DATA";
export const LOGIN_USER = "LOGIN_USER";
export const SET_CATEGORY = "SET_CATEGORY";
export const SET_SUB_CATEGORY = "SET_SUB_CATEGORY";
export const PROFILE_VIEW = "PROFILE_VIEW";
export const EDIT_PROFILE = "EDIT_PROFILE";
export const METHOD_BILLING = "METHOD_BILLING";
export const SELLER_SERVICE_ADD = "SELLER_SERVICE_ADD";
export const SELLER_CATEGORY_GET = "SELLER_CATEGORY_GET";
export const SELLER_GET_SUBCATEGORY = "SELLER_SUBCATEGORY_GET";
export const ADVERTISER_PROFILE_VIEW = "ADVERTISER_PROFILE_VIEW";
export const ADVERTISER_EDIT = "ADVERTISER_EDIT";
export const ADVERTISER_ADDDING_BANNER = "ADVERTISER_ADDDING_BANNER";
export const SELLER_UPLOAD_MEDIA = "SELLER_UPLOAD_MEDIA";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const CATEGORY_NEW_LIST = "CATEGORY_NEW_LIST";
export const LANGUAGES = "LANGUAGES";
export const ADD_ADDRESS = "ADD_ADDRESS";
export const SELLER_INFO = "SELLER_INFO ";
export const FORGET_PASS = "FORGET_PASS";
export const RESET_PAS = "RESET_PAS";
export const SET_OPTIONAL_INFORMATION = "SET_OPTIONAL_INFORMATION";
export const BILLING_DATA = "BILLING_DATA";
export const SELLER_SERVICE_LIST = "SELLER_SERVICE_LIST";
export const REMOVE_SELLER_SERVICE_LIST = "REMOVE_SELLER_SERVICE_LIST";
export const SET_FAQ = "SET_FAQ";
export const LIST_BANNER = "LIST_BANNER";
export const USER_PROFILE = "USER_PROFILE";
export const HOME_DATA = "HOME_DATA";
export const GET_CATEGORY = "GET_CATEGORY";
export const HOME_SUBS = "HOME_SUBS";
export const SERVICE_LIST = "SERVICE_LIST";
export const SET_WISHLIST = "SET_WISHLIST";
export const SET_SERVICE_DETAILS = "SET_SERVICE_DETAILS";
export const GET_OTHER_SERVICE_LIST = "GET_OTHER_SERVICE_LIST";
export const GET_REVIEW_LIST = "GET_REVIEW_LIST";
export const ADD_REVIEW_LIST = "ADD_REVIEW_LIST";
export const NOTIFICATION_USER = "NOTIFICATION_USER";
export const NOTIFICATION_SELLER = "NOTIFICATION_SELLER";
export const READ_NOTIFY_SINGLE = "READ_NOTIFY_SINGLE";
export const REMOVE_UNREAD = "REMOVE_UNREAD";
export const CUSTOMER = "CUSTOMER";
export const SET_SERVICE_OF_SELLER = "SET_SERVICE_OF_SELLER"

export const ADD_WISHLIST_SERVICE_DETAIL = "ADD_WISHLIST_SERVICE_DETAIL"
export const WISHLIST_OTHER_SERVICE = "WISHLIST_OTHER_SERVICE"
export const WISHLIST_RECOMMENDED = "WISHLIST_RECOMMENDED"
export const GET_RECOMMENDED_LIST = "GET_RECOMMENDED_LIST";
export const ADD_TO_CART = "ADD_TO_CART";
export const CLEAN_ADD_TO_CART = "CLEAN_ADD_TO_CART";

export const CART_DETAILS = "CART_DETAILS";
export const REMOVE_ITEM_FROM_CART = "REMOVE_ITEM_FROM_CART";
export const UPDATE_ITEM_FROM_CART = "REMOVE_ITEM_FROM_CART";

export const ORDER_BOOK = "ORDER_BOOK";
export const ORDERS_SUMMARY = "ORDERS_SUMMARY";

export const PAYMENTS = "PAYMENTS";
export const CONFIRM_ORDER = "CONFIRM_ORDER";

export const SET_SELLER_REVIEW_DETAILS = "SET_SELLER_REVIEW_DETAILS"

export const CHAT_SELLER = "CHAT_SELLER";
export const USER_CONVER = "USER_CONVER";
export const USER_CONVER_LIST = "USER_CONVER_LIST";
export const USER_ALL_READ = "USER_ALL_READ";
export const USER_MESSAGE_SEND = "USER_MESSAGE_SEND";
export const USER_TOTAL_MESSAGE = "USER_TOTAL_MESSAGE";
export const USER_MESSAGE_NAME_LIST = "USER_MESSAGE_NAME_LIST";

export const SELLER_CONVER = "SELLER_CONVER";
export const SELLER_CONVER_LIST = "SELLER_CONVER_LIST";
export const SELLER_ALL_READ = "SELLER_ALL_READ";
export const SELLER_MESSAGE_SEND = "SELLER_MESSAGE_SEND";
export const SELLER_TOTAL_MESSAGE = "SELLER_TOTAL_MESSAGE";
export const SELLER_MESSAGE_NAME_LIST = "SELLER_MESSAGE_NAME_LIST";

export const WISHLIST_SELLER_SERVICE = "WISHLIST_SELLER_SERVICE"

export const SET_SELLER_DETAILS = "SET_SELLER_DETAILS";

export const LOADER = "LOADER";
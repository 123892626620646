import React, { useState } from 'react';
import Warning from 'components/Warning/Warning';
import { Link } from 'react-router-dom';

const Dashboard = () => {
	const [isProfileCompleted, setIsProfileCompleted] = useState(true);

	const dashboardCardClasses = isProfileCompleted ?
		'dashboard-card card' :
		'dashboard-card card faded'
	return (
		<React.Fragment>
			<section className="user-dashboard">
				{!isProfileCompleted && <Warning />}
				<div className="container">
					<h2>Your Account</h2>
					<div className="dashboard-wrapper">
						<div className="dashboard-card card">
							<Link to='/user/profile'>
								<div className="wrap-icon">
									<i className="icon-person"></i>
								</div>
								<h4 className="service-name">Your Profile</h4>
								<div className="service-details">Edit or add profile information</div>
							</Link>
						</div>
						<div className={dashboardCardClasses}>
							<Link to='/'>
								<div className="wrap-icon">
									<i className="icon-bag"></i>
								</div>
								<h4 className="service-name">Your Orders</h4>
								<div className="service-details">View your current, past and cancelled orders</div>
							</Link>
						</div>
						<div className={dashboardCardClasses}>
							<Link to='/'>
								<div className="wrap-icon">
									<i className="icon-comment"></i>
								</div>
								<h4 className="service-name">Your Messages</h4>
								<div className="service-details">View your Lab messages</div>
							</Link>
						</div>
						<div className={dashboardCardClasses}>
							<Link to='/'>
								<div className="wrap-icon">
									<i className="icon-home"></i>
								</div>
								<h4 className="service-name">Your Addresses</h4>
								<div className="service-details">Edit addresses for orders</div>
							</Link>
						</div>
						<div className="dashboard-card card">
							<Link to='/user/login-and-security'>
								<div className="wrap-icon">
									<i className="icon-lock"></i>
								</div>
								<h4 className="service-name">Login & Security</h4>
								<div className="service-details">Edit login, name and mobile number</div>
							</Link>
						</div>
						<div className="dashboard-card card">
							<Link to='/'>
								<div className="wrap-icon">
									<i className="icon-card"></i>
								</div>
								<h4 className="service-name">Payment Options</h4>
								<div className="service-details">Edit or add payment methods</div>
							</Link>
						</div>
						<div className="dashboard-card card">
							<Link to='/'>
								<div className="wrap-icon">
									<i className="icon-question"></i>
								</div>
								<h4 className="service-name">Help (FAQ)</h4>
								<div className="service-details">Browse questions and help topics</div>
							</Link>
						</div>
					</div>
				</div>
			</section>
		</React.Fragment>
	)
}

export default Dashboard;

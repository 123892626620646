import { endPoints } from "../../utils/endPoints";
import { axiosInstanceWithUserToken } from "../../utils/Axios";
import * as actiontype from "../actionType";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const allMessages = (data) => (dispatch) => {
    let token = localStorage.getItem("user_token");
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken
        .post(endPoints.USER_READ_ALL, data, {
            headers: {
                Authorization: token,
            },
        })
        .then(async (response) => {
            dispatch({ type: "LOADERSTOP" })
            if (response?.data?.status === "success") {
                toast.success(response?.data?.message)
                dispatch({
                    type: actiontype.USER_ALL_READ,
                    payload: response.data.data,
                });
            } else {
                toast.error(response?.data?.message);
            }
        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
            toast.error("error")
        });
};


const sendMessages = (data) => (dispatch) => {
    let token = localStorage.getItem("user_token");
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken
        .post(endPoints.USER_SEND_MESSAGE, data, {
            headers: {
                Authorization: token,
            },
        })
        .then(async (response) => {
            dispatch({ type: "LOADERSTOP" })
            if (response?.data?.status === "success") {
                toast.success(response?.data?.message)
                dispatch({
                    type: actiontype.USER_MESSAGE_SEND,
                    payload: response.data.data,
                });
            } else {
                toast.error(response?.data?.message);
            }
        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
            toast.error("error")
        });
};

export { allMessages, sendMessages }
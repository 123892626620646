import React, { useEffect, useState } from "react";
import ProfileImage from 'assets/images/edit-card-image.png';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { removeLabTest, removeSellerServiceList } from 'store/action/serviceListAction';
import { ToastContainer, useToast } from "react-toastify";
import { useNotify } from "components/Alert/Alert";

const ServiceCard = ({ item }) => {


    // console.log("item---->", item)

    const toast = useNotify()
    // const toast = useToast();

    const dispatch = useDispatch();
    let history = useNavigate();
    const navigate = useNavigate();



    const [show, setShow] = useState(false);



    const removeCard = (serviceId) => {


        const payload = {
            testId: serviceId// _id from the list_lab_test API
        }

        // console.log("serviceId",serviceId)

        if (payload) {
            // setShow(false);
            if (window.confirm("Are you sure you want to delete this test ?")) {
                setShow(false);
                removeLabTest(payload, toast)(dispatch)

            }

        } else {
            setShow(false);
        }

        // if (window.confirm("Are you sure you want to delete this address ?")) {
        //     setShow(false);
        //     removeLabTest(payload, dispatch)
        // } else {
        //     setShow(false);
        // }
    }

    useEffect(() => {

    }, [item])

    const goEditPage = (id) => {

        // history(`/lab/company/service-list/add?id=${id}`)
        navigate(`/lab/test-list/add/${id}`)
    }

    const goEditPageLogo = (id) => {

        // history(`/lab/company/service-list/add?id=${id}`)
        navigate(`/lab/test-list/add/${id}?to=logo`)
    }

    const getDaysOpen = (item) => {
        if (item?.daysOpened?.length === 1) {
            return item?.daysOpened[0].day
        } else {
            return item?.daysOpened[0].day + "-" + item?.daysOpened[item?.daysOpened?.length - 1].day
        }
    }

    const getDaysClose = (item) => {
        console.log('item', item)
        let dayOptions = [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday"
        ];
        let closedDays = dayOptions.filter((ditem, i) => i > item?.daysOpened.length - 1)
        if (closedDays.length === 1) {
            return closedDays[0]
        } else {
            return closedDays[0] + "-" + closedDays[closedDays.length - 1]
        }
    }

    // console.log(item.addressData?.[0]?.daysOpened.length)

    return (

        <div className='edit-card'>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
            />
            <div className='card-image'>
                {
                    item.coverImage ?
                        <img src={item.imagePath + (item.coverImage?.split('')
                            .map(char => (char === ' ' ? '%20' : char))
                            .join(''))} alt="profile-image" />
                        :
                        <img src={ProfileImage} alt="profile-image" />
                }

            </div>
            <div className='card-data'>
                <div className='service-information flex-grow-1 service-info-custom'>
                    <div className='service-detail'>{item.title}</div>
                    <div className='service-details'>
                        <div className='location'>
                            {
                                item?.addressData?.countryName && <div className='main-location'>{item?.addressData?.countryName}, {item?.addressData?.stateName}, {item?.addressData?.cityName}</div>
                            }
                            {
                                item?.addressData?.addressLine1 && <div className='inner-location'>{item?.addressData?.addressLine1}, {item?.addressData?.addressLine2}</div>
                            }

                        </div>
                        {
                            item?.addressData?.daysOpened?.[0].day ?
                                <div className='service-time'>
                                    <div className='time-info'>
                                        <div className='days'>{getDaysOpen(item?.addressData)}</div>
                                        <div className='day-time'>{item?.addressData?.daysOpened?.[0]?.from} -  {item.addressData?.daysOpened?.[item.addressData?.daysOpened?.length - 1].to}</div>
                                    </div>
                                    <div className='time-info'>
                                        <div className='days'>{getDaysClose(item?.addressData)}</div>
                                        <div className='day-time'>Closed</div>
                                    </div>
                                </div>
                                : null
                        }
                    </div>
                    {/* <div className='inner-location'>{item.description}</div> */}
                    {
                        item.price && <div className='service-price'>₹{item.price}</div>
                    }

                    {/* <div style={{marginTop:"15px"}}>
                        {
                            item.testPreparations && <div className='service-price' >Description : ₹{item.testPreparations}</div>
                        }
                    </div> */}

                </div>
                <div className='menu-wrap with-logo'>
                    <button className='menu-btn' onClick={() => { setShow(!show) }}>
                        <span className='btn-dot'></span>
                        <span className='btn-dot'></span>
                        <span className='btn-dot'></span>
                    </button>
                    {
                        show ?
                            <div className='menu'>
                                <ul className='menu-items'>
                                    <li>
                                        <button onClick={() => goEditPage(item._id)} className='menu-option'>Edit</button>
                                    </li>
                                    <li>
                                        <button onClick={() => removeCard(item._id)} className='menu-option delete'>Delete</button>
                                    </li>
                                </ul>
                            </div>
                            : null
                    }
                    <div className="icons-listing">
                        {
                            item?.logoImage?.map((ele, index) => {
                                return index < 2 ? <div className="logo-image" key={ele}>
                                    <img src={item?.imagePath + ele} alt="logo" />
                                </div> : null
                            })
                        }


                        {/* {
                            item?.logoImage?.length > 2 && <h5>+{item?.logoImage?.length - 2}</h5>
                        } */}


                        {item?.logoImage?.length > 2 && (
                            <h5 onClick={() => goEditPageLogo(item._id)} >+{item?.logoImage?.length - 2}</h5>
                        )}
                    </div>

                </div>
            </div>
        </div >
    )
}


export default ServiceCard;
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Breadcrumb } from 'react-bootstrap';
import CardDetails from './Card/CardDetails';
import BankDetails from './Bank/BankDetails';

const BillingMethod = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [showingCardDetails, setShowingCardDetails] = useState(!true);
	const [showingBankDetails, setShowingBankDetails] = useState(true);
	const user = useSelector((state) => state.profileView);

	const changePage = () => {
		console.log('dataaaaa', user)
		if (user?.data?.[0]?.type == "Company") {
			setTimeout(() => navigate({ pathname: '/seller-dashboard-company' }), 500);
		}
		else {
			setTimeout(() => navigate({ pathname: '/seller-dashboard-freelancer' }), 500);
		}
  };

	return (
		<section className='billing-method'>
			<div className="container">
				<Breadcrumb>
					<Breadcrumb.Item onClick={changePage}>Lab account</Breadcrumb.Item>
					<Breadcrumb.Item active>Billing Method</Breadcrumb.Item>
				</Breadcrumb>
				<div className="section-header">
					<div className="header-left">
						<h1>Billing Method</h1>
					</div>
					<div className="header-right">
						{/* <Link to='/seller/billing-method/edit' className='btn'>Edit</Link> */}
						<Link to='/lab/billing-method/edit' className='btn'>Edit</Link>
					</div>
				</div>
				{showingCardDetails && <CardDetails />}
				{showingBankDetails && <BankDetails />}
			</div>
		</section>
	)
}

export default BillingMethod

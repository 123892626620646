import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import cardLogo from 'assets/images/mastercard.svg';
import itemImg from 'assets/images/item-img.png';
import PaymentComplete from 'components/Alert/PaymentComplete';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { summaryOrder } from 'store/action/orderSummaryAction';
import { paymentProcess } from 'store/action/makePaymentAction';
import Loader from 'components/Loader/Loader';

const Checkout = () => {
	let navigate = useNavigate();
	const dispatch = useDispatch()
	const getOrdersInfo = useSelector((state) => state.summary)
	const [showPopup, setShowPopup] = useState(false);
	const [isloader, setIsLoader] = useState(false);

	useEffect(() => {
		summaryOrder()(dispatch)
	}, [])

	const handlePayment = (e) => {
		e.preventDefault();
		let ids = getOrdersInfo?.data?.map(key => key._id)
		let payload = {
			cartIds: ids
		};
		paymentProcess(payload)(dispatch)
		setIsLoader(true)
		console.log('ids', ids)
	}

	const getTotalCosts = () => {
		return getOrdersInfo?.data?.reduce((total, list) => {
			return total + Number(list.price * list.quantity);
		}, 0);
	};

	console.log('check orders', getOrdersInfo)
	return <section className='checkout'>
		{
			isloader ?
				<Loader />
				:
				null
		}
		<div className="container">
			<div className="back-btn">
				<Link to='/user/cart'>
					<i className="icon-back"></i>
					Back
				</Link>
			</div>
			<div className="checkout-wrap">
				<div className="checkout-left">
					{/* <div className="payment-method">
						<h3>Payment Method</h3>
						<div className="payment-options">
							<div className="payment-option">
								<Form.Check
									inline
									name="group1"
									type='radio'
									className="radio-btn"
									id="credit-input"
									defaultChecked='true'
								/>
								<div className="card-wrap">
									<div className="card-type">
										<img src={cardLogo} alt="mastercard" />
									</div>
									<div className="card-name">July Elk</div>
									<div className="card-number">**** **** **** 8329</div>
									<div className="info-wrap">
										<div className="card-expiry">07/23</div>
										<div className="card-cvv">CVV</div>
									</div>
								</div>
							</div>
						</div>
						<div className="add-more">
							<button type='button'>Add a new payment method</button>
						</div>
					</div> */}
					<h1>Order Summary</h1>
					<ul className='order-list'>
						{getOrdersInfo?.data?.map(item =>
							<li>
								<div className="item-img">
									<img src={item.path + item.serviceCover} alt="item" />
								</div>
								<div className="item-info">
									<div className="product-name">
										<Link to='#'>{item.serviceTitle}</Link>
									</div>
									<div className="product-provider">{item?.sellerData?.[0]?.firstName + " " + item?.sellerData?.[0]?.lastName}</div>
									<div className="product-price">
										${item.price}
										<span className="quantity">x{item.quantity}</span>
									</div>
								</div>
							</li>
						)}
					</ul>
				</div>
				<div className="checkout-right">
					<div className="cart-cta">
						<div className="cart-heading">Total Cost</div>
						<div className="coupon-wrap">
							<form>
								<div className="inputs-wrap">
									<div className="input-wrap">
										<label>Promo Code</label>
										<input type="text" className='form-control' />
									</div>
									<div className="btn-wrap">
										<button className="secondary-btn">Apply</button>
									</div>
								</div>
							</form>
						</div>
						<div className="total-wrap">
							<div className="text-wrap">To Pay</div>
							<div className="amount-wrap">${getTotalCosts()}</div>
						</div>
						<div className="btn-wrap">
							<button className='btn' onClick={handlePayment}>Place Your Order</button>
						</div>
						{showPopup && <PaymentComplete />}
					</div>
				</div>
			</div>
		</div>
	</section>
};

export default Checkout;

import { endPoints } from "../../utils/endPoints";
import { axiosInstanceWithUserToken } from "../../utils/Axios";
import * as actiontype from "../actionType";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const chatWithSel = (data, callback) => (dispatch) => {

    let token = localStorage.getItem("user_token");
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken
        .post(endPoints.CHAT_WITH_SELLER, data, {
            headers: {
                Authorization: token,
            },
        })
        .then(async (response) => {
            dispatch({ type: "LOADERSTOP" })
            if (response?.data?.status === "success") {
                toast.success(response?.data?.message)
                console.log('idd', response.data.data._id)
                callback(response.data.data._id)
            } else {
                toast.error(response?.data?.message);
            }
        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
            toast.error("error")
        });
};
import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const MyAddresses = () => {
	return (
		<section className='my-addresses'>
			<div className="container">
				<Breadcrumb>
					<Breadcrumb.Item href="#">User Account</Breadcrumb.Item>
					<Breadcrumb.Item active>My Adresses</Breadcrumb.Item>
				</Breadcrumb>
				<div className="section-head">
					<div className="heading-wrap">
						<h1>My Adresses</h1>
					</div>
					<div className="btn-wrap">
						<Link to='/user/my-addresses/add' className='btn'>Add New Address</Link>
					</div>
				</div>
				<div className="address-block">
					<div className="heading-wrap">
						<div className="wrap-icon home">
							<i className="icon-home"></i>
						</div>
						<h4>Home Address</h4>
					</div>
					<div className="card address-card">
						<div className="country-wrap">
							<label className="address-label">Country:</label>
							<span className="address-info">Singapore</span>
						</div>
						<div className="city-wrap">
							<label className="address-label">City:</label>
							<span className="address-info">Ubin</span>
						</div>
						<div className="state-wrap">
							<label className="address-label">State:</label>
							<span className="address-info not-applicable">N.A.</span>
						</div>
						<div className="address-wrap">
							<label className="address-label">Address:</label>
							<span className="address-info text-transform-initial">Lorem ipsum st. 54, room 36, 123456</span>
						</div>
						<div className="more-wrap">
							<button type='button'>
								<i className="icon-ellipsis"></i>
							</button>
						</div>
					</div>
				</div>
				<div className="address-block">
					<div className="heading-wrap">
						<div className="wrap-icon">
							<i className="icon-office"></i>
						</div>
						<h4>Office Address</h4>
					</div>
					<div className="card address-card">
						<div className="country-wrap">
							<label className="address-label">Country:</label>
							<span className="address-info">Singapore</span>
						</div>
						<div className="city-wrap">
							<label className="address-label">City:</label>
							<span className="address-info">Ubin</span>
						</div>
						<div className="state-wrap">
							<label className="address-label">State:</label>
							<span className="address-info not-applicable">N.A.</span>
						</div>
						<div className="address-wrap">
							<label className="address-label">Address:</label>
							<span className="address-info text-transform-initial">Lorem ipsum st. 54, room 36, 123456</span>
						</div>
						<div className="more-wrap">
							<button type='button'>
								<i className="icon-ellipsis"></i>
							</button>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default MyAddresses

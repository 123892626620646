import React, { useState } from 'react';
import Slider from 'react-slick';
import ModalImage from 'components/ModalImage';

const SlickSlider = ({ data }) => {

	const [file, setFile] = useState(false);
	const [open, setOpen] = useState(false);
	const [image, setImage] = useState(false);
	const [showModel, setShowModel] = useState(false);
	const [modelData, setModelData] = useState({});
	const [index, setIndex] = useState(0);

	// openModal=()=>{
	// 	setFile
	// }

	// const onOpenModal = (e) => {
	// 	let val = e
	// 	setImage(val)
	// 	setOpen(true)
	// 	console.log('checkin', val)
	// };

	const onCloseModal = () => setShowModel(false);

	let settings = {
		infinite: false,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 3,
				}
			},
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 2,
				}
			},
			{
				breakpoint: 576,
				settings: {
					slidesToShow: 1,
				}
			}
		]
	};

	const openModel = (val, i) => {
		setShowModel(true);
		setModelData(val)
		setIndex(i)
	}

	const handlerNext = (index, allData) => {
		if (allData.length - 1 > index) {
			let newIndex = index + 1;
			setModelData(allData[newIndex])
			setIndex(newIndex)
		}
	}

	const handlerPrev = (index, allData) => {
		if (index !== 0) {
			let newIndex = index - 1;
			setModelData(allData[newIndex])
			setIndex(newIndex)
		}
	}

	return (
		<>
			{
				showModel ?
					<ModalImage
						modelData={modelData}
						data={data}
						handlerNext={handlerNext}
						handlerPrev={handlerPrev}
						allData={data?.sellerCertificateData}
						index={index}
						closeModel={onCloseModal}
					/>
					: null
			}
			<Slider {...settings}>

				{
					data?.sellerCertificateData?.map((item, i) =>
						<div className='slide'>
							<div className="img-wrap">
								<a onClick={() => openModel(item, i)}>
									<img value={image} src={data.path + item.image} alt="service" />
								</a>
							</div>
							<div className="service-info">
								<p>{item.description}</p>
							</div>
						</div>
					)
				}
			</Slider>
		</>

	)
}

export default SlickSlider

import * as actiontype from "../actionType";
import { endPoints } from "../../utils/endPoints";
import { axiosInstanceWithUserToken } from "../../utils/Axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const stateDataList = (payload,callback) => (dispatch) => {
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken.post(endPoints.STATE_LIST_BANK, payload)
        .then(response => {
            dispatch({ type: "LOADERSTOP" })
            callback(response.data.data)
        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
            // toast.error("error")
            // console.log("success respoerrResponsese", errResponse)
        });
};


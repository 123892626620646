import * as actiontype from "../actionType";
import { endPoints } from "../../utils/endPoints";
import { axiosInstanceWithUserToken } from "../../utils/Axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const cityData = (payload) => (dispatch) => {
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken.post(endPoints.CITY_LIST_BANK, payload)
        .then(response => {
            dispatch({ type: "LOADERSTOP" })
            dispatch({
                type: actiontype.SET_CITY_BANK_DATA,
                payload: response.data.data
            })
        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
            // toast.error("error")
            console.log("success respoerrResponsese", errResponse)
        });
};
// import * as actiontype from "../../actionsTypes";
import { endPoints } from "../../utils/endPoints";
import { axiosInstanceWithUserToken } from "../../utils/Axios";
import * as actiontype from "../actionType";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const sellerFormAction = (data, callback) => (dispatch) => {
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken
        .post(endPoints.SELLER_ENQUIRY, data, {
            headers: {
                Authorization: ``,
            },
        })
        .then(async (response) => {
            dispatch({ type: "LOADERSTOP" })
            console.log('response', response.data)
            if (response?.data?.status === "success") {
                toast.success(response?.data?.message);
                callback(true)
            } else {
                console.log("wrong");
                toast.error(response?.data?.message);
                callback(false)
            }
        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
            console.log("success respoerrResponsese", errResponse);
        });
};

export const matchPasswordFor = (data, callback) => (dispatch) => {
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken
        .post(endPoints.verify_password_protected_page, data, {
            headers: {
                Authorization: ``,
            },
        })
        .then(async (response) => {
            dispatch({ type: "LOADERSTOP" })
            console.log('response', response.data)
            if (response?.data?.status === "success") {
                toast.success(response?.data?.message);
                callback(true)
            } else {
                console.log("wrong");
                toast.error(response?.data?.message);
                callback(false)
            }
        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
            console.log("success respoerrResponsese", errResponse);
        });
};

import React, { useState } from 'react'
import CustomPagination from './CustomPagination';

const ViewList = () => {

    const [page, setPage] = useState(1);
    // const [count, setCount] = useState(3);


    // Update count to the total number of list items
    const count = [
        <li key="1">sdafasdf</li>,
        <li key="2">ffdgdg</li>,
        <li key="3">fgadfvfbvrt</li>,
        <li key="4">knnkcndsa</li>,
        <li key="5">cbasdbcanusdfc</li>,
        <li key="6">kjnaskdjnkansdf</li>,
        <li key="7">kjsdanfkjndsfnqwe</li>,
        <li key="8">nasdlfnlndfiojeowqf</li>,
        <li key="9">dfnkjsandfnqworeflkadmflkmwmf</li>,
        <li key="10">adjfnwnfoiweqf</li>,
        <li key="11">adfjnoiwqerfoiqorwf</li>,
        <li key="12">dfjoiwjqroifjoqrwjf</li>,
        <li key="13">knafnqwroinforf</li>,
        <li key="14">fniqnriofnoiqrf</li>,
        <li key="15">lndlfnoiqwrnfrf</li>,
        <li key="16">kadnfoiwnoifnqwr</li>,
        <li key="17">,jsdanfkjnnfnqwef</li>,
        <li key="18">ajdsnfknwqfnqwr</li>,
        <li key="19">knnkcndsa</li>,
        <li key="20">cbasdbcanusdfc</li>,
        <li key="21">kjnaskdjnkansdf</li>,
        <li key="22">kjsdanfkjndsfnqwe</li>,
        <li key="23">nasdlfnlndfiojeowqf</li>,
        <li key="24">dfnkjsandfnqworeflkadmflkmwmf</li>,
        <li key="25">adjfnwnfoiweqf</li>,
        <li key="26">adfjnoiwqerfoiqorwf</li>,
        <li key="27">dfjoiwjqroifjoqrwjf</li>,
        <li key="28">knafnqwroinforf</li>,
        <li key="29">fniqnriofnoiqrf</li>,
        <li key="30">knafnqwroinforf</li>,
        <li key="31">fniqnriofnoiqrf</li>,
        <li key="32">lndlfnoiqwrnfrf</li>,
        <li key="33">kadnfoiwnoifnqwr</li>,
        <li key="34">,jsdanfkjnnfnqwef</li>,
        <li key="35">ajdsnfknwqfnqwr</li>,
        <li key="36">knnkcnd</li>,
    ];


    // Total number of list items

    return (
        <>
            <ul>

                {/* Render the list items for the current page */}
                <ul>
                    {count.slice((page - 1) * 5, page * 5)}
                </ul>
            </ul>

            <CustomPagination

                page={page}
                setPage={setPage}
                count={count}
                rowsPerPage={5}
            />
        </>
    )
}

export default ViewList
import * as actiontype from "../actionType";
import { endPoints } from "../../utils/endPoints";
import { axiosInstanceWithUserToken } from "../../utils/Axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const forgetPassword = (data) => (dispatch) => {

    // let token = localStorage.getItem("user_token");
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken
        .post(endPoints.FORGET_PASSWORD, data, {
            // headers: {
            //     Authorization: token,
            //     // _csrf:xsrfToken
            // },
        })
        .then(async (response) => {
            dispatch({ type: "LOADERSTOP" })
            if (response?.data?.status === "success") {
                toast.success(response?.data?.message)
                // console.log('right')
                dispatch({
                    // type: actiontype.LOGIN_USER,
                    // payload: {
                    //     islogin: true
                    // },
                });
            } else {
                console.log("wrong")
                // dispatch({
                //     // type: actiontype.LOGIN_FAIL,
                //     // payload: {
                //     //     islogin: false
                //     // },
                // });
                toast.error(response?.data?.message);
            }
            console.log("success response", response.data.data.token)
        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
            // toast.error("error")
            console.log("success respoerrResponsese", errResponse)
        });
};


import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
    addressLine1: yup
        .string()
        .required("Please enter Address")
        .min(2, "Address must be at least 2 characters")
        .max(40, "Address must be at most 20 characters")
        .matches(/^[a-zA-Z0-9\s.,'-]*$/, "Please enter valid Address"),
    addressLine2: yup
        .string(),
    cityId: yup
        .string(),
    // .required("Please enter City")
    // .min(2, "City must be at least 2 characters")
    // .max(20, "City must be at most 20 characters")
    // .matches(/^[A-Za-z]+$/i, "Please enter valid City"),
    stateId: yup
        .string(),
    countryId: yup
        .string(),
});

const AddAddress = () => {

	const { register, formState: { errors }, handleSubmit, reset } = useForm({
        mode: "onSubmit",
        resolver: yupResolver(schema),
    });

	const onSubmit = (data, e) => {
        // data.type = "Company";
    };
	return (
		<section className='my-addresses add'>
			<div className="container">
				<Breadcrumb>
					<Breadcrumb.Item href="#">User Account</Breadcrumb.Item>
					<Breadcrumb.Item href="#">My Adresses</Breadcrumb.Item>
					<Breadcrumb.Item active>Add Adresses</Breadcrumb.Item>
				</Breadcrumb>
				<h1>Add Adress</h1>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="address-block">
						<div className="heading-wrap">
							<div className="wrap-icon home">
								<i className="icon-home"></i>
							</div>
							<h4>Home Address</h4>
						</div>
						<div className="card address-card inputs-wrapper">
                            <div className="row input-block">
                                <div className="col-md-6 input-wrapper required">
                                    <label className="input-label">Address Line 1</label>
                                    <div className={`input-wrap ${errors.firstName ? 'has-error' : ''}`}>
                                        <input
                                            type="text"
                                            className="form-control"
                                            {...register("firstName")}
                                            placeholder='Address'
                                        />
                                    </div>
                                    {errors.firstName && (
                                        <span className="error">
                                            {errors.addressLine1.message}
                                        </span>
                                    )}
                                </div>
                                <div className="col-md-6 input-wrapper">
                                    <label className="input-label">Address Line 2</label>
                                    <div className={`input-wrap ${errors.addressLine2 ? 'has-error' : ''}`}>
                                        <input
                                            type="text"
                                            className="form-control"
                                            {...register("addressLine2")}
                                            placeholder='Address'
                                        />
                                    </div>
                                    {errors.addressLine2 && (
                                        <span className="error">
                                            {errors.addressLine2.message}
                                        </span>
                                    )}
                                </div>
                                <div className="col-md-6 input-wrapper required">
                                    <label className="input-label">City</label>
                                    <div className={`input-wrap ${errors.cityId ? 'has-error' : ''}`}>
                                        <input
                                            type="text"
                                            className="form-control"
                                            {...register("cityId")}
                                            placeholder='City'
                                        />
                                    </div>
                                    {errors.cityId && (
                                        <span className="error">
                                            {errors.cityId.message}
                                        </span>
                                    )}
                                </div>
                                <div className="col-md-6 input-wrapper required">
                                    <label className="input-label">State</label>
                                    <div className={`input-wrap ${errors.stateId ? 'has-error' : ''}`}>
                                        <input
                                            type="text"
                                            className="form-control"
                                            {...register("stateId")}
                                            placeholder='State'
                                        />
                                    </div>
                                    {errors.stateId && (
                                        <span className="error">
                                            {errors.stateId.message}
                                        </span>
                                    )}
                                </div>
                                <div className="col-md-6 input-wrapper required">
                                    <label className="input-label">Country</label>
                                    <div className={`input-wrap ${errors.countryId ? 'has-error' : ''}`}>
                                        <input
                                            type="text"
                                            className="form-control"
                                            {...register("countryId")}
                                            placeholder='Country'
                                        />
                                    </div>
                                    {errors.countryId && (
                                        <span className="error">
                                            {errors.countryId.message}
                                        </span>
                                    )}
                                </div>
                                <div className="col-md-6 input-wrapper required">
                                    <label className="input-label">Zip/Postal Code</label>
                                    <div className={`input-wrap ${errors.zip ? 'has-error' : ''}`}>
                                        <input
                                            type="text"
                                            className="form-control"
                                            {...register("zip")}
                                            placeholder='Zip'
                                        />
                                    </div>
                                    {errors.zip && (
                                        <span className="error">
                                            {errors.zip.message}
                                        </span>
                                    )}
                                </div>
                            </div>
						</div>
					</div>
					<div className="address-block">
						<div className="heading-wrap">
							<div className="wrap-icon">
								<i className="icon-office"></i>
							</div>
							<h4>Office Address</h4>
						</div>
						<div className="card address-card inputs-wrapper">
                            <div className="row input-block">
                                <div className="col-md-6 input-wrapper required">
                                    <label className="input-label">Address Line 1</label>
                                    <div className={`input-wrap ${errors.firstName ? 'has-error' : ''}`}>
                                        <input
                                            type="text"
                                            className="form-control"
                                            {...register("firstName")}
                                            placeholder='Address'
                                        />
                                    </div>
                                    {errors.firstName && (
                                        <span className="error">
                                            {errors.addressLine1.message}
                                        </span>
                                    )}
                                </div>
                                <div className="col-md-6 input-wrapper">
                                    <label className="input-label">Address Line 2</label>
                                    <div className={`input-wrap ${errors.addressLine2 ? 'has-error' : ''}`}>
                                        <input
                                            type="text"
                                            className="form-control"
                                            {...register("addressLine2")}
                                            placeholder='Address'
                                        />
                                    </div>
                                    {errors.addressLine2 && (
                                        <span className="error">
                                            {errors.addressLine2.message}
                                        </span>
                                    )}
                                </div>
                                <div className="col-md-6 input-wrapper required">
                                    <label className="input-label">City</label>
                                    <div className={`input-wrap ${errors.cityId ? 'has-error' : ''}`}>
                                        <input
                                            type="text"
                                            className="form-control"
                                            {...register("cityId")}
                                            placeholder='City'
                                        />
                                    </div>
                                    {errors.cityId && (
                                        <span className="error">
                                            {errors.cityId.message}
                                        </span>
                                    )}
                                </div>
                                <div className="col-md-6 input-wrapper required">
                                    <label className="input-label">State</label>
                                    <div className={`input-wrap ${errors.stateId ? 'has-error' : ''}`}>
                                        <input
                                            type="text"
                                            className="form-control"
                                            {...register("stateId")}
                                            placeholder='State'
                                        />
                                    </div>
                                    {errors.stateId && (
                                        <span className="error">
                                            {errors.stateId.message}
                                        </span>
                                    )}
                                </div>
                                <div className="col-md-6 input-wrapper required">
                                    <label className="input-label">Country</label>
                                    <div className={`input-wrap ${errors.countryId ? 'has-error' : ''}`}>
                                        <input
                                            type="text"
                                            className="form-control"
                                            {...register("countryId")}
                                            placeholder='Country'
                                        />
                                    </div>
                                    {errors.countryId && (
                                        <span className="error">
                                            {errors.countryId.message}
                                        </span>
                                    )}
                                </div>
                                <div className="col-md-6 input-wrapper required">
                                    <label className="input-label">Zip/Postal Code</label>
                                    <div className={`input-wrap ${errors.zip ? 'has-error' : ''}`}>
                                        <input
                                            type="text"
                                            className="form-control"
                                            {...register("zip")}
                                            placeholder='Zip'
                                        />
                                    </div>
                                    {errors.zip && (
                                        <span className="error">
                                            {errors.zip.message}
                                        </span>
                                    )}
                                </div>
                            </div>
						</div>
					</div>
					<div className="btns-wrap">
						<button className="secondary-btn" type="button">Cancel</button>
						<input className="btn" type="submit" />
					</div>
				</form>
			</div>
		</section>
	)
}

export default AddAddress

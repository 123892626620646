import React, { useState, useRef, useEffect } from "react";
import gallery from "assets/images/gallery.jpg";
import OutsideClickHandler from "./OutsideClickHandler";

const ModalImage = ({
  modelData,
  data,
  index,
  handlerNext,
  handlerPrev,
  allData,
  closeModel,
  isDelete
}) => {
  const [showModal, setShowModal] = useState(false);
  console.log("modelData", modelData);
  const ref = useRef();

  OutsideClickHandler(ref, () => {
    setShowModal(closeModel);
  });

  const clickHandler = () => {
    window.location.href = "/seller/optional-info/edit";
  }

  return (
    <>
      <div className="modal-backdrop">
        <div ref={ref} className="card-wrapper">
          <div className="btn-wrap prev">
            <button
              type="button"
              onClick={() => {
                handlerPrev(index, allData);
              }}
            >
              <i className="icon-arrow"></i>
            </button>
          </div>
          <div className="card portfolio-card">
            {modelData.image ? (
              <div className="img-wrap">
                <img src={data.path + modelData.image} alt="gallery" />
              </div>
            ) : (
              <div className="img-wrap">
                {modelData.fileType.split("/")[0] === "image" ? (
                  <img src={data.path + modelData.media} alt="gallery" />
                ) : (
                  <video
                    controls
                    src={data.path + modelData.media}
                    alt="service"
                  />
                )}
              </div>
            )}

            <div className="info-wrap">
              <p>{modelData.description}</p>
              {
                isDelete &&
                <div className="btn-wrap">
                  <button type="button" onClick={clickHandler}>
                    <i className="icon-bin"></i>
                  </button>
                </div>
              }
            </div>

          </div>
          <div className="btn-wrap next">
            <button
              type="button"
              onClick={() => {
                handlerNext(index, allData);
              }}
            >
              <i className="icon-arrow"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalImage;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Breadcrumb, Tabs, Tab } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import ServiceCard from './ServiceCard';
import Select from 'react-select';
import { labTestList, sellerServiceList } from 'store/action/serviceListAction';
import { countryList } from "store/action/countryList";
import { stateList } from "store/action/stateList";
import { cityList } from "store/action/cityListAction";
import { sellerrServiceCategory } from 'store/action/sellerServiceAddAction';



const EditService = () => {
    const dispatch = useDispatch();
    let history = useNavigate();
    const navigate = useNavigate();
    const [subCategoryList, setSubCategoryList] = useState([]);
    const [filterData, setFilterData] = useState({})
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);

    const [countryId, setCountryId] = useState("");
    const [searchVal, setSearchVal] = useState("");

    const [serviceVal, setServiceVal] = useState("");





    const [state, setState] = useState({
        categoryVal: "",
        subCat: "",
        countryVal: "",
        stateVal: "",
        cityVal: ""
    })



    const getserviceList = useSelector((state) => state.sellerServiceList);
    const getCategoryList = useSelector((state) => state.sellerServiceCategory);
    const getSubCategory = useSelector((state) => state.sellerServiceSubCategory)
    const getCountry = useSelector((state) => state.countryData);
    const getState = useSelector((state) => state.stateData);
    const getCity = useSelector((state) => state.cityData);
    const user = useSelector((state) => state.profileView);
    const indexOfLastTodo = page * limit;
    const indexOfFirstTodo = indexOfLastTodo - limit;
    // console.log("getserviceList", getserviceList);

    useEffect(() => {
        sellerrServiceCategory()(dispatch);
        countryList()(dispatch);
        let ele = document.getElementById('root');
        ele.scrollIntoView()
        // sellerServiceList(filterData, callback => {
        // if (callback.length === 0) {
        //     setTimeout(() => history("/lab/test-list/add"), 1000);
        // }
        // })(dispatch);
    }, []);

    const getServiceList = (filter) => {
        // sellerServiceList(filter, callback => {

        // })(dispatch);
    }

    useEffect(() => {

        const payload = {
            pageno: 1,
            limit: 100
        }

        labTestList(dispatch, payload)
    }, [])

    useEffect(() => {
        stateList({ countryId: "61c2fb83dc7c0d455ba5e68d" }, dispatch)
    }, [])

    const categoryHandler = (val, type, name) => {
        let filter = { ...filterData };


        labTestList(dispatch, { categoryId: val.value })
        // if (val?.value) {
        // }

        filter = Object.assign({}, filter, { [type]: val.value });
        if (type === "categoryId") {

            let newState = getCategoryList.filter(item => item.categoryId === val.value);
            setSubCategoryList(newState[0]);
        }
        if (type === "countryId") {
            // stateList({ countryId: "61c2fb83dc7c0d455ba5e68d" }, callback => {

            // })(dispatch);
        }
        if (type === "stateId") {


            const payload = {
                test_title: serviceVal,
                pageno: 1,
                limit: 100,
                address: searchVal,
                stateId: val.value,
                cityId: state.cityVal.value ? state.cityVal.value : ""
            }


            labTestList(dispatch, payload)

            cityList({ countryId: "61c2fb83dc7c0d455ba5e68d", stateId: val.value })(dispatch);
        }

        if (type === "cityId") {
            const payload = {
                test_title: serviceVal,
                pageno: 1,
                limit: 100,
                address: searchVal,
                stateId: state.stateVal.value ? state.stateVal.value : "",
                cityId: val.value
            }

            console.log("payload--------->", payload)

            labTestList(dispatch, payload)
        }

        setFilterData(filter);
        setServiceVal("");
        setSearchVal("")
        setState({ ...state, [name]: val })
        getServiceList(filter);


    }

    const clickPagination = (index) => {
        setPage(index);
    }

    const clickPrev = (index) => {
        setPage(index - 1);
    }

    const clickNext = (index) => {
        setPage(index + 1);
    }

    const changePage = () => {
        console.log('dataaaaa', user)
        navigate = '/lab-dashboard'
        // if (user?.data?.[0]?.type == "Company") {
        //     setTimeout(() => navigate({ pathname: '/seller-dashboard-company' }), 500);
        // }
        // else {
        //     setTimeout(() => navigate({ pathname: '/seller-dashboard-freelancer' }), 500);
        // }
    };

    const handlerInputChange = (e) => {
        e.preventDefault();

        const payload = {
            test_title: serviceVal,
            pageno: 1,
            limit: 100,
            address: searchVal,
            stateId: state.stateVal.value ? state.stateVal.value : "",
            cityId: state.cityVal.value ? state.cityVal.value : ""
        }

        console.log("payload--------->", payload)

        labTestList(dispatch, payload)

        // getServiceList({ address: searchVal });
        // setState({
        //     categoryVal: "",
        //     subCat: "",
        //     countryVal: "",
        //     stateVal: "",
        //     cityVal: ""
        // })
    }

    const handlerInputChange1 = (e) => {
        console.log("22222222222", serviceVal)
        e.preventDefault();

        const payload = {
            test_title: serviceVal,
            pageno: 1,
            limit: 100,
            address: searchVal,
            stateId: state.stateVal.value ? state.stateVal.value : "",
            cityId: state.cityVal.value ? state.cityVal.value : ""
        }


        console.log("payload--------->", payload)

        labTestList(dispatch, payload)

        // console.log("payload------->",payload)

        // getServiceList({ serviceTitle: serviceVal });
        // setState({
        //     categoryVal: "",
        //     subCat: "",
        //     countryVal: "",
        //     stateVal: "",
        //     cityVal: ""
        // })
    }

    return (
        <section className='edit-information edit-service'>
            <div className="container">
                <Breadcrumb>
                    <Breadcrumb.Item onClick={() => setTimeout(() => navigate({ pathname: '/lab-dashboard' }), 10)}>Lab Account</Breadcrumb.Item>
                    <Breadcrumb.Item active>Manage Tests</Breadcrumb.Item>
                </Breadcrumb>
                <div className="section-header">
                    <div className="header-left">
                        <h1>Manage Tests</h1>
                    </div>
                    <div className="header-right">
                        <div className="select-wrap">
                            <Select
                                value={state.categoryVal}
                                classNamePrefix="react-select"
                                className='react-select-container'
                                options={[{label:"All", value:""}, ...getCategoryList?.map(item => ({ value: item.categoryId, label: item.categoryName }))]}
                                onChange={(val) => categoryHandler(val, "categoryId", "categoryVal")}
                                placeholder={"Select category"}
                            />
                        </div>
                        <div className="select-wrap">
                            {/* <Select
                                value={state.subCat}
                                classNamePrefix="react-select"
                                className='react-select-container'
                                // options={[{value: 'React Estate Agents & Agencies', label: 'React Estate Agents & Agencies'}]}
                                options={subCategoryList?.subcategoryData?.map((item, i) => ({ value: subCategoryList?.subcategoryIds[i], label: item }))}
                                onChange={(val) => categoryHandler(val, "subcategoryId", "subCat")}
                            /> */}
                        </div>

                        {/* <Link to='/lab/company/service-list/add' className='btn'><i className='icon-plus'></i>Create New Listing</Link> */}
                        {/* <div  style={{padding:"10px"}}>
                        <Link to='/view-package' className='btn'>View Package</Link>
                        </div> */}

                        <Link to='/lab/test-list/add' className='btn'><i className='icon-plus'></i>Create New Test</Link>
                    </div>
                </div>
                <div className='services-inputs'>
                    <form>
                        <div className='form-wrap'>
                            <div className='inputs-wrap'>
                                <div className='input-wrap'>
                                    <input
                                        type="text"
                                        className="input"
                                        placeholder="Search a specific test and description"
                                        onChange={(e) => { setServiceVal(e.target.value); setSearchVal("") }}
                                        value={serviceVal}
                                    />
                                    <button className="search-btn" onClick={handlerInputChange1}>
                                        <i className="icon-search"></i>
                                    </button>
                                </div>
                                <div className='input-wrap'>
                                    <input
                                        type="text"
                                        className="input"
                                        placeholder="Search a specific address"
                                        onChange={(e) => { setSearchVal(e.target.value); setServiceVal("") }}
                                        value={searchVal}
                                    />
                                    <button className="search-btn" onClick={handlerInputChange}>
                                        <i className="icon-search"></i>
                                    </button>
                                </div>
                            </div>
                            <div className='selects-wrap'>
                                <div className="select-wrap bigger">
                                    {/* <Select
                                        classNamePrefix="react-select"
                                        className="react-select-container"
                                        value={state.countryVal}
                                        options={getCountry?.map(item => ({ value: item._id, label: item.name }))}
                                        onChange={(val) => categoryHandler(val, "countryId", "countryVal")}
                                    /> */}
                                    <div className="col-md-6 input-wrapper required">


                                        {/* <select
                                            // {...register("countryId")}
                                            // name="countryId" 
                                            className="form-control" id="countryId"
                                            // onChange={getStateHandler}
                                            disabled
                                        >
                                            <option value={""}>India</option>

                                        </select> */}

                                    </div>
                                </div>
                                <div className="select-wrap selecet-box-wrapper">
                                    <Select
                                        classNamePrefix="react-select"
                                        placeholder="Select state..."
                                        className="react-select-container"
                                        value={state.stateVal}
                                        options={[{label:"Select state..."}, ...getState?.map(item => ({ value: item._id, label: item.name }))]}
                                        onChange={(val) => categoryHandler(val, "stateId", "stateVal")}
                                    />
                                </div>
                                <div className="select-wrap disabled selecet-box-wrapper">
                                    <Select
                                        value={state.cityVal}
                                        placeholder="Select city..."
                                        classNamePrefix="react-select"
                                        className="react-select-container"
                                        options={[{label:"Select city..."}, ...getCity?.map(item => ({ value: item._id, label: item.name }))]}
                                        onChange={(val) => categoryHandler(val, "cityId", "cityVal")}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div className='edit-services'>
                    <div className='cards-wrap'>
                        {
                            getserviceList?.length > 0 ?
                            getserviceList?.slice(indexOfFirstTodo, indexOfLastTodo).map(item =>
                                <ServiceCard item={item} />
                            )
                            :
                            <div><span>No Data Found</span></div>
                        }
                    </div>
                    {
                        getserviceList.length > 0 &&
                        <nav className='pagination-wrap' aria-label="Page navigation example">
                            <ul className="pagination">
                                <li className={page === 1 ? "page-item previous disabled" : "page-item previous"} onClick={() => clickPrev(page)}>
                                    <a
                                        className="page-link"
                                        href="javascript:void(0)"
                                        tabIndex="-1"

                                    >
                                        <i className="icon-arrow"></i>
                                    </a>
                                </li>
                                {
                                    [...Array(Math.ceil(getserviceList.length / 10))].map((item, i) =>
                                        <li className={i + 1 === page ? "page-item active" : "page-item"} aria-current="page">
                                            <a className="page-link" href="javascript:void(0)" onClick={() => clickPagination(i + 1)}>
                                                {i + 1}
                                            </a>
                                        </li>
                                    )
                                }


                                <li className={page === Math.ceil(getserviceList.length / 10) ? "page-item  disabled" : "page-item "}>
                                    <a className="page-link" href="javascript:void(0)" onClick={() => clickNext(page)}>
                                        <i className="icon-arrow"></i>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    }
                </div>
            </div>
        </section>
    )
}

export default EditService

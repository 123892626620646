import * as actiontype from "../actionType";
import { endPoints } from "../../utils/endPoints";
import { axiosInstanceWithUserToken } from "../../utils/Axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



// export const sellerServiceList = (data, callback) => (dispatch) => {

//     let token = localStorage.getItem("user_token");
//     axiosInstanceWithUserToken.post(endPoints.SELLER_LIST_SERVICE, data, {
//         headers: {
//             Authorization: token,
//             // _csrf:xsrfToken
//         },
//     })
//         .then(response => {
//             if (response.data.status === "success") {
//                 dispatch({
//                     type: actiontype.SELLER_SERVICE_LIST,
//                     payload: response.data.data
//                 })
//                 callback(response.data.data)
//             } else {
//                 dispatch({
//                     type: actiontype.SELLER_SERVICE_LIST,
//                     payload: []
//                 })
//                 callback([])
//             }

//         })
//         .catch((errResponse) => {
//             callback([])
//             // toast.error("error")
//             console.log("success respoerrResponsese", errResponse)
//         });
// };


// export const removeSellerServiceList = (data) => (dispatch) => {

//     let token = localStorage.getItem("user_token");
//     axiosInstanceWithUserToken.post(endPoints.DELETE_SELLER_SERVICE, data, {
//         headers: {
//             Authorization: token,
//         },
//     })
//         .then(response => {
//             dispatch({
//                 type: actiontype.REMOVE_SELLER_SERVICE_LIST,
//                 payload: data
//             })
//         })
//         .catch((errResponse) => {
//             // toast.error("error")
//             console.log("success respoerrResponsese", errResponse)
//         });
// };



export const labTestList = (dispatch, payload) => {
    let token = localStorage.getItem("user_token");
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken.post(endPoints.LAB_LIST_DATA, payload, {
        headers: {
            Authorization: token,
            // _csrf:xsrfToken
        },
    })
        .then(response => {
            dispatch({ type: "LOADERSTOP" })
            if (response.data.status === "success") {
                dispatch({
                    type: actiontype.SELLER_SERVICE_LIST,
                    payload: response.data.data
                })
                // callback(response.data.data)
            } else {
                dispatch({ type: "LOADERSTOP" })
                dispatch({
                    type: actiontype.SELLER_SERVICE_LIST,
                    payload: []
                })
                // callback([])
            }

        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
            // callback([])
            // toast.error("error")
            console.log("success respoerrResponsese", errResponse.response)
        });
}

export const labPackageList = (dispatch, payload) => {
    let token = localStorage.getItem("user_token");
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken.post(endPoints.LAB_LIST_DATA_PACKAGE, payload, {
        headers: {
            Authorization: token,
            // _csrf:xsrfToken
        },
    })
        .then(response => {
            dispatch({ type: "LOADERSTOP" })
            if (response.data.status === "success") {
                dispatch({
                    type: actiontype.SELLER_SERVICE_LIST,
                    payload: response.data.data
                })
                // callback(response.data.data)
            } else {
                dispatch({
                    type: actiontype.SELLER_SERVICE_LIST,
                    payload: []
                })
                // callback([])
            }

        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
            // callback([])
            // toast.error("error")
            console.log("success respoerrResponsese", errResponse)
        });
}


export const removeLabTest = (payload, toast) => (dispatch) => {
    let token = localStorage.getItem("user_token");
  
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken.post(endPoints.LAB_TEST_REMOVE, payload, {
        headers: {
            Authorization: token,
        },
    })
        .then((response) => {
            dispatch({ type: "LOADERSTOP" })
            console.log("response---->", response)
            if (response?.data?.message === "success") {

                toast.success(response?.data?.message)
            }
            console.log("response---->1111", response)
            dispatch({
                type: actiontype.REMOVE_SELLER_SERVICE_LIST,
                payload: payload
            })
            // window.location.reload();
            labTestList(dispatch, { pageno: 1, limit: 100 })

        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
            // toast.error("error")
            console.log("asasaas", errResponse?.response?.data?.message)
        

            toast.error(errResponse?.response?.data?.message);
            // alert("asdasdasd")

            console.log("asasaas1111", errResponse?.response?.data?.message)
            console.log("success respoerrResponsese", errResponse)
        });
}

export const removeLabPackage = (payload, dispatch) => {
    let token = localStorage.getItem("user_token");
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken.post(endPoints.LAB_PACKAGE_REMOVE, payload, {
        headers: {
            Authorization: token,
        },
    })
        .then((response) => {
            dispatch({ type: "LOADERSTOP" })
            console.log("response---->", response)
            dispatch({
                type: actiontype.REMOVE_SELLER_SERVICE_LIST,
                payload: payload
            })
            // window.location.reload();
            labPackageList(dispatch, { pageno: 1, limit: 100 })

        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
            // toast.error("error")
            console.log("success respoerrResponsese", errResponse)
        });
}




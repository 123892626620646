import React from 'react';

const FailedPayment = () => {

    return (
        <>
            <h1>This is declined page</h1>
        </>
    )
}

export default FailedPayment;
import React, { useState } from 'react';
import { useWindowSize } from 'hooks/window-size';
import { Link } from 'react-router-dom';
import rating1 from 'assets/images/rating-1.svg'
import rating2 from 'assets/images/rating-2.svg'
import rating3 from 'assets/images/rating-3.svg'
import rating4 from 'assets/images/rating-4.svg'
import Rating from 'assets/images/rating.png';
import services32 from 'assets/images/service-35.png';
import Select from 'react-select';



const SubCategory = () => {
	const [ windowWidth ] = useWindowSize();
	const [showFilters, setShowFilters] = useState(false);

	const showFiltersHandler = () => {
		setShowFilters(true);
	}
	const hideFiltersHandler = () => {
		setShowFilters(false);
	}
	const options = [
        { value: 'Price: Low to High', label: 'Price: Low to High' },
        { value: 'Price: High to Low', label: 'Price: High to Low' },
    ]
	const filtersClasses = showFilters ? 'filters-wrap active' : 'filters-wrap'
	return <section className='sub-category-block search-section'>
		<div className="container">
			<div className="left-block">
				{windowWidth < 767 && <div className="filter-btn">
					<button onClick={showFiltersHandler}>Filters</button>
				</div>}
				<div className={filtersClasses}>
					{windowWidth < 767 && <div className="close-btn">
						<button type='button' onClick={hideFiltersHandler}>
							<i className="icon-cross"></i>
						</button>
					</div>}
					<h3>Filters</h3>
					<form>
						<div className="input-wrap">
							<label className='input-label'>Test Type</label>
							<div className="contains-checkbox">
								<div className="form-check">
									<input className="form-check-input" type="checkbox" value="" />
									<label className="form-check-label" >Test</label>
								</div>
								<div className="form-check">
									<input className="form-check-input" type="checkbox" value="" />
									<label className="form-check-label" >Package</label>
								</div>
							</div>
						</div>
						<div className="input-wrap">
							<label className='input-label'>Location</label>
							<div className="wrap-input contains-btn">
								<input type="text" className='form-control' placeholder='input search text'/>
								<div className="btn-wrap">
									<button type='button'>
										<i className="icon-search"></i>
									</button>
								</div>
							</div>
						</div>
                        <div className="input-wrap">
							<div className="contains-checkbox">
								<div className="form-check">
									<input className="form-check-input" type="checkbox" value="" />
									<label className="form-check-label" >Online</label>
								</div>
								<div className="form-check">
									<input className="form-check-input" type="checkbox" value="" />
									<label className="form-check-label" >Offline</label>
								</div>
							</div>
						</div>
						{/* <div className="input-wrap">
							<label className='input-label'>Distance</label>
							<div className="multiple-inputs">
								<div className="wrap-input">
									<input type="text" className='form-control' placeholder='0 km'/>
								</div>
								<div className="wrap-input">
									<input type="text" className='form-control' placeholder='800 km'/>
								</div>
							</div>
						</div> */}
						<div className="input-wrap">
							<label className='input-label'>Language</label>
							<div className="wrap-input">
								<select  className='form-control drop-down' placeholder='input search text'>
									<option value="">Please select</option>
									<option value="">Language 1</option>
									<option value="">Language 2</option>
								</select>
							</div>
						</div>
						<div className="rating-input">
							<div className="input-label">Ratings</div>
							<label className="custom-checkbox">
								<input type="radio" name="rating"/>
								<span className="rating-wrap">
									<span className="img-wrap">
										<img src={rating1} alt="rating" />
									</span>
									<span className="text-wrap">& Up</span>
								</span>
								<span className="checkmark"></span>
							</label>
							<label className="custom-checkbox">
								<input type="radio" name="rating"/>
								<span className="rating-wrap">
									<span className="img-wrap">
										<img src={rating2} alt="rating" />
									</span>
									<span className="text-wrap">& Up</span>
								</span>
								<span className="checkmark"></span>
							</label>
							<label className="custom-checkbox">
								<input type="radio" name="rating"/>
								<span className="rating-wrap">
									<span className="img-wrap">
										<img src={rating3} alt="rating" />
									</span>
									<span className="text-wrap">& Up</span>
								</span>
								<span className="checkmark"></span>
							</label>
							<label className="custom-checkbox">
								<input type="radio" name="rating"/>
								<span className="rating-wrap">
									<span className="img-wrap">
										<img src={rating4} alt="rating" />
									</span>
									<span className="text-wrap">& Up</span>
								</span>
								<span className="checkmark"></span>
							</label>
						</div>
						<div className="input-wrap">
							<label className='input-label'>Price Type</label>
							<div className="contains-checkbox">
								<div className="form-check">
									<input className="form-check-input" type="checkbox" value="" />
									<label className="form-check-label" >Fixed</label>
								</div>
								<div className="form-check">
									<input className="form-check-input" type="checkbox" value="" />
									<label className="form-check-label" >Hourly Rate</label>
								</div>
							</div>
						</div>
						<div className="btns-wrap">
							<button type='button' className='secondary-btn'>Reset</button>
							<button type='submit' className='btn'>Search</button>
						</div>
					</form>
				</div>
			</div>
			<div className="right-block">
				<div className="head-wrap">
					<div className="tag-wrap">
						<div className="search-tag">
							<div className="remove-btn">
								<button>
									<i className="icon-cross"></i>
								</button>
							</div>
							<span className="text">Media Entertainment</span>
						</div>
					</div>
					<div className='sort-select'>
                        <label>Sort by</label>
                        <div className="sort-wrap">
                            <Select defaultValue={options[1]} classNamePrefix="react-select" className='react-select-container' options={options} />
                        </div>
                    </div>
				</div>
				<div className='sub-category_wrapper'>
                    <Link to="/" className='service-block'>
                        <div className='service-image'>
                            <img src={services32} alt="service" />
                        </div>
                        <div className='service-card'>
                            <div className='service-name'>Photo Session</div>
                            <div className='provider-info'>Media Entertainment Corporation</div>
                            <div className='seller-rating'>
                                <div className='rating-wrap'>
                                    <div className='rating-image'>
                                        <img src={Rating} alt="rating" />
                                    </div>
                                    <div className='rating-number'>101 reviews</div>
                                </div>
                            </div>
                            <div className='services-block'>
                                <div className='price'>$100</div>
                                <button className='wishlist-btn'>
                                    <i className='icon-heart'></i>
                                </button>
                            </div>
                        </div>
                    </Link>
					<Link to="/" className='service-block'>
                        <div className='service-image'>
                            <img src={services32} alt="service" />
                        </div>
                        <div className='service-card'>
                            <div className='service-name'>Photo Session</div>
                            <div className='provider-info'>Media Entertainment Corporation</div>
                            <div className='seller-rating'>
                                <div className='rating-wrap'>
                                    <div className='rating-image'>
                                        <img src={Rating} alt="rating" />
                                    </div>
                                    <div className='rating-number'>101 reviews</div>
                                </div>
                            </div>
                            <div className='services-block'>
                                <div className='price'>$100</div>
                                <button className='wishlist-btn'>
                                    <i className='icon-heart'></i>
                                </button>
                            </div>
                        </div>
                    </Link>
					<Link to="/" className='service-block'>
                        <div className='service-image'>
                            <img src={services32} alt="service" />
                        </div>
                        <div className='service-card'>
                            <div className='service-name'>Photo Session</div>
                            <div className='provider-info'>Media Entertainment Corporation</div>
                            <div className='seller-rating'>
                                <div className='rating-wrap'>
                                    <div className='rating-image'>
                                        <img src={Rating} alt="rating" />
                                    </div>
                                    <div className='rating-number'>101 reviews</div>
                                </div>
                            </div>
                            <div className='services-block'>
                                <div className='price'>$100</div>
                                <button className='wishlist-btn'>
                                    <i className='icon-heart'></i>
                                </button>
                            </div>
                        </div>
                    </Link>
					<Link to="/" className='service-block'>
                        <div className='service-image'>
                            <img src={services32} alt="service" />
                        </div>
                        <div className='service-card'>
                            <div className='service-name'>Photo Session</div>
                            <div className='provider-info'>Media Entertainment Corporation</div>
                            <div className='seller-rating'>
                                <div className='rating-wrap'>
                                    <div className='rating-image'>
                                        <img src={Rating} alt="rating" />
                                    </div>
                                    <div className='rating-number'>101 reviews</div>
                                </div>
                            </div>
                            <div className='services-block'>
                                <div className='price'>$100</div>
                                <button className='wishlist-btn'>
                                    <i className='icon-heart'></i>
                                </button>
                            </div>
                        </div>
                    </Link>
					<Link to="/" className='service-block'>
                        <div className='service-image'>
                            <img src={services32} alt="service" />
                        </div>
                        <div className='service-card'>
                            <div className='service-name'>Photo Session</div>
                            <div className='provider-info'>Media Entertainment Corporation</div>
                            <div className='seller-rating'>
                                <div className='rating-wrap'>
                                    <div className='rating-image'>
                                        <img src={Rating} alt="rating" />
                                    </div>
                                    <div className='rating-number'>101 reviews</div>
                                </div>
                            </div>
                            <div className='services-block'>
                                <div className='price'>$100</div>
                                <button className='wishlist-btn'>
                                    <i className='icon-heart'></i>
                                </button>
                            </div>
                        </div>
                    </Link>
					<Link to="/" className='service-block'>
                        <div className='service-image'>
                            <img src={services32} alt="service" />
                        </div>
                        <div className='service-card'>
                            <div className='service-name'>Photo Session</div>
                            <div className='provider-info'>Media Entertainment Corporation</div>
                            <div className='seller-rating'>
                                <div className='rating-wrap'>
                                    <div className='rating-image'>
                                        <img src={Rating} alt="rating" />
                                    </div>
                                    <div className='rating-number'>101 reviews</div>
                                </div>
                            </div>
                            <div className='services-block'>
                                <div className='price'>$100</div>
                                <button className='wishlist-btn'>
                                    <i className='icon-heart'></i>
                                </button>
                            </div>
                        </div>
                    </Link>
					<Link to="/" className='service-block'>
                        <div className='service-image'>
                            <img src={services32} alt="service" />
                        </div>
                        <div className='service-card'>
                            <div className='service-name'>Photo Session</div>
                            <div className='provider-info'>Media Entertainment Corporation</div>
                            <div className='seller-rating'>
                                <div className='rating-wrap'>
                                    <div className='rating-image'>
                                        <img src={Rating} alt="rating" />
                                    </div>
                                    <div className='rating-number'>101 reviews</div>
                                </div>
                            </div>
                            <div className='services-block'>
                                <div className='price'>$100</div>
                                <button className='wishlist-btn'>
                                    <i className='icon-heart'></i>
                                </button>
                            </div>
                        </div>
                    </Link>
					<Link to="/" className='service-block'>
                        <div className='service-image'>
                            <img src={services32} alt="service" />
                        </div>
                        <div className='service-card'>
                            <div className='service-name'>Photo Session</div>
                            <div className='provider-info'>Media Entertainment Corporation</div>
                            <div className='seller-rating'>
                                <div className='rating-wrap'>
                                    <div className='rating-image'>
                                        <img src={Rating} alt="rating" />
                                    </div>
                                    <div className='rating-number'>101 reviews</div>
                                </div>
                            </div>
                            <div className='services-block'>
                                <div className='price'>$100</div>
                                <button className='wishlist-btn'>
                                    <i className='icon-heart'></i>
                                </button>
                            </div>
                        </div>
                    </Link>
					<Link to="/" className='service-block'>
                        <div className='service-image'>
                            <img src={services32} alt="service" />
                        </div>
                        <div className='service-card'>
                            <div className='service-name'>Photo Session</div>
                            <div className='provider-info'>Media Entertainment Corporation</div>
                            <div className='seller-rating'>
                                <div className='rating-wrap'>
                                    <div className='rating-image'>
                                        <img src={Rating} alt="rating" />
                                    </div>
                                    <div className='rating-number'>101 reviews</div>
                                </div>
                            </div>
                            <div className='services-block'>
                                <div className='price'>$100</div>
                                <button className='wishlist-btn'>
                                    <i className='icon-heart'></i>
                                </button>
                            </div>
                        </div>
                    </Link>
					<Link to="/" className='service-block'>
                        <div className='service-image'>
                            <img src={services32} alt="service" />
                        </div>
                        <div className='service-card'>
                            <div className='service-name'>Photo Session</div>
                            <div className='provider-info'>Media Entertainment Corporation</div>
                            <div className='seller-rating'>
                                <div className='rating-wrap'>
                                    <div className='rating-image'>
                                        <img src={Rating} alt="rating" />
                                    </div>
                                    <div className='rating-number'>101 reviews</div>
                                </div>
                            </div>
                            <div className='services-block'>
                                <div className='price'>$100</div>
                                <button className='wishlist-btn'>
                                    <i className='icon-heart'></i>
                                </button>
                            </div>
                        </div>
                    </Link>
					<Link to="/" className='service-block'>
                        <div className='service-image'>
                            <img src={services32} alt="service" />
                        </div>
                        <div className='service-card'>
                            <div className='service-name'>Photo Session</div>
                            <div className='provider-info'>Media Entertainment Corporation</div>
                            <div className='seller-rating'>
                                <div className='rating-wrap'>
                                    <div className='rating-image'>
                                        <img src={Rating} alt="rating" />
                                    </div>
                                    <div className='rating-number'>101 reviews</div>
                                </div>
                            </div>
                            <div className='services-block'>
                                <div className='price'>$100</div>
                                <button className='wishlist-btn'>
                                    <i className='icon-heart'></i>
                                </button>
                            </div>
                        </div>
                    </Link>
					<Link to="/" className='service-block'>
                        <div className='service-image'>
                            <img src={services32} alt="service" />
                        </div>
                        <div className='service-card'>
                            <div className='service-name'>Photo Session</div>
                            <div className='provider-info'>Media Entertainment Corporation</div>
                            <div className='seller-rating'>
                                <div className='rating-wrap'>
                                    <div className='rating-image'>
                                        <img src={Rating} alt="rating" />
                                    </div>
                                    <div className='rating-number'>101 reviews</div>
                                </div>
                            </div>
                            <div className='services-block'>
                                <div className='price'>$100</div>
                                <button className='wishlist-btn'>
                                    <i className='icon-heart'></i>
                                </button>
                            </div>
                        </div>
                    </Link>
                </div>
				<nav className='pagination-wrap' aria-label="Page navigation example">
                    <ul className="pagination">
                        <li className="page-item previous disabled"><Link to='/' className="page-link" tabIndex="-1" aria-disabled="true"><i className='icon-arrow'></i></Link></li>
                        <li className="page-item active" aria-current="page">
                            <Link to='/' className="page-link">1</Link>
                        </li>
                        <li className="page-item"><Link to='/' className="page-link">2</Link></li>
                        <li className="page-item"><Link to='/' className="page-link">3</Link></li>
                        <li className="page-item"><Link to='/' className="page-link">4</Link></li>
                        <li className="page-item"><Link to='/' className="page-link">5</Link></li>
                        <li className="page-item">
                            <Link to='/' className="page-link">
                                <i className='icon-arrow'></i>
                            </Link>
                        </li>
                    </ul>
                </nav>
			</div>
		</div>
	</section>
};

export default SubCategory;


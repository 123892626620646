import React from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
    firstname: yup
        .string()
        .required("Please enter First Name")
        .min(2, "First name must be at least 2 characters")
        .max(20, "First name must be at most 20 characters")
        .matches(/^[A-Za-z]+$/i, "Please enter valid first name"),
    lastname: yup
        .string()
        .required("Please enter Last Name")
        .min(2, "Last name must be at least 2 characters")
        .max(20, "Last name must be at most 20 characters")
        .matches(/^[A-Za-z]+$/i, "Please enter valid last name"),
    email: yup
        .string()
        .required("Please enter your email address")
        .matches(
            /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            "Please use the correct email"
        ),
    businessName: yup
        .string()
        .required("Please enter Business Name")
        .min(2, "Business name must be at least 2 characters")
        .max(20, "Business name must be at most 20 characters"),
    // .matches(/^[A-Za-z]+$/i, "Please enter valid Business name"),
    primaryContactPerson: yup
        .string()
        .required("Please enter Primary Contact Person")
        .min(2, "Primary contact person must be at least 2 characters")
        .max(20, "Primary contact person must be at most 20 characters")
        .matches(/^[A-Za-z]+$/i, "Please enter valid primary contact person"),
    companyRegNumber: yup
        .string()
        .required("Please enter Company Register Number")
        .matches(/^[0-9]*$/, "Company Register Number is not valid"),
    addressFirst: yup
        .string()
        .required("Please enter Address")
        .min(2, "Address must be at least 2 characters")
        .max(20, "Address must be at most 20 characters")
        .matches(/^[A-Za-z]+$/i, "Please enter valid Address"),
    addressSecond: yup
        .string(),
    city: yup
        .string()
        .required("Please enter City")
        .min(2, "City must be at least 2 characters")
        .max(20, "City must be at most 20 characters")
        .matches(/^[A-Za-z]+$/i, "Please enter valid City"),
    state: yup
        .string(),
    country: yup
        .string()
        .required("Please enter Country")
        .min(2, "Country must be at least 2 characters")
        .max(20, "Country must be at most 20 characters")
        .matches(/^[A-Za-z]+$/i, "Please enter valid Country"),
    phone: yup
        .string()
        .required("Please enter mobile number")
        .matches(/^[0-9]*$/, "Phone number is not valid")
        .min(8)
        .max(10),
    zip: yup
        .string()
        .required("Please enter Zip")
        .matches(/^[0-9]*$/, "Zip code is not valid")
        .min(6)
        .max(6),
    comment: yup
        .string()
        .required("Please enter Comment")
        .matches(/^[A-Za-z]+$/i, "Comment is not valid")
        .min(2)
        .max(300),
});

const CardInput = () => {

    const { register, formState: { errors }, handleSubmit, reset } = useForm({
        mode: "onSubmit",
        resolver: yupResolver(schema),
    });

    return (
        <div className='card-inputs'>
            <div className="row">
                <div className="col-md-8 col-lg-5 col-xl-3 fix-width input-block">
                    <label>Card Number</label>
                    <div className="input-wrap">
                        <input
                            type="number"
                            className="form-control"
                            {...register("cardNumber")}
                            placeholder='XXXX XXXX XXXX XXXX'
                        />
                    </div>
                    {errors.cardNumber && (
                        <span className="error">
                            {errors.cardNumber.message}
                        </span>
                    )}
                </div>
                <div className="col-8 col-md-2 col-lg-2 col-xl-2 fix-width input-block">
                    <label>Month/Year</label>
                    <div className="input-wrap">
                        <input
                            type="number"
                            className="form-control"
                            {...register("expiryDate")}
                            placeholder='mm/yy'
                        />
                    </div>
                    {errors.expiryDate && (
                        <span className="error">
                            {errors.expiryDate.message}
                        </span>
                    )}
                </div>
                <div className="col-4 col-md-2 col-lg-2 col-xl-2 fix-width input-block">
                    <label>CVV</label>
                    <div className="input-wrap">
                        <input
                            type="number"
                            className="form-control"
                            {...register("cvv")}
                            placeholder='***'
                        />
                    </div>
                    {errors.cvv && (
                        <span className="error">
                            {errors.cvv.message}
                        </span>
                    )}
                </div>
            </div>
        </div>
    )
}

export default CardInput

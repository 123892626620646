import * as actiontype from "../actionType";
import { endPoints } from "../../utils/endPoints";
import { axiosInstanceWithUserToken } from "../../utils/Axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const billingMethod = (data) => (dispatch) => {
    let token = localStorage.getItem("user_token");
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken
        .post(endPoints.BILLING_METHOD,data, {
            headers: {
                Authorization: token,
            },
            
        })
        .then(async (response) => {
            dispatch({ type: "LOADERSTOP" })
            if (response?.data?.status === "success") {
                toast.success(response?.data?.message)
                dispatch({
                    type: actiontype.LOGIN_USER,
                    payload: {
                        islogin: true
                    },
                });
            } else {
                // console.log("Billing Method not found")
               
                toast.error(response?.data?.message);
            }
           
        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
            // console.log('error', errResponse?.response?.data)
            if (errResponse?.response?.data?.status === "error") {
                toast.error(errResponse?.response?.data?.message);
            }
           
        });
};
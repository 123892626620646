import React, { useState, useRef, useEffect } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux'
import { customerService } from 'store/action/contactAction';
import { Alert } from 'components/Alert/Alert';
import { useNavigate } from 'react-router-dom';

const schema = yup.object().shape({
	firstName: yup
		.string()
		.required("Please enter First Name")
		.min(2, "First name must be at least 2 characters")
		.max(20, "First name must be at most 20 characters")
		.matches(/^[A-Za-z ]+$/i, "Please enter valid first name"),
	lastName: yup
		.string()
		.required("Please enter Last Name")
		.min(2, "Last name must be at least 2 characters")
		.max(20, "Last name must be at most 20 characters")
		.matches(/^[A-Za-z ]+$/i, "Please enter valid last name"),
	email: yup
		.string()
		.required("Please enter your email address")
		.matches(
			/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
			"Please enter a valid email address"
		  ),
	phone: yup
		.string()
		.required("Please enter mobile number")
		.matches(/^[0-9]*$/, "Phone number is not valid")
		.min(10, "Please enter a valid phone number")
		.max(10),
	attachment: yup
		.string(),
	typeOfIssue: yup
		.string()
		.required("Please Select issue"),
	comment: yup
		.string()
		.required("Please enter Comment")
		.matches(/^[a-zA-Z0-9\s.,'-]*$/, "Comment is not valid")
		.min(2)
		.max(300),
});

const CustomerService = () => {

	
	const navigate=useNavigate()

	let attachmentName = useRef(null);
	const [img, setImg] = useState("");
	const [fileAttach, setFileAttach] = useState(null)
	const dispatch = useDispatch()
	const [issue, setIssue] = useState({});
	const [phone, setPhone] = useState({ value: "" })

	const { register, formState: { errors }, handleSubmit, reset } = useForm({
		mode: "onSubmit",
		resolver: yupResolver(schema),
	});

	useEffect(()=>{
		let ele = document.getElementById('root');
        ele.scrollIntoView()
	},[])

	const issueOptions = [
		{ value: '', label: 'Please Select issue' },
		{ value: 'option 1', label: 'option 1' },
		{ value: 'option 2', label: 'option 2' },
	]

	const imgHandler = (e) => {
		setFileAttach(e.target.files[0])
		let src_ = URL.createObjectURL(e.target.files[0]);
		setImg(src_)
	}

	const phoneVerifyHandler = (event) => {
        let value = event.target.value
        value = value.replaceAll(' ', '')
        setPhone(phone => value.length <= 10 && !isNaN(Number(value)) && { value } || phone)

	}

	const onSubmit = (data, e) => {
		let payload = new FormData();
		payload.append('firstName', data.firstName);
		payload.append('lastName', data.lastName);
		payload.append('comment', data.comment);
		payload.append("email", data.email);
		payload.append("phone", data.phone);
		payload.append("attachment", fileAttach);
		payload.append("typeOfIssue", data.typeOfIssue);
		customerService(payload)(dispatch)
		setTimeout(() => {reset();setFileAttach(null);setImg("")}, 3500);
		setPhone("")
	};

	return (
		<section className='customer-service'>
			<Alert />
			<div className="container">
				<Breadcrumb>
					<Breadcrumb.Item href={localStorage.getItem("user_token")?"/lab-dashboard":"/"}>Home</Breadcrumb.Item>
					<Breadcrumb.Item active>Customer Service</Breadcrumb.Item>
				</Breadcrumb>
				<h1>Customer Service </h1>
				<div className="card">
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="inputs-wrapper">
							<div className="row">
								<div className="col-md-6 col-xl-4 input-wrapper required">
									<label className="input-label">first name</label>
									<div className={`input-wrap ${errors.firstName ? 'has-error' : ''}`}>
										<input
											type="text"
											className="form-control"
											{...register("firstName")}
										/>
									</div>
									{errors.firstName && (
										<span className="error">
											{errors.firstName.message}
										</span>
									)}
								</div>
								<div className="col-md-6 col-xl-4 input-wrapper required">
									<label className="input-label">last name</label>
									<div className={`input-wrap ${errors.lastName ? 'has-error' : ''}`}>
										<input
											type="text"
											className="form-control"
											{...register("lastName")}
										/>
									</div>
									{errors.lastName && (
										<span className="error">
											{errors.lastName.message}
										</span>
									)}
								</div>
								<div className="col-md-6 col-xl-4 input-wrapper required">
									<label className="input-label">Email address</label>
									<div className={`input-wrap ${errors.email ? 'has-error' : ''}`}>
										<input
											type="email"
											{...register("email")}
											className="form-control"
											autoComplete="off"
											placeholder='example@aladyyn.pro'
										/>
									</div>
									{errors.email && (
										<span className="error">
											{errors.email.message}
										</span>
									)}
								</div>
								<div className="col-md-6 col-xl-4 input-wrapper required">
									<label className="input-label">Phone Number </label>
									<div className={`input-wrap ${errors.phone ? 'has-error' : ''}`}>
										<input
											type="number"
											{...register("phone")}
											className="form-control"
											onChange={phoneVerifyHandler}
											value={phone.value}
											autoComplete='off'
										/>
									</div>
									{errors.phone && (
										<span className="error">
											{errors.phone.message}
										</span>
									)}
								</div>
								<div className="col-md-6 col-xl-4 input-wrapper required">
									<label className="input-label text-transform-initial">Type of Issue</label>
									<div className={`select-wrap price-select input-wrap ${errors.typeOfIssue ? 'has-error' : ''}`}>
										{/* <Select
											onChange={handleIssue}
											placeholder={'Please Select'}
											classNamePrefix="react-select"
											className='react-select-container form-control'
											options={issueOptions}
										/>
										*/}
										<select className='react-select-container form-control drop-down'
											{...register("typeOfIssue")}>
											{
												issueOptions.map(item => <option value={item.value}>{item.label}</option>)
											}
										</select>
										{errors.typeOfIssue && (
											<span className="error">
												{errors.typeOfIssue.message}
											</span>
										)}
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-12 input-wrapper required">
									<label className="input-label">Comment</label>
									<div className={`input-wrap ${errors.comment ? 'has-error' : ''}`}>
										<textarea
											type="text"
											className="form-control"
											{...register("comment")}
											placeholder='Tell us more about the services you provide'
										/>
									</div>
									{errors.comment && (
										<span className="error">
											{errors.comment.message}
										</span>
									)}
								</div>
							</div>
								<div className="col-md-6 col-xl-4 input-wrapper has-input-file">
									<label className="">Upload File</label>
									<div className="img-input-wrapper">
										<div className="img-input w-100">
											<i className="icon-plus"></i>
											Upload
											<input
												{...register("attachment")}
												type="file"
												accept="image/*"
												ref={attachmentName}
												onChange={imgHandler}
											/>
										</div>
									</div>
									{
										img !== "" ?
											<div className="img-thumbnail-wrapper attachment-name image">
												<img src={img} />
											</div>
											:
											''
									}
								</div>
						</div>
						<div className="btn-wrap">
							<button type='button' className='btn secondary-btn' onClick={()=>{navigate(-1)}}>Cancel</button>
							<button type='submit' className='btn'>submit</button>
						</div>
					</form>
				</div>
				<div className="card">
					<div className="contact-block">
						<div className="contact-wrap">
							<div className="contact-head">
								<i className="icon-mail"></i>
								<span className="text">Email</span>
							</div>
							<div className="contact-link">
								<a href='mailto:aladyyn.info@gmail.com'>aladyyn.info@gmail.com</a>
							</div>
						</div>
						<div className="contact-wrap">
							<div className="contact-head">
								<i className="icon-dialer"></i>
								<span className="text">Phone Number</span>
							</div>
							<div className="contact-link">
								<a href='tel:0123456789' className='tel-link'>+1 (012) 34-56-789</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

const ImageThumb = ({ image }) => {
	return <div className='thumbnail-wrap'>
		<img src={URL.createObjectURL(image)} alt={image.name} />
	</div>
};

export default CustomerService

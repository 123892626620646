import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import footerLogo from "assets/images/footer-logo.png";

const Footer = () => {
  const [showFooter, setShowFooter] = useState(true);
  const navigate = useNavigate();
  const login = useSelector((state) => state.login);
  const getProfileInfo = useSelector((state) => state.profileView);

  const location = useLocation();

	const token = localStorage.getItem("user_token")
	

  useEffect(() => {
    if (location.pathname === "/page-not-found") {
      setShowFooter(false);
    }
  }, []);

  const hanldeNav = () => {
    if (login.islogin) {
      // setTimeout(() => (window.location.href= '/seller-dashboard-company'), 3000);
      return;
    } else {
      setTimeout(
        () => navigate({ pathname: "/advertiser/create-account" }),
        100
      );
      // setTimeout(() => navigate({ pathname: '/' }), 100);
      // setTimeout(() =>  (window.location.href= '/become-seller'), 3000);
    }
  };

  const handleLogin = () => {
    if (
      getProfileInfo.data[0].type !== "Freelancer" &&
      getProfileInfo.data[0].type !== "Company"
    ) {
      localStorage.removeItem("user_token");
      setTimeout(() => (window.location.href = "/"), 10);
    }
  };

	return (
		<footer className={`footer ${showFooter ? '' : 'p-fix'}`}>
			{showFooter && <div className="container">
				<div className="footer-logo">
					<div className="img-wrap">
						<Link to='#'>
							<img src={footerLogo} alt="logo" />
						</Link>
					</div>
				</div>
				<ul className="footer-menu">
					<li>
						<Link to='#'>Help</Link>
					</li>
					<li>
						<Link to='#'>FAQ</Link>
					</li>
					<li>
						<Link to='#'>Careers</Link>
					</li>
					<li>
						<Link to='#'>Sustainability</Link>
					</li>
{/* 
					{
						token && token ? <li style={{ color: "#ffffff", fontWeight: "700" }}>Become an Lab Partner</li>
							:
							<li>
								<Link to='/register-Lab'>Become an Lab Partner</Link>
							</li>

					} */}

            <li>
              <Link to="/register-Lab">
                Become an Lab Partner
              </Link>
            </li>

            <li>
              <span /*onClick={hanldeNav}*/>Advertise at Aladyyn</span>
            </li>





					{/* {
						token && token ? <li style={{ color: "#ffffff", fontWeight: "700" }}>Lab Login</li>
							:
							<li>
								<Link to='/lab-sign'>Lab Login</Link>
							</li>

					} */}

					
					<li>
						<Link to='/lab-sign'>Lab Login</Link>
					</li>


				</ul>
				<div className="contact-wrap">
					<Link to='/customer-service'>
					<h5>Contact Us</h5>
					</Link>
					<address>20 Collyer Quay #09-01,<br />Singapore 049319</address>
				</div>
				<div className="social-links">
					<h5>Follow Us on</h5>
					<ul>
						<li>
							<Link to='#'>
								<i className="icon-fb"></i>
							</Link>
						</li>
						<li>
							<Link to='#'>
								<i className="icon-twitter"></i>
							</Link>
						</li>
						<li>
							<Link to='#'>
								<i className="icon-linkedin"></i>
							</Link>
						</li>
						<li>
							<Link to='#'>
								<i className="icon-insta"></i>
							</Link>
						</li>
					</ul>
				</div>
			</div>}
			<div className={`copyright-block ${showFooter ? '' : 'p-0'}`}>
				<div className="container">
					<span className="copyright-info">Copyright &copy; 2023 Aladyyn. All Rights Reserved</span>
				</div>
			</div>
		</footer>
	)
}

export default Footer;

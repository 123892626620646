import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Alert } from 'components/Alert/Alert';

const NonApprovedBanner = ({ item, setBannerId, deleteBannerHander }) => {
	const [showMenu, setShowMenu] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [show, setShow] = useState(false);
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);
	const indexOfLastTodo = page * limit;
	const indexOfFirstTodo = indexOfLastTodo - limit;

	const clickPagination = (index) => {
		setPage(index);
	}

	const clickPrev = (index) => {
		setPage(index - 1);
	}

	const clickNext = (index) => {
		setPage(index + 1);
	}

	const showMenuHandler = () => {
		setShowMenu(prev => !prev);
	}

	const removeBanner = (id) => {
		if (window.confirm("Are you sure you want to delete this banner ?")) {
			setTimeout(() => setShowMenu(false), 1000)
			deleteBannerHander(id)
			setTimeout(() => window.location.reload(), 3000)
		} else {
			setShowMenu(false);
		}
	}

	const showModalHandler = () => {
		setShowModal(prev => !prev);
	}

	return(
		<>
	<div className="banner-wrap" style={{ backgroundImage: `url(${item.path + item.webImage})`, backgroundSize: 'cover' }}>
		{/* <Alert /> */}
		<div className="more-btn menu-wrap">
			<button type='button' onClick={showMenuHandler}>
				<i className="icon-ellipsis"></i>
			</button>
			{
				showMenu ?
					<div className="menu">
						<ul className="menu-items">
							<li>
								<button className="menu-option" onClick={() => { setBannerId(item._id); setShowMenu(false) }}>Edit</button>
							</li>
							<li>
								<button className="menu-option delete" onClick={() => removeBanner(item._id)}>Delete</button>
							</li>
						</ul>
					</div>
					: null
			}
		</div>
		<div className='content-wrap'>
			<div className="form-check">
				<input className="form-check-input" disabled="true" type="checkbox" value="" />
			</div>
			{/* <div className="date-wrap">23/12 - 1/2</div> */}
			{/* <h3>{item.title}</h3> */}
			{/* <p>The most biggest sale event.</p> */}
			<div className="btn-wrap">
				{/* <Link to='/' className='btn text-transform-initial'>Discover more</Link> */}
			</div>
		</div>
	</div>
	</>
	) 
};

export default NonApprovedBanner;

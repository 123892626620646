import React, { useState } from 'react'

const CardInfo = () => {
	const [isInfoShowing, setIsInfoShowing] = useState();

	const toggleTextHandler = (event) => {
		event.preventDefault();
		setIsInfoShowing(!isInfoShowing);
	}
	return (
		<div className='card'>
			<div className="heading-wrap">
				<h4>Credit Card</h4>
				<div className="toggle-btn">
					<button type="button" onClick={toggleTextHandler}>
						<i className="icon-eye"></i>
					</button>
				</div>
			</div>
			<div className="information-block">
				<div className="information-card">
					<div className="info-label">Card Number:</div>
					{!isInfoShowing && <div className="billing-detail">**** **** **** 1234</div>}
					{isInfoShowing && <div className="billing-detail showing-details">1234 1234 1234 1234</div>}
					<div className="d-flex">
						<div className="left-block">
							<div className="info-label">Month/Year:</div>
							<div className="billing-detail mb-0">12/21</div>
						</div>
						<div className="right-block">
							<div className="info-label">CVV:</div>
							{!isInfoShowing && <div className="billing-detail mb-0">***</div>}
							{isInfoShowing && <div className="billing-detail mb-0 showing-details">123</div>}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default CardInfo;

// import * as actiontype from "../../actionsTypes";
import { endPoints } from "../../utils/endPoints";
import { axiosInstanceWithUserToken } from "../../utils/Axios";
import * as actiontype from "../actionType";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const sellerReg = (data, dispatch) => {
  dispatch({ type: "LOADER" })
  axiosInstanceWithUserToken
    .post(endPoints.REGISTER, data, {
      headers: {
        Authorization: ``,
      },
    })
    .then(async (response) => {
      dispatch({ type: "LOADERSTOP" })
      // console.log('response', response.data)
      if (response?.data?.status === "success") {
        toast.success(response?.data?.message);
        dispatch({
          type: actiontype.LOGIN_USER,
          payload: {
            islogin: true,
            ...response.data.data,
          },
        });
        localStorage.setItem("user_token", response?.data.data.token);
        // console.log('sfdjsdhfjdfs', response.data.data)
        // return
        // setTimeout(() => (window.location.href = "/seller-dashboard-freelancer"), 2000);
      } else {
        console.log("wrong");
        toast.error(response?.data?.message);
      }
    })
    .catch((errResponse) => {
      dispatch({ type: "LOADERSTOP" })
      console.log("success respoerrResponsese", errResponse);
    });
};

export default sellerReg;


export const labRegAction = (payload, navigate, callback) => {
 
  axiosInstanceWithUserToken.post(endPoints.LAB_REGISTER, payload, {
    headers: {
      Authorization: ``,
    },
  }).then((response) => {
    callback(response?.data)

    if (response?.data?.status === "success") {
      toast.success(response?.data?.message)
      setTimeout(() => window.location.href = '/lab-sign', 2000)
    }

    if (response?.data?.status === "error") {
      toast.error(response?.data?.message)
      navigate('/register-Lab')
    }

  }).catch((err) => {
    toast.error(err)
  })

}

import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Pagination = ({ loadMore, totalData, limit, }) => {

    const [page, setPage] = useState(1);


    const clickPrev = (index) => {
        let newpage = index - 1;
        setPage(newpage);
        loadMore(newpage);
    }

    const clickNext = (index) => {
        let newpage = index + 1;
        setPage(newpage);
        loadMore(newpage);
    }

    const clickPagination = (index) => {
        setPage(index);
        loadMore(index);
    }

    return (
        <nav className='pagination-wrap' aria-label="Page navigation example">
            <ul className="pagination">
                <li className={page === 1 ? "page-item previous disabled" : "page-item previous"}>
                    <a
                        className="page-link"
                        href="javascript:void(0)"
                        tabIndex="-1"
                        onClick={() => clickPrev(page)}
                        disabled={page === 1}
                    >
                        <i className="icon-arrow"></i>
                    </a>
                </li>
                {
                    [...Array(Math.ceil(totalData / limit))].map((item, i) =>
                        <li key={Math.random()} className={i + 1 === page ? "page-item active" : "page-item"} aria-current="page">
                            <a className="page-link" href="javascript:void(0)" onClick={() => clickPagination(i + 1)}>
                                {i + 1}
                            </a>
                        </li>
                    )
                }
                <li className={page === Math.ceil(totalData / limit) ? "page-item  disabled" : "page-item "}>
                    <a className="page-link" href="javascript:void(0)" onClick={() => clickNext(page)}>
                        <i className="icon-arrow"></i>
                    </a>
                </li>
            </ul>
        </nav>
    )
}

export default Pagination;
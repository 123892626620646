import React from 'react';
import { Link } from 'react-router-dom';

const PaymentComplete = () => {
	return <div className="success-card">
		<div className="wrap-icon">
			<i className="icon-success"></i>
		</div>
		<h4>Payment completed</h4>
		<p>Thanks! We have received your payment</p>
	</div>


	// <div className='modal-backdrop'>
	// 	<div className="success-card">
	// 		<div className="wrap-icon">
	// 			<i className="icon-success"></i>
	// 		</div>
	// 		<h4>Payment completed</h4>
	// 		<p>Thanks! We have received your payment</p>
	// 		<div className="btn-wrap">
	// 			<Link to='/' className='btn'>ok</Link>
	// 		</div>
	// 	</div>
	// </div>
};

export default PaymentComplete;
import React from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import service1 from 'assets/images/service-1.jpg';
import service2 from 'assets/images/service-2.jpg';
import service3 from 'assets/images/service-3.jpg';
import service4 from 'assets/images/service-4.jpg';
import SlickSlider from 'components/Slider/SlickSlider';

const Entertainment = () => {


	let settings = {
		infinite: false,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 1,
		responsive: [
			{
			  breakpoint: 1200,
			  settings: {
				slidesToShow: 3,
			  }
			},
			{
			  breakpoint: 992,
			  settings: {
				slidesToShow: 2,
			  }
			},
			{
			  breakpoint: 576,
			  settings: {
				slidesToShow: 1,
			  }
			}
		]
	};
	return (
		<div className='entertainment-tab'>
			{/* <div className="card">
				<div className="info-wrap">
					<h4>George P.Johnson Experience Marketing</h4>
					<div className="date-wrap">March 2020 — Until now</div>
					<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
				</div>
				<div className="info-wrap">
					<h4>Pico Global Services ltd</h4>
					<div className="date-wrap">January 2018 — March 2020</div>
					<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.</p>
				</div>
				<div className="info-wrap">
					<h4>Universal World Events</h4>
					<div className="date-wrap">September 2015 — November 2017</div>
					<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
				</div>
			</div> */}
			<h4>Portfolio</h4>
			<Slider {...settings}>
				<div className='slide'>
					<div className="img-wrap contains-video">
						<Link to='/'>
							<img src={service1} alt="service" />
							<div className="video-wrapper">
							</div>
						</Link>
					</div>
					<div className="service-info">
						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
					</div>
				</div>
				<div className='slide'>
					<div className="img-wrap contains-video">
						<Link to='/'>
							<img src={service2} alt="service" />
							<div className="video-wrapper">
							</div>
						</Link>
					</div>
					<div className="service-info">
						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
					</div>
				</div>
				<div className='slide'>
					<div className="img-wrap contains-video">
						<Link to='/'>
							<img src={service3} alt="service" />
							<div className="video-wrapper">
							</div>
						</Link>
					</div>
					<div className="service-info">
						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
					</div>
				</div>
				<div className='slide'>
					<div className="img-wrap contains-video">
						<Link to='/'>
							<img src={service4} alt="service" />
							<div className="video-wrapper">
							</div>
						</Link>
					</div>
					<div className="service-info">
						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
					</div>
				</div>
				<div className='slide'>
					<div className="img-wrap contains-video">
						<Link to='/'>
							<img src={service4} alt="service" />
							<div className="video-wrapper">
							</div>
						</Link>
					</div>
					<div className="service-info">
						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
					</div>
				</div>
    		</Slider>
			<h4>Certifications & Licenses</h4>
			<div className="certificate-slider">
				<SlickSlider />
			</div>
		</div>
	)
}

export default Entertainment;

import React, { useState, useEffect } from 'react';
import { useWindowSize } from 'hooks/window-size';
import { Link, useParams } from 'react-router-dom';
import rating1 from 'assets/images/rating-1.svg'
import rating2 from 'assets/images/rating-2.svg'
import rating3 from 'assets/images/rating-3.svg'
import rating4 from 'assets/images/rating-4.svg'
// import Rating from 'assets/images/rating.png';\
import starEmpty from 'assets/images/icons/icon-star-empty.svg';
import starFilled from 'assets/images/icons/icon-star-2.svg';
import starHalfFilled from 'assets/images/icons/icon-star-half.svg';
import services32 from 'assets/images/service-35.png';
import Select from 'react-select';
import { getServiceList } from 'store/action/getServiceAction';
import { useDispatch, useSelector } from 'react-redux';
import { addWishListing, removeWishListing } from 'store/action/addWishListAction';
import Rating from 'react-rating';
import { useNavigate } from "react-router-dom";
import { Alert } from 'components/Alert/Alert';

const SubCategory = () => {
    let navigate = useNavigate();
    let params = useParams()
    // console.log("paramssss", params)
    const dispatch = useDispatch()
    const [windowWidth] = useWindowSize();
    const [showFilters, setShowFilters] = useState(false);
    const getServiceInfo = useSelector((state) => state.homeService);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(12);
    const [showWish, setShowWish] = useState(false);
    const indexOfLastTodo = page * limit;
    const indexOfFirstTodo = indexOfLastTodo - limit;
    let checkIslogin = useSelector(state => state.login)
    const [hover, setHover] = useState(false)

    const showFiltersHandler = () => {
        setShowFilters(true);
    }
    const hideFiltersHandler = () => {
        setShowFilters(false);
    }

    const clickPagination = (index) => {
        setPage(index);
    }

    const clickPrev = (index) => {
        if (index > 0) {
            setPage(index - 1);
        }
        else {
            return
        }
    }

    const clickNext = (index) => {
        if (index > 1) {
            setPage(index + 1);
        }
        else {
            return
        }
    }

    const handleWishList = (item) => {
        if (checkIslogin.islogin) {
            if (item.wishlist === false) {
                let payload = {
                    serviceId: item.serviceId
                };
                addWishListing(payload)(dispatch)
            }
            else if (item.wishlist === true) {
                let payload = {
                    serviceId: item.serviceId
                };
                removeWishListing(payload)(dispatch)
            }
        }
        else {
            window.location.href = "/sign-in";
        }
    }

    useEffect(() => {
        getServiceList(params.cid, params.scid, params.name)(dispatch)
    }, [])

    const options = [
        { value: 'Price: Low to High', label: 'Price: Low to High' },
        { value: 'Price: High to Low', label: 'Price: High to Low' },
    ]
    const filtersClasses = showFilters ? 'filters-wrap active' : 'filters-wrap'

    return (
        <section className='sub-category-block'>
            <Alert />
            <div className="container">
                <div className="left-block">
                    {windowWidth < 767 && <div className="filter-btn">
                        <button onClick={showFiltersHandler}>Filters</button>
                    </div>}
                    <div className={filtersClasses}>
                        {windowWidth < 767 && <div className="close-btn">
                            <button type='button' onClick={hideFiltersHandler}>
                                <i className="icon-cross"></i>
                            </button>
                        </div>}
                        <h3>Filters</h3>
                        <form>
                            <div className="input-wrap">
                                <label className='input-label'>Test Type</label>
                                <div className="contains-checkbox">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" />
                                        <label className="form-check-label" >Test</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" />
                                        <label className="form-check-label" >Package</label>
                                    </div>
                                </div>
                            </div>
                            <div className="input-wrap">
                                <label className='input-label'>Location</label>
                                <div className="wrap-input contains-btn">
                                    <input type="text" className='form-control' placeholder='input search text' />
                                    <div className="btn-wrap">
                                        <button type='button'>
                                            <i className="icon-search"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="input-wrap">
                                <div className="contains-checkbox">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" />
                                        <label className="form-check-label" >Online</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" />
                                        <label className="form-check-label" >Offline</label>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="input-wrap">
                                <label className='input-label'>Distance</label>
                                <div className="multiple-inputs">
                                    <div className="wrap-input">
                                        <input type="text" className='form-control' placeholder='0 km' />
                                    </div>
                                    <div className="wrap-input">
                                        <input type="text" className='form-control' placeholder='800 km' />
                                    </div>
                                </div>
                            </div> */}
                            <div className="input-wrap">
                                <label className='input-label'>Language</label>
                                <div className="wrap-input">
                                    <select className='form-control drop-down' placeholder='input search text'>
                                        <option value="">Please select</option>
                                        <option value="">Language 1</option>
                                        <option value="">Language 2</option>
                                    </select>
                                </div>
                            </div>
                            <div className="rating-input">
                                <div className="input-label">Ratings</div>
                                <label className="custom-checkbox">
                                    <input type="radio" name="rating" />
                                    <span className="rating-wrap">
                                        <span className="img-wrap">
                                            <img src={rating1} alt="rating" />
                                        </span>
                                        <span className="text-wrap">& Up</span>
                                    </span>
                                    <span className="checkmark"></span>
                                </label>
                                <label className="custom-checkbox">
                                    <input type="radio" name="rating" />
                                    <span className="rating-wrap">
                                        <span className="img-wrap">
                                            <img src={rating2} alt="rating" />
                                        </span>
                                        <span className="text-wrap">& Up</span>
                                    </span>
                                    <span className="checkmark"></span>
                                </label>
                                <label className="custom-checkbox">
                                    <input type="radio" name="rating" />
                                    <span className="rating-wrap">
                                        <span className="img-wrap">
                                            <img src={rating3} alt="rating" />
                                        </span>
                                        <span className="text-wrap">& Up</span>
                                    </span>
                                    <span className="checkmark"></span>
                                </label>
                                <label className="custom-checkbox">
                                    <input type="radio" name="rating" />
                                    <span className="rating-wrap">
                                        <span className="img-wrap">
                                            <img src={rating4} alt="rating" />
                                        </span>
                                        <span className="text-wrap">& Up</span>
                                    </span>
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div className="input-wrap">
                                <label className='input-label'>Price Type</label>
                                <div className="contains-checkbox">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" />
                                        <label className="form-check-label" >Fixed</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" />
                                        <label className="form-check-label" >Hourly Rate</label>
                                    </div>
                                </div>
                            </div>
                            <div className="btns-wrap">
                                <button type='button' className='secondary-btn'>Reset</button>
                                <button type='submit' className='btn'>Search</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="right-block">
                    <div className="head-wrap">
                        <h1>{params.name}</h1>
                        <div className='sort-select'>
                            <label>Sort by</label>
                            <div className="sort-wrap">
                                <Select defaultValue={options[1]} classNamePrefix="react-select" className='react-select-container' options={options} />
                            </div>
                        </div>
                    </div>
                    <div className='sub-category_wrapper'>
                        {
                            getServiceInfo?.slice(indexOfFirstTodo, indexOfLastTodo).map(item =>
                                <Link to="#" className='service-block'>
                                    <div onClick={(e) => { e.preventDefault(); navigate(`/service-detail/${item._id}/${item.sellerId}`) }} className='service-image'>
                                        {/* {console.log('aaaaaaaaaaaaaaa', item)} */}
                                        {
                                            !item.serviceCover ?
                                                <img src={services32} alt="service" />
                                                :
                                                <img src={item.path + item.serviceCover} alt="service" />
                                        }
                                    </div>
                                    <div className='service-card'>
                                        <div className='service-name'>{item.title}</div>
                                        {
                                            item?.sellerData?.map(key =>
                                                key.type === "Company" ?
                                                    <div className='provider-info'>{key.businessName}
                                                    </div>
                                                    :
                                                    null
                                            )
                                        }
                                        <div className='seller-rating'>
                                            <div className='rating-wrap'>
                                                <div className='rating-image'>
                                                    {/* <img src={Rating} alt="rating" /> */}
                                                    <Rating
                                                        readonly
                                                        placeholderRating={item.averageRating}
                                                        value={item.averageRating}
                                                        emptySymbol={<img src={starEmpty} className="icon" />}
                                                        placeholderSymbol={<img src={starFilled} className="icon" />}
                                                        fullSymbol={<img src={starFilled} className="icon" />}
                                                    />
                                                </div>
                                                <div className='rating-number'>{item.totalReview} reviews</div>
                                            </div>
                                        </div>
                                        <div className='services-block'>
                                            <div className='price'>${item.price}</div>
                                            <button className='wishlist-btn'>
                                                <i className={item.wishlist ? 'icon-heart add' : "icon-heart"} onClick={() => handleWishList(item)}></i>
                                            </button>
                                        </div>
                                    </div>
                                </Link>
                            )
                        }
                        {
                            getServiceInfo.legth === 0 ?
                                <div>
                                    No data found ...
                                </div>
                                :
                                null
                        }
                    </div>
                    <nav className='pagination-wrap' aria-label="Page navigation example">
                        <ul className="pagination">
                            <li className={page === 1 ? "page-item previous disabled" : "page-item previous"}>
                                <a
                                    className="page-link"
                                    href="javascript:void(0)"
                                    tabIndex="-1"
                                    onClick={() => clickPrev(page)}
                                >
                                    <i className="icon-arrow"></i>
                                </a>
                            </li>
                            {
                                getServiceInfo.length > 0 ?
                                    [...Array(Math.ceil(getServiceInfo.length / 12))].map((item, i) =>
                                        <li className={i + 1 === page ? "page-item active" : "page-item"} aria-current="page">
                                            <a className="page-link" href="javascript:void(0)" onClick={() => clickPagination(i + 1)}>
                                                {i + 1}
                                            </a>
                                        </li>
                                    )
                                    : null
                            }


                            <li className={page === Math.ceil(getServiceInfo.length / 12) ? "page-item  disabled" : "page-item "}>
                                <a className="page-link" href="javascript:void(0)" onClick={() => clickNext(page)}>
                                    <i className="icon-arrow"></i>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </section>
    )
};

export default SubCategory;


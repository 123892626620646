import * as actiontype from "../actionType";
import { endPoints } from "../../utils/endPoints";
import { axiosInstance, axiosInstanceWithUserToken } from "../../utils/Axios";

export const verifyEmailAction = (payload,dispatch,toast, callback) => {


    const token = localStorage.getItem("user_token")

    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken.post(endPoints.VERIFY_EMAIL_LAB,payload, dispatch, toast, {
        headers: {
            Authorization: token,
        },
    }).then((response) => {
        dispatch({ type: "LOADERSTOP" })
        console.log("sandeep", response)
        toast.success(response?.data?.message)
        callback(response?.data?.status)

    }).catch((errResponse) => {
        dispatch({ type: "LOADERSTOP" })
        if (errResponse?.response?.data?.status === "error") {
            toast.error(errResponse?.response?.data?.message);
        }

    });


}

// if (response?.data?.status === "success") {
//     toast.success(response?.data?.message);
//     dispatch({
//       type: actiontype.LOGIN_USER,
//       payload: {
//         islogin: true,
//         ...response.data.data,
//       },
//     });
//     localStorage.setItem("user_token", response?.data.data.token);
//     // console.log('sfdjsdhfjdfs', response.data.data)
//     // return
//     // setTimeout(() => (window.location.href = "/seller-dashboard-freelancer"), 2000);
//   } else {
//     console.log("wrong");
//     toast.error(response?.data?.message);
//   }
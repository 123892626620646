import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import rating from 'assets/images/rating.png'

const Reviews = () => {
	return (
		<section className='reviews-block'>
			<div className="container">
				<Breadcrumb>
					<Breadcrumb.Item href="#">User Account</Breadcrumb.Item>
					<Breadcrumb.Item active>Notification</Breadcrumb.Item>
				</Breadcrumb>
				<h3>Reviews</h3>
				<div className="reviews">
				<div className='reviews-wrap'>
                        <div className='review-box'>
                            <div className='review-logo'>
                                <div className="text-image">U</div>
                                {/* <div className="user-img">
									<img src="" alt="" />
								</div> */}
                            </div>
                            <div className='review-text'>
                                <div className='review-details'>
                                    <div className='review-info'>
                                        <ul className='tests-wrap'>
                                            <li>COVID-19 Tests</li>
                                            <li>Anti-Body Tests</li>
                                            <li>COVID-19 Test Centre</li>
                                        </ul>
                                        <div className='review-name'>Han Solo</div>
                                        <div className='review-timing'>14.02.2021 9.41am</div>
                                    </div>
                                    <div className='review-rating'>
                                        <img src={rating} alt="rating" />
                                    </div>
                                </div>
                                <p>We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.</p>
                            </div>
                        </div>
                        <div className='review-box'>
                            <div className='review-logo'>
                                <div className="text-image">U</div>
                                {/* <div className="user-img">
									<img src="" alt="" />
								</div> */}
                            </div>
                            <div className='review-text'>
                                <div className='review-details'>
                                    <div className='review-info'>
                                        <ul className='tests-wrap'>
                                            <li>COVID-19 Tests</li>
                                            <li>Anti-Body Tests</li>
                                            <li>COVID-19 Test Centre</li>
                                        </ul>
                                        <div className='review-name'>Han Solo</div>
                                        <div className='review-timing'>14.02.2021 9.41am</div>
                                    </div>
                                    <div className='review-rating'>
                                        <img src={rating} alt="rating" />
                                    </div>
                                </div>
                                <p>We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.</p>
                            </div>
                        </div>
                        <div className='review-box'>
                            <div className='review-logo'>
                                <div className="text-image">U</div>
                                {/* <div className="user-img">
									<img src="" alt="" />
								</div> */}
                            </div>
                            <div className='review-text'>
                                <div className='review-details'>
                                    <div className='review-info'>
                                        <ul className='tests-wrap'>
                                            <li>COVID-19 Tests</li>
                                            <li>Anti-Body Tests</li>
                                            <li>COVID-19 Test Centre</li>
                                        </ul>
                                        <div className='review-name'>Han Solo</div>
                                        <div className='review-timing'>14.02.2021 9.41am</div>
                                    </div>
                                    <div className='review-rating'>
                                        <img src={rating} alt="rating" />
                                    </div>
                                </div>
                                <p>We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.</p>
                            </div>
                        </div>
                        <div className='review-box'>
                            <div className='review-logo'>
                                <div className="text-image">U</div>
                                {/* <div className="user-img">
									<img src="" alt="" />
								</div> */}
                            </div>
                            <div className='review-text'>
                                <div className='review-details'>
                                    <div className='review-info'>
                                        <ul className='tests-wrap'>
                                            <li>COVID-19 Tests</li>
                                            <li>Anti-Body Tests</li>
                                            <li>COVID-19 Test Centre</li>
                                        </ul>
                                        <div className='review-name'>Han Solo</div>
                                        <div className='review-timing'>14.02.2021 9.41am</div>
                                    </div>
                                    <div className='review-rating'>
                                        <img src={rating} alt="rating" />
                                    </div>
                                </div>
                                <p>We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.</p>
                            </div>
                        </div>
                        <div className='review-box'>
                            <div className='review-logo'>
                                <div className="text-image">U</div>
                                {/* <div className="user-img">
									<img src="" alt="" />
								</div> */}
                            </div>
                            <div className='review-text'>
                                <div className='review-details'>
                                    <div className='review-info'>
                                        <ul className='tests-wrap'>
                                            <li>COVID-19 Tests</li>
                                            <li>Anti-Body Tests</li>
                                            <li>COVID-19 Test Centre</li>
                                        </ul>
                                        <div className='review-name'>Han Solo</div>
                                        <div className='review-timing'>14.02.2021 9.41am</div>
                                    </div>
                                    <div className='review-rating'>
                                        <img src={rating} alt="rating" />
                                    </div>
                                </div>
                                <p>We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.</p>
                            </div>
                        </div>
                        <div className='review-box'>
                            <div className='review-logo'>
                                <div className="text-image">U</div>
                                {/* <div className="user-img">
									<img src="" alt="" />
								</div> */}
                            </div>
                            <div className='review-text'>
                                <div className='review-details'>
                                    <div className='review-info'>
                                        <ul className='tests-wrap'>
                                            <li>COVID-19 Tests</li>
                                            <li>Anti-Body Tests</li>
                                            <li>COVID-19 Test Centre</li>
                                        </ul>
                                        <div className='review-name'>Han Solo</div>
                                        <div className='review-timing'>14.02.2021 9.41am</div>
                                    </div>
                                    <div className='review-rating'>
                                        <img src={rating} alt="rating" />
                                    </div>
                                </div>
                                <p>We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.</p>
                            </div>
                        </div>
                    </div>
				</div>
			</div>
		</section>
	)
}

export default Reviews

import * as actiontype from "../actionType";
import { endPoints } from "../../utils/endPoints";
import { axiosInstanceWithUserToken } from "../../utils/Axios";
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

export const cartView = () => (dispatch) => {
    let token = localStorage.getItem("user_token");
    if (token) {
        dispatch({ type: "LOADER" })
        axiosInstanceWithUserToken.get(endPoints.GET_CART_DETAILS, {
            headers: {
                Authorization: token,
            },
        })
            .then(async (response) => {
                dispatch({ type: "LOADERSTOP" })
                // if (response?.data?.status === "success") {
                //     window.location.href = "/page-not-found";
                // }
                dispatch({
                    type: actiontype.CART_DETAILS,
                    payload: response.data.data
                })
            })
            .catch((errResponse) => {
                dispatch({ type: "LOADERSTOP" })
                // toast.error("error")
                console.log("success respoerrResponsese", errResponse)
            });
    } else {
        let cart = JSON.parse(localStorage.getItem("ala_cart"));
        console.log('ala_cart', cart)
        dispatch({
            type: actiontype.CART_DETAILS,
            payload: cart
        })
    }
};


import React, { useState, useRef, useEffect } from "react";
import { Breadcrumb, Tabs, Tab } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SellerProfile from "assets/images/description-image.png";
import Rating from "assets/images/rating.png";
import Select from "react-select";


import AddCategory from "components/Seller/AddCategory";
import AddAddress from "components/Seller/AddAddress";
import SellerInfoEdit from "components/Seller/SellerInfoEdit"
import SellerView from "components/Seller/SellerView"


import { categoryList } from "store/action/categoryListAction";
import { categoryFinalList } from "store/action/categoryListAction";

import { languageList } from "store/action/languageAction";
// import addCategoryData from 'store/reducer/newCategory.reducer';
import { Alert } from 'components/Alert/Alert'
import { addNewAddress } from "store/action/addAddressAction";
import { sellerAddData, editSellerInfo } from "store/action/sellerInformationAction";
import { finalCategoryData } from "store/reducer/category.reducer";
import LabInformation from "./LabInformation";
import AddressList from "./AddressList"
import { deleteNewAddress } from "store/action/addAddressAction";



const LabProfile = () => {
  const dispatch = useDispatch();
  let history = useNavigate()
  const navigate = useNavigate();
  const user = useSelector((state) => state.profileView);
  const finalCategory = useSelector((state) => state.finalCategoryData);
  const [editInfo, setEditInfo] = useState(false);
  const [showAddCategory, setShowAddCategory] = useState(false);
  const [showAddAddress, setShowAddAddress] = useState(false);
  const [cid, setCid] = useState("cid");

  const [addressList, setAddressList] = useState([]);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [addressId, setAddressId] = useState(null);

  const indexOfLastTodo = page * limit;
  const indexOfFirstTodo = indexOfLastTodo - limit;


 


  const editInfoHandler = () => {
    history('/lab-information-edit/' + cid)
  };

  useEffect(() => {
    setCid(finalCategory[0]?.categoryName)
  }, [finalCategory])

  useEffect(() => {
    languageList()(dispatch);
    categoryFinalList(callback => {
      if (callback.length === 0) {
        editInfoHandler()
      }
    })(dispatch);
  }, []);

  const deleteAddressHander = (id) => {
    deleteNewAddress({ addressId: id }, callback => {
      let newState = [...addressList];
      newState = newState.filter(item => item._id !== id);
      setAddressList(newState);
    })(dispatch)
  }


  const changePage = () => {
    console.log('dataaaaa', user)
    if (user?.data?.[0]?.type == "Company") {
      setTimeout(() => navigate({ pathname: '/seller-dashboard-company' }), 500);
    }
    else {
      setTimeout(() => navigate({ pathname: '/seller-dashboard-freelancer' }), 500);
    }
  };

  const setAddressIdHandler = (id) => {
    setAddressId(id);
    setTimeout(() => setShowAddAddress(true), 500);
  }

  const clickPagination = (index) => {
    setPage(index);
  }

  const clickPrev = (index) => {
    setPage(index - 1);
  }

  const clickNext = (index) => {
    setPage(index + 1);
  }


  // console.log('cccci', cid)

  return (
    <React.Fragment>
      <section className="seller-info">
        <Alert />
        <div className="container">
          <Breadcrumb>
            <Breadcrumb.Item onClick={changePage}>Lab Account</Breadcrumb.Item>
            <Breadcrumb.Item active>Lab Information</Breadcrumb.Item>
          </Breadcrumb>
          <div className="section-header">
            <div className="header-left">
              {!editInfo && <h1>Lab Information</h1>}
              {editInfo && <h1>Edit  Information</h1>}
            </div>
            <div className="header-right">
              <button className="btn edit" onClick={editInfoHandler}>
                Edit
              </button>
            </div>
          </div>
          <Tabs
            defaultActiveKey={finalCategory[0] && finalCategory[0].categoryName}
            id="uncontrolled-tab-example"
            className="mb-3"
            onSelect={(k) => setCid(k)}
          >
            {/* {
              finalCategory?.map(item =>
                <Tab eventKey={item.categoryName} title={item.categoryName} key={Math.round()}>
                  {
                    item.subcategoryData?.length > 0 ?
                      <LabInformation item={item} />
                      : ""
                      // <div>Not found any Category please add.</div>
                  }
                </Tab>
              )} */}



            <div className="address-table">
              <div className="table-wrap">
                {
                  addressList?.slice(indexOfFirstTodo, indexOfLastTodo).map(item =>
                    <AddressList item={item} deleteAddressHander={deleteAddressHander} setAddressId={setAddressIdHandler} />
                  )
                }
                {
                  addressList.length === 0 ?
                    <div>No address found</div>
                    : null
                }
              </div>

              {
                addressList.length > 0 &&
                <nav
                  className="pagination-wrap"
                  aria-label="Page navigation example"
                >
                  <ul className="pagination">
                    <li className={page === 1 ? "page-item previous disabled" : "page-item previous"}>
                      <a
                        className="page-link"
                        href="javascript:void(0)"
                        tabIndex="-1"
                        onClick={() => clickPrev(page)}
                      >
                        <i className="icon-arrow"></i>
                      </a>
                    </li>
                    {
                      addressList.length > 0 ?
                        [...Array(Math.ceil(addressList.length / 5))].map((item, i) =>
                          <li className={i + 1 === page ? "page-item active" : "page-item"} aria-current="page">
                            <a className="page-link" href="javascript:void(0)" onClick={() => clickPagination(i + 1)}>
                              {i + 1}
                            </a>
                          </li>
                        )
                        : null
                    }


                    <li className={page === Math.ceil(addressList.length / 5) ? "page-item  disabled" : "page-item "}>
                      <a className="page-link" href="javascript:void(0)" onClick={() => clickNext(page)}>
                        <i className="icon-arrow"></i>
                      </a>
                    </li>
                  </ul>
                </nav>
              }
            </div>

          </Tabs>







          {/* {editInfo && (
            <div className="btn-wrap">
              <input className="secondary-btn" type="reset" value="cancel" />
              <input className="btn" onClick={submitHandler} type="submit" value="save" />
            </div>
          )} */}
        </div>
      </section>
      {showAddCategory && <AddCategory onCloseModal={setShowAddCategory} />}
      {showAddAddress && <AddAddress onCloseModal={setShowAddAddress} />}
    </React.Fragment>
  );
};
// const ImageThumb = ({ image }) => {
//     return <div className='thumbnail-wrap'>
//         <img src={URL.createObjectURL(image)} alt={image.name} />
//     </div>
// };

export default LabProfile;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation, useParams, useSearchParams } from 'react-router-dom';
import { Breadcrumb } from 'react-bootstrap';
import { Controller, useForm } from "react-hook-form";
import Select from 'react-select';
import { sellerrServiceAdd, getAddressList, getServiceDetail, editSellerrServiceAdd } from 'store/action/sellerServiceAddAction';
import { sellerrServiceCategory } from 'store/action/sellerServiceAddAction';
import { countryList } from 'store/action/countryList';
import { stateList } from 'store/action/stateList';
import { cityList } from 'store/action/cityListAction';
import { uploadFiles1 } from 'store/action/optionalinfo'
import { Alert } from "../../../../components/Alert/Alert"
import queryString from 'query-string'
import profileImg from 'assets/images/employee.png'
import { labTestAdd, updateLabTest, editLabTest, labPackageAdd, packageCheckList, getAddressPackages, editLabPackage, updateLabPackage, addLogo } from 'store/action/labTestAddAction';
import { useNotify } from '../../../../components/Alert/Alert'
import { getValue } from '@testing-library/user-event/dist/utils';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const PackageService = (props) => {

	const [searchParams, setSearchParams] = useSearchParams()
	
	const search = searchParams.get('to')
	useEffect(()=>{
		if(search){
			setTimeout(()=>{
				let ele = document.getElementById("logo-accreditation")
				ele.scrollIntoView()
			},500)
		}
	},[search])

	const [editorData, setEditorData] = useState('<ul><li></li></ul>');
	const [editorData2, setEditorData2] = useState('<ul><li></li></ul>');

    const editorConfig = {
        toolbar: {
        items: [
            'bulletedList',
            'bold',
            'italic'
        ],
        typing: {
            autoNumbering: 'bulleted',
        },
        }
    };


	let id = useParams();
	console.log("id--------", id)

	let toast = useNotify();

	const navigate = useNavigate();
	const dispatch = useDispatch();
	let history = useNavigate();
	let location = useLocation();
	const [portfolioState, setPortfolioState] = useState([{ portfolioDescription: '', media: '', mediaSrc: "", fileType: null }])
	const [state, setState] = useState({
		categoryId: "",
		forTime: '',
		description: '',
		remoteService: 0,
		serviceCover: "",
		serviceCoverSrc: "",
		addressId: ''
	})



	console.log("portfolioDescription", state?.description)

	const [addressList, setAddress] = useState([])

	const [addressListPackage, setAddressListPackage] = useState(null)
	const [subCategoryList, setSubCategoryList] = useState([]);
	const getCategoryList = useSelector((state) => state.sellerServiceCategory);
	console.log("addressListPackage", addressListPackage)
	const getCountry = useSelector(state => state.countryData)
	const getState = useSelector(state => state.stateData)
	const getCity = useSelector(state => state.cityData)
	const [countryname, setCountryname] = useState("");
	const [priceIsChecked, setPriceIsChecked] = useState(false);
	const user = useSelector((state) => state.profileView);
	const { register, formState: { errors }, setError, clearErrors, getValues, handleSubmit, setValue, reset, control } = useForm({
		reValidateMode: "onChange"
	});

	const [catId, setCatId] = useState()
	const [dataForEdit, setDataForEdit] = useState()
	const [packageIdList, setPackageIdList] = useState([])
	const [packageAddressList, setPackageAddressList] = useState({})
	const [totalPrice, setTotalPrice] = useState(0)
	const [logoAccreditation, setLogoAccreditation] = useState(null)
	console.log("packageIdList", packageIdList)

	const [idState, setIdState] = useState(null)
	const [idCity, setIdCity] = useState(null)

	const [packageIdListError, setPackageIdListError] = useState(false)

	const [packageList, setPackageList] = useState(null)
	console.log("packageList", packageList)

	const changePage = () => {
		console.log('dataaaaa', user)
		if (user?.data?.[0]?.type == "Company") {
			setTimeout(() => navigate({ pathname: '/seller-dashboard-company' }), 500);
		}
		else {
			setTimeout(() => navigate({ pathname: '/seller-dashboard-freelancer' }), 500);
		}
	};


	useEffect(() => {
		sellerrServiceCategory()(dispatch);
		countryList()(dispatch);
		packageCheckList(toast, setPackageList)
	}, []);

	useEffect(() => {
		if (packageIdList && packageList?.data && packageList?.data?.length) {
			let temp = []
			packageList?.data?.forEach((item) => {
				if (packageIdList.includes(item._id)) {
					temp = [...temp, item?.addressIds?.[0]]
				}
			})
			getAddressPackages({ testIds: temp }, setAddressListPackage)
		}
	}, [packageList, packageIdList])



	// useEffect(()=>{


	// 	if(state?.categoryId){

	// 		getAddressList({ sellerInformationId: state.categoryId,countryId: "651162039f823cd30dbd3f0e", stateId: idState, cityId: idCity  }, callback => {
	// 			setAddress(callback)
	// 		}
	// 		)(dispatch);
	// 	}
	// },[state?.categoryId])


	useEffect(() => {

		const payload = {
			packageId: id
		}



		if (id.id) {
			editLabPackage(dispatch, payload, setLogoAccreditation, (callback) => {


				// getAddressList({ sellerInformationId: callback?.[0]?.addressData?.[0]?.sellerInformationId }, callback => {
				// 	setAddress(callback)
				// })(dispatch)
				const originalUrl = callback?.[0]?.imagePath + callback?.[0]?.packageCoverImage
				const encodedUrl = encodeURI(originalUrl);


				setState((prevState) => ({
					...prevState,
					serviceCoverSrc: encodedUrl,
					categoryId: callback?.[0]?.categoryData?.[0]?._id,
					addressId: callback?.[0]?.addressIds?.[0],
					description: callback?.[0]?.description,
					serviceCover: callback?.[0]?.packageCoverImage
				}));



				// setDataForEdit(callback)
				setValue("title", callback?.[0]?.package_title)
				setValue("categoryId", callback?.[0]?.categoryData?.[0]?._id)
				setValue("price", callback?.[0]?.package_discountedPrice)
				setValue("serviceCover", callback?.[0]?.imagePath + callback?.[0]?.packageCoverImage)
				setValue("testFor", callback?.[0]?.package_recommendedFor)
				setValue("testPreparations", callback?.[0]?.testPreparations)
				setValue("address1", callback?.[0]?.addressIds)
				setLogoAccreditation({ ...logoAccreditation, imageName: callback?.[0]?.logoImage })
				setEditorData(callback?.[0]?.dos?.[0])
				setEditorData2(callback?.[0]?.dont?.[0])



				setPackageIdList(callback?.[0]?.testIds)
				// let total = 0
				// callback?.[0]?.testIds?.forEach((item)=>{
				// 	total=total+item
				// })
				setTotalPrice(callback?.[0]?.package_price)


				let galleryDescription = [];
				console.log("galleryDescription", galleryDescription)
				// let media = [];
				// let fileType = [];

				// portfolioState.forEach(item => {
				// 	galleryDescription.push(item.portfolioDescription)
				// 	media.push(item.media)
				// 	fileType.push(item.fileType)
				// })

				// callback?.[0]?.testCertificate.forEach((item) => {
				// 	console.log("img----", item)
				// 	portfolioState.push(item.test_description)
				// 	console.log("galleryDescriptionData", portfolioState)
				// 	// setValue("test_description",portfolioState.portfolioDescription)

				// })

				console.log("callback?.[0]?.packageImages", callback?.[0]?.packageImages)

				let newPort = callback?.[0]?.packageImages.map(item => ({
					portfolioDescription: item.package_description,
					mediaSrc: item.package_certificate ? callback?.[0]?.imagePath + item.package_certificate : "",
					fileType: item.fileType,
					media: item.package_certificate ? item.package_certificate : ""

				}))

				console.log("newPort", newPort)

				setPortfolioState(newPort);

			})
		}
	}, [id, setValue])

	const portfolioAddMore = (e) => {
		e.preventDefault();
		let newState = [...portfolioState];
		newState.push({ galleryDescription: '', media: '', mediaSrc: "", type: '' })
		setPortfolioState(newState);
	}

	const portfolioHandlerChange = (e, i, name) => {
		let newState = [...portfolioState];
		if (name === "media") {
			console.log("222222222222222")
			// if(bytesToSize(e.target.files[0].size)===)
			if (Math.round(e.target.files[0].size / 1024) < 25000) {
				let formData = new FormData();
				formData.append('media', e.target.files[0]);
				formData.append('path', "service");
				uploadFiles1(formData, callback => {
					console.log(callback)
					newState[i][name] = callback.imageName;
					newState[i].fileType = e.target.files[0].type;
					newState[i].mediaSrc = callback.path + callback.imageName;
					setPortfolioState(newState);
				})(dispatch);
			} else {
				alert("The file size should not exceed 25 MB");
			}
		} else {
			newState[i][name] = e.target.value;
			setPortfolioState(newState);
		}
	}

	const handlerChange = (e, name) => {

		if (name === "remoteService") {
			let val = e.target.checked ? 1 : 0
			setState({ ...state, [name]: val })
		} else if (name === "serviceCover") {
			if (e.target.files.length > 0) {

				let formData = new FormData();
				formData.append('media', e.target.files[0]);
				formData.append('path', "service");
				uploadFiles1(formData, callback => {
					setState({ ...state, serviceCover: callback.imageName, serviceCoverSrc: callback.path + callback.imageName })
				})(dispatch);
			}
		} else {
			setState({ ...state, [name]: e.target.value })
		}

	}

	const priceOptions = [
		{ value: 'per hour', label: 'Per hour' },
		{ value: 'per day ', label: 'Per Day' },
	]

	const ctegoryHandler = (e) => {
		console.log('dss', e.target.value)
		setValue("categoryId", e.target.value)
		clearErrors("categoryId")
		setCatId(e.target.value)
		setState({ ...state, categoryId: e.target.value });
		let newState = getCategoryList.filter(item => item.categoryId === e.target.value);
		console.log('newState', newState)
		setSubCategoryList(newState[0]);
		getAddressList({ sellerInformationId: newState[0].sellerInformationId }, callback => {
			setAddress(callback)
		})(dispatch)
	}

	const getStateHandler = (e) => {
		let val = e.target.value;
		setIdCity(e.target.value)
		setCountryname(val)
		stateList({ countryId: "651162039f823cd30dbd3f0e" }, dispatch);
		getAddressList({ sellerInformationId: state.categoryId, countryId: "651162039f823cd30dbd3f0e" }, callback => {
			setAddress(callback)
		})(dispatch);
		setValue("stateId", "")
		setValue("cityId", "")
	}



	useEffect(() => {
		stateList({ countryId: "651162039f823cd30dbd3f0e" }, dispatch);
		// getAddressList({ sellerInformationId: state.categoryId, countryId: "651162039f823cd30dbd3f0e" }, callback => {
		// 	setAddress(callback)
		// })(dispatch);
		setValue("stateId", "")
		setValue("cityId", "")
	}, [])

	const getCityHandler = (e) => {
		let temp = []
		packageList?.data?.forEach((item) => {
			if (packageIdList.includes(item._id)) {
				temp = [...temp, item?.addressIds?.[0]]
			}
		})
		getAddressPackages({ testIds: temp, stateId: e.target.value, cityId: getValues("cityId") }, setAddressListPackage)

		setIdState(e.target.value)
		cityList({ countryId: "651162039f823cd30dbd3f0e", stateId: e.target.value })(dispatch);
		// getAddressList({ sellerInformationId: catId, countryId: "651162039f823cd30dbd3f0e", stateId: e.target.value, cityId: getValues("cityId") }, callback => {
		// 	setAddress(callback)
		// })(dispatch);
		setValue("cityId", "")
	}

	const getAddCity = (e) => {
		let temp = []
		packageList?.data?.forEach((item) => {
			if (packageIdList.includes(item._id)) {
				temp = [...temp, item?.addressIds?.[0]]
			}
		})
		getAddressPackages({ testIds: temp, stateId: getValues("stateId"), cityId: e.target.value }, setAddressListPackage)

		let stateId = getValues("stateId");
		// getAddressList({ sellerInformationId: state.categoryId, countryId: countryname, stateId: stateId, cityId: e.target.value }, callback => {
		// 	setAddress(callback)
		// })(dispatch);
	}

	console.log("state", state)

	const onSubmit = (data) => {

		if(data.price >= totalPrice){
			setError("price")
			document.getElementById('discount1').scrollIntoView({ behavior: 'smooth' })
			return;
		}

		if (packageIdList?.length === 0) {
			setPackageIdListError(true)
			return;
		}

		// let id = queryString.parse(location.search);

		const radios = document.querySelectorAll('input[name="flexRadioDefault"]');
		const selected = [...radios].some(radio => radio.checked);
		// if (!selected) {
		// 	let gErrorMsg = "Select address";
		// 	console.log(gErrorMsg);
		// }

		// if (state.categoryId === "") {
		// 	var elmnt = document.getElementById("categoryId");
		// 	elmnt.scrollIntoView();
		// 	setError("categoryId")
		// 	return false;
		// }

		let galleryDescription = [];
		let media = [];
		let fileType = [];

		portfolioState.forEach(item => {
			galleryDescription.push(item.portfolioDescription)
			if (item.media.length > 0) {
				media.push(item.media)
			}
			fileType.push(item.fileType)
		})

		let payload = {
			package_title: data.title,
			// categoryIds: state.categoryId,
			package_price: totalPrice,
			package_discountType: "Fixed",
			package_discountedPrice: data.price,
			description: state.description,
			packageCoverImage: state.serviceCover,
			addressId: state.addressId,
			package_recommendedFor: data.testFor,
			packageImages: media.length > 0 ? media : [],
			packageDescription: [],
			fileType: fileType?.length > 0 ? fileType : [],
			testIds: packageIdList,
			logoImage: logoAccreditation?.imageName?.length > 0 ? logoAccreditation?.imageName : [],
			dos:editorData,
			dont:editorData2

		}

		if (id.id) {
			payload.packageId = id.id; // Include testId only if it exists
		}

		console.log("payload----->", payload)

		if (id.id) {
			updateLabPackage(payload, dispatch, toast, callback => {
				if (callback) {
					setTimeout(() => history("/list_lab_package"), 1000);
					// window.location = "/list_lab_test"
				}
			})
		} else {
			labPackageAdd(payload, dispatch, toast, (callback) => {
				if (callback) {
					setTimeout(() => history("/list_lab_package"), 1000);
					// window.location = "/list_lab_test"
				}
			})
		}


	};

	const removeFile = (i) => {
		let newState = [...portfolioState];
		newState[i].fileType = "";
		newState[i].media = "";
		newState[i].mediaSrc = "";
		newState[i].portfolioDescription = ""
		setPortfolioState(newState);
	}


	const priceValidation = (e) => {
		let val = e.target.value;
		var a = /(^[0-9]+[-]*[0-9]+$)/;
		if (a.test(val) == true) {
			// alert("Match Criteria")
			clearErrors("price")
		}
		else {
			setError("price")
		}
	}

	const getDaysOpen = (item) => {
		if (item.daysOpened.length === 1) {
			return item.daysOpened[0].day
		} else {
			return item.daysOpened[0].day + "-" + item.daysOpened[item?.daysOpened?.length - 1].day
		}
	}

	const handlePackageSelect = (e, id, price, addressId) => {
		setPackageIdListError(false)
		setState((prev) => ({ ...prev, addressId: "" }))
		if (e.target.checked) {
			setPackageAddressList({ ...packageAddressList, [id]: addressId })
			setPackageIdList([...packageIdList, id])
			setTotalPrice(totalPrice + price)
			let tempList = []
			Object.keys({ ...packageAddressList, [id]: addressId }).forEach((item) => {
				tempList = [...tempList, { ...packageAddressList, [id]: addressId }[item]]
			})
			getAddressPackages({ testIds: tempList }, setAddressListPackage)
		}
		else {
			let listing = { ...packageAddressList }
			delete listing[id]
			setPackageAddressList(listing)
			setPackageIdList(packageIdList.filter(item => item !== id))
			let tempList = []
			Object.keys(listing).forEach((item) => {
				tempList = [...tempList, listing[item]]
			})
			getAddressPackages({ testIds: tempList }, setAddressListPackage)
			setTotalPrice(totalPrice - price)
		}
	}

	console.log("packageAddressList", packageAddressList)

	const getDaysClose = (item) => {
		let dayOptions = [
			"Monday",
			"Tuesday",
			"Wednesday",
			"Thursday",
			"Friday",
			"Saturday",
			"Sunday"
		];
		let closedDays = dayOptions.filter((ditem, i) => i > item.daysOpened.length - 1)
		// console.log('closedDays', closedDays)
		if (closedDays.length === 1) {
			return closedDays[0]
		} else {
			return closedDays[0] + "-" + closedDays[closedDays.length - 1]
		}
	}


	const handleLogoIcons = (e) => {
		// setLogoAccreditation(e.target.files)
		console.log("logoAccreditation",logoAccreditation)
		let temp = true;
		for (let element of e.target.files) {
			if (Math.round(element.size / 1024) > 1024) {
				temp = false;
			}
		}

		if(logoAccreditation?.imageName?.length>=10 && e.target.files?.length>0){
			alert("You can not upload more than 10 icons");
			return
		}

		if(e.target.files?.length>10){
			alert("You can not upload more than 10 icons");
			return
		}

		if(logoAccreditation?.imageName?.length+e.target.files?.length>10){
			alert("You can not upload more than 10 icons");
			return
		}

		if (temp) {
			let formData = new FormData();
			for (let element of e.target.files) {
				formData.append("media", element);
			}
			addLogo(formData, setLogoAccreditation, logoAccreditation, dispatch)
		} else {
			alert("The file size should not exceed 1 MB");
		}
	}

	const handleRemoveLogo = (element) => {
		let newState = logoAccreditation?.imageName?.filter(item => item !== element);
		setLogoAccreditation({ ...logoAccreditation, imageName: newState })
	}

	const goToBackHandler =()=>{
		navigate('/list_lab_package')
	}

	return (
		<section className="add-service">
			<Alert />
			<div className="container">
				<Breadcrumb>
					<Breadcrumb.Item onClick={() => setTimeout(() => navigate({ pathname: '/lab-dashboard' }), 10)}>Lab Account</Breadcrumb.Item>
					<Breadcrumb.Item onClick={() => history(-1)}>Manage Packages</Breadcrumb.Item>
					<Breadcrumb.Item active>{id.id ? "Edit Package" : "Create New Package"}</Breadcrumb.Item>
				</Breadcrumb>
				<h1>New Package</h1>
				<div className="form-wrap">
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="card">
							<div className="row inputs-wrapper">
								<div className="col-lg-8">
									<div className="input-wrapper required">
										<label className="input-label">Package Title</label>
										<div className={`input-wrap ${errors.title ? 'has-error' : ''}`}>
											<input
												type="text"
												className="form-control"
												{...register("title",
													{ required: true }
												)}
												// value={dataForEdit?.[0]?.title}
												placeholder="Package Title"
											/>
										</div>
										{errors.title && (
											<span className="error">
												Please enter title
											</span>
										)}
									</div>
									{/* <div className="content-wrap">
										<div className="input-wrapper required">
											<label className="input-label">Category</label>
											<div className="select-wrap multiple-selects">
												<select
													onChange={ctegoryHandler}
													className='form-control'
													name='categoryId'
													id="categoryId"
													value={state.categoryId}
												>
													<option hidden value={""}>Please select category</option>
													{
														getCategoryList?.map(item =>
															<option value={item.categoryId}>{item.categoryName
																? item.categoryName
																:
																state.categoryId
															}</option>
														)
													}
												</select>
												{errors.categoryId && (
													<span className="error">
														Please choose category
													</span>
												)}
											</div>
										</div>
									</div> */}

									<label className="input-label">Select Test</label>
									<div className='package-checkboxes-wrapper'>



										{
											packageList?.data?.map((item) => {
												return <div className='package-checkbox' key={item?._id}>
													<input checked={packageIdList.includes(item._id)}
														type='checkbox' name='package'
														onChange={(e) => handlePackageSelect(e, item._id, item.price, item.addressIds[0])}
														id={`check${item._id}`} />
													<label className='package-label' htmlFor={`check${item._id}`}>{item.title}</label>
												</div>
											})
										}

										{/* {errors.testFor?.type === "required" && (
											<span className="error text-left" >
												Please select gender
											</span>
										)} */}
									</div>
									{packageIdListError && <span className='error fixed'>Please choose any option</span>}
									<div className='total-pricing-count'>
										<div>Total Price: &#x20B9;{totalPrice}</div>
									</div>
									<div className="multiple-inputs">
										<div className="input-wrapper">
											<label className="input-label">Discounted price</label>
											<div className={`with-icon input-wrap multiple-inputs ${errors.price ? 'has-error' : ''}`}>
												<Controller
													name="price"
													control={control}
													render={({ field }) => (
														<input
														type="number"
														id="discount1"
														className="form-control price-input"
														{...register("price")}
														placeholder="Discounted price"
														maxLength={10}
														onBlur={priceValidation}
														{...field}
														onChange={(e) => {
															field.onChange(e);
															clearErrors("price");
														}}
														/>
														)}
													/>
												<i className='input-icon'>₹</i>
											</div>
											{errors.price && (
												<span className="error" style={{position: "static",padding: "3px 0 0 0", width:"200px"}}>
													Discount price can not be equal or greater than test prices
												</span>
											)}
										</div>
									</div>
									<div className='lab-radios radio-btn-warp' style={{ position: "relative" }}>

									Test For :


									<input type="radio" id="male"
										// checked={selectedOption === 'online'}

										{...register("testFor",
											{ required: true }
										)}
										value="Male" className='testBooking'
										autoComplete="off"
									// defaultChecked={dataForEdit?.[0]?.testFor === "Male"} 
									// defaultValue={dataForEdit?.[0]?.testFor === "Male" ? "Male" : ""}
									/>
									<label htmlFor="male">Male</label>


									<input type="radio" id="female"
										// checked={selectedOption === 'offline'}

										{...register("testFor",
											{ required: true }
										)}
										value="Female" className='testBooking'
										autoComplete="off"
									// defaultValue={dataForEdit?.[0]?.testFor === "Female" ? "Female" : ""} 
									/>
									<label htmlFor="female">Female</label>


									<input type="radio" id="all"
										// checked={selectedOption === 'both'}

										{...register("testFor",
											{ required: true }
										)}
										value="All" className='testBooking'
										autoComplete="off"
									// defaultValue={dataForEdit?.[0]?.testFor === "All" ? "All" : ""} 
									/>
									<label htmlFor="all">Both</label>

									{errors.testFor?.type === "required" && (
										<span className="error">
											Please select gender
										</span>
									)}
								</div>
								<div className="">
									<div className="input-wrapper">
										<label className="input-label">Package description</label>
										<div className={`input-wrap has-wordcount ${errors.description ? 'has-error' : ''}`}>
											<textarea
												className="form-control"
												{...register("testPreparations")}
												maxLength={3000}
												value={state.description}
												onChange={(e) => handlerChange(e, "description")}
												placeholder="Brief description of the service"
											/>

										</div>
										<label className="input-label required input-info position-static">Comment should not exceed 3000 characters.</label>
										{errors.description && (
											<span className="error">
												{errors.description.message}
											</span>
										)}
									</div>

									{/* <div className="input-wrap checkbox-wrap">
										<div className="form-check">
											<input
												className="form-check-input"
												type="checkbox"
												onChange={(e) => handlerChange(e, "remoteService")}
												id="flexCheckChecked"
												checked={state.remoteService === 1 ? true : false}
											/>
											<label className="form-check-label" htmlFor="flexCheckChecked" {...register("remoteService")}>
												I can provide this service remotely
											</label>
										</div>
									</div> */}

								</div>
								</div>
								
								<div className="col-lg-4">
									<div className="img-upload">
										<label>Upload Package Cover</label>

										<div className="img-container">
											{
												state?.serviceCoverSrc && state?.serviceCover ?
													<div className="img-placeholder">
														<img
															src={state?.serviceCoverSrc}
															className="" />
														{/* <img src={profileImg} /> */}
													</div>
													: <div className="img-placeholder">
														<img

															src={profileImg}
															// <img src={state.serviceCover}
															alt="Service Cover"
															type=''
														/>
													</div>
											}
										</div>
										<div className="upload-btn">
											<input
												type="file"
												accept="image/*"
												onChange={(e) => handlerChange(e, "serviceCover")}
											/>
											<button
												type='button'

												className='secondary-btn w-100'>Upload Photo</button>
										</div>
									</div>



								</div>
								

								

								
								<div className='col-12 editor-wrapper'>
								<div className='ck-editor-number'>
									<label className='input-label'>Do's</label>
										<CKEditor
											editor={ClassicEditor}
											data={editorData}
											config={editorConfig}
											// onRe ady={(editor) => {
											// // You can set up an event listener here if needed
											// }}
											onChange={(event, editor) => {
												setEditorData(editor.getData());
											}}
										/>   
                                </div>
								<div className='ck-editor-number'>
								<label className='input-label'>Don'ts</label>
										<CKEditor
											editor={ClassicEditor}
											data={editorData2}
											config={editorConfig}
											// onRe ady={(editor) => {
											// // You can set up an event listener here if needed
											// }}
											onChange={(event, editor) => {
												setEditorData2(editor.getData());
											}}
										/>   
                                </div>
								</div>
							</div>
						</div>
						<h3>Operating Address</h3>
						{/* {
							addressList.length !== 0 ? */}
						<div className='selects-wrap'>
							<div className="select-wrap">
								<select className="form-control" {...register("stateId")} name="stateId" id="stateId" onChange={getCityHandler}>
									<option value={""}>Please select state</option>
									{
										getState?.map(item =>
											<option value={item._id}>{item.name}</option>
										)
									}
								</select>
							</div>
							<div className="select-wrap">
								<select className="form-control" {...register("cityId")} name="cityId" id="cityId" onChange={getAddCity}>
									<option value={""}>Please select city</option>
									{
										getCity?.map(item =>
											<option value={item._id}>{item.name}</option>
										)
									}
								</select>
							</div>
						</div>
						{/* :
								""
						} */}
						{/* {
							addressList.length !== 0 ? */}
						{addressListPackage?.data?.length > 0 ? <div className="card address-card inputs-wrapper">
							<label className="text-capitalize address-label show-star">Select Address</label>
							<div className={`input-wrap ${errors.address1 ? 'has-error' : ''}`}>
							</div>
							{errors.address1 && (
								<span className="error">
									Please select address
								</span>
							)}

							{/* <p className="error">{gErrorMsg}</p> */}
							{/* {console.log("addressListPackage?.data", addressListPackage?.data, state.addressId)} */}
							<div className="radio-wrap">
								{
									addressListPackage?.data?.map(item =>
										<div className="radio-left checked" key={item._id}>
											<div className="form-check radio-btn">

												<input
													className="form-check-input"
													// required
													type="radio"
													{...register("address1",
														{ required: true }
													)}
													value={item._id}
													name="flexRadioDefault"
													id="address1"
													checked={item._id === state.addressId}
													onChange={(e) => handlerChange(e, "addressId")}
												/>
												<label className="form-check-label" htmlFor="address1">
													{item.addressLine1 + "," + item.addressLine1 + "," + item.cityName + ", " + item.stateName + "," + item.countryName}
												</label>
											</div>
											<div className="days-wrap">
												<ul>
													<li>{getDaysOpen(item)}</li>
													<li>{item.daysOpened[0].from} — {item.daysOpened[0].to}</li>
												</ul>
												<ul>
													<li>{getDaysClose(item)}</li>
													<li>Closed</li>
												</ul>

												{/* {
													
													item.daysOpened?.map(item1 =>
														<ul>
															<li>{item1.day}</li>
															<li>{item1.from} a.m. — {item1.to} p.m.</li>
														</ul>
													)
												} */}
											</div>
										</div>
									)
								}
							</div>
						</div> : <div className='no-address card address-card inputs-wrapper'><h4>No address found</h4></div>}
						{/* :
								""
						} */}
						<h3>Certificates</h3>
						<div className="card edit-info">
							{
								portfolioState?.map((item, i) =>
									<>

										<div className="input-block mb-0">
											{<div className="input-wrapper required img-input-fix has-input-file">
												<label className="">Upload Photo</label>
												<div className="row">
													<div className="col-lg-12 input-wrapper">
														<div className="img-input-wrapper">
															<div className="img-input">
																<i className="icon-plus"></i>
																Upload
																<input
																	type="file"
																	// accept="image/*,video/*"
																	accept="image/*"
																	onChange={(e) => portfolioHandlerChange(e, i, "media")}
																/>
															</div>
															<span className="img-info">Supported file formats: .jpg, .png (918 x 425)<br />The file size should not exceed 25 MB</span>
														</div>
													</div>
												</div>
											</div>}
											{console.log("item", item)}
											{item.mediaSrc !== "" &&
												<div className='img-thumbnail'>
													<div className="img-wrap">
														<ImageThumb image={item?.mediaSrc} type={item?.fileType} />
													</div>
													<div className="remove-btn">
														<button type='button' onClick={(e) => removeFile(i)}>
															<i className="icon-remove"></i>
														</button>
													</div>
												</div>
											}
										</div>
										{/* <div className="row input-block position-relative">
											<div className="col-md-12">
												<label className="input-label">Description</label>
												<div className="input-wrap">
													<textarea
														type="text"
														className="form-control"
														// {...register("galleryDescription")}
														placeholder='Brief description of the portfolio content'
														maxLength={500}
														value={item.portfolioDescription}
														onChange={(e) => portfolioHandlerChange(e, i, "portfolioDescription")}
													/>
												</div>
												{errors.galleryDescription && (
													<span className="error">
														{errors.galleryDescription.message}
													</span>
												)}
												<label className="input-label required input-info position-static label-fix">Comment should not exceed 500 characters.</label>
											</div>
										</div> */}
									</>
								)
							}

							<div className="add-more">
								<a onClick={portfolioAddMore}><span className="text">Add another media</span></a>
							</div>
						</div>
						<h3 id='logo-accreditation'>Add accreditation's</h3>
						<div className="card accreditation-icons edit-info">
							<div className='input-block mb-0'>
								<div className='input-wrapper required img-input-fix has-input-file'>
									<label>Upload logo</label>
									<div className='row'>
										<div className='col-lg-12 input-wrapper'>
											<div className='img-input-wrapper'>
												<div className='img-input'>
													<i className='icon-plus'></i>
													Upload
													<input type='file' multiple onChange={handleLogoIcons} accept='image/*' />
												</div>
												<span className="img-info">Supported file formats: .jpg, .png<br />The file size should not exceed 1 MB</span>
											</div>
										</div>
									</div>
								</div>
								<div className='logo-previews'>
									{
										logoAccreditation?.imageName?.map((item) => {
											return <div className='image-logo-preview' key={item}>
												<img src={logoAccreditation?.path + item} alt='logo' />
												<div className='remove-logo'>
													<button type='button' onClick={() => handleRemoveLogo(item)}>
														<i className="icon-remove"></i>
													</button>
												</div>
											</div>
										})
									}
								</div>
							</div>
						</div>
						<div className="btn-wrap">
							<input className="secondary-btn" onClick={goToBackHandler} value="cancel" />
							<input className="btn" type="submit" value="save" />
						</div>
					</form>
				</div>
			</div>
		</section>
	)
}

const ImageThumb = ({ image, type }) => {
	console.log("image--->", type)
	let newType = type?.split("/")[0];
	console.log("newType", newType)
	return <div className='thumbnail-wrap'>
		{
			newType === "image" ?
				<img src={image} alt={`img`} />
				: <video src={image} />
		}
	</div>
};

export default PackageService
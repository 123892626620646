import * as actiontype from "../actionType";
import { endPoints } from "../../utils/endPoints";
import { axiosInstanceWithUserToken } from "../../utils/Axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const languageList = () => (dispatch) => {
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken.get(endPoints.LANGUAGE)
        .then(response => {
            dispatch({ type: "LOADERSTOP" })
            dispatch({
                type:actiontype.LANGUAGES,
                payload:response.data.data
            })
        })
        .catch((errResponse) => {
            // toast.error("error")
            dispatch({ type: "LOADERSTOP" })
            console.log("success respoerrResponsese", errResponse)
        });
};


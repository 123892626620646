export const endPoints = {
  FORM_AUTH_TOKEN: "/form",
  REGISTER: "/seller_signup",
  SELLER_ENQUIRY: "/seller_enquiry",
  verify_password_protected_page: "/verify_password_protected_page",
  GET_FAQ: "/get_questionanswer",
  COUNTRY_LIST: "/get_country",
  COUNTRY_LIST_BANK: "/get_country",
  STATE_LIST: "/get_state",
  STATE_LIST_BANK: "/get_state",
  CITY_LIST: "/get_city",
  CITY_LIST_BANK: "/get_city",
  LOGIN: "/login",
  CATEGORY_LIST: "/lab_get_category",
  SUB_CATEGORY_LIST: "/get_subcategory",
  VIEW_PROFILE: "/seller_profile_detail",
  CHECK_AUTH: "/check_auth",
  SELLER_SEND_VARIFICATION_CODE: "/seller_send_verification_code",
  SELLER_VERIFY_VARIFICATION_CODE: "/seller_verify_verification_code",
  PROFILE_EDIT: "/seller_edit_profile",
  CATEGORY: "/lab_information_detail_add_category",
  SHOW_CATEGORY_LIST: "/lab_information_category",
  LANGUAGE: "/get_language",
  SELLER_SUBCATEGORY_GET: "/seller_get_subcategory",
  SELLER_CATEGORY_GET: "/lab_get_category",
  SELLER_MEDIA_UPLOAD: "/lab_media_upload",
  ADDING_NEW_ADDRESS: "/seller_information_detail_add_address",
  EDIT_SELLER_ADDRESS: "/seller_information_detail_edit_address",
  DELETE_SELLER_ADDRESS: "/seller_information_detail_address_delete",
  GET_ADDRESS_DETAILS: "/seller_information_detail_address_detail",
  SELLER_DETAILS: "/seller_information_detail",
  FORGET_PASSWORD: "/forgot_password",
  SELLER_SUBCATEGORY_GET: "/seller_get_subcategory",
  SELLER_CATEGORY_GET: "/lab_get_category",
  SELLER_ADD_SERVICE: "/seller_add_service",
  SELLER_EDIT_SERVICE: "/seller_edit_service",
  RESET_PASSWORD: "/reset_password",
  OPTIONAL_INFORMATION_EDIT: "/seller_edit_optional_information",
  GET_OPTIONAL_INFORMATION: "/seller_detail_optional_information",
  SAVE_SELLER_MEDIA_UPLOAD: "/lab_media_upload",
  GET_SELLER_INFO_ADDRESS: '/seller_information_address',
  SELLER_LIST_SERVICE: "/seller_list_service",
  DELETE_SELLER_SERVICE: "/seller_delete_service",
  SELLER_SERVICE_DETAIL: "/seller_detail_service",

  GET_SERVICE_DETAIL: "/get_service_detail",
  GET_SELLER_DETAIL: "/get_seller_profile",

  GET_REVIEWLIST_SELLER: "/get_review_list_of_seller",
  GET_OTHER_SERVICE_LIST: "/get_another_service_from_same_seller",
  GET_SERVICE_OF_SELLER: "/get_service_of_seller",
  GET_RECOMMENDED_LIST: "/get_recommended_service",
  GET_REVIEW_LIST: "/get_review_list",
  ADD_SELLER_REVIEW: "/user_add_review_rating",
  EDIT_SELLER_REVIEW: "/user_edit_review_rating",
  ADD_TO_CART: "/add_to_cart",

  BILLING_METHOD: "/seller_edit_billing_method",
  VIEW_BILLING_DATA: "/seller_billing_detail",
  SELLER_INFORM_DETAIL_EDIT: "/seller_information_detail_edit",
  ADVRTISER_SIGN_UP: "/user_signup",
  ADVERTISER_PROFILE: "/advertiser_profile_detail",
  ADVERTISER_PROFILE_EDIT: "/advertiser_profile_edit",
  ADVERTISER_ADD_BANNER: "/advertiser_add_banner",
  ADVERTISER_EDIT_BANNER: "/advertiser_edit_banner",
  BANNER_LIST: "/advertiser_list_banner",
  BANNER_DETAIL: "/advertiser_detail_banner",
  BANNER_DELETE: "/advertiser_delete_banner",
  USER_SIGN_UP: "/user_signup",
  USER_PROFILE: "/user_profile_detail",
  USER_PROFILE_EDIT: "/user_profile_edit",
  HOME_PAGE_DATA: "/get_home_page",
  GET_CATEGORY: "/get_category",
  SUB_CATEGORY_LIST_HOME: "/get_service",
  GET_SEVICE_LIST: "/get_service",
  ADD_WISHLIST: "/user_add_to_wishlist",
  REMOVE_WISHLIST: "/user_remove_from_wishlist",
  USER_NOTIFICATIONS: "/user_get_notification",
  SELLER_NOTIFICATION: "/seller_get_notification",
  READ_SELLER_NOTI: "/seller_mark_as_read_notification",
  CUSTOMER_SERVICE: "/contact",
  GET_CART_DETAILS: "/get_cart_data",
  DELETE_CART_ITEM: "/remove_data_from_cart",
  UPDATE_CART_ITEM: '/update_to_cart',

  BOOK_ORDER: "/book_order",
  ORDER_SUMMARY: "/order_summary",

  PAYMENT_GATE: "/place_order",
  ORDER_SUCCESS_MSG: "/order_success",

  CHAT_WITH_SELLER: "/start_conversation_with_seller",
  USER_CONVERSATION_DETAIL: "/user_conversation_detail",
  USER_CONVERSATION_LIST: "/user_conversation_list",
  USER_READ_ALL: "/user_read_all_conversation",
  USER_SEND_MESSAGE: "/user_message_send",
  USER_MESSAGE_COUT: "/user_conversation_count",
  USER_CONVERSAION_NAME: "/user_conversation_name",

  SELLER_CONVERSATION_DETAIL: "/seller_conversation_detail",
  SELLER_CONVERSATION_LIST: "/seller_conversation_list",
  SELLER_READ_ALL: "/seller_read_all_conversation",
  SELLER_SEND_MESSAGE: "/seller_message_send",
  SELLER_MESSAGE_COUT: "/seller_conversation_count",
  SELLER_CONVERSATION_NAME: "/seller_conversation_name",

  LAB_REGISTER: '/seller_signup',

  LAB_TEST_ADD: "/add_lab_test",
  LAB_PACKAGE_ADD: "/create_package",
  LAB_LIST_DATA: "/list_lab_test",
  LAB_LIST_DATA_PACKAGE: "list_lab_packages",
  LAB_TEST_REMOVE: '/delete_lab_test',
  LAB_PACKAGE_REMOVE: '/delete_lab_package',
  EDIT_LAB_TEST: "/view_lab_test",
  LAB_TEST_UPDATE: "/update_lab_test",
  LAB_PACKAGE_UPDATE: "/update_lab_package",
  VERIFY_EMAIL_LAB: "/verify_lab_email",
  LAB_PACKAGE_LIST:"/list_test",
  LAB_PACKAGE_ADDRESS:"/list_address_by_test",
  EDIT_LAB_PACKAGE:"/view_lab_package",

  LAB_DETAILS:"/get_seller_profile",

  SEND_OTP_REG_LAB:'/lab_reg_send_mobile_otp',
  RESEND_OTP_REG_LAB:'/lab_reg_resend_mobile_otp',
  VERIFY_PHONE_OTP:'/lab_reg_verify_otp',
  LOGO_UPLOAD: "/lab_logo_upload",
  MARK_AVAILABILTY_LAB:'/mark_lab_availability',
  ADMIN_LOGIN:"/admin_login_as_seller",

  USER_MOBILE_VALID:"/user_reg_send_mobile_otp",
  USER_MOBILE_VALID_OTP:"/user_reg_verify_otp",
  REMOVE_CATEGORY_IDS:"/lab_information_detail_add_category",

  SELLER_LOGIN: "lab_login"


};

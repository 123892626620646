import React, { useState, useEffect } from 'react';
import PaymentComplete from 'components/Alert/PaymentComplete';
import { orderSuccess } from 'store/action/successOrderAction';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router';
import { parse } from 'query-string';
import Loader from 'components/Loader/Loader';
import { Alert } from 'components/Alert/Alert';

const SuccessPayment = () => {
    const location = useLocation()
    const dispatch = useDispatch()
    const [isloader, setIsLoader] = useState(false);

    useEffect(() => {
        let query = location.search
        setIsLoader(true)
        if (query) {
            orderSuccess(query, callback => {
                setIsLoader(false)
                setTimeout(() => window.location.href = "/", 3000)
            })(dispatch);
        }
        else {
            window.location.href = "/"
        }
    }, [])

    return (
        <section className='cart'>
            <Alert />
            {
                isloader ?
                    <Loader />
                    :
                    null
            }
            <PaymentComplete />
        </section>
    )
}

export default SuccessPayment;
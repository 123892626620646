import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const TrackCurrent = () => {
	const [ showSearch, setShowSearch ] = useState(false);

	const searchClickHandler = () => {
		setShowSearch(prev => !prev);
	}
	return (
		<>
			<div className="btns-wrap">
				<button type='button' className='btn'>Complete Selected</button>
				<button type='button' className='btn secondary'>Cancel Selected</button>
			</div>
			<div className="table-wrap">
				<div className="orders-table">
					<div className="table-header">
						<div className="checkbox-col">
							<div className="form-check">
								<input className="form-check-input" type="checkbox" value="" />
							</div>
						</div>
						<div className="id-col contain-search">Order ID#
							<button type='button' className='sort-btn'>
								<i className="icon-sort"></i>
							</button>
							<button type='button' className='search-btn' onClick={searchClickHandler}>
								<i className="icon-search"></i>
							</button>
							{showSearch ? <div className="search-wrap">
								<input type="text" className='form-control' />
							</div> : null}
						</div>
						<div className="date-col contain-filter">Order Placed
							<button type='button' className='sort-btn asc'>
								<i className="icon-sort"></i>
							</button>
							<button type='button' className='filter-btn'>
								<i className="icon-filter"></i>
							</button>
						</div>
						<div className="status-col contain-filter">Status
							<button type='button' className='filter-btn'>
								<i className="icon-filter"></i>
							</button>
						</div>
						<div className="name-col contain-search">Buyer Full Name
							<button type='button' className='sort-btn'>
								<i className="icon-sort"></i>
							</button>
							<button type='button' className='search-btn' onClick={searchClickHandler}>
								<i className="icon-search"></i>
							</button>
							{showSearch ? <div className="search-wrap">
								<input type="text" className='form-control' />
							</div> : null}
						</div>
						<div className="link-col">Order Details</div>
						<div className="cta-col">Action</div>
					</div>
					<ul className="table-body">
						<li className="table-row">
							<div className="checkbox-col">
								<div className="form-check">
									<input className="form-check-input" type="checkbox" value="" />
								</div>
							</div>
							<div className="id-col">433-9880211-6581152</div>
							<div className="date-col">12/12/2021</div>
							<div className="status-col in-progress">In progress</div>
							<div className="name-col">Jonathan Birmingham</div>
							<div className="link-col">
								<Link to='/'>View</Link>
							</div>
							<div className="cta-col">
								<button type='button' className='btn'>Complete</button>
								<button type='button' className='btn secondary'>Cancel</button>
							</div>
						</li>
						<li className="table-row">
							<div className="checkbox-col">
								<div className="form-check">
									<input className="form-check-input" type="checkbox" value="" />
								</div>
							</div>
							<div className="id-col">433-9880211-6581152</div>
							<div className="date-col">12/12/2021</div>
							<div className="status-col in-progress">In progress</div>
							<div className="name-col">Jonathan Birmingham</div>
							<div className="link-col">
								<Link to='/'>View</Link>
							</div>
							<div className="cta-col">
								<button type='button' className='btn'>Complete</button>
								<button type='button' className='btn secondary'>Cancel</button>
							</div>
						</li>
						<li className="table-row">
							<div className="checkbox-col">
								<div className="form-check">
									<input className="form-check-input" type="checkbox" value="" />
								</div>
							</div>
							<div className="id-col">433-9880211-6581152</div>
							<div className="date-col">12/12/2021</div>
							<div className="status-col in-progress">In progress</div>
							<div className="name-col">Jonathan Birmingham</div>
							<div className="link-col">
								<Link to='/'>View</Link>
							</div>
							<div className="cta-col">
								<button type='button' className='btn'>Complete</button>
								<button type='button' className='btn secondary'>Cancel</button>
							</div>
						</li>
						<li className="table-row">
							<div className="checkbox-col">
								<div className="form-check">
									<input className="form-check-input" type="checkbox" value="" />
								</div>
							</div>
							<div className="id-col">433-9880211-6581152</div>
							<div className="date-col">12/12/2021</div>
							<div className="status-col in-progress">In progress</div>
							<div className="name-col">Jonathan Birmingham</div>
							<div className="link-col">
								<Link to='/'>View</Link>
							</div>
							<div className="cta-col">
								<button type='button' className='btn'>Complete</button>
								<button type='button' className='btn secondary'>Cancel</button>
							</div>
						</li>
						<li className="table-row">
							<div className="checkbox-col">
								<div className="form-check">
									<input className="form-check-input" type="checkbox" value="" />
								</div>
							</div>
							<div className="id-col">433-9880211-6581152</div>
							<div className="date-col">12/12/2021</div>
							<div className="status-col in-progress">In progress</div>
							<div className="name-col">Jonathan Birmingham</div>
							<div className="link-col">
								<Link to='/'>View</Link>
							</div>
							<div className="cta-col">
								<button type='button' className='btn'>Complete</button>
								<button type='button' className='btn secondary'>Cancel</button>
							</div>
						</li>
						<li className="table-row">
							<div className="checkbox-col">
								<div className="form-check">
									<input className="form-check-input" type="checkbox" value="" />
								</div>
							</div>
							<div className="id-col">433-9880211-6581152</div>
							<div className="date-col">12/12/2021</div>
							<div className="status-col in-progress">In progress</div>
							<div className="name-col">Jonathan Birmingham</div>
							<div className="link-col">
								<Link to='/'>View</Link>
							</div>
							<div className="cta-col">
								<button type='button' className='btn'>Complete</button>
								<button type='button' className='btn secondary'>Cancel</button>
							</div>
						</li>
						<li className="table-row">
							<div className="checkbox-col">
								<div className="form-check">
									<input className="form-check-input" type="checkbox" value="" />
								</div>
							</div>
							<div className="id-col">433-9880211-6581152</div>
							<div className="date-col">12/12/2021</div>
							<div className="status-col in-progress">In progress</div>
							<div className="name-col">Jonathan Birmingham</div>
							<div className="link-col">
								<Link to='/'>View</Link>
							</div>
							<div className="cta-col">
								<button type='button' className='btn'>Complete</button>
								<button type='button' className='btn secondary'>Cancel</button>
							</div>
						</li>
						<li className="table-row">
							<div className="checkbox-col">
								<div className="form-check">
									<input className="form-check-input" type="checkbox" value="" />
								</div>
							</div>
							<div className="id-col">433-9880211-6581152</div>
							<div className="date-col">12/12/2021</div>
							<div className="status-col in-progress">In progress</div>
							<div className="name-col">Jonathan Birmingham</div>
							<div className="link-col">
								<Link to='/'>View</Link>
							</div>
							<div className="cta-col">
								<button type='button' className='btn'>Complete</button>
								<button type='button' className='btn secondary'>Cancel</button>
							</div>
						</li>
						<li className="table-row">
							<div className="checkbox-col">
								<div className="form-check">
									<input className="form-check-input" type="checkbox" value="" />
								</div>
							</div>
							<div className="id-col">433-9880211-6581152</div>
							<div className="date-col">12/12/2021</div>
							<div className="status-col in-progress">In progress</div>
							<div className="name-col">Jonathan Birmingham</div>
							<div className="link-col">
								<Link to='/'>View</Link>
							</div>
							<div className="cta-col">
								<button type='button' className='btn'>Complete</button>
								<button type='button' className='btn secondary'>Cancel</button>
							</div>
						</li>
						<li className="table-row">
							<div className="checkbox-col">
								<div className="form-check">
									<input className="form-check-input" type="checkbox" value="" />
								</div>
							</div>
							<div className="id-col">433-9880211-6581152</div>
							<div className="date-col">12/12/2021</div>
							<div className="status-col in-progress">In progress</div>
							<div className="name-col">Jonathan Birmingham</div>
							<div className="link-col">
								<Link to='/'>View</Link>
							</div>
							<div className="cta-col">
								<button type='button' className='btn'>Complete</button>
								<button type='button' className='btn secondary'>Cancel</button>
							</div>
						</li>
					</ul>
					<div className="table-footer">
						<div className="select-wrap">
							<select className='form-control drop-down'>
								<option>10/page</option>
								<option>15/page</option>
								<option>25/page</option>
							</select>
						</div>
						<div className="goto-wrap">
							<label>Go to</label>
							<input type="number" className='form-control' />
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default TrackCurrent
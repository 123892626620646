import * as actiontype from "../actionType";
import { endPoints } from "../../utils/endPoints";
import { axiosInstanceWithUserToken } from "../../utils/Axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNotify } from "components/Alert/Alert";

export const sellerAddData = (data, callback) => (dispatch) => {

    let token = localStorage.getItem("user_token");
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken.post(endPoints.SELLER_DETAILS, data, {
        headers: {
            Authorization: token,
            // _csrf:xsrfToken
        },
    })
        .then(response => {
            dispatch({ type: "LOADERSTOP" })
            callback(response.data.data)
        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
            // toast.error("error")
            console.log("success respoerrResponsese", errResponse)
        });
};


export const editSellerInfo = (data, callback) => (dispatch) => {
    let toast = useNotify();
    let token = localStorage.getItem("user_token");
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken.post(endPoints.SELLER_INFORM_DETAIL_EDIT, data, {
        headers: {
            Authorization: token,
        },
    })
        .then(response => {
            dispatch({ type: "LOADERSTOP" })
            if (response.data.status === "success") {
                toast.success(response?.data?.message)
                callback(true)
            } else {
                toast.error(response?.data?.message);
                callback(false)
            }


        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
            // toast.error("error")
            callback(false)
            console.log("success respoerrResponsese", errResponse)
        });
};


import React, { useState, useEffect } from 'react';
import { Breadcrumb, Tabs, Tab } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import profileImg from 'assets/images/employee.png';
import { useDispatch, useSelector } from 'react-redux'
import { viewProfile } from 'store/action/profileAction';
import { advertiserProfile } from 'store/action/advertiserProfileAction';
import AddBanner from 'components/Advertiser/AddBanner';
import ApprovedBanner from 'pages/Advertiser/Banner/ApprovedBanner';
import NonApprovedBanner from 'pages/Advertiser/Banner/NonApprovedBanner';
import { bannerListing } from 'store/action/bannerListAction';
import { deleteBanner } from 'store/action/bannerDetailAction';
import { Alert } from 'components/Alert/Alert';

const AdvertiserDashboard = () => {
	const dispatch = useDispatch()
	const [showModal, setShowModal] = useState(false);
	const [showMenu, setShowMenu] = useState(false);
	const [bannerId, setBannerId] = useState(null);
	const getProfileInfo = useSelector(state => state.profileView)
	const getAdvertiserInfo = useSelector(state => state.advertiserView)
	const bannerList = useSelector(state => state.bannerShow)
	const [bannerLists, setBannerLists] = useState([]);
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);
	const indexOfLastTodo = page * limit;
	const indexOfFirstTodo = indexOfLastTodo - limit;

	useEffect(() => {
		dispatch(viewProfile())
		dispatch(advertiserProfile())
	}, [])

	useEffect(() => {
		bannerListing()(dispatch)
	}, [])

	const deleteBannerHander = (id) => {
		deleteBanner({ bannerId: id }, callback => {
			let newState = [...bannerLists];
			newState = newState.filter(item => item._id !== id);
			setBannerLists(newState);
		})(dispatch)
	}

	const clickPagination = (index) => {
		setPage(index);
	}

	const clickPrev = (index) => {
		setPage(index - 1);
	}

	const clickNext = (index) => {
		setPage(index + 1);
	}

	const showMenuHandler = () => {
		setShowMenu(prev => !prev);
	}

	const showModalHandler = () => {
		setShowModal(prev => !prev);
	}

	const setBannerIdHandler = (id) => {
		setBannerId(id);
		setTimeout(() => setShowModal(true), 500);
	}

	const nonapprovedBannerList = () => {
		let banerList = bannerList?.data?.filter(item => !item.isApproved)
		console.log('nonapprovedBannerList', banerList)
		return banerList;
	}

	const approvedBannerList = () => {
		let banerList = bannerList?.data?.filter(item => item.isApproved)
		console.log('approvedBannerList', banerList)
		return banerList;
	}

	//test

	return (
		<React.Fragment>
			<section className='advertiser-dashboard'>
				<Alert />
				<div className="container">
					<Breadcrumb>
						<Breadcrumb.Item href="#">Advertiser Account</Breadcrumb.Item>
						<Breadcrumb.Item active>Advertiser Profile</Breadcrumb.Item>
					</Breadcrumb>
					<h1>Advertiser Profile</h1>
					{
						getProfileInfo?.data?.map(item =>
							<div className="card profile-card">
								<div className="profile-info">
									<div className="profile-img">
										<div className="img-wrap">
											{item.image ?
												<img src={item.path + item.image} alt="profile" />
												:
												<img src={profileImg} alt="profile" />
											}
										</div>
										<div className="file-input">
											<form>
												{/* <input type="file" /> */}
											</form>
										</div>
										{/* <div className="btn-wrap">
											<i className="icon-plus"></i>
										</div> */}
									</div>
									<div className="profile-name">{item.firstName + item.lastName}</div>
									<div className="profile-mail"><a >{item.email}</a></div>
								</div>
								<div className="btn-wrap edit">
									<Link to='/user/advertiser-dashboard/edit' className='btn'>Edit Profile</Link>
								</div>
								{/* <div className="btn-wrap delete">
									<Link to='/' className='secondary-btn'>Delete</Link>
								</div> */}
							</div>
						)
					}
					<div className="banner-header">
						<h3>My Banner</h3>
						{/* <div className="tab-btns">
							<button type="button" className='active'>Approved</button>
							<button type="button">Not Approved</button>
						</div> */}
						<div className="btn-wrap">
							<button type='button' onClick={showModalHandler} className='btn text-transform-initial'>Add new banner</button>
						</div>
					</div>
					{/* <div className="tab-content">
						<ApprovedBanner />
						<NonApprovedBanner />
					</div> */}

					<Tabs onSelect={() => {
						setPage(1)
					}} defaultActiveKey="my-banner" id="uncontrolled-tab-example" className="mb-3">
						<Tab eventKey="my-banner" title="My Uploads">
							<div className="card">
								{
									nonapprovedBannerList()?.slice(indexOfFirstTodo, indexOfLastTodo).map(item =>
										<NonApprovedBanner item={item} bannerList={bannerList} deleteBannerHander={deleteBannerHander} setBannerId={setBannerIdHandler} />
									)
								}
								{
									nonapprovedBannerList()?.length === 0 ?
										<div>Please upload your first banner image.</div>
										: null
								}
							</div>
							{
								nonapprovedBannerList()?.length > 0 &&
								<nav
									className="pagination-wrap"
									aria-label="Page navigation example"
								>
									<ul className="pagination">
										<li className={page === 1 ? "page-item previous disabled" : "page-item previous"} >
											<a
												className="page-link"
												href="javascript:void(0)"
												tabIndex="-1"
												onClick={() => clickPrev(page)}
												disable={page === 1 ? true : false}
											>
												<i className="icon-arrow"></i>
											</a>
										</li>

										{
											nonapprovedBannerList()?.length > 0 ?
												[...Array(Math.ceil(nonapprovedBannerList()?.length / 10))].map((item, i) =>
													<li className={i + 1 === page ? "page-item active" : "page-item"} aria-current="page">
														<a className="page-link" href="javascript:void(0)" onClick={() => clickPagination(i + 1)}>
															{i + 1}
														</a>
													</li>
												)
												: null
										}

										<li className={page === Math.ceil(nonapprovedBannerList()?.length / 10) ? "page-item  disabled" : "page-item "}>
											<a className="page-link" href="javascript:void(0)" onClick={() => clickNext(page)}>
												<i className="icon-arrow"></i>
											</a>
										</li>
									</ul>
								</nav>
							}
						</Tab>
						<Tab eventKey="approved" title="Approved">
							<div className="card">
								{
									approvedBannerList()?.slice(indexOfFirstTodo, indexOfLastTodo).map((item, i) =>
										<ApprovedBanner
											item={item}
											deleteBannerHander={deleteBannerHander}
											setBannerId={setBannerIdHandler}
										/>
									)
								}
							</div>
							{
								approvedBannerList()?.length > 0 &&
								<nav
									className="pagination-wrap"
									aria-label="Page navigation example"
								>
									<ul className="pagination">
										<li className={page === 1 ? "page-item previous disabled" : "page-item previous"} >
											<a
												className="page-link"
												href="javascript:void(0)"
												tabIndex="-1"
												onClick={() => clickPrev(page)}
												disable={page === 1 ? true : false}
											>
												<i className="icon-arrow"></i>
											</a>
										</li>

										{
											approvedBannerList()?.length > 0 ?
												[...Array(Math.ceil(approvedBannerList()?.length / 10))].map((item, i) =>
													<li className={i + 1 === page ? "page-item active" : "page-item"} aria-current="page">
														<a className="page-link" href="javascript:void(0)" onClick={() => clickPagination(i + 1)}>
															{i + 1}
														</a>
													</li>
												)
												: null
										}

										<li className={page === Math.ceil(approvedBannerList()?.length / 10) ? "page-item  disabled" : "page-item "}>
											<a className="page-link" href="javascript:void(0)" onClick={() => clickNext(page)}>
												<i className="icon-arrow"></i>
											</a>
										</li>
									</ul>
								</nav>
							}
						</Tab>
					</Tabs>
				</div>
			</section>
			{showModal && <AddBanner onCloseModal={showModalHandler} bannerId={bannerId} />}
		</React.Fragment>
	)
}

export default AdvertiserDashboard

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import profileImg from "assets/images/employee.png";
import { useDispatch, useSelector } from "react-redux";
import { userProfile } from "store/action/userProfileAction";

const Profile = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const getUserInfo = useSelector(state => state.profileView)

	useEffect(() => {
		userProfile()(dispatch);
	}, []);

	const changePage = () => {
		navigate({ pathname: '/user/dashboard' })
	}

	console.log('sdsdfdsdf', getUserInfo)

	return (
		<section className='user-profile'>
			<div className="container">
				<Breadcrumb>
					<Breadcrumb.Item onClick={changePage}>User Account</Breadcrumb.Item>
					<Breadcrumb.Item active>User Profile</Breadcrumb.Item>
				</Breadcrumb>
				<div className="section-head">
					<div className="heading-wrap">
						<h1>User Profile</h1>
					</div>
					<div className="btn-wrap">
						<Link to='/user/profile/edit' className='btn'>Edit</Link>
					</div>
				</div>
				{
					getUserInfo?.data?.map(item =>
						<div className="account-info">
							<div className="card account-card">
								<div className="account-heading">Account Information</div>
								<span className="info-label">First Name:</span>
								<span className="info-wrap">{item.firstName}</span>
								<span className="info-label">Last Name:</span>
								<span className="info-wrap">{item.lastName}</span>
								<span className="info-label">Email:</span>
								<span className="info-wrap">{item.email}</span>
								<span className="info-label">Mobile:</span>
								<span className="info-wrap">{item.phone}</span>
							</div>
							<div className="card account-card">
								<div className="account-heading">Basic Information</div>
								{/* <span className="info-label">Date of Birth:</span>
								<span className="info-wrap">12/12/1992</span> */}
								<div className="profile-img">
									<div className="img-wrap">
										{item.image ?
											<img src={item.path + item.image} alt="profile" />
											:
											<img src={profileImg} alt="profile" />
										}
									</div>
								</div>
							</div>
						</div>
					)
				}			</div>
		</section>
	)
}

export default Profile;

import React, { useState } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";


const SellerVerification = () => {
	const [isPasswordVisible, setIsPasswordVisible] = useState(false);
	const getProfileInfo = useSelector(state => state.profileView)
	const navigate = useNavigate();

	const togglePasswordHandler = event => {
		event.preventDefault();
		setIsPasswordVisible(!isPasswordVisible);
	}

	const changePage = () => {
		navigate({ pathname: '/seller-dashboard-company' })
		console.log('sdjhbj')
	}

	console.log("checkingggg", getProfileInfo?.data?.[0]?.postcode)

	const inputTypeValue = isPasswordVisible ?
		'text' : 'password';
	return (
		<section className="seller-verification">
			<div className="container">
				<Breadcrumb>
					<Breadcrumb.Item onClick={changePage}>lab account</Breadcrumb.Item>
					<Breadcrumb.Item active>Verification & security</Breadcrumb.Item>
				</Breadcrumb>
				<div className="section-header">
					<div className="header-left">
						<h1>Verification & security</h1>
					</div>
					<div className="header-right">
						<Link to='/seller/company/seller-verification/edit' className='btn'>Edit</Link>
						
					</div>
				</div>
				{
					getProfileInfo?.data?.map(item =>
						<div className="information-block">
							<div className="information-card card">
								<h4>Account Information</h4>
								<div className="info-label">First Name:</div>
								<div className="seller-detail">{item.firstName}</div>
								<div className="info-label">Last Name:</div>
								<div className="seller-detail">{item.lastName}</div>
								<div className="info-label">Email:</div>
								<div className="seller-detail">{item.email}</div>
								<div className="info-label">Password:</div>
								<div className="seller-detail contains-btn">
									<div className="input-wrap">
										<input type={inputTypeValue} className="padding-fix" readOnly value="MMMMMMMMMMMMMMM" />
										<div className="toggle-password">
											<button type="button" onClick={togglePasswordHandler}>
											</button>
										</div>
									</div>
								</div>
							</div>
							<div className="information-card card">
								<h4>Business Information</h4>
								<div className="info-label">Business Name:</div>
								<div className="seller-detail">{item.businessName}</div>
								<div className="info-label">Primary Contact Person:</div>
								<div className="seller-detail">{item.primaryContactPerson}</div>
								<div className="row">
									<div className="col-md-6">
										<div className="info-label">Company Register Number:</div>
										<div className="seller-detail">{item.companyRegistrationNumber}</div>
									</div>
									<div className="col-md-6">
										<div className="info-label">VAT Number:</div>
										<div className="seller-detail">{item.vat}</div>
									</div>
								</div>
								<div className="info-label">Phone Number:</div>
								<div className="seller-detail">{item.phone}</div>
							</div>
							<div className="information-card card full-width">
								<h4>Registered Business Address</h4>
								<div className="content-wrapper">
									<div className="row top-block">
										<div className="col-md-6 col-lg-3 info-wrapper">
											<div className="info-label">Address Line 1:</div>
											<div className="seller-detail">{item.addressLine1}</div>
										</div>
										<div className="col-md-6 col-lg-3 info-wrapper">
											<div className="info-label">Address Line 2:</div>
											<div className="seller-detail">{item.addressLine2}</div>
										</div>
									</div>
									<div className="row bottom-block">
										<div className="col-md-6 col-lg-3 info-wrapper">
											<div className="info-label">City:</div>
											<div className="seller-detail">{item.cityName}</div>
										</div>
										<div className="col-md-6 col-lg-3 info-wrapper">
											<div className="info-label">State / Region:</div>
											<div className="seller-detail">{item.stateName}</div>
										</div>
										<div className="col-md-6 col-lg-3 info-wrapper">
											<div className="info-label">Country:</div>
											<div className="seller-detail">{item.countryName}</div>
										</div>
										<div className="col-md-6 col-lg-3 info-wrapper">
											<div className="info-label">ZIP / Postal Code:</div>
											<div className="seller-detail">{item.postcode}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					)
				}
			</div>
		</section>
	)
}

export default SellerVerification;

import * as actiontype from "../actionType";
import { endPoints } from "../../utils/endPoints";
import { axiosInstanceWithUserToken } from "../../utils/Axios";
import { useNotify } from "../../components/Alert/Alert";

const uploadFiles1 = (data,callback) => (dispatch) => {
    console.log("11111111111111")
 
    let toast = useNotify();
    let token = localStorage.getItem("user_token");
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken.post(endPoints.SAVE_SELLER_MEDIA_UPLOAD, data, {
        headers: {
            Authorization: token
        },
    })
        .then(response => {
            dispatch({ type: "LOADERSTOP" })
            callback(response.data)
        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
            console.log("success respoerrResponsese", errResponse)
        });
};

const uploadFiles = (data, callback) => (dispatch) => {
    let toast = useNotify();
    let token = localStorage.getItem("user_token");
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken.post(endPoints.SAVE_SELLER_MEDIA_UPLOAD, data, {
        headers: {
            Authorization: token
        },
    })
        .then(response => {
            dispatch({ type: "LOADERSTOP" })
            callback(response.data.imageName)
        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
            console.log("success respoerrResponsese", errResponse)
        });
};


const optionalinfoDetail = (data, callback) => (dispatch) => {
    let toast = useNotify();
    let token = localStorage.getItem("user_token");
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken.post(endPoints.GET_OPTIONAL_INFORMATION, data, {
        headers: {
            Authorization: token
        },
    })
        .then(response => {
            dispatch({ type: "LOADERSTOP" })
            if (response.data.status === "success") {
                // toast.success(response?.data?.message);
                callback(response.data.data)
            } else {
                toast.error(response?.data?.message);
            }

        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
            console.log("success respoerrResponsese", errResponse)
        });
};

const editOptionalInfo = (data, callback) => (dispatch) => {
    let toast = useNotify();
    let token = localStorage.getItem("user_token");
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken.post(endPoints.OPTIONAL_INFORMATION_EDIT, data, {
        headers: {
            Authorization: token
        },
    })
        .then(response => {
            dispatch({ type: "LOADERSTOP" })
            if (response.data.status === "success") {
                toast.success(response?.data?.message);
                callback(true)
            } else {
                toast.fail("Something wrong");
                callback(false)
            }

        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
            console.log("success respoerrResponsese", errResponse)
        });
};

export { editOptionalInfo, optionalinfoDetail, uploadFiles, uploadFiles1 };

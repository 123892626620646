import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { forgetPassword } from "store/action/forgetPassAction";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const schema = yup.object().shape({
  email: yup
    .string()
    .required("Please enter your email address")
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      "Please use the correct email"
    ),
});

const ForgotPassword = () => {
  let navigate = useNavigate()
  let location = useLocation()
  const dispatch = useDispatch();
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    forgetPassword(data)(dispatch);
  };
  return (
    <section className="forgot-password forgetPassword-wrap">
      <ToastContainer
        className={'modal-backdrop'}
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <div className="container">
        <div className="card">
          <h2>Forgot Password?</h2>
          <div className="reset-info">
            No worries, we'll send you reset instructions.
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="input-block">
              <label htmlFor="user-id">Email</label>
              <div className="input-wrap">
                <input
                  type="text"
                  placeholder="Email or Phone"
                  {...register("email")}
                  className="form-control"
                />
              </div>
              {errors.email && (
                <span className="error">{errors.email.message}</span>
              )}
            </div>
            <div className="btn-wrap">
              <button type="submit" className="btn w-100">
                Reset Password
              </button>
            </div>
          </form>
        </div>
        <div className="card text-center">
          <div className="login-wrap">
            <Link to="#" onClick={(e) => { e.preventDefault(); navigate(-1) }} className="login-link">
              Back to Login
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;

import * as actiontype from "../actionType";
import { endPoints } from "../../utils/endPoints";
import { axiosInstanceWithUserToken } from "../../utils/Axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export const editAddress = (data) => (dispatch) => {
    let token = localStorage.getItem("user_token");
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken
        .post(endPoints.EDIT_SELLER_ADDRESS, data, {
            headers: {
                Authorization: token
            },
        })
        .then(async (response) => {
            dispatch({ type: "LOADERSTOP" })
            if (response?.data?.status === "success") {
                // toast.success(response?.data?.message);
                dispatch({
                    type: actiontype.LOGIN_USER,
                    payload: {
                        islogin: true
                    },
                });
            } else {
                console.log("wrong")
                toast.error(response?.data?.message);
            }
        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
            toast.error("error")
        });
};


export const getAddressDetail = (data, callback) => (dispatch) => {

    let token = localStorage.getItem("user_token");
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken
        .post(endPoints.GET_ADDRESS_DETAILS, data, {
            headers: {
                Authorization: token
            },
        })
        .then(async (response) => {
            dispatch({ type: "LOADERSTOP" })
            console.log("response--->", response)
            if (response?.data?.status === "success") {
                // setTimeout(() => toast.success(response?.data?.message), 1500);
                callback(response?.data.data)
            } else {
                console.log("wrong")
                toast.error(response?.data?.message);
            }
        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
            // toast.error("error")
        });
};


export const addNewAddress = (data,closeModalHandler) => (dispatch) => {
    let token = localStorage.getItem("user_token");
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken
        .post(endPoints.ADDING_NEW_ADDRESS, { ...data, countryId: "61c2fb83dc7c0d455ba5e68d" }, {
            headers: {
                Authorization: token
            },
        })
        .then(async (response) => {
            dispatch({ type: "LOADERSTOP" })
            if (response?.data?.status === "success") {
                setTimeout(() => closeModalHandler(), 1000)
                toast.success(response?.data?.message);
                dispatch({
                    type: actiontype.LOGIN_USER,
                    payload: {
                        islogin: true
                    },
                });
            } else {
                console.log("wrong")
                toast.error(response?.data?.message);
            }
        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
            toast.error("error")
        });
};


export const deleteNewAddress = (data, callback) => (dispatch) => {

    let token = localStorage.getItem("user_token");
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken
        .post(endPoints.DELETE_SELLER_ADDRESS, data, {
            headers: {
                Authorization: token
            },
        })
        .then(async (response) => {
            dispatch({ type: "LOADERSTOP" })
            if (response?.data?.status === "success") {
                toast.success(response?.data?.message);
                callback(true)
            } else {
                console.log("wrong")
                toast.error(response?.data?.message);
            }
        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
            toast.error("error")
        });
};


export const markAvailabilityAction = (payload, dispatch,toast,callback) => {
   

    let token = localStorage.getItem("user_token");
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken.post(endPoints.MARK_AVAILABILTY_LAB, payload, {
        headers: {
            Authorization: token
        },
    })
        .then((response) => {
            dispatch({ type: "LOADERSTOP" })
           
            toast.success(response?.data?.message)
            callback(true)
        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
            toast.error("error",errResponse?.response?.data?.message)
            callback(false)
            toast.success(errResponse?.data?.message)
        });
}
import React, { useState, useEffect } from 'react';
import itemImg from 'assets/images/item-img.jpg';
import { Link } from 'react-router-dom';
import { cartView } from 'store/action/sowCartDataAction';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { removeCartListing } from 'store/action/removeCartAction';
import { updateCartListing } from 'store/action/removeCartAction';
import { Alert } from 'components/Alert/Alert';
import { orderBooking } from 'store/action/bookOrderAction';
import emptyCart from 'assets/images/emptyCart.png';

const Cart = () => {
	let navigate = useNavigate();
	const dispatch = useDispatch()
	const getCartInfo = useSelector((state) => state.cartDetail)
	const [ids, setIds] = useState([]);

	const handleAddQuantity = (item) => {
		let cart = JSON.parse(localStorage.getItem("ala_cart"));
		if (cart) {
			cart = cart.map((newitem, i) => {
				if (newitem.serviceId === item.serviceId) {
					return { ...item, quantity: item.quantity + 1 }
				} else {
					return item
				}
			})
			localStorage.setItem("ala_cart", JSON.stringify(cart))
			setTimeout(() => cartView()(dispatch), 10)
		} else {
			let payload = {
				cartId: item._id,
				quantity: item.quantity + 1
			}
			updateCartListing(payload)(dispatch)
			setTimeout(() => cartView()(dispatch), 150)
		}

	}

	const handleSubtractQuantity = (item) => {
		if (item.quantity > 1) {
			let cart = JSON.parse(localStorage.getItem("ala_cart"));
			if (cart) {
				cart = cart.map((newitem, i) => {
					console.log('newitem', newitem.serviceId, item.serviceId)
					if (newitem.serviceId === item.serviceId) {
						return { ...item, quantity: item.quantity - 1 }
					} else {
						return item
					}
				})
				localStorage.setItem("ala_cart", JSON.stringify(cart))
				setTimeout(() => cartView()(dispatch), 10)
			} else {
				let payload = {
					cartId: item._id,
					quantity: item.quantity - 1
				}
				updateCartListing(payload)(dispatch)
				setTimeout(() => cartView()(dispatch), 150)
			}
		}
		else {
			return
		}
	}

	useEffect(() => {
		cartView()(dispatch)
	}, [])

	const deleteService = (item) => {
		let cart = JSON.parse(localStorage.getItem("ala_cart"));
		if (cart) {
			cart = cart.filter((newitem, i) => {
				if (newitem.serviceId !== item.serviceId) {
					return { ...item, quantity: item.quantity - 1 }
				}
			})
			alert('Are you sure you want to delete this service.')
			localStorage.setItem("ala_cart", JSON.stringify(cart))
			setTimeout(() => cartView()(dispatch), 10)
		} else {
			let payload = {
				cartId: [item._id]
			};
			alert('Are you sure you want to delete this service.')
			removeCartListing(payload)(dispatch);
			setTimeout(() => cartView()(dispatch), 300)
		}
	}

	const getTotalCosts = () => {
		return getCartInfo?.reduce((total, list) => {
			return total + Number(list.price * list.quantity);
		}, 0);
	};


	const handleSelectAll = e => {
		let newState = [...ids];
		let checkboxesAll = document.querySelectorAll("#cartBox");
		if (e.target.checked) {
			checkboxesAll.forEach(item => {
				item.checked = true;
				newState.push(item.value);
			});
		} else {
			checkboxesAll.forEach(item => {
				item.checked = false;
				newState = [];
			});
		}
		setIds(newState)
	};

	const handleClick = e => {
		let newState = [...ids];
		if (e.target.checked) {
			newState.push(e.target.value);
		} else {
			newState = newState.filter(item => item !== e.target.value);
		}
		setIds(newState)
	};

	const bookOrder = (e) => {
		e.preventDefault();
		let ids = getCartInfo?.map(key => key._id)
		let payload = {
			cartIds: ids
		};
		orderBooking(payload)(dispatch);
		setTimeout(() => navigate("/user/checkout"), 50)
		console.log('ids', ids)
	}

	return (
		<section className='cart'>
			<Alert />
			<div className="container">
				{getCartInfo?.length >= 1 ? <h1>Cart ({getCartInfo?.length})</h1> : null}
				<form>
					{getCartInfo?.length >= 1 ?
						<div className="select-all">
							<div className="form-check">
								<input
									className="form-check-input"
									type="checkbox"
									id="selectAll"
									onClick={handleSelectAll}
								/>
								<label className="form-check-label" >Select All</label>
							</div>
						</div>
						:
						null
					}
					<div className="cart-wrap">
						<div className="left-block">
							{getCartInfo?.map(item =>
								<div className="cart-item">
									<div className="checkbox-wrap">
										<div className="form-check">
											<input
												className="form-check-input"
												key={item._id}
												type="checkbox"
												id="cartBox"
												value={item._id}
												onClick={handleClick}
											/>
										</div>
									</div>
									<div className="item-img">
										<img src={item.path + item.serviceCover} alt="item" />
									</div>
									<div className="item-right">
										<div className="item-info">
											<div className="item-name">
												<Link to='#'>{item.serviceTitle}</Link>
											</div>
											<div className="item-provider">{item?.sellerData?.[0]?.firstName + " " + item?.sellerData?.[0]?.lastName}</div>
											<div className="remove-btn">
												<button type='button' onClick={() => deleteService(item)}>
													<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
														<path d="M4.625 1.87354H4.5C4.56875 1.87354 4.625 1.81729 4.625 1.74854V1.87354H9.375V1.74854C9.375 1.81729 9.43125 1.87354 9.5 1.87354H9.375V2.99854H10.5V1.74854C10.5 1.19697 10.0516 0.748535 9.5 0.748535H4.5C3.94844 0.748535 3.5 1.19697 3.5 1.74854V2.99854H4.625V1.87354ZM12.5 2.99854H1.5C1.22344 2.99854 1 3.22197 1 3.49854V3.99854C1 4.06729 1.05625 4.12354 1.125 4.12354H2.06875L2.45469 12.2954C2.47969 12.8282 2.92031 13.2485 3.45313 13.2485H10.5469C11.0813 13.2485 11.5203 12.8298 11.5453 12.2954L11.9313 4.12354H12.875C12.9438 4.12354 13 4.06729 13 3.99854V3.49854C13 3.22197 12.7766 2.99854 12.5 2.99854ZM10.4266 12.1235H3.57344L3.19531 4.12354H10.8047L10.4266 12.1235Z" fill="#9A9FA5" />
													</svg>
												</button>
											</div>
										</div>
										<div className="item-price">
											<div className="price-wrap">${item.price}</div>
											<div className="quantity-wrap">
												<div className="quantity-decrease">
													<button type='button' onClick={() => handleSubtractQuantity(item)}>—</button>
												</div>
												<div className="quantity">{item.quantity}</div>
												<div className="quantity-increase">
													<button type='button' onClick={() => handleAddQuantity(item)}>+</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							)}
						</div>
						{getCartInfo?.length ?
							<div className="right-block">
								<div className="cart-heading">Total Cost</div>
								{getCartInfo?.map(list =>
									<ul className='added-items'>
										<li>
											<div className="added-item">
												<div className="item-name">
													<Link to='#'>{list.serviceTitle}</Link>
												</div>
												<div className="item-provider">{list?.sellerData?.[0]?.firstName + " " + list?.sellerData?.[0]?.lastName}</div>
											</div>
											<div className="item-price">${list.price * list.quantity}</div>
										</li>
									</ul>
								)}
								<div className="total-wrap">
									<div className="text-wrap">To Pay</div>
									<div className="amount-wrap">${getTotalCosts()}</div>
								</div>
								<div className="btn-wrap">
									<button type="submit" className='btn' onClick={bookOrder}>Book</button>
								</div>
							</div>
							:
							<div className="image-wrap">
								<img src={emptyCart}></img>
							</div>
						}
					</div>
				</form>
			</div>
		</section>
	)
}

export default Cart;


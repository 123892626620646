import React, { useState, useEffect, useRef } from 'react'
import { Breadcrumb } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from 'react-router-dom';
import * as yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import { viewProfile, sellerVerificationCode, sellerVerifyCode } from 'store/action/profileAction';
import { editAction } from 'store/action/editProfileAction';
import { countryList } from 'store/action/countryList';
import { stateList } from 'store/action/stateList';
import { cityList } from 'store/action/cityListAction';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from 'react-date-picker';
import ReactCodeInput from 'react-verification-code-input';

const schema = yup.object().shape({
	firstName: yup
		.string()
		.required("Please enter First Name")
		.min(2, "First name must be at least 2 characters")
		.max(20, "First name must be at most 20 characters")
		.matches(/^[A-Za-z ]+$/i, "Please enter valid first name"),
	lastName: yup
		.string()
		.required("Please enter Last Name")
		.min(2, "Last name must be at least 2 characters")
		.max(20, "Last name must be at most 20 characters")
		.matches(/^[A-Za-z ]+$/i, "Please enter valid last name"),
	email: yup
		.string()
		.required("Please enter your email address")
		.matches(
			/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
			"Please use the correct email"
		),
	dob: yup
		.string()
		.required("Please select your Birthday"),
	// businessName: yup
	//     .string()
	//     .required("Please enter Business Name")
	//     .min(2, "Business name must be at least 2 characters")
	//     .max(20, "Business name must be at most 20 characters")
	//     .matches(/^[A-Za-z]+$/i, "Please enter valid Business name"),
	// primaryContactPerson: yup
	//     .string()
	//     .required("Please enter Primary Contact Person")
	//     .min(2, "Primary contact person must be at least 2 characters")
	//     .max(20, "Primary contact person must be at most 20 characters")
	//     .matches(/^[A-Za-z]+$/i, "Please enter valid primary contact person"),
	companyRegistrationNumber: yup
		.string(),
	//     .required("Please Company Register Number")
	//     .matches(/^[0-9]*$/, "Company Register Number is not valid"),
	addressLine1: yup
		.string()
		.required("Please enter Address")
		.min(2, "Address must be at least 2 characters")
		.max(20, "Address must be at most 20 characters")
		.matches(/^[a-zA-Z0-9\s.,'-]*$/, "Please enter valid Address"),
	addressLine2: yup
		.string(),
	cityId: yup
		.string(),
	// .required("Please enter City"),
	// .min(2, "City must be at least 2 characters")
	// .max(20, "City must be at most 20 characters")
	// .matches(/^[A-Za-z]+$/i, "Please enter valid City"),
	stateId: yup
		.string(),
	countryId: yup
		.string()
		.required("Please enter Country"),
	// .min(2, "Country must be at least 2 characters")
	// .max(20, "Country must be at most 20 characters")
	// .matches(/^[A-Za-z]+$/i, "Please enter valid Country"),
	proofOfIdentity: yup
		.string(),
	phone: yup
		.string()
		.required("Please enter mobile number")
		// .matches(/^[0-9]*$/, "Phone number is not valid")
		.min(7)
		.max(14),
	postcode: yup
		.string()
		.required("Please enter Zip"),
	// comment: yup
	//     .string()
	//     .required("Please enter Comment")
	//     .matches(/^[A-Za-z]+$/i, "Comment is not valid")
	//     .min(2)
	//     .max(300),
	password: yup
		.string(),
	// .required("Please enter the password")
	// .min(8, "Password length should be greater than 8"),
	re_password: yup
		.string()
		// .required("Please enter the password")
		// .min(8, "Password length should be greater than 8")
		.oneOf([yup.ref('password'), null], 'Confirm Password must be same as New Password'),
});

const EditSellerVerification = () => {
	let attachmentName = useRef(null);
	const navigate = useNavigate();
	const getCountry = useSelector(state => state.countryData)
	const getState = useSelector(state => state.stateData)
	const getCity = useSelector(state => state.cityData)
	const dispatch = useDispatch()
	const getProfileInfo = useSelector(state => state.profileView)
	console.log("getProfileInfo", getProfileInfo)
	const [value, onChange] = useState(new Date());
	const [file, setFile] = React.useState("");
	const [passwordType, setPasswordType] = useState("password");
	const [con_passwordType, setCon_passwordType] = useState("password");
	const [countryname, setCountryname] = useState("");
	const [isOtp, setIsOtp] = useState(false)
	const [img, setImg] = useState("");
	const [fileAttach, setFileAttach] = useState(null)
	const [isPhoneVerify, setIsPhoneVerify] = useState(false);
	const [isOTPWrong, setIsOTPWrong] = useState(false);
	const [mob, setMob] = useState("")

	const { register, formState: { errors }, handleSubmit, getValues, reset, setValue } = useForm({
		mode: "onSubmit",
		resolver: yupResolver(schema),
	});

	function handleUpload(event) {
		setFile(event.target.files[0]);
	}

	const imgHandler = (e) => {
		setFileAttach(e.target.files[0])
		let src_ = URL.createObjectURL(e.target.files[0]);
		setImg(src_)
		console.log('newwwwww', e.target.files)
	}

	const onSubmit = (data) => {
		// alert('ddsss')
		console.log('dddddd', data)

		let payload = new FormData();
		payload.append('type', data.type);
		payload.append('firstName', data.firstName);
		payload.append('lastName', data.lastName);
		payload.append("cityId", data.cityId);
		payload.append('stateId', data.stateId);
		payload.append("countryId", data.countryId);
		payload.append("addressLine1", data.addressLine1);
		payload.append("addressLine2", data.addressLine2);
		payload.append("email", data.email);
		payload.append("postcode", data.postcode);
		payload.append("phone", mob);
		payload.append("proofOfIdentity", fileAttach);
		payload.append("dob", data.dob.split("T")[0]);
		// console.log("tokenShow",localStorage.setItem("user_token", response?.data.data.token));
		if (isPhoneVerify) {
			editAction(payload, data)(dispatch);
			setTimeout(
				() => (window.location.href = "/seller-verification"),
				4000
			);
		} else {
			alert("Please verify your mobile number first")
		}
	};

	const changePage = () => {
		navigate({ pathname: '/seller-dashboard-freelancer' })
		console.log('sdjhbj')
	}

	useEffect(() => {
		viewProfile()(dispatch);
		countryList()(dispatch);
	}, []);

	const getStateHandler = (e) => {
		let val = e.target.value;
		setCountryname(val)
		stateList({ countryId: val })(dispatch);
	}

	const getCityHandler = (e) => {
		cityList({ countryId: countryname, stateId: e.target.value })(dispatch);
	}

	const changeHandler = () => {
		navigate({ pathname: '/seller-verification' })
	}

	useEffect((data) => {
		// console.log('profile', getProfileInfo)
		if (getProfileInfo?.data?.length > 0) {
			stateList({ countryId: getProfileInfo.data[0].countryId })(dispatch);
			cityList({ countryId: getProfileInfo.data[0].countryId, stateId: getProfileInfo.data[0].stateId })(dispatch);
			setTimeout(() => {
				setValue("firstName", getProfileInfo.data[0].type);
				setValue("firstName", getProfileInfo.data[0].firstName);
				setValue("lastName", getProfileInfo.data[0].lastName);
				setValue("cityId", getProfileInfo.data[0].cityId);
				setValue("stateId", getProfileInfo.data[0].stateId);
				setValue("countryId", getProfileInfo.data[0].countryId);
				setValue("addressLine1", getProfileInfo.data[0].addressLine1);
				setValue("addressLine2", getProfileInfo.data[0].addressLine2);
				setValue("companyRegistrationNumber", getProfileInfo.data[0].companyRegistrationNumber);
				setValue("email", getProfileInfo.data[0].email);
				setValue("postcode", getProfileInfo.data[0].postcode);
				setImg(getProfileInfo.data[0].proofOfIdentity ? getProfileInfo.data[0].sellerAttachmentShowPath + getProfileInfo.data[0].proofOfIdentity : "");
				setValue("phone", getProfileInfo.data[0].phone);
				setValue("dob", getProfileInfo?.data?.[0]?.dob?.split("T")[0]);
				setIsPhoneVerify(getProfileInfo.data[0].phoneNumberVerified)
				setMob(getProfileInfo.data[0].phone);
			}, 1000)
		}
	}, [getProfileInfo]);

	const sendOtp = () => {

		sellerVerificationCode({ phone: mob }, callback => {
			if (callback) {
				setIsOtp(true)
			}
		})(dispatch)
	}

	const verifyOtp = (val) => {
		sellerVerifyCode({ otp: val }, callback => {
			console.log('callback', callback)
			if (callback) {
				setIsOtp(false)
				setIsOTPWrong(false)
				setIsPhoneVerify(true)
			} else {
				setIsOTPWrong(true)
			}
		})(dispatch)
	}

	const mobHandler = (e) => {
		// console.log('getProfileInfo', getProfileInfo.data[0].phone, e.target.value)
		if (getProfileInfo.data[0].phone === parseInt(e.target.value)) {
			setMob(e.target.value)
			if (getProfileInfo.data[0].phoneNumberVerified) {
				setIsPhoneVerify(true)
			} else {
				setIsPhoneVerify(false)
			}
		} else {
			setMob(e.target.value)
			setIsPhoneVerify(false)
		}
	}

	return (
		<section className="edit-verification">
			<div className="container">
				<ToastContainer
					position="top-right"
					autoClose={3000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
				/>
				<Breadcrumb>
					<Breadcrumb.Item onClick={changePage}>Lab account</Breadcrumb.Item>
					<Breadcrumb.Item onClick={changeHandler}>Verification & Security</Breadcrumb.Item>
					<Breadcrumb.Item active>Edit  Verification & Security</Breadcrumb.Item>
				</Breadcrumb>
				<h1>Edit Verification & Security</h1>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="card inputs-wrapper">
						<h4>Verification</h4>
						<div className="inputs-heading">Account Information</div>
						<div className="row input-block">
							<div className="col-md-6 col-lg-4 input-wrapper required">
								<label className="input-label">first name</label>
								<div className={`input-wrap ${errors.firstName ? 'has-error' : ''}`}>
									<input
										placeholder='First name'
										type="text"
										className="form-control"
										{...register("firstName")}
									/>
								</div>
								{errors.firstName && (
									<span className="error">
										{errors.firstName.message}
									</span>
								)}
							</div>
							<div className="col-md-6 col-lg-4 input-wrapper required">
								<label className="input-label">Last name</label>
								<div className={`input-wrap ${errors.lastName ? 'has-error' : ''}`}>
									<input
										placeholder='Last name'
										type="text"
										{...register("lastName")}
										className="form-control"
									/>
								</div>
								{errors.lastName && (
									<span className="error">
										{errors.lastName.message}
									</span>
								)}
							</div>
							<div className="col-md-6 col-lg-4 input-wrapper required">
								<label className="input-label">Email address</label>
								<div className={`input-wrap ${errors.email ? 'has-error' : ''}`}>
									<input
										type="email"
										{...register("email")}
										className="form-control"
										autoComplete="off"
										placeholder='example@aladyyn.pro'
									/>
								</div>
								{errors.email && (
									<span className="error">
										{errors.email.message}
									</span>
								)}
							</div>
							<div className="col-md-6 col-lg-4 input-wrapper">
								<label className="input-label">Password</label>
								<div className={`input-wrap password ${errors.password ? 'has-error' : ''}`}>
									<input
										type={passwordType}
										{...register("password")}
										className="form-control"
										placeholder='Password'
									/>
									<i onClick={() => { setPasswordType(passwordType === "password" ? "text" : "password") }} className={`icon-eye ${passwordType === "password" ? "" : "open"}`}></i>
								</div>
								{errors.password && (
									<span className="error">
										{errors.password.message}
									</span>
								)}
							</div>
							<div className="col-md-6 col-lg-4 input-wrapper">
								<label className="input-label">Confirm Password</label>
								<div className={`input-wrap password ${errors.re_password ? 'has-error' : ''}`}>
									<input
										type={con_passwordType}
										{...register("re_password")}
										className="form-control"
										placeholder='Confirm password'
									/>
									<i onClick={() => { setCon_passwordType(con_passwordType === "password" ? "text" : "password") }} className={`icon-eye ${con_passwordType === "password" ? "" : "open"}`}></i>
								</div>
								{errors.re_password && (
									<span className="error">
										{errors.re_password.message}
									</span>
								)}
							</div>
						</div>
						<div className="inputs-heading">Lab Information</div>
						<div className="row input-block margin-fix">
							<div className="col-md-6 col-lg-4 input-wrapper required text-fix">
								<label className="input-label">Date of Birth</label>
								{/* <DatePicker className="input-wrap"
        		                        onChange={onChange}
        		                        value={value}
        		                        type="date"
        		                            className="form-control"
        		                            {...register("date")}
											placeholder='Select date'
        		                    /> */}
								<div className={`input-wrap ${errors.dob ? 'has-error' : ''}`}>
									<input
										type='date'
										className="form-control date-input"
										{...register("dob")}
										placeholder='Select date'
									/>
								</div>
								{errors.dob && (
									<span className="error">
										{errors.dob.message}
									</span>
								)}
							</div>
							<div className="col-md-6 col-lg-4 contains-btn">
								<div className="input-wrapper required">
									<label className="input-label text-normal">Phone Number for verification</label>
									<div className={`input-wrap ${isOTPWrong ? 'has-error' : ''}`}>
										<input
											type="number"
											// {...register("phone")}
											onChange={mobHandler}
											value={mob}
											className="form-control"
											placeholder='Phone number'
										/>
										<div className="btn-wrap">
											{
												isPhoneVerify ?
													<div className="verify-btn">Verified</div>
													: <button
														onClick={sendOtp}
														type='button'
													>{isOtp ? "Resend OTP" : "send OTP"}</button>
											}
										</div>
										{!errors.phone && <span className='number-detail'>We have sent you a four-digit code. Please enter it in the next input to confirm the number</span>}
									</div>
									{errors.phone && (
										<span className="error">
											{errors.phone.message}
										</span>
									)}
								</div>
							</div>
							{
								isOtp ?
									<div className="col-md-6 col-lg-4 input-wrapper required">
										<label className="input-label text-normal">Verification Code</label>
										<div className="input-wrap security-input">
											<ReactCodeInput fields={4} onComplete={verifyOtp} />
										</div>
										{errors.phone && (
											<span className="error">
												{errors.phone.message}
											</span>
										)}
									</div>
									: null
							}
						</div>
						<div className="input-wrapper required img-input-fix has-input-file">
							<label className="margin-fix">Proof of Identify</label>
							<div className="row input-block">
								<div className="col-lg-8 input-wrapper mb-xl-0">
									<div className="img-input-wrapper">
										<div className="img-input">
											<i className="icon-plus"></i>
											Upload
											<input
												{...register("proofOfIdentity")}
												type="file"
												accept="image/*"
												ref={attachmentName}
												onChange={imgHandler}
											/>
										</div>
										<span className="img-info">Upload scanned copy of passport, nationalID, driver’s license etc.</span>
									</div>
									{
										img !== "" ?
											<div className="img-thumbnail-wrapper attachment-name image">
												<img src={img} />
											</div>
											:
											''
									}
								</div>
							</div>
							{file && <ImageThumb image={file} />}
						</div>
						<div className="inputs-heading">Business Address</div>
						<div className="row input-block">
							<div className="col-md-6 input-wrapper required">
								<label className="input-label">address line 1</label>
								<div className={`input-wrap ${errors.addressLine1 ? 'has-error' : ''}`}>
									<input
										type="text"
										className="form-control"
										{...register("addressLine1")}
										placeholder='Address line 1'
									/>
									<span className="input-info">Building number and Street</span>
								</div>
								{errors.addressLine1 && (
									<span className="error">
										{errors.addressLine1.message}
									</span>
								)}
							</div>
							<div className="col-md-6 input-wrapper">
								<label className="input-label">address line 2</label>
								<div className={`input-wrap ${errors.addressLine2 ? 'has-error' : ''}`}>
									<input
										type="text"
										{...register("addressLine2")}
										className="form-control"
										placeholder='Address line 2'
									/>
									<span className="input-info">Room/Block/Apartments</span>
								</div>
							</div>
							<div className="col-md-6 input-wrapper required">
								<label className="input-label">Country</label>
								<div className="input-wrap">
									<select {...register("countryId")} name="countryId"
										className="form-control" id="countryId"
										onChange={getStateHandler}
										placeholder='Select'
									>
										<option value={""}>Please select country</option>
										{
											getCountry?.map(item =>
												<option value={item._id}>{item.name}</option>
											)
										}

									</select>
								</div>
								{errors.countryId && (
									<span className="error">
										{errors.countryId.message}
									</span>
								)}
							</div>
							<div className="col-md-6 input-wrapper">
								<label className="input-label">State / Region</label>
								<div className={`input-wrap ${errors.stateId ? 'has-error' : ''}`}>
									<select className="form-control" {...register("stateId")} name="stateId" onClick={getCityHandler}>
										<option value={""}>Please select state</option>
										{
											getState?.map(item =>
												<option value={item._id}>{item.name}</option>
											)
										}

									</select>
								</div>
								{errors.stateId && (
									<span className="error">
										{errors.stateId.message}
									</span>
								)}
							</div>
							<div className="col-md-6 input-wrapper">
								<label className="input-label">City</label>
								<div className="input-wrap">
									<select className="form-control" {...register("cityId")} name="cityId">
										<option value={""}>Please select city</option>
										{
											getCity?.map(item =>
												<option value={item._id}>{item.name}</option>
											)
										}

									</select>
								</div>
								{errors.cityId && (
									<span className="error">
										{errors.cityId.message}
									</span>
								)}
							</div>
							<div className="col-md-6 input-wrapper required">
								<label className="input-label">ZIP / Postal Code</label>
								<div className={`input-wrap ${errors.postcode ? 'has-error' : ''}`}>
									<input
										type="text"
										{...register("postcode")}
										className="form-control"
										autoComplete="off"
										placeholder='ZIP / Postal Code'
									/>
								</div>
								{errors.postcode && (
									<span className="error">
										{errors.postcode.message}
									</span>
								)}
							</div>
						</div>
					</div>
					<div className="btn-wrap">
						<input onClick={changeHandler} className="secondary-btn" type="reset" value="cancel" />
						<input className="btn" type="submit" value="save" />
					</div>
				</form>
			</div>
		</section>
	)
}

const ImageThumb = ({ image }) => {
	return <div className='thumbnail-wrap'>
		<img src={URL.createObjectURL(image)} alt={image.name} />
	</div>
};

export default EditSellerVerification

import React, { useState, useEffect } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { userNotification } from 'store/action/notificationAction';
import { useDispatch, useSelector } from 'react-redux'

const Notifications = () => {

	const dispatch = useDispatch()
	const navigate = useNavigate();
	const getUserNotify = useSelector(state => state.userNotify)
	const date = new Date()
	const yest = new Date().getDate() - 1

	useEffect(() => {
		userNotification()(dispatch)
	}, [])

	const handleWishList = (e, item) => {
		if (item.readStatus === false) {
			let payload = {
				notificationId: item._id
			};
			// readSingleNotification(payload)(dispatch)
			setTimeout(() => userNotification()(dispatch), 300)
		}
	}

	function timeSince(ndate) {
		let date = new Date(ndate);
		var seconds = Math.floor((new Date() - date) / 1000);

		var interval = seconds / 31536000;

		if (interval > 1) {
			return Math.floor(interval) + " years ago";
		}
		interval = seconds / 2592000;
		if (interval > 1) {
			return Math.floor(interval) + " months ago";
		}
		interval = seconds / 86400;
		if (interval > 1) {
			return Math.floor(interval) + " days ago";
		}
		interval = seconds / 3600;
		if (interval > 1) {
			return Math.floor(interval) + " hours ago";
		}
		interval = seconds / 60;
		if (interval > 1) {
			return Math.floor(interval) + " minutes ago";
		}

		return Math.floor(seconds) + " seconds ago";
	}


	console.log("user notification", getUserNotify);

	return (
		<section className='notifications'>
			<div className="container">
				<Breadcrumb>
					<Breadcrumb.Item onClick={() => setTimeout(() => navigate({ pathname: '/lab-dashboard' }), 10)}>Lab Account</Breadcrumb.Item>
					<Breadcrumb.Item active>Notification</Breadcrumb.Item>
				</Breadcrumb>
				<h1>Notification</h1>
				<div className="notification-date">Today</div>
				{getUserNotify?.data?.map(item =>
					new Date(item.createdAt).getDate() + "/" + new Date(item.createdAt).getMonth() === date.getDate() + "/" + date.getMonth() ?

						<div className={item.readStatus ? "notification-wrap" : "notification-wrap unread"} onClick={(e) => handleWishList(e, item)}>
							{console.log('sadasdas', new Date(item.createdAt).getDate() + "/" + new Date(item.createdAt).getMonth() === date.getDate() + "/" + date.getMonth(), new Date(item.createdAt).getDate() + "/" + new Date(item.createdAt).getDate(), date.getDate() + "/" + date.getMonth())}
							<div className="close-wrap">
								<button type='button'>
									<i className="icon-cross"></i>
								</button>
							</div>
							<div className="notification-heading">{item.title}</div>
							<div className="notification-details">
								<p>{item.message}<button type='button'>Read more</button></p>
							</div>
							<div className="time-wrap">{timeSince(item.createdAt)}</div>
						</div>
						:
						null
				)}

				<div className="notification-date">Yesterday</div>
				{getUserNotify?.data?.map(item =>
					new Date(item.createdAt).getDate() + "/" + new Date(item.createdAt).getMonth() === yest + "/" + date.getMonth() ?

						<div className={item.readStatus ? "notification-wrap" : "notification-wrap unread"} onClick={() => handleWishList(item)}>
							{console.log('yesterday', yest)}
							<div className="close-wrap">
								<button type='button'>
									<i className="icon-cross"></i>
								</button>
							</div>
							<div className="notification-heading">{item.title}</div>
							<div className="notification-details">
								<p>{item.message}<button type='button'>Read more</button></p>
							</div>
							<div className="time-wrap">{timeSince(item.createdAt)}</div>
						</div>
						:
						null
				)}

				<div className="notification-date m-fix">Old Notification</div>
				{getUserNotify?.data?.map(item =>
					new Date(item.createdAt).getDate() + "/" + new Date(item.createdAt).getMonth() < yest + "/" + date.getMonth() ?
						<div className={item.readStatus ? "notification-wrap" : "notification-wrap unread"} onClick={(e) => handleWishList(e, item)}>
							<div className="close-wrap">
								<button type='button'>
									<i className="icon-cross"></i>
								</button>
							</div>
							<div className="notification-heading">{item.title}</div>
							<div className="notification-details">
								<p> {item.message}</p>
								{/* <p>Lorem ipsum. In publishing and graphic design, lorem ipsum is a placeholder text used to demonstrate the visual form of a document without relying on meaningful content (also called greeking. to demonstrate the visual form of a document without relying...<button type='button'>Less</button></p> */}
							</div>
							<div className="time-wrap">{timeSince(item.createdAt)}</div>
						</div>
						:
						null
				)}
			</div>
		</section>
	)
}

export default Notifications

import React from 'react';
import { Breadcrumb, Tabs, Tab } from 'react-bootstrap';
import EditInfo from '../../../components/Seller/EditOptInfo';

const EditOptionalInformation = () => {
	return (
		<section className='edit-information'>
			<div className="container">
				<Breadcrumb>
					<Breadcrumb.Item href="#">Seller account</Breadcrumb.Item>
					<Breadcrumb.Item href="#">Optional Information</Breadcrumb.Item>
					<Breadcrumb.Item active>Edit Optional Information</Breadcrumb.Item>
				</Breadcrumb>
				<div className="section-header">
					<h1>Edit Optional Information</h1>
				</div>
				<Tabs defaultActiveKey="entertainment" id="uncontrolled-tab-example" className="mb-3">
					<Tab eventKey="entertainment" title="Entertainment">
						<EditInfo />
					</Tab>
					<Tab eventKey="wedding" title="Wedding Services">
						No data
					</Tab>
				</Tabs>
			</div>
		</section>
	)
}

export default EditOptionalInformation

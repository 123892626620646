import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { viewBillingInfo } from 'store/action/billingViewAction';

const BankDetails = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch()
	const getBillingInfo = useSelector(state => state.billingView)

	useEffect(() => {
		viewBillingInfo()(dispatch)
		if(!getBillingInfo?.data){
			// navigate('/seller/billing-method/edit', {replace:true})
			navigate('/lab/billing-method/edit', {replace:true})
		}
	},[]);

	// useEffect(()=>{
	// 	if(!getBillingInfo.data){
	// 		setTimeout(() =>navigate({ pathname: '/seller/billing-method/edit' }),2000)
	// 	}
	// },[]);

	console.log('gettttt',getBillingInfo)
	return (
		<section>
			{/* {
			getBillingInfo?.data?.map(item => */}
			<div className="bank-details card">
				<h4>Beneficiary Information</h4>
				<div className="row">
					<div className="col-md-6 col-xl-6 width-fix">
						<div className="info-wrapper w-100">
							<div className="info-label">Beneficiary Name:</div>
							<div className="billing-detail">{getBillingInfo?.data?.beneficiaryName}</div>
						</div>
					</div>
					<div className="col-md-6 col-xl-5">
						<div className="info-wrapper w-100">
							<div className="info-label">Beneficiary Account Number / IBAN:</div>
							<div className="billing-detail">{getBillingInfo?.data?.beneficiaryAccountNumber}</div>
						</div>
					</div>
				</div>
				<address>
					<div className="row">
						<div className="col-12">
							<div className="row">
								<div className="col-md-6 col-xl-3 width-fix">
									<div className="info-wrapper">
										<div className="info-label">Address Line 1:</div>
										<div className="billing-detail">{getBillingInfo?.data?.addressLine1}</div>
									</div>
								</div>
								<div className="col-md-6 col-xl-3 width-fix">
									<div className="info-wrapper">
										<div className="info-label">Address Line 2:</div>
										<div className="billing-detail">{getBillingInfo?.data?.addressLine2}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="row four-blocks">
						<div className="col-md-6 col-xl-6 width-fix d-xl-flex">
							<div className="info-wrapper width-fix mb-xl-0">
								<div className="info-label">City:</div>
								<div className="billing-detail">{getBillingInfo?.data?.cityName}</div>
							</div>
							<div className="info-wrapper width-fix mb-md-0">
								<div className="info-label">State / Region:</div>
								<div className="billing-detail">{getBillingInfo?.data?.stateName}</div>
							</div>
						</div>
						<div className="col-md-6 col-xl-5 d-xl-flex">
							<div className="info-wrapper width-fix-74 mb-xl-0">
								<div className="info-label">Country:</div>
								<div className="billing-detail">{getBillingInfo?.data?.countryName}</div>
							</div>
							<div className="info-wrapper width-fix mb-md-0">
								<div className="info-label">ZIP / Postal Code:</div>
								<div className="billing-detail">{getBillingInfo?.data?.postcode}</div>
							</div>
						</div>
					</div>
				</address>
			</div>
				{/* )} */}
			<div className="bank-details card">
				<h4>Beneficiary Bank Information</h4>
				<div className="row">
					<div className="col-md-6 col-xl-6 width-fix">
						<div className="info-wrapper w-100">
							<div className="info-label">Beneficiary Bank Name:</div>
							<div className="billing-detail">{getBillingInfo?.data?.beneficiaryBankName}</div>
						</div>
					</div>
					<div className="col-md-6 col-xl-5">
						<div className="info-wrapper w-100">
							<div className="info-label">Intermediary Bank</div>
							<div className="billing-detail">{getBillingInfo?.data?.intermediaryBank}</div>
						</div>
					</div>
				</div>
				<address>
					<div className="row">
						<div className="col-12">
							<div className="row">
								<div className="col-md-6 col-xl-3 width-fix">
									<div className="info-wrapper">
										<div className="info-label">Address Line 1:</div>
										<div className="billing-detail">{getBillingInfo?.data?.bankAddressLine1}</div>
									</div>
								</div>
								<div className="col-md-6 col-xl-3 width-fix">
									<div className="info-wrapper">
										<div className="info-label">Address Line 2:</div>
										<div className="billing-detail">{getBillingInfo?.data?.bankAddressLine2}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="row four-blocks">
						<div className="col-md-6 col-xl-6 width-fix d-xl-flex">
							<div className="info-wrapper width-fix">
								<div className="info-label">City:</div>
								<div className="billing-detail">{getBillingInfo?.data?.bankCityName}</div>
							</div>
							<div className="info-wrapper width-fix">
								<div className="info-label">State / Region:</div>
								<div className="billing-detail">{getBillingInfo?.data?.bankStateName}</div>
							</div>
						</div>
						<div className="col-md-6 col-xl-5 d-xl-flex">
							<div className="info-wrapper width-fix-74">
								<div className="info-label">Country:</div>
								<div className="billing-detail">{getBillingInfo?.data?.bankCountryName}</div>
							</div>
							<div className="info-wrapper width-fix">
								<div className="info-label">ZIP / Postal Code:</div>
								<div className="billing-detail">{getBillingInfo?.data?.bankPostcode}</div>
							</div>
						</div>
					</div>
				</address>
				<div className="row">
					<div className="col-12">
						<div className="row">
							<div className="col-md-6 col-xl-3 width-fix">
								<div className="info-wrapper mb-md-0">
									<div className="info-label">Swift Code:</div>
									<div className="billing-detail">{getBillingInfo?.data?.swiftCode}</div>
								</div>
							</div>
							<div className="col-md-6 col-xl-3 width-fix">
								<div className="info-wrapper mb-0">
									<div className="info-label">Sort Code:</div>
									<div className="billing-detail">{getBillingInfo?.data?.sortCode}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>




			{/* <div className="bank-details card">
				<h4>Beneficiary Bank Information</h4>
				<div className="row">
					<div className="col-md-6 col-xl-6 width-fix">
						<div className="info-wrapper w-100">
							<div className="info-label">Beneficiary Bank Name:</div>
							<div className="billing-detail">Metropolis Capital Bank</div>
						</div>
					</div>
					<div className="col-md-6 col-xl-5">
						<div className="info-wrapper w-100">
							<div className="info-label">Intermediary Bank</div>
							<div className="billing-detail">Suncorp Bank</div>
						</div>
					</div>
				</div>
				<address>
					<div className="row">
						<div className="col-12">
							<div className="row">
								<div className="col-md-6 col-xl-6 width-fix">
									<div className="info-wrapper">
										<div className="info-label">Address Line 1:</div>
										<div className="billing-detail">Lorem ipsum dolor sit amet 54</div>
									</div>
								</div>
								<div className="col-md-6 col-xl-6 width-fix">
									<div className="info-wrapper">
										<div className="info-label">Address Line 2:</div>
										<div className="billing-detail">Sit amet 36</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-6 col-xl-6 width-fix">
							<div className="info-wrapper">
								<div className="info-label">City:</div>
								<div className="billing-detail">Singapore</div>
							</div>
							<div className="info-wrapper">
								<div className="info-label">State / Region:</div>
								<div className="billing-detail">None</div>
							</div>
						</div>
						<div className="col-md-6 col-xl-5">
							<div className="info-wrapper width-fix">
								<div className="info-label">Country:</div>
								<div className="billing-detail">Singapore</div>
							</div>
							<div className="info-wrapper">
								<div className="info-label">ZIP / Postal Code:</div>
								<div className="billing-detail">123456</div>
							</div>
						</div>
					</div>
				</address>
				<div className="row">
					<div className="col-md-6 col-xl-6 width-fix">
						<div className="info-wrapper">
							<div className="info-label">Swift Code:</div>
							<div className="billing-detail">DEUTDEBB101</div>
						</div>
						<div className="info-wrapper">
							<div className="info-label">Sort Code:s</div>
							<div className="billing-detail">12-34-56</div>
						</div>
					</div>
				</div>
			</div> */}
		</section>
	)
}

export default BankDetails

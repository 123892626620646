import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import userReg from 'store/action/userSignUpAction';
import ReactCodeInput from 'react-verification-code-input';
import { userMobileValid, userMobileValidOtp } from 'store/action/userLoginAction';
import { useNotify } from 'components/Alert/Alert';


const SignIn = () => {
	const dispatch = useDispatch()
	const toast = useNotify()

	const [sendOTPEnable, setSendOTPEnable] = useState(false)

	const [seconds, setSeconds] = useState(0);
	const [resendEnable, setResendEnable] = useState(false)

	const [phoneFieldDisabled, setPhoneFieldDisabled] = useState(false);

	const [firstName, setFirstName] = useState("")
	const [lastName, setLastName] = useState("")
	const [phone, setPhone] = useState({ value: "" })
	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")
	const [cnfPassword, setCnfPassword] = useState("")
	const [terms, setTerms] = useState(false)

	const [showTimer, setShowTimer] = useState(false)

	const [firstNameError, setFirstNameError] = useState(false)
	const [lastNameError, setLastNameError] = useState(false)
	const [phoneError, setPhoneError] = useState(false)
	const [emailError, setEmailError] = useState(false)
	const [emailValidError, setEmailValidError] = useState(false)
	const [passwordError, setPasswordError] = useState(false)
	const [cnfPasswordError, setCnfPasswordError] = useState(false)
	const [termsError, setTermsError] = useState(false)
	const [pwdMatch, setPwdMatch] = useState(false)
	const [passwordLengthError, setPasswordLengthError] = useState(false)

	const [isOtp, setIsOtp] = useState(false)

	const [isShowPassword, setIsShowPassword] = useState(false);
	const [isShowPassword2, setIsShowPassword2] = useState(false);

	useEffect(() => {
        if (phone.value.length === 10) {
            setSendOTPEnable(true)
        }
        else {
            setSendOTPEnable(false)
        }
    }, [phone])

	useEffect(() => {
        if (seconds === 0) {
            console.log("TIME LEFT IS 0");
            setShowTimer(false)
			if(!phoneFieldDisabled){
				setResendEnable(true)
			}
            setSeconds(null)
        }

        // exit early when we reach 0
        if (!seconds) return;

        // save intervalId to clear the interval when the
        // component re-renders
        const intervalId = setInterval(() => {

            setSeconds(seconds - 1);
        }, 1000);

        // clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);
        // add timeLeft as a dependency to re-rerun the effect
        // when we update it
    }, [seconds]);


	const handlePhone = (event) => {
		setPhoneError(false)
		let value = event.target.value
		value = value.replaceAll(' ', '')
		setPhone(phone => value.length <= 10 && !isNaN(Number(value)) && { value } || phone)
		setResendEnable(false)
	}

	const togglePasswordHandler = () => {
		setIsShowPassword(!isShowPassword);
	}
	const togglePasswordHandler2 = () => {
		setIsShowPassword2(!isShowPassword2);
	}

	const inputType = isShowPassword ?
		'text' : 'password';
	const inputType2 = isShowPassword2 ?
		'text' : 'password';
	const togglePasswordBtnClasses = isShowPassword ? 'toggle-btn show' : 'toggle-btn hide';
	const togglePasswordBtnClasses2 = isShowPassword2 ? 'toggle-btn show' : 'toggle-btn hide';


	const onSubmit = (e) => {
		e.preventDefault();
		if (firstName?.length === 0) {
			setFirstNameError(true)
			return
		}
		else if (lastName?.length === 0) {
			setLastNameError(true)
			return
		}
		else if (phone?.value?.length === 0) {
			setPhoneError(true)
			return
		}
		else if (email?.length === 0) {
			setEmailError(true)
			return
		}
		else if (emailValidError) {
			return;
		}
		else if (password?.length === 0) {
			setPasswordError(true)
			return
		}
		else if (password?.length < 8) {
			setPasswordLengthError(true)
			return
		}
		else if (cnfPassword?.length === 0) {
			setCnfPasswordError(true)
			return
		}
		else if (password !== cnfPassword) {
			setPwdMatch(true)
			return
		}
		else if (!terms) {
			setTermsError(true)
			return
		}
		let data = {
			firstName: firstName,
			lastName: lastName,
			phone: phone?.value,
			email: email,
			password: password,
			type: "User",
		}
		userReg(data)(dispatch)
		return
	};

	const handleEmail = (e) => {
		var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
		console.log("filter.test(e.target.value", filter.test(e.target.value))
		if (filter.test(e.target.value)) {
			setEmailValidError(false)
		}
		else {
			setEmailValidError(true)
		}
		setEmail(e.target.value)
		setEmailError(false)
	}

	const handleSeconds = () => {
        setSeconds(5)
    }

	const sendOtpHandler = () => {
        const payload = {
            phone: phone.value,
            countryCode: "+91"
        }
		userMobileValid(dispatch, payload, setIsOtp, toast, setSendOTPEnable, handleSeconds, setShowTimer)
    }

	const verifyOtp = (val) => {

        let payload = {
            phone: phone.value,
            otp: val
        }

		userMobileValidOtp(dispatch, payload, setIsOtp,toast, setShowTimer, setResendEnable, setPhoneFieldDisabled)

    }

	return (
		<section className='create-account'>
			<ToastContainer
				// className={'modal-backdrop'}
				position="top-right"
				autoClose={3000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
			/>
			<div className="container">
				<div className="card">
					<h2>Create Account</h2>
					<form onSubmit={onSubmit}>
						<div className="row inputs-wrapper">
							<div className="col-md-6 pr-fix input-block">
								<div className="input-wrapper required">
									<label>First Name</label>
									<div className={`input-wrap ${firstNameError ? 'has-error' : ''}`}>
										<input
											type="text"
											onChange={(e) => { setFirstName(e.target.value); setFirstNameError(false) }}
											value={firstName}
											className='form-control' />
									</div>
									{firstNameError && <p className='error'>Please enter first name</p>}
								</div>
							</div>
							<div className="col-md-6 pl-fix input-block">
								<div className="input-wrapper required">
									<label>Last Name</label>
									<div className={`input-wrap ${lastNameError ? 'has-error' : ''}`}>
										<input
											type="text"
											onChange={(e) => { setLastName(e.target.value); setLastNameError(false) }}
											value={lastName}
											className='form-control' />
									</div>
									{lastNameError && <p className='error'>Please enter last name</p>}
								</div>
							</div>
						</div>
						<div className="row inputs-wrapper">
							<div className="col-12 input-block mobile-otp">
								<div className={`${isOtp ? "has-otp":""} input-wrapper required mobile-input`}>
									<label htmlFor="user-id">Mobile Number</label>
									<div className={`input-wrap p-relative ${phoneError ? 'has-error' : ''}`}>
										<input
											type="text"
											onChange={handlePhone}
											value={phone.value}
											disabled={phoneFieldDisabled}
											className='form-control' />
									</div>
									{
									!resendEnable && 
									!showTimer && 
									sendOTPEnable && 
										<button type='button' onClick={sendOtpHandler} className='send-button-otp'>Send OTP</button>
									}
									 {
                                            phoneFieldDisabled ?
                                                <div className="verify-btn">Verified</div>
                                                : ""
                                        }
									{
                                        showTimer ? <div className='timer-user'>Time remaining: {seconds} seconds</div> : ""
                                    }
									{
                                            !showTimer && resendEnable && phone.value?.length === 10 && <button
                                                onClick={sendOtpHandler}
                                                type='button' className='send-button-otp'>Resend OTP </button>
                                        }
									{phoneError && <p className='error'>Please enter mobile number</p>}
								</div>
								{
									isOtp  ?
										<div className="col-3 input-wrapper required">
											<label className="input-label text-normal">Verification Code</label>
											<div className="input-wrap security-input">
												<ReactCodeInput
													fields={4}
													onComplete={verifyOtp}
												/>
											</div>
											{/* {errors.phone && (
												<span className="error">
													{errors.phone.message}
												</span>
											)} */}
										</div>
										: null
								}
							</div>
							<div className="col-12 input-block">
								<div className="input-wrapper required">
									<label htmlFor="user-id">Email Address</label>
									<div className={`input-wrap ${emailError ? 'has-error' : ''}`}>
										<input
											type="text"
											onChange={handleEmail}
											value={email}
											className='form-control' />
									</div>
									{emailError && <p className='error'>Please enter email</p>}
									{emailValidError && <div className='error'>Please enter valid email</div>}
								</div>
							</div>
							<div className="col-12 input-block">
								<div className="input-wrapper required">
									<label htmlFor="password">Password</label>
									<div className={`input-wrap ${passwordError ? 'has-error' : ''}`}>
										<input type={inputType}
											onChange={(e) => { setPassword(e.target.value); setPasswordError(false); setPasswordLengthError(false) }}
											value={password}
											className='form-control' />
										<div className="toggle-wrap">
											<button className={togglePasswordBtnClasses} type="button" onClick={togglePasswordHandler}>
												<i className="icon-eye"></i>
											</button>
										</div>
									</div>
									{passwordError && <p className='error'>Please enter password</p>}
									{passwordLengthError && <p className='error'>Password must have atleast 8 characters</p>}
								</div>
							</div>
							<div className="col-12 input-block">
								<div className="input-wrapper required">
									<label htmlFor="password">Confirm Password</label>
									<div className={`input-wrap ${cnfPasswordError ? 'has-error' : ''}`}>
										<input type={inputType2}
											onChange={(e) => { setCnfPassword(e.target.value); setCnfPasswordError(false); setPwdMatch(false) }}
											value={cnfPassword}
											className='form-control' />
										<div className="toggle-wrap">
											<button className={togglePasswordBtnClasses2} type="button" onClick={togglePasswordHandler2}>
												<i className="icon-eye"></i>
											</button>
										</div>
									</div>
									{cnfPasswordError && <p className='error'>Please enter confirm password</p>}
									{pwdMatch && <p className='error'>Password and confirm password should be same</p>}
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-12 mb-2 mb-md-0">
								<div className="form-check p-relative">
									<input className="form-check-input" onChange={(e) => { setTerms(e.target.checked); setTermsError(false) }} type="checkbox" value="" />
									<label className="form-check-label" >By signing in you agree to the <Link to='/'>Terms and Conditions</Link> of Aladyyn</label>
									{termsError && <p className='error'>Please agree terms and conditions</p>}
								</div>
							</div>
						</div>
						<div className="btn-wrap">
							<button type='submit' className='btn w-100'>Create Account</button>
						</div>
					</form>
				</div>
				<div className="card text-center">
					<div className="signin-wrap">
						Already have an account?
						<Link to='/sign-in' className='signin-link'>Sign In</Link>
					</div>
				</div>
			</div>
		</section>
	)
}

export default SignIn

import React, { useState } from 'react';


const Warning = (props) => {


	return (
		<React.Fragment>
			<div className="alert alert-warning alert-dismissible fade show" role="alert">
				<div className="warning-header">
					<div className="wrap-icon">
						<div className="icon-warning"></div>
					</div>
					<div className="heading-wrap">
						<h4>Warning Notes</h4>
					</div>
				</div>
				<p>Please fill in the information in the available sections (Lab Information; Verification & Security; Billing Method and Optional Information). You will be able to publish your services after we approve your account. Thank you!</p>
				<div className="btn-wrap">
					<button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => props.onCloseWarning(false)}>
						<i aria-hidden="true" className="icon-cross"></i>
					</button>
				</div>
			</div>
		</React.Fragment>
	)
}

export default Warning
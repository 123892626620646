import * as actiontype from "../actionType";
import { endPoints } from "../../utils/endPoints";
import { axiosInstanceWithUserToken } from "../../utils/Axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const stateList = (payload,dispatch) => {
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken.post(endPoints.STATE_LIST, payload)
        .then(response => {
            dispatch({
                type: actiontype.SET_STATE_DATA,
                payload: response.data.data
            })
            dispatch({ type: "LOADERSTOP" })
        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
            // toast.error("error")
            // console.log("success respoerrResponsese", errResponse)
        });
};


import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const LoginAndSecurity = () => {
	return (
		<section className='login-security'>
			<div className="container">
				<Breadcrumb>
					<Breadcrumb.Item href="#">User Account</Breadcrumb.Item>
					<Breadcrumb.Item active>Login & Security</Breadcrumb.Item>
				</Breadcrumb>
				<div className="section-head">
					<div className="heading-wrap">
						<h1>Login & Security</h1>
					</div>
					<div className="btn-wrap">
						<Link to='/user/login-and-security/Edit' className='btn'>Edit</Link>
					</div>
				</div>
				<div className="login-info">
					<div className="card info-card">
					<div className="info-heading">Account Information</div>
						<span className="info-label">Phone Number:</span>
						<span className="info-wrap">+1 (012) 34-56-789</span>
						<span className="info-label">Password:</span>
						<span className="info-wrap">********</span>
					</div>
				</div>
			</div>
		</section>
	)
}

export default LoginAndSecurity

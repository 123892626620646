import React, { useState, useEffect, useRef } from 'react';
import Categories from 'components/Categories';
import { Link, useParams } from 'react-router-dom';
import subCategory1 from 'assets/images/sub-category-1.png';
import { getHomeSub } from 'store/action/homeSubCategory.Action';
import { useDispatch, useSelector } from 'react-redux'

const Category = (props) => {
	let params = useParams()
	const dispatch = useDispatch()
	const getHomeSubs = useSelector((state) => state.homeSubCat)
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(12);
	const indexOfLastTodo = page * limit;
	const indexOfFirstTodo = indexOfLastTodo - limit;

	useEffect(() => {
		getHomeSub(params.cid, params.name)(dispatch)
	}, [])

	const clickPagination = (index) => {
		setPage(index);
	}

	const clickPrev = (index) => {
		if (index > 0) {
			setPage(index - 1);
		}
		else {
			return
		}
	}

	const clickNext = (index) => {
		setPage(index + 1);
	}

	const servicePage = (item) => {
		console.log('wreer', item.categoryId)
		window.location.href = `/category/particular/${item.categoryId}/${item._id}/${item.name}`
	}

	console.log("subss", getHomeSubs)

	return (
		<section className='category-block'>
			<div className="container">
				<Categories />
				<div className="particular-category">
					<h1>{params.name}</h1>
					<div className="services-block">
						{
							getHomeSubs?.slice(indexOfFirstTodo, indexOfLastTodo).map(item =>
								<div className="service-wrap">
									<a onClick={() => servicePage(item)}>
										<div className='img-wrap'>
											{
												!item.subcategoryIcon ?
													<img src={subCategory1} alt="service" />
													:
													<img src={item.path + item.subcategoryIcon} alt="service" />
											}
										</div>
										<div className="service-info">{item.name}</div>
									</a>
								</div>
							)}
						{
							getHomeSubs.length === 0 ?
								<div>No data found ...</div>
								: null
						}
					</div>
					{
						getHomeSubs.length > 0 &&
						<nav
							className="pagination-wrap"
							aria-label="Page navigation example"
						>
							<ul className="pagination">
								<li className={page === 1 ? "page-item previous disabled" : "page-item previous"}>
									<a
										className="page-link"
										href="javascript:void(0)"
										tabIndex="-1"
										onClick={() => clickPrev(page)}
									>
										<i className="icon-arrow"></i>
									</a>
								</li>
								{
									getHomeSubs.length > 0 ?
										[...Array(Math.ceil(getHomeSubs.length / 12))].map((item, i) =>
											<li className={i + 1 === page ? "page-item active" : "page-item"} aria-current="page">
												<a className="page-link" href="javascript:void(0)" onClick={() => clickPagination(i + 1)}>
													{i + 1}
												</a>
											</li>
										)
										: null
								}


								<li className={page === Math.ceil(getHomeSubs.length / 12) ? "page-item  disabled" : "page-item "}>
									<a className="page-link" href="javascript:void(0)" onClick={() => clickNext(page)}>
										<i className="icon-arrow"></i>
									</a>
								</li>
							</ul>
						</nav>
					}
				</div>
			</div>
		</section>
	)
};

export default Category;

import { endPoints } from "../../utils/endPoints";
import { axiosInstanceWithUserToken } from "../../utils/Axios";
import * as actiontype from "../actionType";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const paymentProcess = (data) => (dispatch) => {
    let token = localStorage.getItem("user_token");
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken
        .post(endPoints.PAYMENT_GATE, data, {
            headers: {
                Authorization: token,
            },
        })
        .then(async (response) => {
            dispatch({ type: "LOADERSTOP" })
            if (response?.data?.status === "success") {
                toast.success(response?.data?.message)
                dispatch({
                    type: actiontype.PAYMENTS,
                    payload: data
                });
                setTimeout(() => window.location.href = (response?.data?.data))
                console.log("response url", response?.data?.data)

                // console.log('smdsmn', 'user_token')
            } else {
                // console.log("wrong")
                // dispatch({
                //     // type: actiontype.LOGIN_FAIL,
                //     // payload: {
                //     //     islogin: false
                //     // },
                // });
                toast.error(response?.data?.message);
            }
            // console.log("success response", response.data.data.token)
        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
            toast.error("error")
            // console.log("success respoerrResponsese", errResponse)
        });
};

export { paymentProcess }
import React, { useState, useEffect } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { viewProfile } from 'store/action/profileAction';
import ProfileImage from 'assets/images/edit-card-image.png';

const SellerVerification = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch()
	const getProfileInfo = useSelector(state => state.profileView)
	const [isPasswordVisible, setIsPasswordVisible] = useState(false);

	const togglePasswordHandler = event => {
		event.preventDefault();
		setIsPasswordVisible(!isPasswordVisible);
	}

	useEffect(() => {
		viewProfile()(dispatch);
		console.log('dasdsdsdaa', getProfileInfo)
	}, []);

	const handleLink = () => {
		console.log('dataaaaa', getProfileInfo)
		if (getProfileInfo.data[0].type == "Freelancer") {
			setTimeout(() => navigate({ pathname: '/seller-verification/edit' }), 500);
		}
		else {
			// setTimeout(() => navigate({ pathname: '/seller/company/seller-verification/edit' }), 500);
			setTimeout(() => navigate({ pathname: '/lab/lab-verification/edit' }), 500);
		}
	}

	const changePage = () => {
		navigate('/lab-dashboard')
		// navigate({ pathname: '/lab-dashboard' })
		// console.log('sdjhbj')
	}

	const inputTypeValue = isPasswordVisible ?
		'text' : 'password';
	// console.log('checking data', getProfileInfo)
	return (
		<section className="seller-verification">
			<div className="container">
				<Breadcrumb>
					<Breadcrumb.Item onClick={changePage}>Lab account</Breadcrumb.Item>
					<Breadcrumb.Item active>Lab Verification & security</Breadcrumb.Item>
				</Breadcrumb>
				<div className="section-header">
					<div className="header-left">
						<h1>Lab Verification & security</h1>
					</div>
					<div className="header-right">
						<button onClick={handleLink} className='btn'>Edit</button>
					</div>
				</div>
				{
					getProfileInfo?.data?.map(item =>
						<div className="information-block">
							<div className="information-card card">
								<h4>Account Information</h4>
								<div className="info-label">First Name:</div>
								<div className="seller-detail">{item.firstName}</div>
								<div className="info-label">Last Name:</div>
								<div className="seller-detail">{item.lastName}</div>
								<div className="info-label">Email:</div>
								<div className="seller-detail">{item.email}</div>
								<div className="info-label">Password:</div>
								<div className="seller-detail contains-btn">
									<div className="input-wrap">
										<input type={inputTypeValue} className="padding-fix" readOnly value={item.password} />
										<div className="toggle-password">
											<button type="button" onClick={togglePasswordHandler}>
											</button>
										</div>
									</div>
								</div>
							</div>
							<div className="information-card card">
								<h4>Contact Details</h4>
								{
									item.type == "Freelancer" ?
										<>
											<div className="info-label">Date of Birth:</div>
											<div className="seller-detail">{item?.dob?.split("T")[0]}</div>
										</>
										:
										null
								}
								<div className="info-label">Phone Number:</div>
								<div className="seller-detail">{item.phone}</div>

{/* {console.log("eeeeeeeeeeeeeeee",item.sellerAttachmentShowPath + item.proofOfIdentity)} */}

								{
									item.type == "Lab" ?
										<>
											<div className="info-label">Proof of Identify</div>
											{item.proofOfIdentity ? <div className="image-wrap">
												<img src={item.sellerAttachmentShowPath + item.proofOfIdentity} />
											</div>:
											<div className="image-wrap">
											<img src={ProfileImage} />
										</div>}
										</>
										:
										null
								}
							</div>
							<div className="information-card card full-width">
								<h4>Registered Business Address</h4>
								<div className="content-wrapper">
									<div className="row top-block">
										<div className="col-md-6 col-lg-3 info-wrapper">
											<div className="info-label">Address Line 1:</div>
											<div className="seller-detail">{item.addressLine1}</div>
										</div>
										<div className="col-md-6 col-lg-3 info-wrapper">
											<div className="info-label">Address Line 2:</div>
											<div className="seller-detail">{item.addressLine2}</div>
										</div>
									</div>
									<div className="row bottom-block">
										<div className="col-md-6 col-lg-3 info-wrapper">
											<div className="info-label">City:</div>
											<div className="seller-detail">{item.cityName}</div>
										</div>
										<div className="col-md-6 col-lg-3 info-wrapper">
											<div className="info-label">State / Region:</div>
											<div className="seller-detail">{item.stateName}</div>
										</div>
										<div className="col-md-6 col-lg-3 info-wrapper">
											<div className="info-label">Country:</div>
											<div className="seller-detail">{item.countryName}</div>
										</div>
										<div className="col-md-6 col-lg-3 info-wrapper">
											<div className="info-label">ZIP / Postal Code:</div>
											<div className="seller-detail">{item.postcode}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					)
				}
			</div>
		</section>
	)
}

export default SellerVerification;

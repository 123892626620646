import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const Navbar = () => {
	const navigate = useNavigate();
	const login = useSelector((state) => state.login);
	console.log("login---", login)

	const token = localStorage.getItem("user_token")

	const getProfileInfo = useSelector(state => state.profileView)
	console.log("getProfileInfo",getProfileInfo)


	const hanldeNav = () => {
		if (login.islogin) {
			setTimeout(() => (window.location.href = '/lab-dashboard'), 100);
			return
		}
		else {
			// setTimeout(() => navigate({ pathname: '/become-seller-form' }), 100);
			setTimeout(() => (window.location.href = '/register-Lab'), 100);
		}
	};

	// const hanldeNavigator = () => {
	// 	if (login.islogin) {
	// 		setTimeout(() => (window.location.href= '/seller-dashboard-company'), 3000);
	// 		return
	// 	}
	// 	else {
	// 		setTimeout(() => navigate({ pathname: '/' }), 100);
	// 		setTimeout(() =>  (window.location.href= '/become-seller'), 3000);
	// 	}
	// };

	return (
		<nav className='nav'>
			<ul className='w-100'>

				{/* <li>
					<a onClick={hanldeNavigator}>Customer Service</a>
				</li>
				<li>
					<a onClick={hanldeNavigator}>Best Sellers</a>
				</li> */}


				{token
					? <span style={{color:"white"}}>Welcome {getProfileInfo?.data?.[0]?.businessName}</span>
					:
					<li>
						<a onClick={hanldeNav}>Register as a Lab</a>
					</li>
				}


			</ul>
		</nav>
	)
}

export default Navbar;

import * as actiontype from "../actionType";
import { endPoints } from "../../utils/endPoints";
import { axiosInstanceWithUserToken } from "../../utils/Axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const getHomeSub = (payload) => (dispatch) => {
    let token = localStorage.getItem("user_token");
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken.post(endPoints.SUB_CATEGORY_LIST_HOME + "?categoryId=" + payload, {
        headers: {
            Authorization: token,
        },
    }
    )
        .then(response => {
            dispatch({ type: "LOADERSTOP" })
            dispatch({
                type: actiontype.HOME_SUBS,
                payload: response.data.data
            })
        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
            // toast.error("error")
            console.log("success respoerrResponsese", errResponse)
        });
};

import React, { useState, useEffect } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import moment from "moment";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import { useDispatch, useSelector } from "react-redux";
import { countryList } from "store/action/countryList";
import { stateList } from "store/action/stateList";
import { cityList } from "store/action/cityListAction";
import Time from "./Time";
import { addNewAddress, getAddressDetail, editAddress, markAvailabilityAction } from "store/action/addAddressAction";
import { categoryFinalList } from "store/action/categoryListAction";
import { finalCategoryData } from "store/reducer/category.reducer";
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';


import DatePicker from "react-date-picker";
import 'react-datepicker/dist/react-datepicker.css';
import { useNotify } from '../../components/Alert/Alert';
import { getAddressList } from "store/action/sellerServiceAddAction";
import { useNavigate, useParams } from "react-router-dom";






const ManageDatePopup = (props) => {

  console.log("props--->", props)

 

  const toast = useNotify();

const naviagte=useNavigate();

  const dispatch = useDispatch()

  const {

    formState: { errors },
    handleSubmit,

  } = useForm({
    mode: "onSubmit"
  });



  const [startDate, setStartDate] = useState(null);
  console.log("startDate", startDate)
  const [endDate, setEndDate] = useState(null);
  // console.log("startDate....", startDate, "............endDate", endDate)

  const [startMonth, setStartMonth] = useState(null)


  const [selectStartDate, setSelectStartDate] = useState(null)
  console.log("selectStartDate---", selectStartDate)
  const [selectEndDate, setSelectEndDate] = useState(null)

  const handleStartDateChange = date => {
    setStartDate(date);
  };

  const handleEndDateChange = date => {
    setEndDate(date);
  };

  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];


  // useEffect(() => {
  //   const d = new Date(startDate);
  //   let month = months[d.getMonth()];
  //   setStartMonth(month)
  // }, [startDate])

  // const d = new Date("2021-03-25");
  // let month = months[d.getMonth()];

  const closeModalHandler = () => {
    props.onCloseModal(false)
  }

  const handleChangeFrom = (val) => {

    const currentDate = new Date(val);
    // console.log("val---->", val)
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(currentDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;

    // console.log("formattedDate", formattedDate);
    // setSelectStartDate(currentDate)
    setSelectStartDate(formattedDate)
  }

  const handleChangeTo = (val) => {
    console.log("first", val)
    const currentDate = new Date(val);
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(currentDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;

    // console.log("formattedDate", formattedDate);
    setSelectEndDate(formattedDate)
  }

  const daysAvailable = [{
    from: startDate,
    to: endDate
  }]

  // const onSubmit = () => {

  //   if (!selectStartDate && !selectEndDate) {
  //     toast.error(" Please select start  and enddate")
  //   } else if (!selectStartDate) {
  //     toast.error(" Please select start date")
  //   } else if (!selectEndDate) {
  //     toast.error(" Please select end date")
  //   }

  //   if (selectStartDate && selectEndDate) {
  //     const payload = {
  //       addressId: props.addressId,
  //       daysAvailable,
  //       isMarked: selectStartDate && selectEndDate ? true : false

  //     }
  //     markAvailabilityAction(payload, dispatch, toast, callback => {
  //       if (callback) {
  //         setTimeout(() => {
  //           window.location.reload('/lab-information');
  //         }, 3000);

  //       }
  //     })
  //   }




  const onSubmit = () => {

    if (!startDate && !endDate) {
      toast.error(" Please select start  and enddate")
    } else if (!startDate) {
      toast.error(" Please select start date")
    } else if (!endDate) {
      toast.error(" Please select end date")
    }

    if (startDate && endDate) {
      const payload = {
        addressId: props.addressId,
        daysAvailable,
        isMarked: startDate && endDate ? true : false

      }



      markAvailabilityAction(payload, dispatch, toast, callback => {
        if (callback) {
          // console.log("callback",callback)
          getAddressList({sellerInformationId:props.sellerInformationId}, (data)=>{console.log("gggggggg",data); props.setAddressList(data)})(dispatch)
          // naviagte('/lab-information?to=availability')
          props.onCloseModal(false)
          // setTimeout(() => {
          //   window.location.reload('/lab-information');
          // }, 3000);

        }
      })
    }

  }

  console.log("startDate", startDate);

  return (
    <div className="modal-backdrop date-box-wrapper">
      <div className="add-address card add-date-wrap">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <h1 className="date-heading">Select Dates </h1>
            <div className="date-content-wrap">
              <div className={`date-content ${startDate ? "has-date" : ""}`}>
                Start Date:{' '}
                <DatePicker

                  value={startDate}
                  // onChange={(val) => handleChangeFrom(val)}
                  onChange={setStartDate}

                  dateFormat="MM/dd/yyyy"

                />
              </div>

              <div className={`date-content ${endDate ? "has-date" : ""}`}>
                End Date:{' '}
                <DatePicker
                  value={endDate}
                  onChange={setEndDate}

                  dateFormat="MM/dd/yyyy"

                />
              </div >
            </div>
            {/* <h3>{endDate}</h3> */}

          </div>
          <div className="btn-wrap btn-wrapper">
            <input
              className="secondary-btn secondary-cancel-btn"
              type="reset"
              onClick={closeModalHandler}
              value="cancel"
            />
            <input
              //   className={isBtnDisable ? "btn -disable" : "btn"}
              type="submit"
              value="submit"
              //   disabled={isBtnDisable}
              className="submit-btn-wrap btn"
            />
          </div>

        </form>




      </div>

    </div>
  );
};

export default ManageDatePopup;

import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Tabs, Tab } from 'react-bootstrap';
import Entertainment from 'components/OptionalInformation/Entertainment2';

const OptionalInformation = () => {
	return (
		<section className='seller-optional-info'>
			<div className="container">
				<Breadcrumb>
					<Breadcrumb.Item href="#">Lab account</Breadcrumb.Item>
					<Breadcrumb.Item active>Optional Information</Breadcrumb.Item>
				</Breadcrumb>
				<div className="section-header">
					<div className="header-left">
						<h1>Optional Information</h1>
					</div>
					<div className="header-right">
						<Link to='/optional-info/edit' className='btn'>Edit</Link>
					</div>
				</div>
				<Tabs defaultActiveKey="entertainment" id="uncontrolled-tab-example" className="mb-3">
					<Tab eventKey="entertainment" title="Entertainment">
						<Entertainment />
					</Tab>
					<Tab eventKey="wedding" title="Wedding Services">
						No data
					</Tab>
				</Tabs>
			</div>
		</section>
	)
}

export default OptionalInformation

import Rating from 'react-rating';
import starEmpty from 'assets/images/icons/icon-star-empty.svg';
import starFilled from 'assets/images/icons/icon-star-2.svg';
import { Link } from 'react-router-dom';
import Services from 'assets/images/service-image-1.png';
import {
    addServiceWishlist, removeServiceWishlist
} from 'store/action/ServiceDetailAction'
import { useDispatch } from 'react-redux';

const OtherServiceList = ({ item }) => {
    let dispatch = useDispatch()

    const addToWishlist = (e, item) => {
        e.preventDefault()
        if (item.wishlist) {
            removeServiceWishlist({ serviceId: item._id }, "otherservice")(dispatch)
        } else {
            addServiceWishlist({ serviceId: item._id }, "otherservice")(dispatch)
        }
    }

    return (
        <div className='slide'>
            <div className='card-wrap'>
                <Link to={`/service-detail/${item._id}/${item.sellerId}`} className='service-block'>
                    <div className='service-image'>
                        {
                            item.serviceCover === "" ?
                                <img src={Services} alt="service-image" />
                                : <img src={item.path + item.serviceCover} alt="service-image" />
                        }

                    </div>
                    <div className='service-card'>
                        <div className='service-name'>{item.title}</div>
                        {
                            item.addressData.length > 0 ?
                                <>
                                    <div className='location'>{item.addressData[0].countryName + " " + item.addressData[0].stateName}</div>
                                    <div className='address'>
                                        {
                                            item.addressData[0].addressLine1
                                        }
                                        <>
                                            {
                                                item.addressData[0].addressLine2 !== "" &&
                                                + ", " +
                                                item.addressData[0].addressLine2
                                            }

                                        </>
                                    </div>
                                </> : null
                        }

                        <div className='seller-rating'>
                            <div className='rating-wrap'>
                                <div className='rating-image'>
                                    <Rating
                                        readonly
                                        placeholderRating={item?.averageRating ? item?.averageRating : 0}
                                        emptySymbol={<img src={starEmpty} className="icon" />}
                                        placeholderSymbol={<img src={starFilled} className="icon" />}
                                        fullSymbol={<img src={starFilled} className="icon" />}
                                    />
                                </div>
                                <div className='rating-number'>{item.totalReview} reviews</div>
                            </div>
                        </div>
                        <div className='services-block'>
                            <div className='price'>${item.price}</div>
                            <button className='wishlist-btn' onClick={(e) => addToWishlist(e, item)}>
                                <i className={item?.wishlist ? 'icon-heart add' : 'icon-heart'}></i>
                            </button>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default OtherServiceList;
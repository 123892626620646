import React from 'react';
import orderImg from 'assets/images/order-img.png';
import { Link } from 'react-router-dom';

const CurrentOrders = () => {
  return <div className='order-block'>
    <div className="order-wrap">
      <div className="order-img">
        <img src={orderImg} alt="order" />
      </div>
      <div className="order-info">
        <div className="order-left">
          <div className="order-name">
            <Link to='/'>Photo Session</Link>
          </div>
          <div className="order-provider">Media Entertainment Corporation</div>
          <div className="order-detail">
            <div className="order-id">
              <div className="order-heading">Order ID#</div>
              <div className="order-value">433-9880211-6581152</div>
            </div>
            <div className="order-placed">
              <div className="order-heading">Order Placed</div>
              <div className="order-value">8 Feb 2021</div>
            </div>
            <div className="order-amount">
              <div className="order-heading">Total Cost</div>
              <div className="order-value">$150</div>
            </div>
          </div>
        </div>
        <div className="order-cta">
          <div className="wishlist-wrap">
            <button>Add to Wishlist</button>
          </div>
          <div className="btns-wrap">
            <div className="chat-btn">
              <Link to='/' className='btn text-transform-initial'>Chat with Lab</Link>
            </div>
            <div className="cancel-btn">
              <Link to='/' className='secondary-btn text-transform-initial'>Cancel the Order</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="order-wrap">
      <div className="order-img">
        <img src={orderImg} alt="order" />
      </div>
      <div className="order-info">
        <div className="order-left">
          <div className="order-name">
            <Link to='/'>Carpentry</Link>
          </div>
          <div className="order-provider">Media Entertainment Corporation</div>
          <div className="order-detail">
            <div className="order-id">
              <div className="order-heading">Order ID#</div>
              <div className="order-value">433-9880211-6581152</div>
            </div>
            <div className="order-placed">
              <div className="order-heading">Order Placed</div>
              <div className="order-value">8 Feb 2021</div>
            </div>
            <div className="order-amount">
              <div className="order-heading">Total Cost</div>
              <div className="order-value">$150</div>
            </div>
          </div>
        </div>
        <div className="order-cta">
          <div className="wishlist-wrap">
            <button>Add to Wishlist</button>
          </div>
          <div className="btns-wrap">
            <div className="chat-btn">
              <Link to='/' className='btn text-transform-initial'>Chat with Lab</Link>
            </div>
            <div className="cancel-btn">
              <Link to='/' className='secondary-btn text-transform-initial'>Cancel the Order</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="order-wrap">
      <div className="order-img">
        <img src={orderImg} alt="order" />
      </div>
      <div className="order-info">
        <div className="order-left">
          <div className="order-name">
            <Link to='/'>Cooking Master Class</Link>
          </div>
          <div className="order-provider">Media Entertainment Corporation</div>
          <div className="order-detail">
            <div className="order-id">
              <div className="order-heading">Order ID#</div>
              <div className="order-value">433-9880211-6581152</div>
            </div>
            <div className="order-placed">
              <div className="order-heading">Order Placed</div>
              <div className="order-value">8 Feb 2021</div>
            </div>
            <div className="order-amount">
              <div className="order-heading">Total Cost</div>
              <div className="order-value">$150</div>
            </div>
          </div>
        </div>
        <div className="order-cta">
          <div className="wishlist-wrap">
            <button>Add to Wishlist</button>
          </div>
          <div className="btns-wrap">
            <div className="chat-btn">
              <Link to='/' className='btn text-transform-initial'>Chat with lab</Link>
            </div>
            <div className="cancel-btn">
              <Link to='/' className='secondary-btn text-transform-initial'>Cancel the Order</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="order-wrap">
      <div className="order-img">
        <img src={orderImg} alt="order" />
      </div>
      <div className="order-info">
        <div className="order-left">
          <div className="order-name">
            <Link to='/'>Makeup Services</Link>
          </div>
          <div className="order-provider">Media Entertainment Corporation</div>
          <div className="order-detail">
            <div className="order-id">
              <div className="order-heading">Order ID#</div>
              <div className="order-value">433-9880211-6581152</div>
            </div>
            <div className="order-placed">
              <div className="order-heading">Order Placed</div>
              <div className="order-value">8 Feb 2021</div>
            </div>
            <div className="order-amount">
              <div className="order-heading">Total Cost</div>
              <div className="order-value">$150</div>
            </div>
          </div>
        </div>
        <div className="order-cta">
          <div className="wishlist-wrap">
            <button>Add to Wishlist</button>
          </div>
          <div className="btns-wrap">
            <div className="chat-btn">
              <Link to='/' className='btn text-transform-initial'>Chat with Lab</Link>
            </div>
            <div className="cancel-btn">
              <Link to='/' className='secondary-btn text-transform-initial'>Cancel the Order</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <nav className='pagination-wrap' aria-label="Page navigation example">
      <ul className="pagination">
        <li className="page-item previous disabled"><a className="page-link" href="#" tabIndex="-1" aria-disabled="true"><i className='icon-arrow'></i></a></li>
        <li className="page-item active" aria-current="page">
          <a className="page-link" href="#">1</a>
        </li>
        <li className="page-item"><a className="page-link" href="#">2</a></li>
        <li className="page-item"><a className="page-link" href="#">3</a></li>
        <li className="page-item"><a className="page-link" href="#">4</a></li>
        <li className="page-item"><a className="page-link" href="#">5</a></li>
        <li className="page-item">
          <a className="page-link" href="#">
            <i className='icon-arrow'></i>
          </a>
        </li>
      </ul>
    </nav>
  </div>;
};

export default CurrentOrders;

import * as types from '../actionType'

const initialState = {}

const getOtherServiceList = (state, payload) => {
    let newState = { ...state };
    return { ...newState, otherServiceList: payload }
}


const setReviewList = (state, payload) => {
    let newState = { ...state };
    return { ...newState, reviewList: payload }
}

const wishlistOtherServices = (state, payload) => {
    let newData = { ...state };
    let newState = [...state.otherServiceList.data];

    newState = newState.map(item => {
        if (item._id === payload.data.serviceId) {
            return { ...item, wishlist: payload.type }
        } else {
            return item
        }
    })
    state.otherServiceList.data = newState
    return { ...state, ...newData }
}


const sellerProfileRedu = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_SELLER_DETAILS:
            return action.payload;
        case types.SET_SERVICE_OF_SELLER:
            return getOtherServiceList(state, action.payload)
        case types.SET_SELLER_REVIEW_DETAILS:
            return setReviewList(state, action.payload)
        case types.WISHLIST_SELLER_SERVICE:
            return wishlistOtherServices(state, action.payload)
        default:
            return state
    }
}

export { sellerProfileRedu };
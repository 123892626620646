import React from "react";
import { Link } from "react-router-dom";

const TermsConditions = () => {
  return (
    <div className="terms-conditions">
      <div className="container">
        <h1>TERMS OF SALE</h1>
        <p>
          These Terms of Sale (hereinafter “Terms of Sale”) between{" "}
          <Link to="#">Aladyyn.com</Link> Pte Ltd having address at 20 Collyer
          Quay, 09-01, Singapore 049319 (hereinafter referred to as “Aladyyn”
          which expression shall unless otherwise provided includes its
          Successors in Interest and Permitted Assigns), and the seller
          (hereinafter referred to as “Seller” which expression shall unless
          otherwise provided includes its Successors in Interest and Permitted
          Assigns).
        </p>
        <p>
          These Terms of Sale contain the complete terms and conditions that
          apply to a Seller and govern the Seller’s access to and use of the
          Site. The Seller’s use of the Site is conditioned on the Seller’s
          acceptance of the Terms of Sale. By (i) using the Site; or (ii)
          browsing through the Site, the Seller is deemed to have read,
          understood and accepted the Terms of Sale, as well as any privacy
          policy that may be available on the Site; or (iii) clicking the "I
          Accept" button from the registration screen, the Seller acknowledges
          that the Seller has read and understand the contents of these Terms of
          Sale, and that you agree to be legally bound by it.
        </p>
        <ul>
          <li className="sub-heading">
            <span className="marker-upper">I</span>
            DEFINITIONS
            <ul>
              <li>
                <span className="marker-lower">(i)</span>
                “Account” means the registration on the Site, which creates an
                account for the Seller to have access to the facilities of the
                Site.
              </li>
              <li>
                <span className="marker-lower">(ii)</span>
                “Buyer” means a purchaser of services who has registered on the
                Site, for purchasing Services and has created a User Account.
              </li>
              <li>
                <span className="marker-lower">(iii)</span>
                “Confidential Information” means all information of a
                confidential nature disclosed (whether in writing, verbally or
                by any other means and whether directly or indirectly) by one
                party to any other party whether before or after the date of
                execution (and specified by the disclosing party as being
                confidential information) including information relating to the
                party's products, operations, processes, plans, product
                information, know-how, design rights, trade secrets, market
                opportunities and business affairs including information of a
                commercial, technical or scientific nature, customers,
                contractors, documents, computer software, programs, technology,
                inventions, concepts, processes, whether or not covered by
                intellectual property rights.
              </li>
              <li>
                <span className="marker-lower">(iv)</span>
                “Seller Services” means all services performed for or delivered
                to Buyer by Seller.
              </li>
              <li>
                <span className="marker-lower">(v)</span>
                “Intellectual Property Rights” means all patent rights,
                copyright rights, mask work rights, moral rights, rights of
                publicity, trademark, trade dress and service mark rights,
                goodwill, trade secret rights and other intellectual property
                rights as may now exist or hereafter come into existence, and
                all applications therefore and registrations, renewals and
                extensions thereof, in each case, under the laws of any state,
                country, territory or other jurisdiction.
              </li>
              <li>
                <span className="marker-lower">(vi)</span>
                “Payment Method” means a valid credit card issued by a bank
                acceptable to Aladyyn, a bank account linked to your Account, a
                PayPal account, a debit card, or such other method of payment as
                Stripe may accept from time to time in our sole discretion.
              </li>
              <li>
                <span className="marker-lower">(vii)</span>
                “Services” means the services that a Seller provides under this
                Agreement.
              </li>
              <li>
                <span className="marker-lower">(viii)</span>
                “Site” means the website on which the Services are provided by
                the Seller.
              </li>
              <li>
                <span className="marker-lower">(ix)</span>
                “User Account” means the account into which a Buyer can register
                on the Site in order to use the Site.
              </li>
              <li>
                <span className="marker-lower">(x)</span>
                “Work Product” means any tangible or intangible results or
                deliverables that Seller agrees to create for, or actually
                delivers to Buyer as a result of performing the Services.
              </li>
            </ul>
          </li>
          <li className="sub-heading">
            <span className="marker-upper">II</span>
            General
            <ul>
              <li>
                <span className="marker-lower">(i)</span>
                Aladyyn is a company incorporated under the laws of Singapore.
                Aladyyn is an intermediary in the form of an online marketplace
                and is limited to managing the Site to enable Service Provider
                to exhibit, advertise, display, make available and offer to sell
                the Services thereto including use of the Site by any Seller.
              </li>
              <li>
                <span className="marker-lower">(ii)</span>
                These Terms of Sale are subject to revision by Aladyyn at any
                time and hence the Sellers are requested to carefully read these
                Terms of Sale from time to time before using the Site.
              </li>
            </ul>
          </li>
          <li className="sub-heading">
            <span className="marker-upper">III</span>
            RELATIONSHIP WITH ALADYYN:
            <ul>
              <li>
                <span className="marker-lower">(i)</span>
                Aladyyn is merely a facilitator who provides a common platform
                for the Sellers and Buyers to meet and do business.
              </li>
              <li>
                <span className="marker-lower">(ii)</span>
                Aladyyn makes the Site and Site Services available for Sellers
                to find Buyers, enter into service relationships, make and
                receive payments through Stripe, and provide Services.
              </li>
              <li>
                <span className="marker-lower">(iii)</span>
                Principal to Principal Relationship: You and Aladyyn are
                independent contractors, and both you and Aladyyn have entered
                this agreement on principal-to-principal basis.
              </li>
            </ul>
          </li>
          <li className="sub-heading">
            <span className="marker-upper">IV</span>
            INTELLECTUAL PROPERTY RIGHTS:
            <ul>
              <li>
                <span className="marker-lower">(i)</span>
                The Site and the processes, and their selection and arrangement,
                is protected by intellectual property rights. Through your use
                of the Site, by no means are any rights impliedly or expressly
                granted to you in respect of such Content. Aladyyn reserves the
                right to change or modify the Content from time to time at its
                sole discretion.
              </li>
            </ul>
          </li>
          <li className="sub-heading">
            <span className="marker-upper">V</span>
            SELLER ACCOUNT
            <ul>
              <li>
                <span className="marker-lower">(i)</span>
                SELLER: You can register for an Account or add an Account type
                to use the Site as a Seller and your account will be called a
                Seller Account.
              </li>
              <li>
                <span className="marker-lower">(ii)</span>
                IDENTITY AND LOCATION VERIFICATION: By registering and obtaining
                an account from our Site, you agree you will be subject to
                verification, and validation of your identity and location by
                scouring through government and third-party databases.
              </li>
              <li>
                <span className="marker-lower">(iii)</span>
                SELLER NAMES AND PASSWORDS: When you register for an Account,
                you will be asked to choose a Seller name and password for the
                Account. The responsibility for safeguarding and maintaining the
                confidentiality of your Seller name and password is entirely
                upon you.
              </li>
              <li>
                <span className="marker-lower">(iv)</span>
                PERSONAL INFORMATION: As a Seller who wishes to use the Site,
                and the nature of Services You wish to avail from the Site, you
                may be required to provide certain personal information and
                Aladyyn may collect certain personal information.
              </li>
            </ul>
          </li>
          <li className="sub-heading">
            <span className="marker-upper">VI</span>
            TAXES AND BENEFITS
            <p>
              Seller acknowledges and agrees that Seller is solely responsible
              for all tax liability associated with payments received from
              Buyers and that Aladyyn will not withhold any taxes from payments
              through the Site made to Seller.
            </p>
          </li>
          <li className="sub-heading">
            <span className="marker-upper">VII</span>
            SELLER OBLIGATIONS AND ROLE OF ALADYYN
            <ul>
              <li>
                <span className="marker-lower">(i)</span>
                Subject to compliance with the Agreement(s), Aladyyn grants You
                a non-exclusive, non-sub-licensable, non-transferable,
                revocable, and limited right to access and use this Site and the
                Services provided therein.
              </li>
              <li>
                <span className="marker-lower">(ii)</span>
                You agree to use the Services, Site and the Content (as defined
                herein) provided therein only for purposes that are permitted
                by: (a) Terms of Sale); and (b) any applicable law.
              </li>
            </ul>
          </li>
          <li className="sub-heading">
            <span className="marker-upper">VIII</span>
            CONFIDENTIAL INFORMATION
            <p>
              To the extent a one party provides Confidential Information to
              another the recipient will protect the secrecy of the discloser’s
              Confidential Information with the same degree of care as it uses
              to protect its own Confidential Information, but in no event with
              less than due care.
            </p>
          </li>
          <li className="sub-heading">
            <span className="marker-upper">IX</span>
            FEES FOR SELLERS
            <ul>
              <li>
                <span className="marker-lower">(i)</span>
                Subscription Fee: There will be no Services Fee charged
                initially i.e., up to March 15th, 2021. After this date Aladyyn
                as it may deem fit, will charge a subscription fee. All Sellers
                joining Aladyyn prior to this date will have a life time free
                membership on the Site.
              </li>
              <li>
                <span className="marker-lower">(ii)</span>
                Transaction Fee: There will be no transaction fee charged
                initially. After the initial period, Aladyyn may as it deems fit
                charge a transaction fee, at which point Sellers may determine
                whether they want to continue using the Site or not.
              </li>
              <li>
                <span className="marker-lower">(iii)</span>
                The Service Fees (to use the Site) are paid solely by Seller.
              </li>
              <li>
                <span className="marker-lower">(iv)</span>
                NO FEE FOR INTRODUCING OR FOR FINDING PROJECTS: No finder’s fee
                is charged by Aladyyn for introducing Buyers to Sellers and
                Aladyyn does not help Sellers secure Projects. While Aladyyn may
                from time to time highlight Projects that may be of interest to
                the Sellers and Buyers, Aladyyn does not charge a fee when a
                Seller finds a suitable Buyer or finds a Project. Aladyyn also
                does not charge any fee or dues for posting public feedback.
              </li>
            </ul>
          </li>
          <li className="sub-heading">
            <span className="marker-upper">X</span>
            PAYMENT METHODS
            <ul>
              <li>
                <span className="marker-lower">(i)</span>
                In order to use certain Site Services, Seller must provide
                account information for at least one valid Payment Method.
                Seller hereby authorizes Stripe to run credit card
                authorizations on all credit cards provided by Seller, to store
                credit card and banking or other financial details as Sellers
                method of payment consistent with Stripe Privacy Policy, and to
                charge Seller’s credit card (or any other Payment Method) for
                the Seller Fees and any other amounts owed under the Terms of
                Sale
              </li>
            </ul>
          </li>
          <li className="sub-heading">
            <span className="marker-upper">XI</span>
            WARRANTY DISCLAIMER AND LIMITATION OF LIABILITY
            <p className="text-capital">
              ALADYYN MAKES NO REPRESENTATIONS OR WARRANTIES WITH REGARD TO THE
              SITE, THE SITE SERVICES, WORK PRODUCT, SELLER CONTENT, OR ANY
              ACTIVITIES OR ITEMS RELATED TO THIS AGREEMENT. TO THE MAXIMUM
              EXTENT PERMITTED BY APPLICABLE LAW, ALADYYN DISCLAIMS ALL EXPRESS
              AND IMPLIED CONDITIONS, REPRESENTATIONS, AND WARRANTIES INCLUDING,
              BUT NOT LIMITED TO, THE WARRANTIES OF MERCHANTABILITY, ACCURACY,
              FITNESS FOR APARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT.
              LIMITATION OF LIABILITY.
            </p>
            <p className="text-capital">
              IN NO EVENT WILL ALADYYN, OUR AFFILIATES, OR OUR
              THIRD-PARTYSERVICE PROVIDERS BE LIABLE FOR ANY SPECIAL,
              CONSEQUENTIAL, INCIDENTAL, PUNITIVE, EXEMPLARY, OR INDIRECT COSTS
              OR DAMAGES, INCLUDING, BUT NOT LIMITED TO, LITIGATION COSTS,
              INSTALLATION AND REMOVAL COSTS, OR LOSS OF DATA, PRODUCTION,
              PROFIT, OR BUSINESS OPPORTUNITIES. THE LIABILITY OF ALADYYN, OUR
              AFFILIATES, AND OUR THIRD-PARTY SERVICE PROVIDERS TO ANY SELLER
              FOR ANY CLAIM ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT
              OR THE OTHER TERMS OF SALE WILL NOT EXCEED THE LESSER OF: (A)
              $2,500; OR (B) ANY FEES RETAINED BY ALADYYN WITH RESPECT TO
              SERVICE CONTRACTS ON WHICH SELLER WAS INVOLVED AS BUYER OR SELLER
              DURING THE SIX-MONTH PERIOD PRECEDING THE DATE OF THE CLAIM. THESE
              LIMITATIONS WILL APPLY TO ANY LIABILITY, ARISING FROM ANY CAUSE OF
              ACTION WHATSOEVER ARISING OUT OF OR IN CONNECTION WITH THIS
              AGREEMENT OR THE OTHER TERMS OF SALE, WHETHER IN CONTRACT, TORT
              (INCLUDING NEGLIGENCE), STRICT LIABILITY, OR OTHERWISE, EVEN IF
              ADVISED OF THE POSSIBILITY OF SUCH COSTS OR DAMAGES AND EVEN IF
              THE LIMITED REMEDIES PROVIDED HEREIN FAIL OF THEIR ESSENTIAL
              PURPOSE.
            </p>
          </li>
          <li className="sub-heading">
            <span className="marker-upper">XII</span>
            INDEMNIFICATION
            <ul>
              <li>
                <span className="marker-lower">(i)</span>
                You agree to indemnify, defend and hold harmless Aladyyn, their
                respective subsidiaries and affiliates, and each of their
                respective officers, directors, agents, employees, and
                assignees, from any and all claims, liabilities, expenses and
                damages, including reasonable attorneys’ fees and costs, made by
                any third party relating to or arising out of (a) your use or
                attempted use of the Sites or in violation of the Terms of Sale;
                (b) your violation of any law or rights of any third party, or
                (c) information that you post or otherwise make available on the
                Sites or through the Course, including without limitation any
                claim of infringement or misappropriation of intellectual
                property or other proprietary rights.
              </li>
              <li>
                <span className="marker-lower">(ii)</span>
                Aladyyn may notify you of any claims which you shall be liable
                to indemnify Aladyyn against. You will then be required to
                consult with Aladyyn regarding the course of action to be
                undertaken in defending such a claim. Further, you shall not
                compromise or settle any claim or admit any liability or
                wrongdoing on the part of Aladyyn without the express prior
                written consent of Aladyyn which can be withheld or denied or
                conditioned by Aladyyn in its sole discretion.
              </li>
            </ul>
          </li>
          <li className="sub-heading">
            <span className="marker-upper">XIII</span>
            AGREEMENT TERM AND TERMINATION
            <ul>
              <li>
                <span className="marker-lower">(i)</span>
                The term of this Agreement will start on the date of your
                completed registration for use of one or more of the Services
                and continue until terminated by us or you as provided below.
                You may at any time terminate your use of the Site immediately
                on notice to us via email. We may terminate your use of any
                Services or terminate this Agreement for convenience with 30
                days’ advance notice.
              </li>
              <li>
                <span className="marker-lower">(ii)</span>
                We may suspend or terminate your use of any Services immediately
                if we determine that (a) you have materially breached the
                Agreement and failed to cure within 7 days of a cure notice
                unless your breach exposes us to liability towards a third
                party, in which case we are entitled to reduce, or waive, the
                aforementioned cure period at our reasonable discretion; (b)
                your account has been, or our controls identify that it may be
                used for deceptive or fraudulent or illegal activity; or (c)
                your use of the Services has harmed or our controls identify
                that it might harm other Buyers of Sellers or (d) Aladyyn’s is
                required to do so by law, legal process, or law enforcement.
              </li>
            </ul>
          </li>
          <li className="sub-heading">
            <span className="marker-upper">XIV</span>
            DISPUTES BETWEEN YOU AND ALADYYN
            <ul>
              <li>
                <span className="marker-lower">(i)</span>
                The parties agree that any arbitration commenced pursuant to
                this clause shall be conducted in accordance with the Expedited
                Procedure set out in Rule 5.2 of the Arbitration Rules of the
                Singapore International Arbitration Centre ("SIAC Rules"). The
                seat of the arbitration shall be Singapore. The Tribunal shall
                consist of one arbitrator. The language of the arbitration shall
                be English. The Governing Laws shall be the laws of Singapore.
              </li>
              <li>
                <span className="marker-lower">(ii)</span>
                Notice: All notices with respect to these Terms of Sale from
                Aladyyn will be served to You by email or by general
                notification on the Site. Any notice provided to Aladyyn
                pursuant to these Terms of Sale should be sent to Grievance
                Officer at{" "}
                <a href="mailto:grievanceofficer@Aladyyn.com">
                  grievanceofficer@Aladyyn.com
                </a>
                .
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TermsConditions;

import React, { useState, useEffect } from 'react';
import { Breadcrumb, Tabs, Tab } from 'react-bootstrap';
import Slider from 'react-slick';
import SlickSlider from 'components/Slider/SlickSlider';
import SellerProfile from 'assets/images/description-image.png';
import PortFolio from 'components/SellerProfile/PortFolio'
import Services from 'assets/images/service-image-1.png';
import {
    addServiceWishlist, removeServiceWishlist
} from 'store/action/ServiceDetailAction'
import Select from 'react-select';
import Rating from 'react-rating';
import starEmpty from 'assets/images/icons/icon-star-empty.svg';
import starFilled from 'assets/images/icons/icon-star-2.svg';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useNavigate } from 'react-router-dom';
import ReviewCard from 'components/ServiceDetail/ReviewList'
import { getSellerDetail, getSellerReviewedDetail, getServices } from '../../store/action/sellerProfileAction';
import { getOtherService } from '../../store/action/ServiceDetailAction'
import Pagination from 'components/Pagination/Pagination'


const Profile = () => {
    let dispatch = useDispatch();
    let params = useParams();
    let navigate = useNavigate();
    let sellerDetails = useSelector(store => store.sellerProfileRedu);
    const [limit, setLimit] = useState(10);
    const [servicelimit, setServicelimit] = useState(12);

    console.log('sellerDetails', sellerDetails)

    useEffect(() => {
        console.log('sellerId', params)
        getSellerDetail(params)(dispatch)
        setTimeout(() => getServices({ sellerId: params.sid, pageno: 1, Limit: servicelimit })(dispatch), 500)
        setTimeout(() => getSellerReviewedDetail({ ...params, pageno: 1, Limit: 10 })(dispatch), 1000)
    }, [])

    const loadMore = (page) => {
        getSellerReviewedDetail({ ...params, pageno: page, Limit: limit })(dispatch)
    }

    const serviceLoadMore = (page) => {
        getServices({ sellerId: params.sid, pageno: page, Limit: servicelimit })(dispatch)
    }


    const addToWishlist = (e, item) => {
        e.preventDefault()
        if (item.wishlist) {
            removeServiceWishlist({ serviceId: item._id }, "sellerServices")(dispatch)
        } else {
            addServiceWishlist({ serviceId: item._id }, "sellerServices")(dispatch)
        }
    }


    const options = [
        { value: 'entertainment', label: 'entertainment' },
        { value: 'wedding services', label: 'wedding services' },
    ]
    const options1 = [
        { value: 'photographers', label: 'photographers' },
        { value: 'videographers', label: 'videographers' },
    ]
    const options2 = [
        { value: 'Singapore', label: 'Singapore' },
        { value: 'America', label: 'America' },
    ]
    const options3 = [
        { value: 'Ubin', label: 'Ubin' },
        { value: 'Ubin2', label: 'Ubin2' },
    ]
    const options4 = [
        { value: 'State', label: 'State' },
        { value: 'Country', label: 'Country' },
    ]

    const getTimeDate = (item) => {
        const monthNames = ["January", "Feb", "March", "April", "May", "June",
            "July", "Aug", "Sep", "October", "Nov", "Dec"
        ];
        let sDate = new Date(item.startDate);
        let eDate = item.endDate ? new Date(item.endDate) : null;
        let sYear = sDate.getFullYear();
        let eYear = eDate?.getFullYear() ?? null;
        return `${sDate.getDate()} ${monthNames[sDate.getMonth()]} ${sYear} - ${eDate ? [eDate.getDate()] + " " + monthNames[eDate.getMonth()] + " " + eYear : "Until now"
            }`
    }


    let settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };


    return (
        <section className='edit-information profile'>
            <div className="container">
                <Breadcrumb>
                    <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="#">Entertainment</Breadcrumb.Item>
                    <Breadcrumb.Item active>Media Entertainment Corporation</Breadcrumb.Item>
                </Breadcrumb>
                <div className='tabbing-head'>
                    <div className='tabbing-select'>
                        <h3>Categories Provided:</h3>
                        <div className="select-wrap">
                            <Select defaultValue={options[0]} classNamePrefix="react-select" className='react-select-container' options={options} />
                        </div>
                    </div>
                    <Tabs defaultActiveKey="description" id="uncontrolled-tab-example" className="tabs-wrap">
                        <Tab eventKey="description" title="Description">
                            <div className='description-wrap'>
                                <div className='image-block'>
                                    <div className='image-wrap'>
                                        {
                                            sellerDetails?.data?.sellerInformationData?.image ?
                                                <img src={sellerDetails?.data?.path + sellerDetails?.data?.sellerInformationData?.image} alt='profile' />
                                                : <img src={SellerProfile} alt='profile' />
                                        }

                                    </div>
                                    <Link to='#' className='btn'>chat now</Link>
                                </div>
                                <div className='seller-details'>
                                    <div className='details-head'>
                                        <div className='main-head'>
                                            <div className='seller-btns'>
                                                <button className='btn-wrap'>
                                                    <i className='icon-share'></i>
                                                    Share This Seller
                                                </button>
                                            </div>
                                            {
                                                sellerDetails?.data?.sellerData?.type === "Freelancer" ?
                                                    <h2>{sellerDetails?.data?.sellerData.firstName + " " + sellerDetails?.data?.sellerData.lastName}</h2>
                                                    : <h2>{sellerDetails?.data?.sellerData?.businessName}</h2>
                                            }

                                        </div>
                                        <div className='pricing-wrap'>$100-$200</div>
                                    </div>
                                    <div className='seller-rating'>
                                        <div className='rating-wrap'>
                                            <div className='rating-image'>
                                                <Rating
                                                    readonly='false'
                                                    placeholderRating={sellerDetails?.data?.sellerData?.averageRating ?? 0}
                                                    emptySymbol={<img src={starEmpty} className="icon" />}
                                                    placeholderSymbol={<img src={starFilled} className="icon" />}
                                                    fullSymbol={<img src={starFilled} className="icon" />}
                                                />
                                            </div>
                                            <div className='rating-number'>{sellerDetails?.data?.sellerData?.totalReview} reviews</div>
                                        </div>
                                    </div>
                                    <div className='details-wrap'>
                                        <div className='detail-list'>
                                            <h3>Languages Spoken:</h3>
                                            <ul className='details-values'>
                                                {
                                                    sellerDetails?.data?.languageArray?.map(item =>
                                                        <li key={Math.random()}>
                                                            <div className='detail-text'>{item.languageName}</div>
                                                            <div className='detail-text'>{item.languageType}</div>
                                                        </li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                        <div className='detail-list'>
                                            <h3>Operating Countries:</h3>
                                            <ul className='details-values'>
                                                {
                                                    sellerDetails?.data?.addressData?.map(item =>
                                                        <li>
                                                            <div className='detail-text'>{item.countryName}</div>
                                                        </li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                    <p>{sellerDetails?.data?.sellerInformationData?.description}</p>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="portfolio" title="Portfolio & Experience">
                            <div className='tab-wrapper'>
                                {
                                    sellerDetails?.data?.sellerWorkExperienceData?.map(item =>
                                        <div className='experience-wrap'>
                                            <div className='naming'>{item.name}</div>
                                            {item.startDate && <div className="date-wrap">{getTimeDate(item)}</div>}
                                            <p>{item.description}</p>
                                            <div className='view-btn'>
                                                <Link to='#' className='view-more'>View More</Link>
                                            </div>
                                        </div>
                                    )
                                }

                                <div className='portfolio-sliders entertainment-tab'>
                                    <h4>Portfolio</h4>
                                    <PortFolio
                                        serviceDetail={sellerDetails?.data}
                                    />
                                    {/* <Slider {...settings}>
                                        <div className='slide'>
                                            <div className="img-wrap contains-video">
                                                <Link to='/'>
                                                    <img src={service1} alt="service" />
                                                    <div className="video-wrapper">
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="service-info">
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                                            </div>
                                        </div>
                                    </Slider> */}
                                    <h4>Certifications & Licenses</h4>
                                    <div className="certificate-slider">
                                        <SlickSlider />
                                    </div>
                                </div>
                            </div>
                        </Tab>
                    </Tabs>
                </div>
                <div className='services'>
                    <div className='services-head'>
                        <h2>Services</h2>
                        <div className='services-select'>
                            <h3>Sub-Categories Provided:</h3>
                            <div className="select-wrap">
                                <Select defaultValue={options1[1]} classNamePrefix="react-select" className='react-select-container' options={options1} />
                            </div>
                        </div>
                    </div>
                    <div className='services-inputs'>
                        <form>
                            <div className='form-wrap'>
                                <div className='inputs-wrap'>
                                    <div className='input-wrap'>
                                        <input type="text" className='input' placeholder="Search a specific service" />
                                        <button className='search-btn'>
                                            <i className='icon-search'></i>
                                        </button>
                                    </div>
                                    <div className='input-wrap'>
                                        <input type="text" className='input' placeholder="Search a specific address" />
                                        <button className='search-btn'>
                                            <i className='icon-search'></i>
                                        </button>
                                    </div>
                                </div>
                                <div className='selects-wrap'>
                                    <div className="select-wrap bigger">
                                        <Select defaultValue={options2[0]} classNamePrefix="react-select" className='react-select-container' options={options2} />
                                    </div>
                                    <div className="select-wrap">
                                        <Select defaultValue={options3[0]} classNamePrefix="react-select" className='react-select-container' options={options3} />
                                    </div>
                                    <div className="select-wrap">
                                        <Select defaultValue={options4[0]} classNamePrefix="react-select" className='react-select-container' options={options4} />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className='services-wrap'>
                        {
                            sellerDetails?.otherServiceList?.data?.map(item =>
                                <div className='card-wrap'>
                                    <Link to={`/service-detail/${item._id}/${item.sellerId}`} className='service-block'>
                                        <div className='service-image'>
                                            {
                                                item.serviceCover === "" ?
                                                    <img src={Services} alt="service-image" />
                                                    : <img src={item.path + item.serviceCover} alt="service-image" />
                                            }

                                        </div>
                                        <div className='service-card'>
                                            <div className='service-name'>{item.title}</div>
                                            {
                                                item.addressData.length > 0 ?
                                                    <>
                                                        <div className='location'>{item.addressData[0].countryName + " " + item.addressData[0].stateName}</div>
                                                        <div className='address'>
                                                            {
                                                                item.addressData[0].addressLine1
                                                            }
                                                            <>
                                                                {
                                                                    item.addressData[0].addressLine2 !== "" &&
                                                                    + ", " +
                                                                    item.addressData[0].addressLine2
                                                                }

                                                            </>
                                                        </div>
                                                    </> : null
                                            }

                                            <div className='seller-rating'>
                                                <div className='rating-wrap'>
                                                    <div className='rating-image'>
                                                        <Rating
                                                            readonly
                                                            placeholderRating={item?.averageRating ? item?.averageRating : 0}
                                                            emptySymbol={<img src={starEmpty} className="icon" />}
                                                            placeholderSymbol={<img src={starFilled} className="icon" />}
                                                            fullSymbol={<img src={starFilled} className="icon" />}
                                                        />
                                                    </div>
                                                    <div className='rating-number'>{item.totalReview} reviews</div>
                                                </div>
                                            </div>
                                            <div className='services-block'>
                                                <div className='price'>${item.price}</div>
                                                <button className='wishlist-btn' onClick={(e) => addToWishlist(e, item)}>
                                                    <i className={item?.wishlist ? 'icon-heart add' : 'icon-heart'}></i>
                                                </button>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            )
                        }

                    </div>
                    {
                        sellerDetails?.otherServiceList?.totalRecord > limit ?
                            <Pagination
                                totalData={sellerDetails?.otherServiceList?.totalRecord}
                                limit={servicelimit}
                                loadMore={serviceLoadMore}
                            />
                            : null
                    }
                </div>
                <div className='reviews'>
                    <div className='review-head'>
                        <h2>Reviews</h2>
                    </div>
                    <div className='reviews-wrap'>
                        {
                            sellerDetails?.reviewList?.data?.map(item =>
                                <ReviewCard
                                    key={Math.random()}
                                    item={item}
                                />
                            )
                        }
                    </div>
                    {
                        sellerDetails?.reviewList?.totalRecord > limit ?
                            <Pagination
                                totalData={sellerDetails?.reviewList?.totalRecord}
                                limit={limit}
                                loadMore={loadMore}
                            />
                            : null
                    }
                </div>
            </div>
        </section>
    )
}

export default Profile

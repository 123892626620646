import * as actiontype from "../actionType";
import { endPoints } from "../../utils/endPoints";
import { axiosInstanceWithUserToken } from "../../utils/Axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const addNewCategory = (data) => (dispatch) => {

    console.log("data--->", data)

    let token = localStorage.getItem("user_token");
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken
        .post(endPoints.CATEGORY, data, {
            headers: {
                Authorization: token,
                // _csrf:xsrfToken
            },
        })
        .then(async (response) => {
            console.log("rrrrrrrrrrrr",response)
            dispatch({ type: "LOADERSTOP" })
            if (response?.data?.status === "success") {
                toast.success(response?.data?.message)
                // console.log('right')
                dispatch({
                    type: actiontype.LOGIN_USER,
                    payload: {
                        islogin: true
                    },
                });
            } else {
                console.log("wrong")
                // dispatch({
                //     // type: actiontype.LOGIN_FAIL,
                //     // payload: {
                //     //     islogin: false
                //     // },
                // });
                toast.error(response?.data?.message);
            }
            // console.log("success response", response.data.data)
        })
        .catch((errResponse) => {
            console.log("rrrrrrrr111111",errResponse?.response)
            // toast.error("error")
            dispatch({ type: "LOADERSTOP" })
            toast.error(errResponse?.response?.data?.message)
            console.log("success respoerrResponsese", errResponse)
        });
};


// export const removeCategoryAction = (newCatIds, dispatch) => {
//     console.log("newCatIds111")
//     console.log("newCatIds", newCatIds)

//     let token = localStorage.getItem("user_token");

//     console.log("newCatIds2222")
//     axiosInstanceWithUserToken
//         .post(endPoints.REMOVE_CATEGORY_IDS, newCatIds, {
//             headers: {
//                 Authorization: token,
//                 // _csrf:xsrfToken
//             },
//         })
//         .then(async (response) => {

//             console.log("2222222222sandeep", response)
//             if (response?.data?.status === "success") {
//                 toast.success(response?.data?.message)
//                 // console.log('right')
//             } else {
//                 console.log("wrong")
//                 toast.error(response?.data?.message);
//             }
//             console.log("success response", response.data.data)
//         })
//         .catch((errResponse) => {
//             // toast.error("error")
//             console.log("2222222222sandeep", errResponse?.response)

//             console.log("success respoerrResponsese", errResponse)
//         });

// }


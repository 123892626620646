import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import logo from 'assets/images/logo.png';
import location from 'assets/images/location.png';
import { useDispatch, useSelector } from 'react-redux'
import Navbar from './Navbar';
import { useWindowSize } from 'hooks/window-size';
import DropdownButton from 'react-bootstrap/DropdownButton'
import { Dropdown } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import OutsideClickHandler from 'components/OutsideClickHandler';
import { viewProfile, checkAuth } from 'store/action/profileAction';
import { advertiserProfile } from 'store/action/advertiserProfileAction';
import { userProfile } from 'store/action/userProfileAction';
import { getCategory } from 'store/action/categoryAction';
import { cartView } from 'store/action/sowCartDataAction';
import { totalMessageCount } from 'store/action/userTotalMessagesAction';
import Loader from 'components/Loader/Loader';

const Header = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch()
	const params = useParams();
	const ref = useRef();
	let checkIslogin = useSelector(state => state.login)
	const [windowWidth] = useWindowSize();
	const [showMenu, setShowMenu] = useState(false);
	const getProfileInfo = useSelector(state => state.profileView)
	const loader = useSelector(state => state.loader.loader)
	
	const [isLoggedin, setIsLoggedin] = useState(true);
	const getAdvertiserInfo = useSelector(state => state.advertiserView)
	const getUserInfo = useSelector(state => state.userView)
	const categoryUser = useSelector(state => state.category)
	const getCartInfo = useSelector((state) => state.cartDetail)
	const getMessageCount = useSelector((state) => state.userAllMessage)

	useEffect(() => {
		if (localStorage.getItem("user_token")) {
			dispatch(viewProfile())
			dispatch(advertiserProfile())
			dispatch(userProfile())
			dispatch(cartView())
			dispatch(totalMessageCount())
		}
	}, [])


	useEffect(() => {
		if (localStorage.getItem("user_token")) {
			dispatch(checkAuth())
		}
	}, [params])

	OutsideClickHandler(ref, () => {
		console.log('showMenu', showMenu)
		setTimeout(() => setShowMenu(false), 100)
	});

	const handleCategory = () => {
		setShowMenu(!showMenu)
		getCategory()(dispatch)
	}

	const categoryNaviagationClasses = showMenu
		? "navigation active"
		: "navigation";

	const menuBtnClasses = showMenu
		? "menu-btn active"
		: "menu-btn";

	const headerClasses = isLoggedin
		? "header logged-in"
		: "header";

	const submitHandler = event => {
		event.preventDefault();
	}

	const handleCategoryPage = (id) => {
		window.location.href = "/category"
	}

	const logoutUser = () => {
		localStorage.removeItem("user_token");
		localStorage.removeItem("keepMeLogin");
		localStorage.removeItem("lastLoginTime")
		window.location.href = "/";
		setTimeout(() => navigate({ pathname: '/' }), 500);
	}

	const handleNotification = () => {
		if (getProfileInfo.data[0].type == "Freelancer") {
			setTimeout(() => navigate({ pathname: '/seller-notification' }), 500);
		}
		else if (getProfileInfo.data[0].type == "User") {
			setTimeout(() => navigate({ pathname: '/user/notifications' }), 500);
		}
		else {
			setTimeout(() => navigate({ pathname: '/seller-notification' }), 500);
		}
	}

	const handleAccount = () => {
		if (getProfileInfo.data[0].type == "Lab") {
			setTimeout(() => navigate({ pathname: '/lab-dashboard' }), 500);
		}
		else if (getProfileInfo.data[0].type == "Advertiser") {
			setTimeout(() => navigate({ pathname: '/user/advertiser-dashboard' }), 500);
		}
		else if (getProfileInfo.data[0].type == "User") {
			setTimeout(() => navigate({ pathname: '/user/dashboard' }), 500);
		}
		else {
			setTimeout(() => navigate({ pathname: '/seller-dashboard-company' }), 500);
		}
	}


	const isUser = () => {
		let user = true;
		if (window.location.hostname === "sis1166.devsparxit.com" || window.location.hostname === "seller.aladyyn.com" || window.location.hostname==="sis1166.uatsparxit.xyz") {
			user = false;
		}
		return user
	}

	console.log('aaaaaaa', getMessageCount)


	return (
		<header className={headerClasses}>
			<div className="primary-header">
				<div className="container">
				{
			loader ?
				<Loader />
				:
				null
		    }
					<div className="logo">
						{
							isUser() ?
								<Link to="/">
									<img src={logo} alt="logo" />
								</Link> :
								<Link to="#">
									<img src={logo} alt="logo" />
								</Link>
						}


					</div>
					<div className="search-wrapper">
						<form onSubmit={submitHandler}>
							<div className="input-wrap">
								<input type="text" className='form-control' placeholder='Search' />
								<div className="btn-wrap">
									<button type="submit" className='btn'>
										<i className="icon-search"></i>
									</button>
								</div>
							</div>
						</form>
					</div>
					<div className="header-right">
						{getProfileInfo?.data?.[0]?.type !== "Company" &&
							getProfileInfo?.data?.[0]?.type !== "Feelancer" &&
							isUser() ?
							<div className="cart-wrap">
								<Link to="/user/cart">
									<i className="icon-cart"></i>
									{getCartInfo?.length >= 1 ?
										<div className="item-counter">{getCartInfo?.length}</div>
										:
										<div className="item-counter">0</div>
									}
								</Link>
							</div>
							: null
						}
						{checkIslogin.islogin &&
							isUser() ?
							<div className="notification-wrap">
								<Link to='/user/messages'>
									<i className="icon-mail"></i>
									{/* <div className="item-counter">3</div> */}
								</Link>
							</div>
							: <div className="notification-wrap">
								<Link to='#'>
									<i className="icon-mail"></i>
									{/* <div className="item-counter">3</div> */}
								</Link>
							</div>
						}
						{!checkIslogin.islogin &&
							isUser() ?
							<div className="signin-btn">
								<Link to="/sign-in" className='btn'>Sign In</Link>
							</div>
							: null
						}
						{!checkIslogin.islogin &&
							!isUser() ?
							<div className="signin-btn">
								<Link to="#" className='btn'>Sign In</Link>
							</div>
							: null
						}
						{checkIslogin.islogin && getProfileInfo?.data?.map(item =>

							<div className='profile-dropdown'>
								<DropdownButton align="end" title={item.firstName}>
									<Dropdown.Item onClick={handleAccount}>Your Account</Dropdown.Item>
									<Dropdown.Item href="#" onClick={handleNotification}>Notifications</Dropdown.Item>
									{getProfileInfo?.data?.[0]?.type === "User" ?
										<Dropdown.Item href="#" /*onClick={logoutUser}*/>My Wishlist</Dropdown.Item>
										:
										null
									}
									<Dropdown.Item href="#" onClick={logoutUser}>Sign Out</Dropdown.Item>
								</DropdownButton>
							</div>
						)}
					</div>
				</div>
			</div>
			<div className="secondary-header">
				<div className="container">
					<div className="header-left">
						{isUser() ?
							// getProfileInfo?.data?.[0]?.type !== "Freelancer" && getProfileInfo?.data?.[0]?.type !== "Company" ?
							// {getProfileInfo?.data?.[0]?.type === "User" ?
							<button type='button' className={showMenu ? "menu-btn active" : "menu-btn"} onClick={handleCategory}>
								<div className="ham-burger">
									<span className="line"></span>
									<span className="line"></span>
									<span className="line"></span>
								</div>
								<div className="text-wrap">All Categories</div>
							</button>
							:
							<button type='button' className={showMenu ? "menu-btn active" : "menu-btn"}>
								<div className="ham-burger">
									<span className="line"></span>
									<span className="line"></span>
									<span className="line"></span>
								</div>
								<div className="text-wrap">All Categories</div>
							</button>
						}
					</div>
					{windowWidth > 767 && <Navbar />}
					{/* <div className="country-select">
						<Link to='#'>
							<img src={location} alt="location" />
						</Link>
					</div> */}
				</div>
				<div className={categoryNaviagationClasses}>
					<div className="container">
						{
							showMenu
								?
								<div ref={ref} className="menu-wrapper">
									<Scrollbars style={{ maxWidth: 360, height: '100%' }}>
										{windowWidth < 767 && <Navbar />}
										<ul className="category-list">
											{categoryUser?.map((item) => (
												<li key={item._id}>
													<a href={`/category/${item._id}/${item.name}`}>{item.name}</a>
												</li>
											))}
										</ul >
									</Scrollbars>
								</div>
								:
								null
						}
					</div>
				</div>
			</div>
		</header >
	)
}

export default Header;

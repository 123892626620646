import * as actiontype from "../actionType";
import { endPoints } from "../../utils/endPoints";
import { axiosInstanceWithUserToken } from "../../utils/Axios";
import { useNotify } from "../../components/Alert/Alert";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



export const checkAuth = (data) => (dispatch) => {
    // dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken
        .get(endPoints.CHECK_AUTH, {
            headers: {
                Authorization: `${localStorage.getItem("user_token")}`,
            },
        })
        .then(async (response) => {
            console.log("auth ressss")
            // dispatch({ type: "LOADERSTOP" })
            if (response?.data?.status === "success") {

            } else {

            }
        })
        .catch((errResponse) => {
            console.log("authcatchhhjh")
            // dispatch({ type: "LOADERSTOP" })
            if (errResponse?.response?.data?.status === "error") {
                localStorage.removeItem("user_token");
                window.location.href = "/";
            }
        });
};



export const viewProfile = (data) => (dispatch) => {
    // let toast = useNotify();
    // loadingAction(true)(dispatch);

    // console.log('toke', localStorage.getItem("user_token"))
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken
        .get(endPoints.VIEW_PROFILE, {
            headers: {
                Authorization: `${localStorage.getItem("user_token")}`,
            },
        })
        .then(async (response) => {
            dispatch({ type: "LOADERSTOP" })
            if (response?.data?.status === "success") {
                // toast.success(response?.data?.message)
                dispatch({
                    type: actiontype.PROFILE_VIEW,
                    payload: {
                        ...response.data
                    },
                });
            } else {
                // toast.error(response?.data?.message);
            }
            // loadingAction(false)(dispatch);
        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
            // console.log('error', errResponse?.response?.data)
            if (errResponse?.response?.data?.status === "error") {
                // toast.error(errResponse?.response?.data?.message);
            }
            // dispatch({
            //     type: actiontype.GET_PROFILE_FAIL,
            //     payload: {
            //         payload: {
            //             userData: {}, status: "error"
            //         },
            //     },
            // });
            // loadingAction(false)(dispatch);
        });
};



// export const sellerVerificationCode = (data, callback) => (dispatch) => {
//     // let toast = useNotify();
//     dispatch({ type: "LOADER" })
//     axiosInstanceWithUserToken
//         .post(endPoints.SELLER_SEND_VARIFICATION_CODE, data, {
//             headers: {
//                 Authorization: `${localStorage.getItem("user_token")}`,
//             },
//         })
//         .then(async (response) => {
//             dispatch({ type: "LOADERSTOP" })
//             console.log('response', response)
//             if (response?.data?.status === "success") {
//                 toast.success(response?.data?.message);
//                 callback(true)
//             } else {
//                 toast.error(response?.data?.message);
//                 callback(false)
//             }
//         })
//         .catch((errResponse) => {
//             dispatch({ type: "LOADERSTOP" })
//             console.log('errResponse', errResponse)
//             callback(false)
//         });
// };


export const sellerVerificationCode = (payload, toast, sendOTPEnable, setShowTimer, setIsOtp, handleSeconds, setEnableInst) => (dispatch) => {
  
    console.log("sellerVerificationCode")
  
    dispatch({ type: "LOADER" })

    axiosInstanceWithUserToken.post(endPoints.SELLER_SEND_VARIFICATION_CODE, payload, {
        headers: {
            Authorization: `${localStorage.getItem("user_token")}`,
        },
    })
        .then((response) => {

            
            toast.success(response?.data?.message)
            if(response?.data?.message === "success"){

            }
            sendOTPEnable(false)
            setShowTimer(true)
            setIsOtp(true)
            handleSeconds()
            setEnableInst(true)
            dispatch({ type: "LOADERSTOP" })
        }).catch((errResponse) => {
            toast.error(errResponse?.response?.data?.message)
            console.log('errResponse', errResponse)
            dispatch({ type: "LOADERSTOP" })
            setIsOtp(false)
        })
};


// export const sellerVerifyCode = (data, callback) => (dispatch) => {
//     dispatch({ type: "LOADER" })
//     axiosInstanceWithUserToken
//         .post(endPoints.SELLER_VERIFY_VARIFICATION_CODE, data, {
//             headers: {
//                 Authorization: `${localStorage.getItem("user_token")}`,
//             },
//         })
//         .then(async (response) => {
//             dispatch({ type: "LOADERSTOP" })
//             if (response?.data?.status === "success") {
//                 toast.success(response?.data?.message);
//                 callback(true)
//             } else {
//                 toast.fail(response?.data?.message);
//                 callback(false)
//             }
//         })
//         .catch((errResponse) => {
//             dispatch({ type: "LOADERSTOP" })
//             console.log('errResponse', errResponse)
//             toast.error("The OTP you entered is wrong. Please try again");
//             callback(false)
//         });
// };

export const sellerVerifyCode = (payload, toast, setPhoneFieldDisabled, setSendOTPEnable, setShowTimer, setResendEnable, resendEnable,setIsOtp, setSubmitDisable,setEnableInst,setIsPhoneVerify) => (dispatch) => {
  
    console.log("sellerVerifyCode")

    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken
        .post(endPoints.SELLER_VERIFY_VARIFICATION_CODE, payload, {
            headers: {
                Authorization: `${localStorage.getItem("user_token")}`,
            },
        })
        .then(async (response) => {
            dispatch({ type: "LOADERSTOP" })
            console.log("verify--", response)

            console.log("verifyResponse----->",response.data.message)
            

            if (response?.data?.status === "success") {
                toast.success(response?.data?.message)
                setEnableInst(false)
                setPhoneFieldDisabled(true)
                setSendOTPEnable(false)
                setShowTimer(false)
                setResendEnable(false)

                setIsOtp(false)
                setSubmitDisable(false)
                setIsPhoneVerify(true)
                // callback(true)
            }

console.log("sandeep0000",resendEnable)

            if (response?.data?.status === "error") {
                toast.error(response?.data?.message)
            }
        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
            toast.error(errResponse?.response?.data?.message)

            // callback(false)

            console.log('errResponse', errResponse)
        });
};

export const sendOtpRegAction = (payload, dispatch, toast, sendOTPEnable, setShowTimer, setIsOtp, handleSeconds, setEnableInst) => {
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken.post(endPoints.SEND_OTP_REG_LAB, payload)
        .then((response) => {

            console.log("response222222", response?.data)
            toast.success(response?.data?.message)
            sendOTPEnable(false)
            setShowTimer(true)
            setIsOtp(true)
            handleSeconds()
            setEnableInst(true)
            dispatch({ type: "LOADERSTOP" })
        }).catch((errResponse) => {
            toast.error(errResponse?.response?.data?.message)
            console.log('errResponse', errResponse)
            dispatch({ type: "LOADERSTOP" })
            setIsOtp(false)
        })
}

export const reSendOtpAction = (payload, dispatch, toast) => {
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken.post(endPoints.SEND_OTP_REG_LAB, payload)
        .then((response) => {
            dispatch({ type: "LOADERSTOP" })
            console.log("response222222", response?.data)
            toast.success(response?.data?.message)
        }).catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
            toast.error(errResponse?.response?.data?.message)
            console.log('errResponse', errResponse)
        })
}

export const verifyOtpRegLabAction = (payload, dispatch, toast, setPhoneFieldDisabled, setSendOTPEnable, setShowTimer, setResendEnable, setIsOtp, setSubmitDisable, setEnableInst) => {

    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken.post(endPoints.VERIFY_PHONE_OTP, payload)
        .then((response) => {
            dispatch({ type: "LOADERSTOP" })
            console.log("verify--", response)


            if (response?.data?.status === "success") {
                toast.success(response?.data?.message)
                setEnableInst(false)
                setPhoneFieldDisabled(true)
                setSendOTPEnable(false)
                setShowTimer(false)
                setResendEnable(false)
                setIsOtp(false)
                setSubmitDisable(false)
                // callback(true)
            }

            if (response?.data?.status === "error") {
                toast.error(response?.data?.message)
            }


        }).catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
            toast.error(errResponse?.respone?.data?.message)

            // callback(false)

            console.log('errResponse', errResponse)
        })

}

export const loginAsAdmin = (payload, navigate) => {

    axiosInstanceWithUserToken.post(endPoints.ADMIN_LOGIN, payload)
        .then((response) => {

            localStorage.setItem("user_token", response?.data?.data?.token)
            // navigate("/lab-dashboard")
            window.location.href = "/lab-dashboard"
        }).catch((errResponse) => {

        })
}
import React, { useState, useEffect } from 'react';
import Warning from 'components/Warning/Warning';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { viewProfile } from 'store/action/profileAction';

const SellerDashboardCompany = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch()
	const [showWarning, setShowWarning] = useState(false);
	const user = useSelector((state) => state.profileView);

	

	useEffect(()=>{
		dispatch(viewProfile())
	},[])

	useEffect(() => {
		setShowWarning(!user?.data?.[0]?.isApproved ? true : false)
	}, [user])

	

	const handleLink = () => {
		// console.log('dataaaaa', getProfileInfo)
		if (user?.data?.[0]?.type == "Lab") {
			setTimeout(() =>
				window.location.reload('/lab-dashboard')
				// navigate({ pathname: '/lab-dashboard' })
				, 500);
		}
		// else {
		// 	setTimeout(() => navigate({ pathname: '/seller-verification' }), 500);
		// }
	}


	return (
		<section className={`seller-dashboard ${showWarning ? '' : 'not-approved'}`}>
			{showWarning && <Warning onCloseWarning={setShowWarning} />}
			<div className="container">
				<h2>Account</h2>
				<div className="dashboard-wrapper dashboard-wraps">
					<div className="dashboard-card card">
						<Link to='/lab-information'>
							<div className="wrap-icon">
								<i className="icon-person"></i>
							</div>
							<h4 className="service-name">Lab information</h4>
							<div className="service-details">Lab information and operating addresses </div>
						</Link>
					</div>
					{/* <div className={user?.data?.[0]?.isApproved ? "dashboard-card card" : "dashboard-card card faded"}>


						<Link to='#'>
							<div className="wrap-icon">
								<i className="icon-comment"></i>
							</div>
							<h4 className="service-name">Seller Messages</h4>
							<div className="service-details">View your seller messages</div>
						</Link>


					</div> */}

					{/* <div className={user?.data?.[0]?.isApproved ? "dashboard-card card" : "dashboard-card card faded"}>
						<Link to='#'>
							<div className="wrap-icon">
								<i className="icon-bag"></i>
							</div>
							<h4 className="service-name">Seller Orders</h4>
							<div className="service-details">View order status and check payment and statistics</div>
						</Link>
					</div> */}

					<div className={user?.data?.[0]?.isApproved ? "dashboard-card card" : "dashboard-card card faded"}>

						<Link to={user?.data?.[0]?.isApproved ? "/lab-information?to=availability" : "/"}>
							<div className="wrap-icon">
								<i className="icon-plus"></i>
							</div>

							<h4
								// onClick={handleLink}
								className="service-name">Availability</h4>
							<div className="service-details">Create new and manage listings</div>
						</Link>
					</div>

					<div className="dashboard-card card">
						<Link to='/lab-verification'>
							<div className="wrap-icon">
								<i className="icon-lock"></i>
							</div>
							<h4
								// onClick={handleLink}
								className="service-name"> Lab Verification & Security</h4>
							<div className="service-details">Edit or add verification information</div>
						</Link>
					</div>
					<div className={user?.data?.[0]?.isApproved ? "dashboard-card card faded" : "dashboard-card card faded"}>
						{/* <Link to={user?.data?.[0]?.isApproved ? '/seller-orders' : "/"}> */}
						<Link to={user?.data?.[0]?.isApproved ? '/' : "/"}>
							<div className="wrap-icon">
								<i className="icon-bag"></i>
							</div>
							<h4 className="service-name">Lab Orders</h4>
							<div className="service-details">View order status and check payment and statistics</div>
						</Link>
					</div>
					<div
						className={user?.data?.[0]?.isApproved ? "dashboard-card card" : "dashboard-card card faded"}
					// className={user?.data?.[0]?.isApproved ? "dashboard-card card" : "dashboard-card card faded"}
					>
						{/* <Link to='/seller/optional-info'> */}
						<Link to={user?.data?.[0]?.isApproved ? '/list_lab_test' : "/"}>

							<div className="wrap-icon">
								<i className="icon-snippet"></i>
							</div>
							<h4 className="service-name">Manage Tests</h4>
							<div className="service-details"> certifications & licenses and portfolio</div>
						</Link>
					</div>
					<div className={user?.data?.[0]?.isApproved ? "dashboard-card card" : "dashboard-card card faded"}>
						<Link to={user?.data?.[0]?.isApproved ? '/list_lab_package' : "/"}>
							<div className="wrap-icon">
								<i className="icon-packages"></i>
								{/* <i className="icon-package"></i> */}
							</div>
							<h4 className="service-name">Manage Packages</h4>
							<div className="service-details">Browse questions and help topics</div>
						</Link>
					</div>
					<div className="dashboard-card card faded">
						{/* <Link to='/seller-messages'> */}
						{/* <Link to='/lab-messages'> */}
						<Link to='#'>
							<div className="wrap-icon">
								<i className="icon-comment"></i>
							</div>
							<h4 className="service-name">Manage Message</h4>
							<div className="service-details">Browse questions and help topics</div>
						</Link>
					</div>
					<div className="dashboard-card card">
						<Link to='/faq'>
							<div className="wrap-icon">
								<i className="icon-question"></i>
							</div>
							<h4 className="service-name">Help (FAQ)</h4>
							<div className="service-details">Browse questions and help topics</div>
						</Link>
					</div>
				</div>
			</div>
		</section>
	)
}

export default SellerDashboardCompany;

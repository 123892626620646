import React, { useState, useEffect } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import moment from "moment";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import { useDispatch, useSelector } from "react-redux";
import { countryList } from "store/action/countryList";
import { stateList } from "store/action/stateList";
import { cityList } from "store/action/cityListAction";
import Time from "./Time";
import { addNewAddress, getAddressDetail, editAddress } from "store/action/addAddressAction";
import { categoryFinalList } from "store/action/categoryListAction";
import { finalCategoryData } from "store/reducer/category.reducer";

const animatedComponents = makeAnimated();

const schema = yup.object().shape({
  addressLine1: yup
    .string()
    .required("Please enter Address")
    .min(2, "Address must be at least 2 characters")
    .max(60, "Address must be at most 60 characters")
    .matches(/^[a-zA-Z0-9\s.,'-]*$/, "Please enter valid Address"),
  addressLine2: yup
    .string(),
  cityId: yup
    .string()
    .required("Please enter city"),
  stateId: yup
    .string()
    .required("Please enter State"),
  postcode: yup
    .string()
    .required("Please enter Zip")
    .matches(/^[0-9a-zA-Z]+$/, "Zip code is not valid")
    .min(3),
});

let dayOptions = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday"
];



const AddCategory = (props) => {

  console.log("props--->", props)

  const getCountry = useSelector((state) => state.countryData);
  const getState = useSelector((state) => state.stateData);
  const finalCategory = useSelector((state) => state.finalCategoryData);
  const getCity = useSelector((state) => state.cityData);
  const [countryname, setCountryname] = useState("");
  const [isBtnDisable, setIsBtnDisable] = useState(false);
  const dispatch = useDispatch();

  const [selectedTimes, setSelectedTimes] = useState({});

  const [isFormValid, setIsFormValid] = useState(true);



  const [toTimeGivenError, setToTimeGivenError] = useState()

  const [fromTimeGivenError, setFromTimeGivenError] = useState({})

  const [fromToErrMessage, setFromToErrMessage] = useState()

  const [oneDayTimeSelect, setOneDayTimeSlect] = useState()





  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    reset,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });


  const [state, setState] = useState([
    {
      day: "",
      from: "",
      to: "",
      isError: false,
      errorMsg: ""
    },
    {
      day: "",
      from: "",
      to: "",
      isError: false,
      errorMsg: ""
    }
  ]);

  console.log("state11111", state)


  function disabledMinutes() {
    return [];
  }

  function disabledHours() {
    return [];
  }

  const format = "h:mm a";


  function handleChangeFrom(evt, i) {
    console.log("evt----", evt)
    console.log("mmmmmm", i)
    let fromTime = document.getElementById(`from_0`);
    console.log("fromTime", fromTime)
    let fromError = document.getElementById(`from_error0`);
    console.log("fromError", fromError)
    if (fromTime.value === "") {
      fromTime.focus();
      fromError.style.display = "block";
    } else {
      fromError.style.display = "none";
    }
    let timeval = evt;
    console.log("timeval", timeval)
    let newState = [...state];
    newState[i].from = timeval
    console.log("newState", newState)
    setState(newState)
  }





  function handleChangeTo(evt, i) {
    let toTime = document.getElementById(`to_0`);
    let toError = document.getElementById(`to_error0`);
    if (toTime.value === "") {
      toTime.focus();
      toError.style.display = "block";
    } else {
      toError.style.display = "none";
    }
    let timeval = evt;
    let newState = [...state];
    newState[i].to = timeval
    setState(newState)
  }

  const addMoreDay = (e) => {
    e.preventDefault();
    let newState = [...state];
    newState.push({ day: "", from: "", to: "", isError: false, errorMsg: "" });
    setState(newState);
  };


  const now = moment().hour(14).minute(30);

  // console.log('now', now)

  useEffect(() => {
    stateList({ countryId: "61c2fb83dc7c0d455ba5e68d" }, dispatch)
  }, [])




  useEffect(() => {
    categoryFinalList(callback => { })(dispatch)
    countryList()(dispatch);

    if (props.addressId) {
      getAddressDetail({ addressId: props.addressId }, callback => {

        console.log("callback--->", callback)

        stateList({ countryId: "61c2fb83dc7c0d455ba5e68d" }, dispatch);
        cityList({ countryId: "61c2fb83dc7c0d455ba5e68d", stateId: callback.stateId })(dispatch);



        setTimeout(() => {
          setValue('addressLine1', callback?.addressLine1)
          setValue('addressLine2', callback.addressLine2)
          setValue('countryId', "61c2fb83dc7c0d455ba5e68d")
          setValue('stateId', callback.stateId)
          setValue('cityId', callback.cityId)
          setValue('postcode', callback.postcode)

          // let a = callback.daysOpened.map(item => ({
          //   ...item,
          //   dayOptions,
          //   from: item.from ? moment(item.from, 'hh:mm a') : "",
          //   to: item.to ? moment(item.to, 'hh:mm a') : ""
          // }))
          let temp = {}
          callback.daysOpened.forEach((item) => {
            temp[item.day] = { from: item.from ? moment(item.from, 'hh:mm a') : "", to: item.to ? moment(item.to, 'hh:mm a') : "" }
          })
          console.log("aaaaaaaaaaaaa", temp)
          setSelectedTimes(temp)
          setState(temp);
          setIsBtnDisable(false);
        }, 1000);

      })(dispatch)
    }

  }, []);

  const getStateHandler = (e) => {
    let val = e.target.value;
    if (val === "") {
      setError("countryId")
    } else {
      clearErrors("countryId")
    }
    setCountryname(val);
    stateList({ countryId: "61c2fb83dc7c0d455ba5e68d" })(dispatch);
  };

  const getCityHandler = (e) => {
    if (e.target.value === "") {
      setError("stateId")
    } else {
      clearErrors("stateId")
    }
    cityList({ countryId: countryname, stateId: e.target.value })(dispatch);
  };

  const closeModalHandler = () => {
    props.onCloseModal(false);
  };


  const handlerChangeMulti = (e, i) => {
    let isDisable = false;
    let newState = [...state];
    newState = newState.map(item => ({ ...item, isError: false, errorMsg: "" }));
    newState.forEach((item, index) => {
      if (item.day !== "" && item.day === e.target.value) {
        isDisable = true;
        newState[i].isError = true;
        newState[i].errorMsg = "You can't select same day multi time.";
      }
    })
    newState[i].day = e.target.value;
    setState(newState)
    setIsBtnDisable(isDisable)
  }


  const removeField = (index) => {
    let newState = [...state];
    newState = newState.filter((item, i) => {
      if (i !== index) {
        return item;
      }
    });

    newState = newState.map((item, i) => {
      return { ...item, isDisable: false, isError: false }
    })

    console.log('newState', newState);

    setState(newState);
  }




  const onSubmit = (data) => {

    let isValidate = false;


    const dataArray = Object.keys(selectedTimes).map(day => {
      return {
        day: day,
        from: selectedTimes[day].from,
        to: selectedTimes[day].to
      };
    });



    let daysOpenedData = [];




    if (dataArray?.length > 0) {

      // Loop through the days in the abc object
      for (let day in dataArray) {
        // Extract 'from' and 'to' properties from each day's object and format them as strings
        // let dayName=dataArray


        let fromTime = dataArray[day].from?.format('HH:mm');
        let toTime = dataArray[day].to?.format('HH:mm');

        // Push day and corresponding 'from' and 'to' times into openDaysData array
        daysOpenedData.push({
          day: dataArray[day]?.day,
          from: fromTime,
          to: toTime
        });
      }
    } else {
      data.daysOpened = []
    }



    data.daysOpened = daysOpenedData;
    data.sellerInformationId = props.sellerInformationId;


    if (props.addressId) {
      data.addressId = props.addressId
      editAddress(data)(dispatch);
    } else {
      let hasError = false;
      if (data?.daysOpened?.length === 0) {
        setOneDayTimeSlect("Select one time");
        hasError = true;
        setIsBtnDisable(true); // Disable the button if no time is selected
        return
      } else if (data?.daysOpened?.some(item => item.from && !item.to)) {
        setToTimeGivenError("Please select 'To' time");
        setIsBtnDisable(true); // Disable the button if 'To' time is missing
        return
      } else if (data?.daysOpened?.some(item => !item.from && item.to)) {
        setFromTimeGivenError("Please select 'From' time");
        setIsBtnDisable(true); // Disable the button if 'From' time is missing
        return
      }
      else {
        console.log("fromToErrMessage", fromToErrMessage)
        if (!fromToErrMessage || fromToErrMessage?.length === 0) {
          addNewAddress(data, closeModalHandler)(dispatch);
        }


      }
    }

    setTimeout(() => closeModalHandler(), 1000)

  };



  const handleTimeChange = (day, type, time) => {
    setFromToErrMessage();
    setToTimeGivenError();

    setOneDayTimeSlect("")

    const updatedTimes = { ...selectedTimes };
    updatedTimes[day] = {
      ...updatedTimes[day],
      [type]: time
    };

    // Check for errors across all days
    let hasError = false;
    Object.keys(updatedTimes).forEach((dayKey) => {
      const { from, to } = updatedTimes[dayKey];
      if ((!from && to) || (from && !to)) {
        setFromToErrMessage(`Please select both 'From' and 'To' times for ${dayKey}`);
        hasError = true;
      } else if (from && to) {
        if (from.isSame(to)) {
          setFromToErrMessage(`'From' time cannot be the same as 'To' time for ${dayKey}`);
          hasError = true;
        } else if (to.isBefore(from)) {
          setFromToErrMessage(`'To' time cannot be before 'From' time for ${dayKey}`);
          hasError = true;
        }
      }
    });

    if (hasError) {
      setIsBtnDisable(true);
    } else {
      setIsBtnDisable(false);
      // Clear error messages when there are no errors
      setFromToErrMessage();
      setToTimeGivenError();
    }

    // Update the selectedTimes state
    setSelectedTimes(updatedTimes);


  };



  // const handleTimeChange = (day, type, time) => {

  //   setFromToErrMessage();
  //   setToTimeGivenError();

  //   // Clone the selectedTimes object to avoid mutating the state directly
  //   const updatedTimes = { ...selectedTimes };

  //   // Update the selected time based on the type ('from' or 'to')
  //   updatedTimes[day] = {
  //     ...updatedTimes[day],
  //     [type]: time
  //   };

  //   // Check if both 'from' and 'to' times are selected
  //   if (updatedTimes[day].from && updatedTimes[day].to) {
  //     // Condition (1): Check if 'from' time and 'to' time are the same
  //     if (updatedTimes[day].from.isSame(updatedTimes[day].to)) {
  //       setIsBtnDisable(true);
  //       setFromToErrMessage(`'From' time cannot be the same as 'To' time for ${day}`);
  //     }
  //     // Condition (3): Check if 'to' time is before 'from' time
  //     else if (updatedTimes[day].to.isBefore(updatedTimes[day].from)) {
  //       setIsBtnDisable(true);
  //       setFromToErrMessage(`'To' time cannot be before 'From' time for ${day}`);
  //     } else {
  //       setIsBtnDisable(false);
  //     }
  //   } else {
  //     // If either 'from' or 'to' time is not selected, enable the button
  //     setIsBtnDisable(false);
  //   }

  //   // Update the selectedTimes state
  //   setSelectedTimes(updatedTimes);
  // };





  return (
    <div className="modal-backdrop">
      <div className="add-address card">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="inputs-wrapper row">
            <div className="col-12 inputs-heading">Operating Address</div>
            <div className="col-md-6 input-wrapper required">
              <label className="input-label required">Address Line 1</label>
              <div className="input-wrap">
                <input
                  type="text"
                  {...register("addressLine1", { required: true })}
                  className="form-control"
                  placeholder="Address line 1"
                />
              </div>
              {errors.addressLine1 && (
                <span className="error">{errors.addressLine1.message}</span>
              )}
            </div>
            <div className="col-md-6 input-wrapper">
              <label className="input-label">Address Line 2</label>
              <div className="input-wrap">
                <input
                  type="text"
                  {...register("addressLine2", { required: true })}
                  className="form-control"
                  placeholder="Address line 2"
                />
              </div>
              {errors.addressLine2 && (
                <span className="error">{errors.addressLine2.message}</span>
              )}
            </div>
            <div className="col-md-6 input-wrapper required">
              <label className="input-label required">Operating Country</label>
              <div className="input-wrap contains-select" style={{ background: "#e9ecef" }}>
                <select
                  // {...register("countryId")}
                  // name="countryId" 
                  className="form-control country-class"
                  id="countryId"
                  // onChange={getStateHandler}
                  disabled
                >
                  <option value={""}>India</option>

                </select>
              </div>
            </div>
            <div className="col-md-6 input-wrapper required">
              <label className="input-label required">Operating State/Region</label>
              <div className="input-wrap contains-select">
                <select
                  className="form-control"
                  {...register("stateId", { required: true })}
                  name="stateId"
                  onChange={getCityHandler}
                >
                  <option value={``}>Please select state</option>
                  {getState?.map((item) => (
                    <option value={item._id}>{item.name}</option>
                  ))}
                </select>
              </div>
              {errors.stateId && (
                <span className="error">{errors.stateId.message}</span>
              )}
            </div>
            <div className="col-md-6 input-wrapper required">
              <label className="input-label">Operating City</label>
              <div className="input-wrap contains-select">
                <select
                  className="form-control"
                  {...register("cityId", { required: true })}
                  name="cityId"
                >
                  <option value={``}>Please select city</option>
                  {getCity?.map((item) => (
                    <option value={item._id}>{item.name}</option>
                  ))}
                </select>
              </div>
              {errors.cityId && (
                <span className="error">{errors.cityId.message}</span>
              )}
            </div>


            <div className="col-md-6 input-wrapper required">
              <label className="input-label required">Zip / Postal Code</label>
              <div className="input-wrap">
                <input
                  type="text"
                  {...register("postcode", { required: true })}
                  className="form-control"
                  placeholder="Zip / Postal Code"
                />
              </div>
              {errors.postcode && (
                <span className="error">{errors.postcode.message}</span>
              )}
            </div>
          </div>
          <div className="timing-wrap">
            <div className="row inputs-wrapper mobile-fix">
              <div className="col-12 inputs-heading ">Days Opened</div>
            </div>
            {
              dayOptions.map((item, index) => {

                return (
                  <div className="row inputs-wrapper mobile-fix" key={item._id}>
                    <div className="col-md-4 col-lg-6 col-lg-5-fix">
                      {index === 0 && <label className="input-label d-none d-md-block">Day</label>}

                      <div className="select-wrap">
                        {item}

                      </div>
                    </div>
                    <div className="col-md-3 col-lg-2-fix input-wrapper margin-bottom-none">
                      {index === 0 && <label className="input-label d-none d-md-block">From</label>}


                      <div className="select-wrap">
                        <TimePicker
                          placeholder={`From`}
                          name="fromTime"
                          // id={`from_` + i}
                          popupClassName={'test'}
                          // value={item.from !== "" ? item.from : ""}
                          // value={item && }
                          // onChange={(val) => handleChangeFrom(val, i)}
                          disabledHours={disabledHours}
                          disabledMinutes={disabledMinutes}
                          hideDisabledOptions={true}
                          showSecond={false}
                          inputReadOnly={true}
                          defaultValue={moment()}

                          value={selectedTimes[item]?.from}
                          // value={Object.keys(selectedTimes)?.find(ele => selectedTimes[ele].day === item)?.from}
                          onChange={(time) => handleTimeChange(item, 'from', time)}

                        />
                        {/* <p class="error" style={{ display: "none" }} id={`from_error` + i}>Please choose from time</p> */}

                        {fromTimeGivenError?.errorOf === item && <p class="error">
                          Please select From Time
                        </p>}
                      </div>
                    </div>
                    <div className="position-relative col-md-3 col-lg-2-fix input-wrapper margin-bottom-none">
                      {index === 0 && <label className="input-label d-none d-md-block">To</label>}
                      <div className="select-wrap">
                        <TimePicker
                          name="toTime"
                          placeholder={`To`}
                          // id={`to_` + i}
                          // value={item.to !== "" ? item.to : ""}
                          disabledHours={disabledHours}
                          disabledMinutes={disabledMinutes}
                          hideDisabledOptions={true}
                          // onChange={(val) => handleChangeTo(val, i)}
                          showSecond={false}
                          inputReadOnly={true}

                          // value={selectedTimes[item]?.to || ''}
                          value={selectedTimes[item]?.to}

                          onChange={(time) => handleTimeChange(item, 'to', time)}
                        />
                        {/* <p class="error" style={{ display: "none" }} id={`to_error` + i}>
                      Please choose to time
                    </p> */}



                        {toTimeGivenError?.errorOf === item && <p class="error">
                          Please select To Time
                        </p>}
                      </div>


                    </div>



                    <div>{fromToErrMessage && <p class="error">
                      {fromToErrMessage}
                    </p>}</div>



                    <div>{
                      toTimeGivenError && <p class="error">
                        {toTimeGivenError}
                      </p>}</div>

                    <div>
                      {oneDayTimeSelect && <p class="error">
                        {oneDayTimeSelect}
                      </p>}
                    </div>

                  </div>
                )
              })

            }
            {/* {
              state.map((item, i) => (
                <div className="row inputs-wrapper mobile-fix" key={item._id}>
                  <div className="col-md-4 col-lg-6 col-lg-5-fix">
                    {i === 0 && <label className="input-label d-none d-md-block">Day</label>}

                    <div className="select-wrap">
                      <select
                        className="form-control"
                        value={item.day}
                        onChange={(e) => handlerChangeMulti(e, i)}
                        id={`day_` + i}
                      >
                        <option value={''}>Please choose day</option>
                        {
                          dayOptions.map(nitem =>
                            <option value={nitem}>{nitem}</option>
                          )
                        }
                      </select>
                      {
                        item.isError ?
                          <p
                            className="error"
                            // style={{ display: "none" }}
                            id={`day_error` + i}
                          >{item.errorMsg}</p>
                          : null
                      }

                    </div>
                  </div>
                  <div className="col-md-3 col-lg-2-fix input-wrapper">
                    {i === 0 && <label className="input-label d-none d-md-block">From</label>}

                    <div className="select-wrap">
                      <TimePicker
                        placeholder={`From`}
                        name="fromTime"
                        id={`from_` + i}
                        popupClassName={'test'}
                        value={item.from !== "" ? item.from : ""}
                        onChange={(val) => handleChangeFrom(val, i)}
                        disabledHours={disabledHours}
                        disabledMinutes={disabledMinutes}
                        hideDisabledOptions={true}
                        showSecond={false}
                        inputReadOnly={true}
                        defaultValue={moment()}

                      />
                      <p class="error" style={{ display: "none" }} id={`from_error` + i}>Please choose from time</p>
                    </div>
                  </div>
                  <div className="position-relative col-md-3 col-lg-2-fix input-wrapper">
                    {i === 0 && <label className="input-label d-none d-md-block">To</label>}
                    <div className="select-wrap">
                      <TimePicker
                        name="toTime"
                        placeholder={`To`}
                        id={`to_` + i}
                        value={item.to !== "" ? item.to : ""}
                        disabledHours={disabledHours}
                        disabledMinutes={disabledMinutes}
                        hideDisabledOptions={true}
                        onChange={(val) => handleChangeTo(val, i)}
                        showSecond={false}
                        inputReadOnly={true}
                      />
                      <p class="error" style={{ display: "none" }} id={`to_error` + i}>
                        Please choose to time
                      </p>
                    </div>

                    {
                      i !== 0 &&
                      <button
                        onClick={() => removeField(i)}
                        type="button"
                        className="remove-btn"
                      ></button>
                    }
                  </div>

                </div>
              ))
            } */}
            {/* <div className="add-day">
              <button onClick={addMoreDay} className="add-btn">
                Add another day
              </button>
            </div> */}
          </div>
          <div className="btn-wrap">
            <input
              className="secondary-btn"
              type="reset"
              onClick={closeModalHandler}
              value="cancel"
            />
            <input
              className={isBtnDisable ? "btn -disable" : "btn"}
              type="submit"
              value="submit"
              disabled={isBtnDisable}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddCategory;

import { useEffect, useState } from 'react';

const AddressList = ({ item, deleteAddressHander, addressEditHandler, setAddressId, setManageDateHandler ,daysListShow, setDaysListShow}) => {

    console.log("item---->", item)

    let dayLists = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday"
    ];

    const [show, setShow] = useState(false);
    // const [daysListShow, setDaysListShow] = useState(false)
    const [showManageDate, setManageDate] = useState(false)

    const [selectStartDate, setSelectStartDate] = useState()
    const [selectEndDate, setSelectEndDate] = useState()



    useEffect(() => {
        const currentDate = new Date(item.daysAvailable?.[0]?.from);
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(currentDate.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;

        // console.log("formattedDate", formattedDate);
        setSelectStartDate(formattedDate)
    }, [])

    useEffect(() => {
        const currentDate = new Date(item.daysAvailable?.[0]?.to);
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(currentDate.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;

        // console.log("formattedDate", formattedDate);
        setSelectEndDate(formattedDate)
    }, [])


    const removeCard = (id) => {
        if (window.confirm("Are you sure you want to delete this address ?")) {
            setTimeout(() => setShow(false), 1000)
            deleteAddressHander(id)
        } else {
            setShow(false);
        }
    }

    const getDaysOpen = () => {
        if (item.daysOpened.length === 1) {
            return item.daysOpened[0].day
        } else {
            return item.daysOpened[0].day + "-" + item.daysOpened[item?.daysOpened?.length - 1].day
        }
    }

    let dayList = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday"
    ];

    const getDaysClose = () => {
        let dayOptions = [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday"
        ];
        // console.log('dayOptions', item.daysOpened)

        let closedDays = dayOptions.filter((ditem, i) => i > item.daysOpened.length - 1)

        console.log('closedDays', closedDays)


        if (closedDays.length === 1) {
            return closedDays[0]
        } else {
            return closedDays[0] + "-" + closedDays[closedDays.length - 1]
        }
    }

    // let openDays = item?.daysOpened?.map(item => item.day);
    // console.log("openDays",openDays)

    let nonMatchingDays = dayList.filter(day => !item?.daysOpened?.some(openDay => openDay?.day === day));



    const daysOpenPopupHandler = (id) => {
    
        setDaysListShow(id);
    }




    return (
        <div className="table-card">
            <div className="menu-wrap">
                <button className="menu-btn" onClick={() => { setShow(!show) }}>
                    <span className="btn-dot"></span>
                    <span className="btn-dot"></span>
                    <span className="btn-dot"></span>
                </button>
                {
                    show ?
                        <div className="menu">
                            <ul className="menu-items">
                                <li>
                                    <button onClick={() => { setAddressId(item._id); setShow(false) }} className="menu-option">Edit</button>
                                </li>
                                <li>
                                    <button onClick={() => removeCard(item._id)} className="menu-option delete">Delete</button>
                                </li>
                                <li>
                                    <button onClick={() => { setManageDateHandler(item._id); setManageDate(false) }} className="menu-option">Set availability</button>
                                </li>
                            </ul>
                        </div>
                        : null
                }

            </div>
            <div className="card-column card-content-wrap">
                <h2>Operating Country:</h2>
                <div className="value">{item.countryName}</div>


            </div>
            <div className="card-column card-content-wrap">
                <h2>Operating City:</h2>
                <div className="value">{item.cityName}</div>
            </div>
            <div className="card-column card-content-wrap">
                <h2>Operating State:</h2>
                <div className="value no-value">{item.stateName}</div>
            </div>
            <div className="card-column card-content-wrap">
                <h2>Operating Address:</h2>
                <div className="value">
                    {item.addressLine1} {item.addressLine2}
                </div>
            </div>
            {/* availability */}
            <div className="card-column card-content-wrap">
                <h2>availability</h2>
                {item.daysAvailable?.length > 0 ?
                    <>
                        <div className='availability-date'><span className='available-date-info'>From</span> : {selectStartDate} </div>
                        <div> <span className='available-date-info'>To</span> : {selectEndDate} </div>
                    </>
                    :
                    <div className='available-date-info'>Not Set</div>
                }

                {/* <div className="value">
                    <div>From :  {item.daysAvailable?.[0]?.from} </div>
                    <div> To : {item.daysAvailable?.[0]?.to} </div>
                </div> */}
            </div>
            <div className="card-column days card-content-wrap">
                <h2>Days opened:</h2>
                <div className="value">
                    {/* {
                        item?.daysOpened?.length > 0 ?
                            <div className="value-part">
                                <div className="part-left">
                                    {ele.day}
                                </div>
                                <div className="part-right" style={{whiteSpace:"nowrap"}}>{ele.from} — {ele.to}</div>

                            </div>
                            : null
                    } */}


                    <button className="menu-btn" onClick={()=>daysOpenPopupHandler(item._id)}>
                        <span className='open-days-btn'>Click Here</span>

                    </button>


                    {
                       daysListShow === item._id && daysListShow ?
                            <div className="menu-days-view days-list">
                                <div className='close-btn' onClick={() => { setDaysListShow(!daysListShow) }}></div>
                                <ul className="menu-items">
                                    <>
                                        {
                                            item?.daysOpened?.map((ele) => {
                                                return (
                                                    <li key={ele._id}>
                                                        <span className='days-items'> {ele?.day} :-  </span>{ele?.from} — {ele?.to}</li>
                                                )
                                            })
                                        }
                                    </>
                                </ul>
                            </div>
                            : null
                    }




                    {/* {item?.daysOpened?.map((openDaysItems) => {
                        return (
                            <div className="value-part">
                                <div className="part-left">
                                 
                                    {openDaysItems?.day}

                                </div>
                               
                                <div className="part-right"> {openDaysItems?.from} — {openDaysItems?.to}</div>

                            </div>
                        )
                    })} */}




                    {/* <div className="value-part">
                        <div className="part-left">
                            {nonMatchingDays}
                            </div>
                        <div className="part-right">Closed</div>
                    </div> */}

                    {/* <div className="menu-wrap days-views" style={{ marginTop: "50px" }}>
                        <button className="menu-btn" onClick={() => { setDaysListShow(!daysListShow) }}>
                            <span className="btn-dots">View all days list</span>

                        </button>
                        {
                            daysListShow ?
                                <div className="menu-days-view" style={{width:"300px",height:"300px",background:"#b2b0a9"}}>
                                    <ul className="menu-items">
                                        <>
                                        {
                                            item?.daysOpened?.map((ele)=>{
                                                return (
                                                    <li key={ele._id}>{ele?.day}:-   {ele?.from} — {ele?.to}</li>
                                                )
                                            })
                                        }
                                        </>
                                    </ul>
                                </div>
                                : null
                        }

                    </div> */}


                </div>



            </div>
        </div >
    )
}

export default AddressList;
import React, { useState, useEffect, useRef } from 'react';
import { Breadcrumb, Tabs, Tab } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ProfileImage from 'assets/images/edit-card-image.png';
import { conversationSellerList } from 'store/action/sellerConvrstnAction';
import { conversationSeller } from 'store/action/sellerConvrstnAction';
import Pagination from 'components/Pagination/Pagination';
import { allMessagesSeller } from 'store/action/sellerReadMessagesAction';
import OutsideClickHandler from 'components/OutsideClickHandler';

const SellerMessages = () => {

    const ref = useRef();
    let params = useParams()
    let dispatch = useDispatch()
    let navigate = useNavigate();
    const [showMenu, setShowMenu] = useState(false);
    const [active, setActive] = useState('active');
    const [activeUnread, setActiveUnread] = useState('');
    const [searchVal, setSearchVal] = useState("");
    const [limit, setLimit] = useState(10);
    const getMessageListSeller = useSelector((state) => state.sellerToUserConversationList);

    useEffect(() => {
        conversationSellerList()(dispatch)
    }, [])

    const showMenuHandler = () => {
        setShowMenu(!showMenu);
    }

    OutsideClickHandler(ref, () => {
        // console.log('showMenu', showMenu)
        setTimeout(() => setShowMenu(false), 100)
    });

    const handleMessage = (e, item) => {
        e.preventDefault();
        console.log(item._id)
        let payload = {
            conversationId: item._id,
        }
        conversationSeller(payload)(dispatch)
        allMessagesSeller(payload)(dispatch)
        setTimeout(() => navigate('/seller/messages/opened/' + item._id), 100)
    }

    useEffect(() => {
        conversationSellerList({ pageno: 1, limit: limit })(dispatch)
    }, [])

    const serviceLoadMore = (page) => {
        conversationSellerList({ pageno: page, limit: limit })(dispatch)
    }

    const handlerInputChange = (e) => {
        e.preventDefault();
        if (searchVal !== "") {
            conversationSellerList({ searchMessage: searchVal })
                (dispatch)
            setActiveUnread("");
            setActive("active")
        }
        else {
            conversationSellerList({ searchMessage: searchVal })
                (dispatch)
            setActiveUnread("");
            setActive("active")

        }
    }

    const handleAll = (e) => {
        e.preventDefault()
        setActiveUnread("");
        setActive("active")
        // setActive(engine.target.id);
        conversationSellerList()(dispatch)
    }

    const handleUnread = (e) => {
        e.preventDefault()
        setActiveUnread("active");
        setActive("")
        // setActive(e.target.id);
        let payload = {
            unreadList: true,
        }
        conversationSellerList(payload)(dispatch)
    }

    console.log('seller messages', getMessageListSeller)

    return (
        <section className='edit-information seller-messages'>
            <div className="container">
                <Breadcrumb>
                    <Breadcrumb.Item href="#">Lab Account</Breadcrumb.Item>
                    <Breadcrumb.Item active>Lab Messages</Breadcrumb.Item>
                </Breadcrumb>
                <div className="section-header">
                    <div className="header-left">
                        <h1>Lab Messages</h1>
                    </div>
                </div>
                <div className='messages-wrap'>
                    <div className='messages-head'>
                        <form>
                            <div className='form-wrap'>
                                <button className={`msg-type ${active}`} onClick={(e) => handleAll(e)}>All Chats</button>
                                <button className={`msg-type unread ${activeUnread}`} onClick={(e) => handleUnread(e)}>Unread Messages</button>
                                <input
                                    className='search-input flex-grow-1'
                                    type='text'
                                    placeholder='Search for a message'
                                    onChange={(e) => setSearchVal(e.target.value)}
                                    value={searchVal}
                                />
                                <button className='search-btn' onClick={handlerInputChange}>
                                    <i className='icon-magnifier'></i>
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className='messages-collector'>
                        {getMessageListSeller?.data?.map(item =>
                            <div className={item.unreadConversationDetailDataCount >= 1 ? "message-wrap" : "message-wrap unreaded-messages"}>
                                <Link to='#' onClick={(e) => handleMessage(e, item)} className='msg-box'>
                                    <div className='msg-pic online'>{item?.userData?.[0]?.firstName[0]}</div>
                                    <div className='msg-data'>
                                        <div className='text-data'>
                                            <ul className='tests-wrap'>
                                                <li>COVID-19 Tests</li>
                                                <li>Anti-Body Tests</li>
                                                <li>COVID-19 Test Centre</li>
                                            </ul>
                                            <div className='msg-name'>{item?.userData?.[0]?.firstName}</div>
                                            <p>{item?.lastConversationDetailData?.message}</p>
                                        </div>
                                        <div className='msg-edit'>
                                            {item.unreadConversationDetailDataCount >= 1 ?
                                                <div className='msg-number'>{item.unreadConversationDetailDataCount}</div>
                                                :
                                                null
                                            }
                                        </div>
                                    </div>
                                </Link>
                                <div className='menu-wrap' onClick={showMenuHandler}>
                                    <button className='menu-btn'>
                                        <span className='btn-dot'></span>
                                        <span className='btn-dot'></span>
                                        <span className='btn-dot'></span>
                                    </button>
                                    {
                                        showMenu ?
                                            <div ref={ref} className='menu'>
                                                <ul className='menu-items'>
                                                    <li>
                                                        <button className='menu-option'>Edit</button>
                                                    </li>
                                                    <li>
                                                        <button className='menu-option delete'>Delete</button>
                                                    </li>
                                                </ul>
                                            </div>
                                            :
                                            null
                                    }
                                </div>
                            </div>
                        )}
                    </div>
                    {
                        getMessageListSeller?.totalRecord > limit ?
                            <Pagination
                                totalData={getMessageListSeller?.totalRecord}
                                limit={limit}
                                loadMore={serviceLoadMore}
                            />
                            : null
                    }
                </div>
            </div>
        </section>
    )
}

export default SellerMessages

import React, { useEffect } from 'react';
import { Breadcrumb, Accordion } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { getfaq } from '../store/action/faqAction'

const Faq = () => {

	const dispatch = useDispatch();
	let history = useNavigate();
	const navigate = useNavigate();
	const user = useSelector((state) => state.profileView);
	let faqData = useSelector(store => store.faqRedu);

	useEffect(() => {
		let ele = document.getElementById("root");
		ele.scrollIntoView();
		getfaq()(dispatch)
	}, [])

	const changePage = () => {

		navigate('/lab-dashboard')
		// console.log('dataaaaa', user)
		// if (user?.data?.[0]?.type == "Company") {
		// 	setTimeout(() => navigate({ pathname: '/seller-dashboard-company' }), 500);
		// }
		// else {
		// 	setTimeout(() => navigate({ pathname: '/seller-dashboard-freelancer' }), 500);
		// }
	};

	return (
		<section className='faq'>
			<div className="container">
				<Breadcrumb>
					<Breadcrumb.Item onClick={changePage}>Lab Account</Breadcrumb.Item>
					<Breadcrumb.Item active className='text-capitalize'>FAQ</Breadcrumb.Item>
				</Breadcrumb>
				<h1>Frequently Asked Questions</h1>
				<Accordion defaultActiveKey="1">
					{
						faqData?.map((item, i) =>
							<Accordion.Item eventKey={i}>
								<Accordion.Header>
									<div className="left-block text-capitalize">Q</div>
									<div className="right-block question-wrap">
										<p>
											{item.question}
										</p>
									</div>
								</Accordion.Header>
								<Accordion.Body>
									<div className="left-block text-capitalize">Ans.</div>
									<div className="right-block answer-wrap">
										<p>
											{item.answer}
										</p>
									</div>
								</Accordion.Body>
							</Accordion.Item>
						)
					}
				</Accordion>
			</div>
		</section>
	)
}

export default Faq

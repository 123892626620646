import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link, useParams } from 'react-router-dom';
import userImg from 'assets/images/user-img.png';
import { chatWithSel } from 'store/action/chatStartAction';
import { conversationUser } from 'store/action/userCnvrstnAction';
import { useDispatch, useSelector } from 'react-redux';
import { allMessages, sendMessages } from 'store/action/readAllMessagesAction';
import { totalName } from 'store/action/userTotalMessagesAction';

const UserDialogue = () => {

	let params = useParams()
	let dispatch = useDispatch()
	let navigate = useNavigate();
	const [showUserList, setShowUserList] = useState(false);
	const [searchVal, setSearchVal] = useState("");
	const getConvrstnInfo = useSelector((state) => state.userToSellerConversation)
	const getNameList = useSelector((state) => state.userAllName)

	const toggleUserListHandler = () => {
		setShowUserList(!showUserList);
	}

	useEffect(() => {
		console.log('ddsdfdsfdsf', params)
		let payload = {
			conversationId: params.conid,

		}
		conversationUser(payload)(dispatch);
		totalName()(dispatch)
	}, [])

	useEffect(() => {
		var element = document.getElementsByClassName('msg-box');

		element.scrollTop = element.scrollHeight;

	}, [])

	const handlerSendMessage = (e) => {
		e.preventDefault();
		let payload = {
			message: searchVal,
			conversationId: params.conid
		}
		console.log('searchVal', searchVal, payload)
		if (searchVal !== '') {
			sendMessages(payload)(dispatch)
			setSearchVal('')
			setTimeout(() => allMessages({ conversationId: params.conid })(dispatch), 200)
			setTimeout(() => conversationUser({ conversationId: params.conid })(dispatch), 300)
		}
		else {
			return
		}
	}

	const servicePage = (item) => {
		console.log('wreer', item.categoryId)
		window.location.href = `/category/particular/${item.serviceData?.[0]?.categoryId}/${item.serviceData?.[0]?.subcategoryId}/${item.subcategoryName}`
	}

	const userListClasses = showUserList ? 'users-list active' : 'users-list'

	console.log("check names", getNameList)
	console.log("check message", getConvrstnInfo)

	return (
		<section className='user-dialogue'>
			<div className="container">
				<div className="card dialogue-wrap">
					<div className="dialogue-left">
						<div className="users-header">
							<button type="button" onClick={toggleUserListHandler}>All Chats</button>
							<button type="button" onClick={toggleUserListHandler}>Unread Messages</button>
						</div>
						<div className="users-wrap custom-scroll">
							<ul className={userListClasses}>
								{getNameList?.data?.map(item =>
									<li><Link to='#' /*className='active'*/>{item.sellerData?.[0]?.firstName}</Link></li>
								)}
							</ul>
						</div>
					</div>
					<div className="dialogue-right">
						<div className="user-header">
							<div className="back-wrap">
								<Link to='/user/messages' className='back-link'>Back</Link>
							</div>
							<div className="name-wrap">
								<span className="name">Jeremy Chandler</span>
								<span className="status">online</span>
							</div>
							<div className="more-wrap">
								<button>
									<i className="icon-dots"></i>
								</button>
							</div>
						</div>
						<div className="msg-box custom-scroll" id="scroll">
							<div className="msg-tags">
								<div className="tag-wrap">Entertainment</div>
								<div className="tag-wrap">Photographers</div>
								<div className="tag-wrap">Photo Session Lorem ipsum dolor sit amet</div>
							</div>
							{
								getConvrstnInfo?.map(item =>
									item.from === "User" ?
										<div className="msg-wrap">
											<div className="user-img no-img">
												{item?.sellerData?.[0]?.firstName[0]}
											</div>
											<div className="msg-details">
												<div className="user-name">
													<Link to='#'>
														{item?.sellerData?.[0]?.firstName}
													</Link>
													<div className="link-wrap">
														<Link to='#' onClick={() => servicePage(item)} className='page-link'>Go to Service Listing Page</Link>
													</div>
												</div>
												<p>{item.message}</p>
												<p>{item.createdAt.split(":")[0]}</p>
											</div>
										</div>
										:
										<div className="msg-wrap">
											<div className="user-img no-img">
												{item?.sellerData?.[0]?.firstName[0]}
											</div>
											<div className="msg-details">
												<div className="user-name">
													<Link to='#'>
														{item?.sellerData?.[0]?.firstName}
													</Link>
													{/* <div className="link-wrap">
														<Link to='/' className='page-link'>Go to Service Listing Page</Link>
													</div> */}
												</div>
												<p>{item.message}</p>
											</div>
										</div>
								)
							}
							{/* <div className="msg-wrap">
								<div className="user-img">
									<img src={userImg} alt="user" />
								</div>
								<div className="msg-details">
									<div className="user-name">
										<Link to='/'>
											Jonath
										</Link>
										an</div>
									<span className="greet">Hello!</span>
									<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
									<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet.</p>
								</div>
							</div> */}
						</div>
						<div className="msg-input">
							<form>
								<div className="form-wrap">
									<div className="clip-wrap">
										<button type='button'>
											<i className="icon-clip"></i>
										</button>
									</div>
									<div className="input-wrap">
										<textarea
											placeholder='Write a message...'
											type='text'
											onChange={(e) => setSearchVal(e.target.value)}
											value={searchVal}
										>
										</textarea>
									</div>
									<div className="send-wrap">
										<button type='submit' onClick={handlerSendMessage}>
											<i className="icon-send"></i>
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default UserDialogue

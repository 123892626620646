import React, { useState, useRef, useEffect } from "react";
import { Breadcrumb, Tabs, Tab } from "react-bootstrap";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SellerProfile from "assets/images/description-image.png";
import Rating from "assets/images/rating.png";
import Select from "react-select";


import AddCategory from "components/Seller/AddCategory";
import AddAddress from "components/Seller/AddAddress";
import SellerInfoEdit from "components/Seller/SellerInfoEdit"
import SellerView from "components/Seller/SellerView"


import { categoryList } from "store/action/categoryListAction";
import { categoryFinalList } from "store/action/categoryListAction";

import { languageList } from "store/action/languageAction";
// import addCategoryData from 'store/reducer/newCategory.reducer';
import { Alert } from 'components/Alert/Alert'
import { addNewAddress } from "store/action/addAddressAction";
import { sellerAddData, editSellerInfo } from "store/action/sellerInformationAction";
import { finalCategoryData } from "store/reducer/category.reducer";
import LabProfileAddress from "components/Seller/LabProfileAddress";
import { getAddressList } from "store/action/sellerServiceAddAction";
import AddressList from "components/Seller/AddressList";
import { deleteNewAddress } from "store/action/addAddressAction";
// import Loader from 'components/Loader/Loader';



const SellerInfo = () => {
  const dispatch = useDispatch();
  let history = useNavigate()
  const navigate = useNavigate();
  const user = useSelector((state) => state.profileView);
  const finalCategory = useSelector((state) => state.finalCategoryData);
  console.log("finalCategory",finalCategory)

  const [editInfo, setEditInfo] = useState(false);
  const [showAddCategory, setShowAddCategory] = useState(false);
  const [showAddAddress, setShowAddAddress] = useState(false);
  const [cid, setCid] = useState("cid");

  const [addressId, setAddressId] = useState(null);
  // const [isloader, setIsLoader] = useState(false);

  const [activeTab, setActiveTab] = useState()
 

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);

  const [addressList, setAddressList] = useState([]);
  console.log("addressList", addressList)

  const indexOfLastTodo = page * limit;
  const indexOfFirstTodo = indexOfLastTodo - limit;


  

  const [catName, setCatName] = useState()

  console.log("catName", catName)

  // console.log("catId", catId)


  useEffect(() => {
    setActiveTab(finalCategory[0]?.categoryName)
  }, [])

  const editInfoHandler = () => {
    history('/lab-information-edit/' + cid)
  };

  useEffect(() => {

    if (catName) {
      getAddressList({ sellerInformationId: catName })(dispatch)
    }
  }, [catName])

  useEffect(() => {
    setCid(finalCategory[0]?.categoryName)
  }, [finalCategory])

  useEffect(() => {
    languageList()(dispatch);
    categoryFinalList(callback => {
      if (callback.length === 0) {
        // editInfoHandler()
      }
    })(dispatch);
  }, []);


  // const changePage = () => {
  //   console.log('dataaaaa', user)
  //   if (user?.data?.[0]?.type == "Company") {
  //     setTimeout(() => navigate({ pathname: '/seller-dashboard-company' }), 500);
  //   }
  //   else {
  //     setTimeout(() => navigate({ pathname: '/seller-dashboard-freelancer' }), 500);
  //   }
  // };




  useEffect(() => {

    getAddressList({ sellerInformationId: finalCategory[0]?._id }, callback => {
      setAddressList(callback)
    })(dispatch)



  }, [finalCategory])

  const selectCatIdHandler = (k) => {
    console.log("kkkkkk", k)
    setCid((k) => setCid(k))
    setCatName(k)
  }

  const clickPagination = (index) => {
    setPage(index);
  }

  const clickPrev = (index) => {
    setPage(index - 1);
  }

  const clickNext = (index) => {
    setPage(index + 1);
  }

  const deleteAddressHander = (id) => {
    deleteNewAddress({ addressId: id }, callback => {
      let newState = [...addressList];
      newState = newState.filter(item => item._id !== id);
      setAddressList(newState);
    })(dispatch)
  }

  const setAddressIdHandler = (id) => {
    setAddressId(id);
    setTimeout(() => setShowAddAddress(true), 500);
  }

  useEffect(() => {
    sellerAddData({ sellerInformationId: finalCategory[0]?._id }, callback => {

      if (callback?.[0]?._id) {
        // window.location.reload("/lab-information");
        // setIsLoader(true)
      }
    })(dispatch)
  }, [])

  return (
    <React.Fragment>
      <section className="seller-info">
        <Alert />
        {/* {
			isloader ?
				<Loader />
				:
				null
		} */}
        <div className="container">
          <Breadcrumb>
            <Breadcrumb.Item
              // onClick={changePage}
              onClick={() => setTimeout(() => navigate({ pathname: '/lab-dashboard' }), 10)}
            > <Link to='/lab-dashboard'></Link>Lab Account</Breadcrumb.Item>
            <Breadcrumb.Item active>Lab Information</Breadcrumb.Item>
          </Breadcrumb>
          <div className="section-header">
            <div className="header-left">
              {!editInfo && <h1>Lab Information</h1>}
              {editInfo && <h1>Edit  Information</h1>}
            </div>
            <div className="header-right">
              <button className="btn edit" onClick={editInfoHandler}>
                Edit
              </button>
            </div>
          </div>
          <Tabs
            // defaultActiveKey={finalCategory[0] && finalCategory[0]?.categoryName}
            defaultActiveKey={activeTab}
            id="uncontrolled-tab-example"
            // className="mb-3"
            className={activeTab ? "nav-link active" : "nav-link"}
            // onSelect={(k) => setCid(k)}
            onSelect={selectCatIdHandler}
          >
            {
              finalCategory?.map(item => {
               
                return (
                  <Tab eventKey={item._id} title={item.categoryName} catName={catName} key={Math.round()}>
                    {
                      item ?
                        <SellerView item={item}
                          catName={catName}


                        />

                        : <div>Not found any Category please add.</div>


                    }
                  </Tab>
                )
              }

              )}


          </Tabs>







          {/* {editInfo && (
            <div className="btn-wrap">
              <input className="secondary-btn" type="reset" value="cancel" />
              <input className="btn" onClick={submitHandler} type="submit" value="save" />
            </div>
          )} */}
        </div>
      </section>

      {showAddCategory && <AddCategory onCloseModal={setShowAddCategory} />}
      {showAddAddress && <AddAddress onCloseModal={setShowAddAddress} />}
    </React.Fragment>
  );
};
// const ImageThumb = ({ image }) => {
//     return <div className='thumbnail-wrap'>
//         <img src={URL.createObjectURL(image)} alt={image.name} />
//     </div>
// };

export default SellerInfo;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Breadcrumb, Form } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { billingMethod } from 'store/action/sellerBillingMethod';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Link, useNavigate } from 'react-router-dom';
import { countryList } from 'store/action/countryList';
import { stateList } from 'store/action/stateList';
import { cityList } from 'store/action/cityListAction';
import { countryData } from 'store/action/countryDataAction';
import { stateDataList } from 'store/action/stateDataAction';
import { cityData } from 'store/action/cityDataAction';
import { viewBillingInfo } from 'store/action/billingViewAction';

const schema = yup.object().shape({
	addressLine1: yup
		.string()
		.required("Please enter Address")
		.min(2, "Address must be at least 2 characters")
		.max(20, "Address must be at most 20 characters")
		.matches(/^[a-zA-Z0-9\s.,'-]*$/, "Please enter valid Address"),
	addressLine2: yup
		.string(),
	cityId: yup
		.string(),
	stateId: yup
		.string(),
	countryId: yup
		.string(),
	postcode: yup
		.string()
		.required("Please enter Zip")
		.matches(/^[0-9a-zA-Z]+$/, "Zip code is not valid")
		.min(3),
	bankAddressLine1: yup
		.string()
		.required("Please enter Address")
		.min(2, "Address must be at least 2 characters")
		.max(20, "Address must be at most 20 characters")
		.matches(/^[a-zA-Z0-9\s.,'-]*$/, "Please enter valid Address"),
	bankAddressLine2: yup
		.string(),
	bankCityId: yup
		.string(),
	// .required("Please enter City")
	// .min(2, "City must be at least 2 characters")
	// .max(20, "City must be at most 20 characters")
	// .matches(/^[A-Za-z]+$/i, "Please enter valid City"),
	bankStateId: yup
		.string(),
	bankCountryId: yup
		.string(),
	bankPostcode: yup
		.string()
		.required("Please enter Zip")
		.matches(/^[0-9]*$/, "Zip code is not valid")
		.min(6)
		.max(6),
	beneficiaryName: yup
		.string()
		.required("Please enter Beneficiary Name")
		.matches(/^[A-Za-z ]+$/i, "Beneficiary Name is not valid")
		.min(2)
		.max(20),
	beneficiaryAccountNumber: yup
		.string()
		.required("Please enter Beneficiary Account")
		.matches(/^[0-9]*$/, "Beneficiary Account is not valid")
		.min(2)
		.max(20),
	beneficiaryBankName: yup
		.string()
		.required("Please enter Beneficiary Bank Name")
		.matches(/^[A-Za-z ]+$/i, "Beneficiary Bank Name is not valid")
		.min(2)
		.max(20),
	intermediaryBank: yup
		.string(),
	// .required("Please enter Intermediary Bank")
	// .matches(/^[A-Za-z]+$/i, "Intermediary Bank is not valid")
	// .min(2)
	// .max(20),
	swiftCode: yup
		.string()
		.required("Please enter 11 albhbets and number")
		.matches(/^[0-9]*$/, "Swift Code is not valid")
		.min(2)
		.max(20),
	sortCode: yup
		.string()
		.required("Please enter Sort Code")
		.matches(/^[0-9]*$/, "Sort Code is not valid")
		.min(2)
		.max(20),
});

const EditSavedCard = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch()
	const getCountry = useSelector(state => state.countryData)
	const getState = useSelector(state => state.stateData)
	const getCity = useSelector(state => state.cityData)
	const getCountryBank = useSelector(state => state.countryDataBank)
	const getStateBank = useSelector(state => state.stateDataBank)
	const getCityBank = useSelector(state => state.cityDataBank)
	const login = useSelector(state => state.login)
	const [isCardInputShowing, setIsCardInputShowing] = useState(true);
	const [isBankInputShowing, setIsBankInputShowing] = useState(false);
	const [countryname, setCountryname] = useState("");
	const [countrynameBank, setCountrynameBank] = useState("");
	const getBillingInfo = useSelector(state => state.billingView)
	const [getSecState, setGetSecState] = useState([])

	const { register, formState: { errors }, handleSubmit, reset, setValue } = useForm({
		mode: "onSubmit",
		resolver: yupResolver(schema),
	});

	useEffect(() => {
		viewBillingInfo()(dispatch);
		countryList()(dispatch);
		countryData()(dispatch);
	}, []);

	useEffect((data) => {
		console.log('profile', getBillingInfo.data)
		if (getBillingInfo?.data) {
			console.log('checkkkkk', getBillingInfo.data)
			setValue("addressLine1", getBillingInfo?.data?.addressLine1);
			setValue("addressLine2", getBillingInfo.data.addressLine2);
			setValue("bankAddressLine1", getBillingInfo.data.bankAddressLine1);
			setValue("bankAddressLine2", getBillingInfo.data.bankAddressLine2);
			setValue("stateId", getBillingInfo.data.stateId);
			setValue("countryId", getBillingInfo.data.countryId);
			setValue("bankPostcode", getBillingInfo.data.bankPostcode);
			setValue("postcode", getBillingInfo.data.postcode);
			setValue("sortCode", getBillingInfo.data.sortCode);
			setValue("swiftCode", getBillingInfo.data.swiftCode);
			setValue("beneficiaryName", getBillingInfo.data.beneficiaryName);
			setValue("beneficiaryAccountNumber", getBillingInfo.data.beneficiaryAccountNumber);
			setValue("beneficiaryBankName", getBillingInfo.data.beneficiaryBankName);
			setValue("bankCountryId", getBillingInfo.data.bankCountryId);
			setValue("bankStateId", getBillingInfo.data.bankStateId);
			setValue("bankCityId", getBillingInfo.data.bankCityId);
			setValue("postcode", getBillingInfo.data.postcode);
		}
	}, [getBillingInfo]);

	const primaryRadioChangeHandler = () => {
		setIsBankInputShowing(false);
		setIsCardInputShowing(true);
	}
	const secondaryRadioChangeHandler = () => {
		setIsCardInputShowing(false);
		setIsBankInputShowing(true);
	}

	const changeHandler = () => {
		navigate({ pathname: '/user/save-card' })
	}

	const getStateHandler = (e) => {
		let val = e.target.value;
		setCountryname(val)
		stateList({ countryId: val })(dispatch);
	}

	const getCityHandler = (e) => {
		cityList({ countryId: countryname, stateId: e.target.value })(dispatch);
	}


	const getStateHandlerBank = (e) => {
		let vals = e.target.value;
		setCountrynameBank(vals)
		stateDataList({ countryId: vals }, callback => {
			setGetSecState(callback)
		})(dispatch);
	}

	const getCityHandlerBank = (e) => {
		cityData({ countryId: countrynameBank, stateId: e.target.value })(dispatch);
	}

	const onSubmit = (data) => {
		data.billingType = "Bank Account"
		let payload = new FormData();
		payload.append('billingType', data.billingType);
		payload.append('swiftCode', data.swiftCode);
		payload.append('bankAddressLine2', data.bankAddressLine2);
		payload.append('beneficiaryAccountNumber', data.beneficiaryAccountNumber);
		payload.append('stateId', data.stateId);
		payload.append('bankAddressLine1', data.bankAddressLine1);
		payload.append("countryId", data.countryId);
		payload.append("addressLine1", data.addressLine1);
		payload.append("addressLine2", data.addressLine2);
		payload.append("cityId", data.cityId);
		payload.append("bankPostcode", data.bankPostcode);
		payload.append("beneficiaryName", data.beneficiaryName);
		payload.append("bankCountryId", data.bankCountryId);
		payload.append("bankStateId", data.bankStateId);
		payload.append("bankCityId", data.bankCityId);
		payload.append("beneficiaryBankName", data.beneficiaryBankName);
		payload.append("postcode", data.postcode);
		payload.append("sortCode", data.sortCode);
		billingMethod(payload)(dispatch)
	};

	return (
		<section className="billing-method edit">
			<div className="container">
				<Breadcrumb>
					<Breadcrumb.Item href="#">Account</Breadcrumb.Item>
					<Breadcrumb.Item active>Edit Card</Breadcrumb.Item>
				</Breadcrumb>
				<div className="section-header">
					<h1>Edit Card</h1>
				</div>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="card">
						<label className="input-label">Select Billing Method</label>
						<div className="input-wrap radio-group">
							<Form.Check
								inline
								label="Credit Card"
								name="group1"
								type='radio'
								className="radio-btn"
								onChange={primaryRadioChangeHandler}
								id="credit-input"
								defaultChecked='true'
							/>
							<Form.Check
								inline
								label="Bank Account Number"
								name="group1"
								type='radio'
								className="radio-btn"
								onChange={secondaryRadioChangeHandler}
								id="bank-input"
							/>
						</div>
						{isCardInputShowing && <div className='card-inputs'>
							<div className="row">
								<div className="col-md-8 col-lg-5 col-xl-3 fix-width input-block">
									<label>Card Number</label>
									<div className={`input-wrap ${errors.cardNumber ? 'has-error' : ''}`}>
										<input
											type="number"
											className="form-control"
											{...register("cardNumber")}
											placeholder='XXXX XXXX XXXX XXXX'
										/>
									</div>
									{/* {errors.cardNumber && (
        				        	    <span className="error">
        				        	        {errors.cardNumber.message}
        				        	    </span>
        				        	)} */}
								</div>
								<div className="col-8 col-md-2 col-lg-2 col-xl-2 fix-width input-block">
									<label>Month/Year</label>
									<div className={`input-wrap ${errors.expiryDate ? 'has-error' : ''}`}>
										<input
											type="number"
											className="form-control"
											{...register("expiryDate")}
											placeholder='mm/yy'
										/>
									</div>
									{/* {errors.expiryDate && (
        				        	    <span className="error">
        				        	        {errors.expiryDate.message}
        				        	    </span>
        				        	)} */}
								</div>
								<div className="col-4 col-md-2 col-lg-2 col-xl-2 fix-width input-block">
									<label>CVV</label>
									<div className={`input-wrap ${errors.cvv ? 'has-error' : ''}`}>
										<input
											type="number"
											className="form-control"
											{...register("cvv")}
											placeholder='***'
										/>
									</div>
									{/* {errors.cvv && (
        				        	    <span className="error">
        				        	        {errors.cvv.message}
        				        	    </span>
        				        	)} */}
								</div>
							</div>
						</div>}
						{isBankInputShowing && <div className='bank-inputs inputs-wrapper'>
							<div className="input-heading">Beneficiary Information</div>
							<div className="row">
								<div className="col-md-6 input-wrapper required">
									<label>Beneficiary Name</label>
									<div className={`input-wrap ${errors.beneficiaryName ? 'has-error' : ''}`}>
										<input
											type="text"
											className="form-control"
											{...register("beneficiaryName")}
										/>
									</div>
									{errors.beneficiaryName && (
										<span className="error">
											{errors.beneficiaryName.message}
										</span>
									)}
								</div>
								<div className="col-md-6 input-wrapper required">
									<label>Beneficiary Account Number / IBAN</label>
									<div className={`input-wrap ${errors.beneficiaryAccountNumber ? 'has-error' : ''}`}>
										<input
											type="text"
											className="form-control"
											{...register("beneficiaryAccountNumber")}
										/>
									</div>
									{errors.beneficiaryAccountNumber && (
										<span className="error">
											{errors.beneficiaryAccountNumber.message}
										</span>
									)}
								</div>
								<div className="col-md-6 input-wrapper required">
									<label className="input-label margin-fix">address line 1</label>
									<div className={`input-wrap ${errors.addressLine1 ? 'has-error' : ''}`}>
										<input
											type="text"
											className="form-control"
											{...register("addressLine1")}
										/>
										<span className="input-info">Building number and Street</span>
									</div>
									{errors.addressLine1 && (
										<span className="error">
											{errors.addressLine1.message}
										</span>
									)}
								</div>
								<div className="col-md-6 input-wrapper">
									<label className="input-label margin-fix">address line 2</label>
									<div className="input-wrap">
										<input
											type="text"
											{...register("addressLine2")}
											className="form-control"
										/>
										<span className="input-info">Room/Block/Apartments</span>
									</div>
								</div>
								<div className="col-md-6 input-wrapper required">
									<label className="input-label">Country</label>
									<div className="input-wrap">
										<select {...register("countryId")} name="countryId"
											className="form-control" id="countryId"
											onChange={getStateHandler}>
											{
												getCountry?.map(item =>
													<option value={item._id}>{item.name}</option>
												)
											}

										</select>
									</div>
									{errors.countryId && (
										<span className="error">
											{errors.countryId.message}
										</span>
									)}
								</div>
								<div className="col-md-6 input-wrapper required">
									<label className="input-label">State / Region</label>
									<div className="input-wrap">
										<select className="form-control" {...register("stateId")} name="stateId" onChange={getCityHandler}>
											{
												getState?.map(item =>
													<option value={item._id}>{item.name}</option>
												)
											}

										</select>
									</div>
									{errors.stateId && (
										<span className="error">
											{errors.stateId.message}
										</span>
									)}
								</div>
								<div className="col-md-6 input-wrapper">
									<label className="input-label">City</label>
									<div className="input-wrap">
										<select className="form-control" {...register("cityId")} name="cityId">
											{
												getCity?.map(item =>
													<option value={item._id}>{item.name}</option>
												)
											}

										</select>
									</div>
									{errors.cityId && (
										<span className="error">
											{errors.cityId.message}
										</span>
									)}
								</div>
								<div className="col-md-6 input-wrapper required">
									<label className="input-label">ZIP / Postal Code</label>
									<div className={`input-wrap ${errors.postcode ? 'has-error' : ''}`}>
										<input
											type="text"
											{...register("postcode")}
											className="form-control"
											autoComplete="off"
										/>
									</div>
									{errors.postcode && (
										<span className="error">
											{errors.postcode.message}
										</span>
									)}
								</div>
							</div>
							<div className="input-heading">Beneficiary Bank Information</div>
							<div className="row">
								<div className="col-md-6 input-wrapper required">
									<label>Beneficiary Bank Name</label>
									<div className={`input-wrap ${errors.beneficiaryBankName ? 'has-error' : ''}`}>
										<input
											type="text"
											className="form-control"
											{...register("beneficiaryBankName")}
										/>
									</div>
									{errors.beneficiaryBankName && (
										<span className="error">
											{errors.beneficiaryBankName.message}
										</span>
									)}
								</div>
								<div className="col-md-6 input-wrapper">
									<label>Intermediary Bank</label>
									<div className={`input-wrap ${errors.intermediaryBank ? 'has-error' : ''}`}>
										<input
											type="text"
											className="form-control"
											{...register("intermediaryBank")}
										/>
									</div>
									{errors.intermediaryBank && (
										<span className="error">
											{errors.intermediaryBank.message}
										</span>
									)}
								</div>
								<div className="col-md-6 input-wrapper required">
									<label className="input-label">address line 1</label>
									<div className={`input-wrap ${errors.bankAddressLine1 ? 'has-error' : ''}`}>
										<input
											type="text"
											className="form-control"
											{...register("bankAddressLine1")}
										/>
										<span className="input-info">Building number and Street</span>
									</div>
									{errors.bankAddressLine1 && (
										<span className="error">
											{errors.bankAddressLine1.message}
										</span>
									)}
								</div>
								<div className="col-md-6 input-wrapper">
									<label className="input-label">address line 2</label>
									<div className="input-wrap">
										<input
											type="text"
											{...register("bankAddressLine2")}
											className="form-control"
										/>
										<span className="input-info">Room/Block/Apartments</span>
									</div>
								</div>
								<div className="col-md-6 input-wrapper required">
									<label className="input-label">Country</label>
									<div className="input-wrap">
										<select {...register("bankCountryId")} name="bankCountryId"
											className="form-control" id="bankCountryId"
											onChange={getStateHandlerBank}>
											{
												getCountryBank?.map(items =>
													<option value={items._id}>{items.name}</option>
												)
											}

										</select>
									</div>
									{errors.bankCountryId && (
										<span className="error">
											{errors.bankCountryId.message}
										</span>
									)}
								</div>
								<div className="col-md-6 input-wrapper required">
									<label className="input-label">State / Region</label>
									<div className="input-wrap">
										<select className="form-control" {...register("bankStateId")} name="bankStateId" onChange={getCityHandlerBank}>
											{
												getSecState?.map(items =>
													<option value={items._id}>{items.name}</option>
												)
											}

										</select>
									</div>
									{errors.bankStateId && (
										<span className="error">
											{errors.bankStateId.message}
										</span>
									)}
								</div>
								<div className="col-md-6 input-wrapper">
									<label className="input-label">City</label>
									<div className="input-wrap">
										<select className="form-control" {...register("bankCityId")} name="bankCityId">
											{
												getCityBank?.map(items =>
													<option value={items._id}>{items.name}</option>
												)
											}

										</select>
									</div>
									{errors.bankCityId && (
										<span className="error">
											{errors.bankCityId.message}
										</span>
									)}
								</div>
								<div className="col-md-6 input-wrapper required">
									<label className="input-label">ZIP / Postal Code</label>
									<div className={`input-wrap ${errors.bankPostcode ? 'has-error' : ''}`}>
										<input
											type="text"
											{...register("bankPostcode")}
											className="form-control"
											autoComplete="off"
										/>
									</div>
									{errors.bankPostcode && (
										<span className="error">
											{errors.bankPostcode.message}
										</span>
									)}
								</div>
								<div className="col-md-6 input-wrapper required">
									<label className="input-label">Swift Code</label>
									<div className={`input-wrap ${errors.swiftCode ? 'has-error' : ''}`}>
										<input
											type="text"
											{...register("swiftCode")}
											className="form-control"
											autoComplete="off"
										// placeholder='AAAA-BB-CC-123'
										/>
									</div>
									{errors.swiftCode && (
										<span className="error">
											{errors.swiftCode.message}
										</span>
									)}
								</div>
								<div className="col-md-6 input-wrapper required">
									<label className="input-label">Sort Code</label>
									<div className={`input-wrap ${errors.sortCode ? 'has-error' : ''}`}>
										<input
											type="text"
											{...register("sortCode")}
											className="form-control"
											placeholder='12-34-56'
										/>
										<span className="input-info">for US account holders</span>
									</div>
									{errors.sortCode && (
										<span className="error">
											{errors.sortCode.message}
										</span>
									)}
								</div>
							</div>
						</div>}
					</div>
					<div className="btn-wrap">
						<input onClick={changeHandler} className="secondary-btn" type="reset" value="cancel" />
						<input className="btn" type="submit" value="save" />
					</div>
				</form>
			</div>
		</section>
	)
}

export default EditSavedCard

import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNotify } from '../../components/Alert/Alert';
import { verifyEmailAction } from 'store/action/verifyEmailAction';
import { useNavigate, useSearchParams } from 'react-router-dom';
import './verifyEmail.scss'

const VerifyEmail = () => {

    const toast =useNotify();

    const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  console.log("token---->>",token)

    const [email, setEmail] = useState('');
    const dispatch=useDispatch()
    const navigate=useNavigate();
    const [verificationStatus, setVerificationStatus] = useState('');
    const [verifyState, setVerifyState]=useState()
    console.log("verifyState",verifyState)

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

       

        const payload={
            token:token
        }

        verifyEmailAction(payload,dispatch,toast,
            callback=>{
                console.log("calback--->",callback)
                if(callback){
                    
                    navigate('/lab-sign')
                
                }
              }
            // setVerifyState(callback)
        )

            // if(verifyState){
            //     navigate('/lab-sign')
            // }

        // // Send a verification email to the provided email address
        // try {
        //     // Replace this with your actual API call to send a verification email
        //     // Example: const response = await sendVerificationEmail(email);

        //     // For demonstration purposes, simulate a successful email sent
        //     const response = await simulateVerificationEmailSend(email);

        //     if (response.status === 'success') {
        //         setVerificationStatus('Verification email sent. Please check your inbox.');
        //     } else {
        //         setVerificationStatus('Error sending verification email.');
        //     }
        // } catch (error) {
        //     setVerificationStatus('Error sending verification email.');
        // }
    };

    // Simulate sending a verification email (replace this with your actual API call)
    const simulateVerificationEmailSend = async (email) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                // Simulate a successful email send
                resolve({ status: 'success' });

                // Simulate an error (uncomment to test error handling)
                // reject(new Error('Email sending failed'));
            }, 2000); // Simulate a delay
        });
    };
    return (
        <>

            <div className='email-verification'>
                <h2>Please verify your email</h2>
                <form onSubmit={handleSubmit}>
                <div className="btn-wrap">
                    {/* <input className="btn" type="submit" /> */}
                    <button className='btn'>Verfiy Email</button>
                </div>
                </form>
                {/* {verificationStatus && <p>{verificationStatus}</p>} */}
            </div>

        </>
    )
}

export default VerifyEmail
import React, { useEffect, useState } from "react";
import { Breadcrumb, Tabs, Tab } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AddCategory from "components/Seller/AddCategory";
import { categoryList } from "store/action/categoryListAction";
import SellerInfoEdit from "components/Seller/SellerInfoEdit"
import { categoryFinalList } from "store/action/categoryListAction";
import { languageList } from "store/action/languageAction";
import { Alert } from 'components/Alert/Alert'
import ManageDatePopup from "components/Seller/ManageDatePopup";

const SellerInfo = () => {


    const dispatch = useDispatch();
    const param = useParams()

    let history = useNavigate()
    const navigate = useNavigate();
    const user = useSelector((state) => state.profileView);
    const finalCategory = useSelector((state) => state.finalCategoryData);
    console.log("finalCategory", finalCategory)
    const [showAddCategory, setShowAddCategory] = useState(false);
    const [cid, setCid] = useState(param.cid);
    const [showManageData, setShowManageData] = useState(false)

    // console.log("cid22222",cid)


    useEffect(() => {
        let ele = document.getElementById("root")
        ele.scrollIntoView()
        languageList()(dispatch);
        categoryFinalList(callback => {
        })(dispatch);
    }, []);


    useEffect(() => {
        const bodyRef = document.getElementById('body');
        console.log(showAddCategory)
        if (showAddCategory) {
            bodyRef.classList.add('no-scroll');
        } else {
            bodyRef.classList.remove('no-scroll');
        }
    }, [showAddCategory])


    const changePage = () => {
        if (user?.data?.[0]?.type == "Company") {
            setTimeout(() => navigate({ pathname: '/lab-dashboard' }), 500);
        }
        else {
            setTimeout(() => navigate({ pathname: '/lab-dashboard' }), 500);
        }
    };

    const showAddCategoryHandler = () => {
        setShowAddCategory(true);
        categoryList()(dispatch);
    };


    const closeManageDateModel = (status) => {
        setShowManageData(status)
    }

    // console.log('finalCategory', param.cid, finalCategory[0]?.categoryName)


    return (
        <React.Fragment>
            <section className="seller-info">
                <Alert />
                <div className="container">
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={changePage}>Lab Account</Breadcrumb.Item>
                        <Breadcrumb.Item onClick={() => setTimeout(() => navigate({ pathname: '/lab-information' }), 10)}>Lab Information</Breadcrumb.Item>
                        <Breadcrumb.Item active >Edit Lab Information</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="section-header">
                        <div className="header-left">
                            <h1>Edit Lab Information</h1>
                        </div>
                        <div className="header-right">
                            <button className="btn add" onClick={showAddCategoryHandler}>
                                <i className="icon-plus"></i>
                                Add New Categories
                            </button>
                        </div>
                    </div>
                    <Tabs
                        defaultActiveKey={param.cid ? param.cid : finalCategory?.[0]?.categoryName}
                        id="uncontrolled-tab-example"
                        className="mb-3 tab-content"
                        onSelect={(k) => history('/lab-information-edit/' + k)}
                    >
                        {
                            finalCategory?.map((item, i) =>
                                <Tab eventKey={item.categoryName} title={item.categoryName} key={item._id} >
                                    <SellerInfoEdit
                                        item={item}
                                        index={i}
                                    />
                                </Tab>
                            )}
                    </Tabs>
                </div>
            </section>
            {showAddCategory && <AddCategory onCloseModal={setShowAddCategory} />}


            {showManageData && <ManageDatePopup
                // sellerInformationId={item.sellerInformationId}
                onCloseModal={closeManageDateModel}
                // addressId={addressId}
                param={param}
            />}

        </React.Fragment>
    );
};

export default SellerInfo;

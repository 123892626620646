import CancelledOrders from 'components/Orders/CancelledOrders';
import CurrentOrders from 'components/Orders/CurrentOrders';
import PastOrders from 'components/Orders/PastOrders';
import React, { useState, useEffect } from 'react';
import { Breadcrumb, Tabs, Tab } from 'react-bootstrap';
// import { summaryOrder } from 'store/action/orderSummaryAction';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

const Orders = () => {
	const dispatch = useDispatch()

	return <section className='your-orders'>
		<div className="container">
			<Breadcrumb>
				<Breadcrumb.Item href="#">User Account</Breadcrumb.Item>
				<Breadcrumb.Item active>Your Orders</Breadcrumb.Item>
			</Breadcrumb>
			<h1>Your Orders</h1>
			<Tabs defaultActiveKey="pastOrders" id="uncontrolled-tab-example" className="mb-3">
				<Tab eventKey="pastOrders" title="Past Orders">
					<PastOrders />
				</Tab>
				<Tab eventKey="currentOrders" title="Current Orders">
					<CurrentOrders />
				</Tab>
				<Tab eventKey="cancelledOrders" title="Cancelled Orders">
					<CancelledOrders />
				</Tab>
			</Tabs>
		</div>
	</section>;
};

export default Orders;

import React from 'react'

const SellerAccountComplete = () => {
	return (
		<div className='seller-account-complete'>
			<div className="container">
				<div className="card">
					<h2>Congratulations!</h2>
					<p>Your request has been successfully submitted. Our team will get back to you within 24 hours.</p>
					<p>We appreciate your time and patience.</p>
				</div>
			</div>
		</div>
	)
}

export default SellerAccountComplete
import React, { useEffect, useState } from 'react';
import { Breadcrumb, Tabs, Tab } from 'react-bootstrap';
import EditInfo from '../../components/Seller/EditInfo';
import { categoryFinalList } from '../../store/action/categoryListAction'
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from "../../components/Alert/Alert"
import { Link, useNavigate } from 'react-router-dom';

const EditOptionalInformation = () => {
	const navigate = useNavigate();
	let dispatch = useDispatch();

	let categoryList = useSelector(store => store.finalCategoryData);
	const user = useSelector((state) => state.profileView);

	useEffect(() => {
		categoryFinalList(callback => {

		})(dispatch);
	}, [])

	const changeHandler = () => {
		navigate({ pathname: '/seller/optional-info' })
	}

	const handleLink = () => {
		console.log('dataaaaa', user)
		if (user?.data?.[0]?.type == "Company") {
			setTimeout(() => navigate({ pathname: '/seller-dashboard-company' }), 500);
		}
		else {
			setTimeout(() => navigate({ pathname: '/seller-dashboard-freelancer' }), 500);
		}
	}

	return (
		<section className='edit-information'>
			<Alert />
			<div className="container">
				<Breadcrumb>
					<Breadcrumb.Item onClick={handleLink}>Seller account</Breadcrumb.Item>
					<Breadcrumb.Item onClick={changeHandler}>Optional Information</Breadcrumb.Item>
					<Breadcrumb.Item active>Edit Optional Information</Breadcrumb.Item>
				</Breadcrumb>
				<div className="section-header">
					<h1>Edit Optional Information</h1>
				</div>
				<Tabs defaultActiveKey={categoryList[0] && categoryList[0].categoryName} id="uncontrolled-tab-example" className="mb-3">
					{
						categoryList?.map(item =>
							<Tab eventKey={item.categoryName} key={Math.random()} title={item.categoryName}>
								<EditInfo sellerInformationId={item.sellerInformationId} />
							</Tab>
						)
					}
					{/* <Tab eventKey="wedding" title="Wedding Services">
					 	No data
					</Tab> */}
				</Tabs>
			</div>
		</section>
	)
}

export default EditOptionalInformation

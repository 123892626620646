import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { loginAsAdmin } from 'store/action/profileAction';
// import { loginAsAdmin } from 'store/action/profileAction';

const LoginAsAdmin = () => {
    const {id} = useParams();
    const navigate = useNavigate()
    useEffect(()=>{
        loginAsAdmin({sellerId: id},navigate)
    },[id])
  return (
    <div style={{position:"fixed", top:"0", left:"0", width:"100%",height:"100%",background:"#ffffff",zIndex:"9999"}}></div>
  )
}

export default LoginAsAdmin
import React, { useState, useEffect } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { sellerNotification } from 'store/action/notificationAction';
import { useDispatch, useSelector } from 'react-redux'
import { readSingleNotification } from 'store/action/readNotificationAction';
import { useNavigate } from "react-router-dom";

const SellerNotification = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch()
    const getSellerNotify = useSelector(state => state.sellerNotify)
    console.log("getSellerNotify", getSellerNotify)
    const [value, onChange] = useState(new Date());
    const date = new Date()
    const yest = new Date().getDate() - 1
    const login = useSelector((state) => state.login);
    const getProfileInfo = useSelector(state => state.profileView)

    useEffect(() => {
        sellerNotification()(dispatch)
    }, [])

    const [todayState, setTodayState] = useState(false)
    const [oldState, setOldState] = useState(false)
    const [yesterdaystate, setYesterdayState] = useState(false)


    useEffect(() => {
        let yestTemp = false;
        getSellerNotify?.data?.forEach((item) => {
            if (new Date(item.createdAt).getDate() + "/" + new Date(item.createdAt).getMonth() === yest + "/" + date.getMonth()) {
                yestTemp = true
            }
        }
        )
        if (yestTemp) {
            setYesterdayState(true)
        }
    }, [getSellerNotify])

    useEffect(() => {
        let todayTemp = false;
        getSellerNotify?.data?.forEach((item) => {
            if (new Date(item.createdAt).getDate() + "/" + new Date(item.createdAt).getMonth() === date.getDate() + "/" + date.getMonth()) {
                todayTemp = true
            }
        })
        console.log("todayTemp", todayTemp)
        if (todayTemp) {
            setTodayState(true)
        }
    }, [getSellerNotify])

    useEffect(()=>{
let oldTemp=false;
getSellerNotify?.data?.forEach((item)=>{
    if(new Date(item.createdAt).getDate() + "/" + new Date(item.createdAt).getMonth() < yest + "/" + date.getMonth()){
        oldTemp=true;
    }

    if(oldTemp){
        setOldState(true)
    }
})
    },[getSellerNotify])



    const handleWishList = (e, item) => {
        if (item.readStatus === false) {
            let payload = {
                notificationId: item._id
            };
            readSingleNotification(payload)(dispatch)
        }

        if (item.messageType === 2) {
            if (getProfileInfo.data[0].type === "Company") {
                navigate("/seller-dashboard-company");
            } else if (getProfileInfo.data[0].type === "Freelancer") {
                navigate("/seller-dashboard-freelancer");
            }
        }

        if (item.messageType === 3) {
            navigate("/lab-information");
        }

        if (item.messageType === 4) {
            if (getProfileInfo.data[0].type === "Company") {
                navigate("/seller-dashboard-company");
            } else if (getProfileInfo.data[0].type === "Freelancer") {
                navigate("/seller-dashboard-freelancer");
            }
        }
    }

    function timeSince(ndate) {
        let date = new Date(ndate);
        var seconds = Math.floor((new Date() - date) / 1000);

        var interval = seconds / 31536000;

        if (interval > 1) {
            return Math.floor(interval) + " years ago";
        }
        interval = seconds / 2592000;
        if (interval > 1) {
            return Math.floor(interval) + " months ago";
        }
        interval = seconds / 86400;
        if (interval > 1) {
            return Math.floor(interval) + " days ago";
        }
        interval = seconds / 3600;
        if (interval > 1) {
            return Math.floor(interval) + " hours ago";
        }
        interval = seconds / 60;
        if (interval > 1) {
            return Math.floor(interval) + " minutes ago";
        }

        return Math.floor(seconds) + " seconds ago";
    }


    return (
        <section className='notifications'>
            <div className="container">
                <Breadcrumb>
                    <Breadcrumb.Item href="/lab-dashboard">Lab Account</Breadcrumb.Item>
                    <Breadcrumb.Item active>Notification</Breadcrumb.Item>
                </Breadcrumb>
                <h1>Notification</h1>
                {todayState && <div className="notification-date">Today</div>}
                {getSellerNotify?.data?.map(item =>
                    new Date(item.createdAt).getDate() + "/" + new Date(item.createdAt).getMonth() === date.getDate() + "/" + date.getMonth() ?

                        <>
                            <div className={item.readStatus ? "notification-wrap" : "notification-wrap unread"} onClick={(e) => handleWishList(e, item)}>
                                <div className="close-wrap">
                                    <button type='button'>
                                        <i className="icon-cross"></i>
                                    </button>
                                </div>
                                <div className="notification-heading">{item.title}</div>
                                {/* <div className="notification-details">
                                    <p>{item.message}<button type='button'>Read more</button></p>
                                </div> */}
                                <div className="time-wrap">{timeSince(item.createdAt)}</div>
                            </div>
                        </>
                        :
                        null
                )}

                {yesterdaystate && <div className="notification-date">Yesterday</div>}
                {getSellerNotify?.data?.map(item =>
                    new Date(item.createdAt).getDate() + "/" + new Date(item.createdAt).getMonth() === yest + "/" + date.getMonth() ?

                        <div className={item.readStatus ? "notification-wrap" : "notification-wrap unread"} onClick={() => handleWishList(item)}>
                            {console.log('yesterday', yest)}
                            <div className="close-wrap">
                                <button type='button'>
                                    <i className="icon-cross"></i>
                                </button>
                            </div>
                            <div className="notification-heading">{item.title}</div>
                            {/* <div className="notification-details">
                                <p>{item.message}<button type='button'>Read more</button></p>
                            </div> */}
                            <div className="time-wrap">{timeSince(item.createdAt)}</div>
                        </div>
                        :
                        null
                )}

               { oldState && <div className="notification-date m-fix">Old Notification</div>}
                {getSellerNotify?.data?.map(item =>
                    new Date(item.createdAt).getDate() + "/" + new Date(item.createdAt).getMonth() < yest + "/" + date.getMonth() ?
                        <div className={item.readStatus ? "notification-wrap" : "notification-wrap unread"} onClick={(e) => handleWishList(e, item)}>
                            <div className="close-wrap">
                                <button type='button'>
                                    <i className="icon-cross"></i>
                                </button>
                            </div>
                            <div className="notification-heading">{item.title}</div>
                            <div className="notification-details">
                                <p> {item.message}</p>
                                {/* <p>Lorem ipsum. In publishing and graphic design, lorem ipsum is a placeholder text used to demonstrate the visual form of a document without relying on meaningful content (also called greeking. to demonstrate the visual form of a document without relying...<button type='button'>Less</button></p> */}
                            </div>
                            <div className="time-wrap">{timeSince(item.createdAt)}</div>
                        </div>
                        :
                        null
                )}
            </div>
        </section>
    )
}

export default SellerNotification

import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Breadcrumb, Tabs, Tab } from 'react-bootstrap';
import Entertainment from 'components/OptionalInformation/Entertainment';
import { categoryFinalList } from '../../store/action/categoryListAction'
import { useDispatch, useSelector } from 'react-redux';


const OptionalInformation = () => {
	let dispatch = useDispatch();
	let history = useNavigate()
	const navigate = useNavigate();

	let categoryList = useSelector(store => store.finalCategoryData);
	const user = useSelector((state) => state.profileView);

	const editInfoHandler = () => {
		// setEditInfo(true);
		history('/seller/optional-info/edit')
	  };

	useEffect(() => {
		categoryFinalList(callback => {
			if (callback.length === 0) {
			  editInfoHandler()
			}
		  })(dispatch);
	}, [])

	const handleLink = () => {
		console.log('dataaaaa', user)
		if (user?.data?.[0]?.type == "Company") {
			setTimeout(() => navigate({ pathname: '/seller-dashboard-company' }), 500);
		}
		else {
			setTimeout(() => navigate({ pathname: '/seller-dashboard-freelancer' }), 500);
		}
	}

	return (
		<section className='seller-optional-info'>
			<div className="container">
				<Breadcrumb>
					<Breadcrumb.Item onClick={handleLink}>Seller account</Breadcrumb.Item>
					<Breadcrumb.Item active>Optional Information</Breadcrumb.Item>
				</Breadcrumb>
				<div className="section-header">
					<div className="header-left">
						<h1>Optional Information</h1>
					</div>
					<div className="header-right">
						<Link to='/seller/optional-info/edit' className='btn'>Edit</Link>
					</div>
				</div>

				<Tabs defaultActiveKey={categoryList[0] && categoryList[0].categoryName} id="uncontrolled-tab-example" className="mb-3">
					{
						categoryList?.map(item =>
							<Tab eventKey={item.categoryName} key={Math.random()} title={item.categoryName}>
								<Entertainment sellerInformationId={item.sellerInformationId} />
							</Tab>
						)
					}

				</Tabs>
			</div>
		</section>
	)
}

export default OptionalInformation

import React, { useState, useEffect } from 'react';
import { Breadcrumb, Tabs, Tab } from 'react-bootstrap';
import Slider from 'react-slick';
import { Link, useParams, useNavigate } from 'react-router-dom';
// import Rating from 'assets/images/rating.png';
import {
    getServiceDetail,
    getOtherService,
    getReviewList,
    addReview,
    getRecommendedList
} from '../../store/action/ServiceDetailAction'
import { useDispatch, useSelector } from 'react-redux';
import ReviewCard from 'components/ServiceDetail/ReviewList'
import DescTab from 'components/ServiceDetail/DescTab'
import GalleryTab from 'components/ServiceDetail/GalleryTab'
import OtherServiceList from 'components/ServiceDetail/OtherServiceList'
import RecommdedService from 'components/ServiceDetail/RecommdedService'
import Rating from 'react-rating';
import starEmpty from 'assets/images/icons/icon-star-empty.svg';
import starFilled from 'assets/images/icons/icon-star-2.svg';
import { Alert } from 'components/Alert/Alert'

const Listing = () => {
    let dispatch = useDispatch();
    let params = useParams();
    let navigate = useNavigate();
    let serviceDetail = useSelector(store => store.sellerServiceDetail);
    console.log('serviceDetail', serviceDetail)
    let profile = useSelector(store => store.profileView);
    let login = useSelector(store => store.login);


    const [reviewText, setReviewText] = useState("");
    const [userstar, setUserstate] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(5);
    const indexOfLastTodo = page * limit;
    const indexOfFirstTodo = indexOfLastTodo - limit;


    useEffect(() => {
        getServiceDetail(params.id, callback => {
            let payload = {
                categoryId: callback.categoryId,
                subcategoryId: callback.subcategoryId,
                serviceId: callback._id,
            }
            getRecommendedList(payload)(dispatch)
        })(dispatch)
        getOtherService(params)(dispatch)
        setTimeout(() => getReviewList(params.id)(dispatch), 1000)
    }, [params])

    const reviewSubmit = (e) => {
        e.preventDefault();
        if (!login.islogin) {
            navigate('/sign-in')
        } else {
            if (userstar === 0) {
                alert("Please rate the service")
            } else {
                let payload = {
                    serviceId: params.id,
                    rating: userstar,
                    review: reviewText
                }
                console.log('patload', payload)
                addReview(payload, callback => {
                    setReviewText("")
                    setUserstate(0)
                    getReviewList(params.id)(dispatch)
                })(dispatch);
            }
        }
    }

    const clickPrev = (index) => {
        setPage(index - 1);
    }

    const clickNext = (index) => {
        setPage(index + 1);
    }

    const clickPagination = (index) => {
        setPage(index);
    }

    const ratingClickHandler = (val) => {
        setUserstate(val)
    }

    let settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };


    return (
        <section className='edit-information profile listing'>
            <Alert />
            <div className="container">
                <Breadcrumb>
                    <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="#">Entertainment</Breadcrumb.Item>
                    <Breadcrumb.Item href="#">Photographers</Breadcrumb.Item>
                    <Breadcrumb.Item active>Photo Session Media Entertainment</Breadcrumb.Item>
                </Breadcrumb>
                <div className='tabbing-head listing'>
                    <div className='tabbing-select'>
                        <Link className='profile-link' to={`/seller-profile/${serviceDetail?.data?.sellerId}/${serviceDetail?.data?.categoryId}`}>
                            <i className='icon-arrow'></i>
                            Go to Profile
                        </Link>
                    </div>
                    <Tabs defaultActiveKey="description" id="uncontrolled-tab-example" className="tabs-wrap">
                        <Tab eventKey="description" title="Description">
                            <DescTab serviceDetail={serviceDetail} />
                        </Tab >
                        <Tab eventKey="gallery" title="Gallery">
                            <GalleryTab serviceDetail={serviceDetail} />
                        </Tab>
                    </Tabs >
                </div >
                {
                    serviceDetail?.otherServiceList?.length > 0 ?
                        <div className='simple-sliders'>
                            <div className='slider-heading'>
                                <h2>Other Services from This Seller</h2>
                            </div>
                            <div className='slider-wrap'>
                                <Slider {...settings}>
                                    {
                                        serviceDetail?.otherServiceList?.map(item =>
                                            <OtherServiceList item={item} key={Math.random()} />
                                        )
                                    }
                                </Slider>
                            </div>
                        </div>
                        : null
                }

                <div className='simple-sliders recommend'>
                    <div className='slider-heading'>
                        <h2>Recommended Services</h2>
                    </div>
                    <div className='slider-wrap'>
                        <Slider {...settings}>
                            {
                                serviceDetail?.recommendedList?.map(item =>
                                    <RecommdedService item={item} key={Math.random()} />
                                )
                            }
                        </Slider >
                    </div >
                </div >
                <div className='reviews'>
                    {!serviceDetail?.data?.averageRating && !serviceDetail?.data?.totalReview
                        ?
                        <div className='review-head'>
                            <h2>Reviews</h2>
                        </div>
                        :
                        null}
                    <div className='reviews-wrap'>
                        {
                            serviceDetail.reviewList?.slice(indexOfFirstTodo, indexOfLastTodo).map(item =>
                                <ReviewCard
                                    key={Math.random()}
                                    item={item}
                                />
                            )
                        }
                    </div >

                    {
                        serviceDetail.reviewList?.length > 0 &&
                        <nav className='pagination-wrap' aria-label="Page navigation example">
                            <ul className="pagination">
                                <li className={page === 1 ? "page-item previous disabled" : "page-item previous"} onClick={() => clickPrev(page)}>
                                    <a
                                        className="page-link"
                                        href="javascript:void(0)"
                                        tabIndex="-1"

                                    >
                                        <i className="icon-arrow"></i>
                                    </a>
                                </li>
                                {
                                    [...Array(Math.ceil(serviceDetail.reviewList.length / 5))].map((item, i) =>
                                        <li key={Math.random()} className={i + 1 === page ? "page-item active" : "page-item"} aria-current="page">
                                            <a className="page-link" href="javascript:void(0)" onClick={() => clickPagination(i + 1)}>
                                                {i + 1}
                                            </a>
                                        </li>
                                    )
                                }


                                <li className={page === Math.ceil(serviceDetail.reviewList.length / 5) ? "page-item  disabled" : "page-item "}>
                                    <a className="page-link" href="javascript:void(0)" onClick={() => clickNext(page)}>
                                        <i className="icon-arrow"></i>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    }



                    {!serviceDetail?.data?.averageRating && !serviceDetail?.data?.totalReview
                        ?
                        < div className='add-review' >

                            <div className='review-logo'>
                                <div className="text-image">{profile?.data?.[0]?.firstName?.[0]}</div>
                            </div>
                            {/* :
                            null} */}
                            <div className='add-form'>
                                {/* {!serviceDetail?.data?.averageRating && !serviceDetail?.data?.totalReview
                                ? */}
                                <form>
                                    <div className='form-wrap'>
                                        <div className='textarea-wrap'>
                                            <textarea value={reviewText} onChange={(e) => setReviewText(e.target.value)} className='textarea' placeholder='Write a review'></textarea>
                                        </div>
                                        <div className='rate-service'>
                                            <h4>Rate the service:</h4>
                                            <div className='rate-image'>
                                                <div className='rating-image'>
                                                    <Rating
                                                        placeholderRating={userstar}
                                                        emptySymbol={<img src={starEmpty} className="icon" />}
                                                        placeholderSymbol={<img src={starFilled} className="icon" />}
                                                        fullSymbol={<img src={starFilled} className="icon" />}
                                                        onClick={ratingClickHandler}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <input type="submit" onClick={reviewSubmit} value="Add a Review" className='btn' />
                                </form>
                                {/* :
                            null
                         } */}
                            </div>
                        </div >
                        :
                        null}
                </div >
            </div >
        </section >
    )
}

export default Listing

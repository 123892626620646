import { endPoints } from "../../utils/endPoints";
import { axiosInstanceWithUserToken } from "../../utils/Axios";
import * as actiontype from "../actionType";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addToCart } from 'store/action/ServiceDetailAction'
import { stringify } from "query-string";







export const loginAction = (data, time) => (dispatch) => {

    // Get the current date and time
    const currentDateTime = new Date();

    // Format the date and time as a string
    const formattedDateTime = currentDateTime.toLocaleString();

    // Store the formatted date and time in localStorage
    localStorage.setItem('lastLoginTime', formattedDateTime);

    // Retrieve and display the stored date and time
    const storedDateTime = localStorage.getItem('lastLoginTime');
    console.log('Stored Date and Time:', storedDateTime);

    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken
        .post(endPoints.LOGIN, data, {
        })
        .then(async (response) => {
            dispatch({ type: "LOADERSTOP" })
            console.log("data1111111", data)
            if (response?.data?.status === "success") {
                JSON.stringify(localStorage.setItem("keepMeLogin", data && data.keep_me_signed_in === true ? "1" : "0"));
                if (response.data.data.type === "User") {
                    localStorage.setItem("user_token", response?.data.data.token);
                    let cart = JSON.parse(localStorage.getItem("ala_cart"));
                    if (cart) {
                        cart.forEach(item => {
                            let payload = {
                                serviceId: item.serviceId,
                                quantity: item.quantity
                            }
                            addToCart(payload)(dispatch);
                        })
                    }

                    setTimeout(() => {
                        localStorage.removeItem("ala_cart");
                        toast.success(response?.data?.message)
                        dispatch({
                            type: actiontype.LOGIN_USER,
                            payload: {
                                islogin: true,
                                type: response.data.data.type,
                            },
                        });
                    }, 5)
                } else {
                    toast.success(response?.data?.message)
                    dispatch({
                        type: actiontype.LOGIN_USER,
                        payload: {
                            islogin: true,
                            type: response.data.data.type,
                        },
                    });
                    localStorage.setItem("user_token", response?.data.data.token);
                }
            } else {
                toast.error(response?.data?.message);
            }
        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
            toast.error("error")
        });
};

export const sellerLoginAction = (data, time) => (dispatch) => {

    // Get the current date and time
    const currentDateTime = new Date();

    // Format the date and time as a string
    const formattedDateTime = currentDateTime.toLocaleString();

    // Store the formatted date and time in localStorage
    localStorage.setItem('lastLoginTime', formattedDateTime);

    // Retrieve and display the stored date and time
    const storedDateTime = localStorage.getItem('lastLoginTime');
    console.log('Stored Date and Time:', storedDateTime);

    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken
        .post(endPoints.SELLER_LOGIN, data, {
        })
        .then(async (response) => {
            dispatch({ type: "LOADERSTOP" })
            console.log("data1111111", data)
            if (response?.data?.status === "success") {
                JSON.stringify(localStorage.setItem("keepMeLogin", data && data.keep_me_signed_in === true ? "1" : "0"));
                if (response.data.data.type === "User") {
                    localStorage.setItem("user_token", response?.data.data.token);
                    let cart = JSON.parse(localStorage.getItem("ala_cart"));
                    if (cart) {
                        cart.forEach(item => {
                            let payload = {
                                serviceId: item.serviceId,
                                quantity: item.quantity
                            }
                            addToCart(payload)(dispatch);
                        })
                    }

                    setTimeout(() => {
                        localStorage.removeItem("ala_cart");
                        toast.success(response?.data?.message)
                        dispatch({
                            type: actiontype.LOGIN_USER,
                            payload: {
                                islogin: true,
                                type: response.data.data.type,
                            },
                        });
                    }, 5)
                } else {
                    toast.success(response?.data?.message)
                    dispatch({
                        type: actiontype.LOGIN_USER,
                        payload: {
                            islogin: true,
                            type: response.data.data.type,
                        },
                    });
                    localStorage.setItem("user_token", response?.data.data.token);
                }
            } else {
                toast.error(response?.data?.message);
            }
        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
            toast.error("error")
        });
};

export const autoLogoutAction = (lastLoginTime, dispatch) => {
    // dispatch({
    //     type: actiontype.AUTO_LOGOUT,
    //     payload: {
    //         islogin: true,
    //         type: response.data.data.type,
    //     },
    // });
}

export const userMobileValid = (dispatch, payload, setIsOtp,toast, setSendOTPEnable, handleSeconds, setShowTimer) => {
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken
        .post(endPoints.USER_MOBILE_VALID, payload, {
        })
        .then((response) => {
            dispatch({ type: "LOADERSTOP" })
            setIsOtp(true)
            setSendOTPEnable(false)
            handleSeconds()
            setShowTimer(true)
            toast.success(response?.data?.message)
        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
            toast.error(errResponse?.response?.data?.message)
        });
}

export const userMobileValidOtp = (dispatch, payload, setIsOtp,toast, setShowTimer, setResendEnable, setPhoneFieldDisabled) => {
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken
        .post(endPoints.USER_MOBILE_VALID_OTP, payload, {
        })
        .then((response) => {
            dispatch({ type: "LOADERSTOP" })
            setIsOtp(false)
            setShowTimer(false)
            setResendEnable(false)
            setPhoneFieldDisabled(true)
            toast.success(response?.data?.message)
        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
            toast.error(errResponse?.response?.data?.message)
        });
}
import * as actiontype from "../actionType";
import { endPoints } from "../../utils/endPoints";
import { axiosInstanceWithUserToken } from "../../utils/Axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const editBanner = (data) => (dispatch) => {
    let token = localStorage.getItem("user_token");
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken
        .post(endPoints.ADVERTISER_EDIT_BANNER, data, {
            headers: {
                Authorization: token
            },
        })
        .then(async (response) => {
            dispatch({ type: "LOADERSTOP" })
            if (response?.data?.status === "success") {
                toast.success(response?.data?.message);
                dispatch({
                    type: actiontype.LOGIN_USER,
                    payload: {
                        islogin: true
                    },
                });
            } else {
                console.log("wrong")
                toast.error(response?.data?.message);
            }
        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
            toast.error("error")
        });
};

export const getBannerDetail = (data, callback) => (dispatch) => {

    let token = localStorage.getItem("user_token");
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken
        .post(endPoints.BANNER_DETAIL, data, {
            headers: {
                Authorization: token
            },
        })
        .then(async (response) => {
            dispatch({ type: "LOADERSTOP" })
            if (response?.data?.status === "success") {
                // toast.success(response?.data?.message);
                callback(response?.data.data)
            } else {
                console.log("wrong")
                toast.error(response?.data?.message);
            }
        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
            toast.error("error")
        });
};

export const deleteBanner = (data, callback) => (dispatch) => {

    let token = localStorage.getItem("user_token");
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken
        .post(endPoints.BANNER_DELETE, data, {
            headers: {
                Authorization: token
            },
        })
        .then(async (response) => {
            dispatch({ type: "LOADERSTOP" })
            if (response?.data?.status === "success") {
                toast.success(response?.data?.message);
                callback(true)
            } else {
                console.log("wrong")
                toast.error(response?.data?.message);
            }
        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
            toast.error("error")
        });
};
// import * as actiontype from "../../actionsTypes";
import { endPoints } from "../../utils/endPoints";
import { axiosInstanceWithUserToken } from "../../utils/Axios";
import * as actiontype from "../actionType";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const userReg = (data, xsrfToken) => (dispatch) => {
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken
        .post(endPoints.USER_SIGN_UP, data, {
            headers: {
                Authorization: ``,
            },
        })
        .then(async (response) => {
            dispatch({ type: "LOADERSTOP" })
            console.log('response', response.data)
            if (response?.data?.status === "success") {
                toast.success(response?.data?.message);
                dispatch({
                    type: actiontype.LOGIN_USER,
                    payload: {
                        islogin: true,
                        status: response.data.data.status,
                    },
                });
                localStorage.setItem("user_token", response?.data.data.token);
                // return
                setTimeout(() => (window.location.href = "/user/dashboard"), 2000);
            } else {
                console.log("wrong");
                toast.error(response?.data?.message);
            }
        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
            console.log("success respoerrResponsese", errResponse);
        });
};

export default userReg;

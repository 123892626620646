import * as actiontype from "../actionType";
import { endPoints } from "../../utils/endPoints";
import { axiosInstanceWithUserToken } from "../../utils/Axios";
import { useNotify } from "../../components/Alert/Alert";


export const getServiceDetail = (data, callback) => (dispatch) => {

    let token = localStorage.getItem("user_token");
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken.post(endPoints.SELLER_SERVICE_DETAIL, data, {
        headers: {
            Authorization: token,
        },
    })
        .then(response => {
            dispatch({ type: "LOADERSTOP" })
            callback(response.data.data)

        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
            // toast.error("error")
            console.log("success respoerrResponsese", errResponse)
        });
};

export const getAddressList = (data, callback) => (dispatch) => {

    console.log("sandeep3333",data)
    // let toast = useNotify();
    let token = localStorage.getItem("user_token");
    dispatch({ type: "LOADER" })
    if (callback) {
        
        callback([])
    }

    axiosInstanceWithUserToken
        .post(endPoints.GET_SELLER_INFO_ADDRESS, data, {
            headers: {
                Authorization: token,
            },

        })
        .then(async (response) => {
            dispatch({ type: "LOADERSTOP" })
            if (response?.data?.status === "success") {
               
                    // toast.success(response?.data?.message)
                callback(response?.data?.data)
            } else {
                callback([])
                // toast.error(response?.data?.message);
            }
            
            if (response?.data?.status === "error") {
                console.log("response----->1111")
                // toast.error(response?.data?.me)
            }
            
        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
            console.log("errorrrrr2222")
            if (errResponse?.response?.data?.status === "error") {
                // toast.error(errResponse?.response?.data?.message);
            }
            callback([])

        });
        
    };
    



export const editSellerrServiceAdd = (data, callback) => (dispatch) => {
    let toast = useNotify();
    let token = localStorage.getItem("user_token");
    axiosInstanceWithUserToken
        .post(endPoints.SELLER_EDIT_SERVICE, data, {
            headers: {
                Authorization: token,
            },

        })
        .then(async (response) => {
            if (response?.data?.status === "success") {
                toast.success(response?.data?.message)
                callback(true)
            } else {
                toast.error(response?.data?.message);
                callback(false)
            }

        })
        .catch((errResponse) => {
            if (errResponse?.response?.data?.status === "error") {
                toast.error(errResponse?.response?.data?.message);
            }

        });

};



export const sellerrServiceAdd = (data, callback) => (dispatch) => {
    // console.log("data---->",data)
    let toast = useNotify();
    let token = localStorage.getItem("user_token");
    axiosInstanceWithUserToken
        .post(endPoints.SELLER_ADD_SERVICE, data, {
            headers: {
                Authorization: token,
            },

        })
        .then(async (response) => {
            if (response?.data?.status === "success") {
                toast.success(response?.data?.message)
                callback(true)
            } else {
                toast.error(response?.data?.message);
                callback(false)
            }

        })
        .catch((errResponse) => {
            if (errResponse?.response?.data?.status === "error") {
                toast.error(errResponse?.response?.data?.message);
            }

        });

};
export const sellerrServiceCategory = (data) => (dispatch) => {

    let toast = useNotify();
    // loadingAction(true)(dispatch);

    // console.log('yyyy', localStorage.getItem("user_token"))
    let token = localStorage.getItem("user_token");
    axiosInstanceWithUserToken
        .get(endPoints.SHOW_CATEGORY_LIST, {
            headers: {
                Authorization: token,
            },

        })
        .then(async (response) => {

            if (response?.data?.status === "success") {
                // toast.success(response?.data?.message)
                dispatch({
                    type: actiontype.SELLER_CATEGORY_GET,
                    payload: response.data.data
                });
            } else {
                toast.error(response?.data?.message);
            }

        })
        .catch((errResponse) => {
            if (errResponse?.response?.data?.status === "error") {
                toast.error(errResponse?.response?.data?.message);
            }

        });

};

export const sellerrServiceSubCategory = (data) => (dispatch) => {
    let toast = useNotify();
    let token = localStorage.getItem("user_token");
    axiosInstanceWithUserToken
        .post(endPoints.SELLER_SUBCATEGORY_GET, data, {
            headers: {
                Authorization: token,
            },

        })
        .then(async (response) => {
            if (response?.data?.status === "success") {
                // console.log('ggggg',response.data)
                // toast.success(response?.data?.message)
                dispatch({
                    type: actiontype.SELLER_GET_SUBCATEGORY,
                    payload: response.data.data
                });
            } else {
                toast.error(response?.data?.message);
            }

        })
        .catch((errResponse) => {
            if (errResponse?.response?.data?.status === "error") {
                toast.error(errResponse?.response?.data?.message);
            }

        });

};


export const sellerMediaUpload = (data) => (dispatch) => {
    let toast = useNotify();
    let token = localStorage.getItem("user_token");
    axiosInstanceWithUserToken
        .post(endPoints.SELLER_MEDIA_UPLOAD, data, {
            headers: {
                Authorization: token,
            },

        })
        .then(async (response) => {
            if (response?.data?.status === "success") {
                // console.log('ggggg',response.data)
                toast.success(response?.data?.message)
                dispatch({
                    type: actiontype.SELLER_UPLOAD_MEDIA,
                    payload: response.data.data
                });
            } else {
                toast.error(response?.data?.message);
            }

        })
        .catch((errResponse) => {
            if (errResponse?.response?.data?.status === "error") {
                toast.error(errResponse?.response?.data?.message);
            }

        });

};


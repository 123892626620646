import * as actiontype from "../actionType";
import { endPoints } from "../../utils/endPoints";
import { axiosInstanceWithUserToken } from "../../utils/Axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const categoryList = (data) => (dispatch) => {

    let token = localStorage.getItem("user_token");
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken.post(endPoints.CATEGORY_LIST, data, {
        headers: {
            Authorization: token,
            // _csrf:xsrfToken
        },
    })
        .then(response => {
            dispatch({ type: "LOADERSTOP" })
           
            dispatch({
                type: actiontype.SET_CATEGORY,
                payload: response.data.data
            })
        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
            // toast.error("error")
            console.log("success respoerrResponsese", errResponse)
        });
};

const categoryFinalList = (callback) => (dispatch) => {
    let token = localStorage.getItem("user_token");
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken.get(endPoints.SHOW_CATEGORY_LIST, {
        headers: {
            Authorization: token
        },
    })
        .then(response => {
            dispatch({ type: "LOADERSTOP" })
            console.log("response-->",response)
            if (response.data.status === "success") {
                dispatch({
                    type: actiontype.CATEGORY_NEW_LIST,
                    payload: response.data.data
                })
                callback(response.data.data)
            } else {
                dispatch({
                    type: actiontype.CATEGORY_NEW_LIST,
                    payload: []
                })
                callback([])
            }


        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
            // toast.error("error")
            console.log("success respoerrResponsese", errResponse)
        });
};

export { categoryList, categoryFinalList };

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
    firstName: yup
        .string()
        .required("Please enter First Name")
        .min(2, "First name must be at least 2 characters")
        .max(20, "First name must be at most 20 characters")
        .matches(/^[A-Za-z]+$/i, "Please enter valid first name"),
    lastName: yup
        .string()
        .required("Please enter Last Name")
        .min(2, "Last name must be at least 2 characters")
        .max(20, "Last name must be at most 20 characters")
        .matches(/^[A-Za-z]+$/i, "Please enter valid last name"),
    email: yup
        .string()
        .required("Please enter your email address")
        .matches(
            /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            "Please use the correct email"
        ),
    businessName: yup
        .string()
        .required("Please enter Business Name")
        .min(2, "Business name must be at least 2 characters")
        .max(20, "Business name must be at most 20 characters"),
    // .matches(/^[A-Za-z]+$/i, "Please enter valid Business name"),
    primaryContactPerson: yup
        .string()
        .required("Please enter Primary Contact Person")
        .min(2, "Primary contact person must be at least 2 characters")
        .max(20, "Primary contact person must be at most 20 characters")
        .matches(/^[A-Za-z]+$/i, "Please enter valid primary contact person"),
    companyRegistrationNumber: yup
        .string()
        .required("Please enter Company Register Number")
        .matches(/^[0-9]*$/, "Company Register Number is not valid"),
    vat: yup
        .string()
        .required("Please enter Company Register Number")
        .matches(/^[0-9]*$/, "Company Register Number is not valid"),
    addressLine1: yup
        .string()
        .required("Please enter Address")
        .min(2, "Address must be at least 2 characters")
        .max(20, "Address must be at most 20 characters")
        .matches(/^[A-Za-z]+$/i, "Please enter valid Address"),
    addressSecond: yup
        .string(),
    city: yup
        .string(),
    // .required("Please enter City")
    // .min(2, "City must be at least 2 characters")
    // .max(20, "City must be at most 20 characters")
    // .matches(/^[A-Za-z]+$/i, "Please enter valid City"),
    stateId: yup
        .string(),
    countryId: yup
        .string(),
    phone: yup
        .string()
        .required("Please enter mobile number")
        .matches(/^[0-9]*$/, "Phone number is not valid")
        .min(8)
        .max(10),
    postcode: yup
        .string()
        .required("Please enter Zip")
        .matches(/^[0-9a-zA-Z]+$/, "Zip code is not valid")
        .min(3),
    comment: yup
        .string()
        .required("Please enter Comment")
        .matches(/^[A-Za-z]+$/i, "Comment is not valid")
        .min(2)
        .max(300),
    password: yup
        .string()
        .required("Please enter the password")
        .min(8, "Password length should be greater than 8"),
    re_password: yup
        .string()
        .required("Please enter the password")
        .min(8, "Password length should be greater than 8")
        .oneOf([yup.ref('password'), null], 'Confirm Password must be same as New Password'),
});

const PasswordResetSuccess = () => {

    const { register, formState: { errors }, handleSubmit, reset } = useForm({
        mode: "onSubmit",
        resolver: yupResolver(schema),
    });

    const onSubmit = (data) => {
        console.log('data', data)
    };
    return (
        <section className='forgot-password success'>
            <div className="container">
                <div className="card">
                    <div className="wrap-icon">
                        <div className="icon-success"></div>
                    </div>
                    <h2>Password Reset</h2>
                    <div className="reset-info">Your password has been successfully reset.<br />Click below to login magically</div>
                    <div className="btn-wrap">
                        <Link to='/sign-in' className='btn w-100'>Continue</Link>
                    </div>
                </div>
                <div className="card text-center">
                    <div className="login-wrap">
                        <Link to='/sign-in' className='login-link'>Back to Login</Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PasswordResetSuccess

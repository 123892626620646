import * as actiontype from "../actionType";
import { endPoints } from "../../utils/endPoints";
import { axiosInstanceWithUserToken } from "../../utils/Axios";
import { useNotify } from "../../components/Alert/Alert";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export const homePageData = (data) => (dispatch) => {
    // let toast = useNotify();
    // loadingAction(true)(dispatch);

    // console.log('toke', localStorage.getItem("user_token"))
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken
        .get(endPoints.HOME_PAGE_DATA, {
            headers: {
                Authorization: `${localStorage.getItem("user_token")}`,
            },
        })
        .then(async (response) => {
            dispatch({ type: "LOADERSTOP" })
            if (response?.data?.status === "success") {
                // console.log('profile of user')
                dispatch({
                    type: actiontype.HOME_DATA,
                    payload: {
                        ...response.data
                    },
                });
            } else {
                // console.log("profile not found")
                // dispatch({
                //     type: actiontype.GET_PROFILE_FAIL,
                //     payload: {
                //         ...response.data
                //     },
                // });
                // toast.error(response?.data?.message);
            }
            // loadingAction(false)(dispatch);
        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
            // console.log('error', errResponse?.response?.data)
            if (errResponse?.response?.data?.status === "error") {
                // toast.error(errResponse?.response?.data?.message);
            }
            // dispatch({
            //     type: actiontype.GET_PROFILE_FAIL,
            //     payload: {
            //         payload: {
            //             userData: {}, status: "error"
            //         },
            //     },
            // });
            // loadingAction(false)(dispatch);
        });
};
import * as actiontype from "../actionType";
import { endPoints } from "../../utils/endPoints";
import { axiosInstanceWithUserToken } from "../../utils/Axios";
import { useNotify } from "../../components/Alert/Alert";




export const addToCart = (data) => (dispatch) => {
    let toast = useNotify();
    let token = localStorage.getItem("user_token");
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken.post(endPoints.ADD_TO_CART, data, {
        headers: {
            Authorization: token,
        },
    })
        .then(response => {
            dispatch({ type: "LOADERSTOP" })
            if (response.data.status === "success") {
                dispatch({
                    type: actiontype.ADD_TO_CART,
                    payload: response.data
                })
                toast.success(response?.data?.message)
            } else {
                toast.error(response?.data?.message)
            }
        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
            console.log('errResponse', errResponse)
            toast.error(errResponse.response?.data?.message)
        });
};


export const cleanAddToCart = (data) => (dispatch) => {
    dispatch({
        type: actiontype.CLEAN_ADD_TO_CART,
    })
};


export const getServiceDetail = (data, callback) => (dispatch) => {
    let token = localStorage.getItem("user_token");
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken.get(endPoints.GET_SERVICE_DETAIL + "?serviceId=" + data, {
        headers: {
            Authorization: token,
        },
    })
        .then(response => {
            dispatch({ type: "LOADERSTOP" })
            dispatch({
                type: actiontype.SET_SERVICE_DETAILS,
                payload: response.data
            })
            callback(response.data.data)
        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
            console.log("success respoerrResponsese", errResponse)
            dispatch({
                type: actiontype.SET_SERVICE_DETAILS,
                payload: {}
            })
        });
};

export const getOtherService = (data) => (dispatch) => {
    let token = localStorage.getItem("user_token");
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken.get(endPoints.GET_OTHER_SERVICE_LIST + "?serviceId=" + data.id + "&sellerId=" + data.sid, {
        headers: {
            Authorization: token,
        },
    })
        .then(response => {
            dispatch({ type: "LOADERSTOP" })
            if (response.data.status === "success") {
                dispatch({
                    type: actiontype.GET_OTHER_SERVICE_LIST,
                    payload: response.data.data
                })
            } else {
                dispatch({
                    type: actiontype.GET_OTHER_SERVICE_LIST,
                    payload: []
                })
            }
        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
            console.log("success respoerrResponsese", errResponse)
            dispatch({
                type: actiontype.GET_OTHER_SERVICE_LIST,
                payload: []
            })
        });
};


export const getRecommendedList = (data) => (dispatch) => {
    let token = localStorage.getItem("user_token");
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken.get(endPoints.GET_RECOMMENDED_LIST + "?serviceId=" + data.serviceId + "&categoryId=" + data.categoryId + "&subcategoryId=" + data.subcategoryId, {
        headers: {
            Authorization: token,
        },
    })
        .then(response => {
            dispatch({ type: "LOADERSTOP" })
            if (response.data.status === "success") {
                dispatch({
                    type: actiontype.GET_RECOMMENDED_LIST,
                    payload: response.data.data
                })
            } else {
                dispatch({
                    type: actiontype.GET_RECOMMENDED_LIST,
                    payload: []
                })
            }
        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
            console.log("success respoerrResponsese", errResponse)
            dispatch({
                type: actiontype.GET_RECOMMENDED_LIST,
                payload: []
            })
        });
};


export const getReviewList = (data) => (dispatch) => {
    let token = localStorage.getItem("user_token");
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken.get(endPoints.GET_REVIEW_LIST + "?serviceId=" + data, {
        headers: {
            Authorization: token,
        },
    })
        .then(response => {
            dispatch({ type: "LOADERSTOP" })
            if (response.data.status === "success") {
                dispatch({
                    type: actiontype.GET_REVIEW_LIST,
                    payload: response.data.data
                })
            } else {
                dispatch({
                    type: actiontype.GET_REVIEW_LIST,
                    payload: []
                })
            }
        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
            console.log("success respoerrResponsese", errResponse)
            dispatch({
                type: actiontype.GET_REVIEW_LIST,
                payload: []
            })
        });
};



export const addReview = (data, callback) => (dispatch) => {
    let toast = useNotify();
    let token = localStorage.getItem("user_token");
    axiosInstanceWithUserToken.post(endPoints.ADD_SELLER_REVIEW, data, {
        headers: {
            Authorization: token,
        },
    })
        .then(response => {
            toast.success(response?.data?.message)
            callback(true)
        })
        .catch((errResponse) => {

            toast.error(errResponse.response?.data?.message)
            console.log("success respoerrResponsese", errResponse)
            callback(true)
        });
};

export const addServiceWishlist = (data, type) => (dispatch) => {
    let toast = useNotify();
    let token = localStorage.getItem("user_token");
    axiosInstanceWithUserToken.post(endPoints.ADD_WISHLIST, data, {
        headers: {
            Authorization: token,
        },
    })
        .then(response => {
            if (response.data.status === "success") {
                toast.success(response?.data?.message)
                if (type === "service") {
                    dispatch({
                        type: actiontype.ADD_WISHLIST_SERVICE_DETAIL,
                        payload: true
                    })
                } else if (type === "otherservice") {
                    dispatch({
                        type: actiontype.WISHLIST_OTHER_SERVICE,
                        payload: { type: true, data }
                    })
                } else if (type === "sellerServices") {
                    dispatch({
                        type: actiontype.WISHLIST_SELLER_SERVICE,
                        payload: { type: true, data }
                    })
                } else {
                    dispatch({
                        type: actiontype.WISHLIST_RECOMMENDED,
                        payload: { type: true, data }
                    })
                }

            } else {

            }
        })
        .catch((errResponse) => {
            console.log("success respoerrResponsese", errResponse)
        });
};



export const removeServiceWishlist = (data, type) => (dispatch) => {
    let toast = useNotify();
    let token = localStorage.getItem("user_token");
    axiosInstanceWithUserToken.post(endPoints.REMOVE_WISHLIST, data, {
        headers: {
            Authorization: token,
        },
    })
        .then(response => {
            if (response.data.status === "success") {
                toast.success(response?.data?.message)
                if (type === "service") {
                    dispatch({
                        type: actiontype.ADD_WISHLIST_SERVICE_DETAIL,
                        payload: false
                    })
                } else if (type === "otherservice") {
                    dispatch({
                        type: actiontype.WISHLIST_OTHER_SERVICE,
                        payload: { type: false, data }
                    })
                } else if (type === "sellerServices") {
                    dispatch({
                        type: actiontype.WISHLIST_SELLER_SERVICE,
                        payload: { type: false, data }
                    })
                } else {
                    dispatch({
                        type: actiontype.WISHLIST_RECOMMENDED,
                        payload: { type: false, data }
                    })
                }

            } else {
            }


        })
        .catch((errResponse) => {
            console.log("success respoerrResponsese", errResponse)
        });
};
import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import { Link, useNavigate } from 'react-router-dom';
import service1 from 'assets/images/service-1.jpg';
import service2 from 'assets/images/service-2.jpg';
import service3 from 'assets/images/service-3.jpg';
import service4 from 'assets/images/service-4.jpg';
import SlickSlider from 'components/Slider/SlickSlider';
import { editOptionalInfo, optionalinfoDetail } from '../../store/action/optionalinfo'
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from "../../components/Alert/Alert";
import PortfolioSec from './PortfolioSection'
import ModalImage from 'components/ModalImage';
import OutsideClickHandler from 'components/OutsideClickHandler';

const Entertainment = ({ sellerInformationId }) => {
	const navigate = useNavigate();
	let dispatch = useDispatch();
	const ref = useRef();
	const [data, setData] = useState({});
	const [showModel, setShowModel] = useState(false);
	const [modelData, setModelData] = useState({});
	const [index, setIndex] = useState(0);

	useEffect(() => {
		optionalinfoDetail({ sellerInformationId }, callback => {
			setData(callback)
		})(dispatch)
	}, [])

	OutsideClickHandler(ref, () => {
		// console.log('showMenu', closeModel)
		// setTimeout(() => (closeModel),1000)
		// alert('click')
	});


	let settings = {
		infinite: false,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 3,
				}
			},
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 2,
				}
			},
			{
				breakpoint: 576,
				settings: {
					slidesToShow: 1,
				}
			}
		]
	};

	const getTimeDate = (item) => {
		const monthNames = ["January", "Feb", "March", "April", "May", "June",
			"July", "Aug", "Sep", "October", "Nov", "Dec"
		];
		let sDate = new Date(item.startDate);
		let eDate = item.endDate ? new Date(item.endDate) : null;
		let sYear = sDate.getFullYear();
		let eYear = eDate?.getFullYear() ?? null;
		return `${monthNames[sDate.getMonth()]} ${sYear} - ${eDate ? monthNames[eDate.getMonth()] + " " + eYear : "Until now"
			}`
	}
	// console.log('data', data)

	const onCloseModal = () => setShowModel(false);

	const openModel = (val, i) => {
		setShowModel(true);
		setModelData(val)
		setIndex(i)
	}

	const handlerNext = (index, allData) => {
		if (allData.length - 1 > index) {
			let newIndex = index + 1;
			setModelData(allData[newIndex])
			setIndex(newIndex)
		}
	}

	const handlerPrev = (index, allData) => {
		if (index !== 0) {
			let newIndex = index - 1;
			setModelData(allData[newIndex])
			setIndex(newIndex)
		}
	}


	return (
		<div ref={ref} className='entertainment-tab'>
			<Alert />
			{
				// data?.sellerWorkExperienceData?.length &&
				data?.sellerWorkExperienceData?.[0]?.name !== "" &&
				<div className="card">
					{
						data?.sellerWorkExperienceData?.map(item =>
							<div className="info-wrap">
								<h4>{item.name}</h4>
								{item.startDate && <div className="date-wrap">{getTimeDate(item)}</div>}
								<p>{item.description}</p>
							</div>
						)
					}
				</div>
			}

			{
				showModel ?
					<ModalImage
						modelData={modelData}
						data={data}
						handlerNext={handlerNext}
						handlerPrev={handlerPrev}
						allData={data?.sellerPortfolioData}
						index={index}
						closeModel={onCloseModal}
					/>
					: ''
			}


			{
				data?.sellerPortfolioData?.length > 0 &&
					data?.sellerPortfolioData[0].media !== "" ?
					<>
						<h4>Portfolio</h4>
						<Slider {...settings}>
							{
								data?.sellerPortfolioData?.map((item, i) =>
									item.media != "" ?
										<PortfolioSec openModel={openModel} item={item} index={i} data={data} />
										:
										''
								)
							}
						</Slider>
					</>
					: ''
			}

			{
				data?.sellerCertificateData?.[0]?.image &&
				<>
					<h4>Certifications & Licenses</h4>
					<div className="certificate-slider">
						<SlickSlider data={data} />
					</div>
				</>

			}
		</div>
	)
}

export default Entertainment;

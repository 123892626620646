import { endPoints } from "../../utils/endPoints";
import { axiosInstanceWithUserToken } from "../../utils/Axios";
import * as actiontype from "../actionType";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const bannerListing = (data) => (dispatch) => {
    // let token = localStorage.getItem("user_token");
    dispatch({ type: "LOADER" })
    axiosInstanceWithUserToken
        .get(endPoints.BANNER_LIST, {
            headers: {
                Authorization: `${localStorage.getItem("user_token")}`,
            },
        })
        .then(async (response) => {
            dispatch({ type: "LOADERSTOP" })
            if (response?.data?.status === "success") {
                // toast.success(response?.data?.message)
                dispatch({
                    type: actiontype.LIST_BANNER,
                    payload: {
                        ...response.data
                    },
                });

                // console.log('smdsmn', 'user_token')
            } else {
                // console.log("wrong")
                // dispatch({
                //     // type: actiontype.LOGIN_FAIL,
                //     // payload: {
                //     //     islogin: false
                //     // },
                // });
                // toast.error(response?.data?.message);
            }
            // console.log("success response", response.data.data.token)
        })
        .catch((errResponse) => {
            dispatch({ type: "LOADERSTOP" })
            toast.error("error")
            // console.log("success respoerrResponsese", errResponse)
        });
};
import React, { useState } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CardInfo from 'components/User/PaymentInfo/CardInfo/CardInfo';

const SavedCard = () => {
	return (
		<section className='billing-method'>
			<div className="container">
				<Breadcrumb>
					<Breadcrumb.Item href="#">Account</Breadcrumb.Item>
					<Breadcrumb.Item active>Save Card</Breadcrumb.Item>
				</Breadcrumb>
				<div className="section-header">
					<div className="header-left">
						<h1>Save Card</h1>
					</div>
					<div className="header-right">
						<Link to='/user/save-card/edit' className='btn'>Edit</Link>
					</div>
				</div>
				<CardInfo />
			</div>
		</section>
	)
}

export default SavedCard

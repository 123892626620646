import React, { useState, useRef, useEffect } from "react";
import Select from "react-select";
import { Editor } from "@tinymce/tinymce-react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { uploadFiles1 } from 'store/action/optionalinfo';
import { sellerAddData, editSellerInfo } from "store/action/sellerInformationAction";
import { subCategorySellerInfo } from "store/action/subCategoryAction";
import { getAddressList } from 'store/action/sellerServiceAddAction'
import AddAddress from "components/Seller/AddAddress";
import AddressList from "./AddressList"
import { deleteNewAddress } from "store/action/addAddressAction";
import { countryList } from "store/action/countryList";
import { stateList } from "store/action/stateList";
import { cityList } from "store/action/cityListAction";
// import {profileImg} from 'assets/images/employee.png';
import profileImg from '../../assets/images/employee.png'
import ManageDatePopup from "./ManageDatePopup";
// import profileImg from '../../assets/images/employee'

const SellerInfoEdit = ({ item, index }) => {
    let dispatch = useDispatch();
    let history = useNavigate()


    const cidSlug = useParams();
    console.log("cidSlug", item)

    const editorRef = useRef(null);
    const getLanguage = useSelector((state) => state.languageDrop);

    let getCountry = useSelector((state) => state.countryData);

    const getState = useSelector((state) => state.stateData);
    const getCity = useSelector((state) => state.cityData);

    const [subCat, setSubcat] = useState([]);
    const [addressList, setAddressList] = useState([]);
    const [showAddAddress, setShowAddAddress] = useState(false);
    const [addressId, setAddressId] = useState(null);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(5);
    const [countryId, setCountryId] = useState("");
    const [stateId, setStateId] = useState("");

    const [countryVal, setCountryVal] = useState("");
    const [stateVal, setStateVal] = useState("");
    const [cityVal, setCityVal] = useState("");
    const [searchVal, setSearchVal] = useState("");

    const [showManageData, setShowManageData] = useState(false)
    const [daysListShow, setDaysListShow] = useState(false)

    const indexOfLastTodo = page * limit;
    const indexOfFirstTodo = indexOfLastTodo - limit;

    const [state, setState] = useState({
        sellerInformationId: "",
        subCategory: [],
        description: '',
        image: '',
        imageSrc: null
    })

    const [imageData, setImageData] = useState({
        path: null,
        image: null
    })


    console.log("uploadImg---", state.imageSrc)

    useEffect(() => {

    }, [cidSlug.cid])

    // console.log("state--->", state)

    useEffect(() => {
        if (cidSlug.cid) {
            sellerAddData({ sellerInformationId: item.sellerInformationId })(dispatch)
        }
    }, [cidSlug.cid])


    const showAddAddressHandler = () => {
        setAddressId(null);
        setShowAddAddress(true);
    };

    const [lang, setLang] = useState([{ spokenLanguage: "", languageType: "" }]);
    const [uniqueLang, setUniqueLang] = useState([]);

    useEffect(() => {
        countryList()(dispatch);
        getAddressList({ sellerInformationId: item.sellerInformationId }, callback => {
            setAddressList(callback)
        })(dispatch)


        sellerAddData({ sellerInformationId: item.sellerInformationId }, callback => {
            console.log("imagecheck", callback)
            let subOptions = []
            if (callback[0]?.subcategoryData?.length) {
                subOptions = callback[0]?.subcategoryData?.map((a, i) => ({ value: callback[0].subcategoryIds[i], label: a }))
            } else {
                subOptions = [];

            }

            setState({
                ...state,
                sellerInformationId: callback[0]?.categoryId ?? '',
                subCategory: subOptions ?? [],
                description: callback[0]?.description ?? '',
                image: callback[0]?.image ?? '',
                imageSrc: callback[0]?.image ? callback[0]?.sellerAttachmentShowPath + callback[0]?.image : null
            })
            setImageData({ path: callback[0]?.image ? callback[0]?.sellerAttachmentShowPath : null, image: callback[0]?.image ? callback[0]?.image : null })
            setLang(callback[0]?.languages.length === 0 ? lang : callback[0]?.languages)
        })(dispatch)

        subCategorySellerInfo({ categoryId: item.categoryId }, callback => {
            setSubcat(callback)
        })(dispatch)
    }, [])


    useEffect(() => {
        const bodyRef = document.getElementById('body');
        console.log(showAddAddress)
        if (showAddAddress) {
            bodyRef.classList.add('no-scroll');
        } else {
            bodyRef.classList.remove('no-scroll');
        }
    }, [showAddAddress])

    // console.log('aaaaa', state)


    const getSubCategoryHandler = (val) => {
        setState({ ...state, subCategory: val, sellerInformationId: item.sellerInformationId })
    }
    // item.imagePath+ item.image

    useEffect(() => {
        setState({ ...state, imageSrc: (item.image ? item.imagePath + item.image : null) });
    }, [cidSlug.cid])

    function handlerImgChange(e) {
      
        if (e.target.files.length > 0) {
            let formData = new FormData();
            formData.append('media', e.target.files[0]);
            formData.append('path', "service");
            uploadFiles1(formData, callback => {
                console.log("callback", callback)
                // setState({ ...state, image: callback.imageName, imageSrc: (callback.imageName ? callback.path + callback.imageName : null) });

                setImageData({ path: callback.imageName ? callback.path : null, image: callback.imageName ? callback.imageName : null })
                // setImageDetails(callback.path + callback.imageName)llback.

                // setState({ ...state, image: item.image, imageSrc: item.imagePath+ item.image });
            })(dispatch);
        }

    }

    const handlerChange = (e, i, name) => {
        let ele = document.getElementById(`lang_` + index + "_" + i);
        let eleError = document.getElementById(`lang_error` + index + "_" + i);
        let ele1 = document.getElementById(`langType_` + index + "_" + i);
        let eleError1 = document.getElementById(`langType_error` + index + "_" + i);

        console.log('ele', ele)
        console.log('eleError', eleError)
        console.log('ele1', ele1)
        console.log('eleError1', eleError1)



        if (ele.value === "") {
            ele.focus();
            eleError.style.display = "block";
        } else {
            eleError.style.display = "none";
        }
        if (ele1.value === "") {
            ele1.focus();
            eleError1.style.display = "block";
        } else {
            eleError1.style.display = "none";
        }
        let newState = [...lang];
        newState[i][name] = e.target.value;
        setLang(newState);
        // if (name === "spokenLanguage") {
        //     let newUniLang = [...uniqueLang];
        //     newUniLang.push(e.target.value);
        //     setUniqueLang(newUniLang)
        // }
        // console.log('name', name)
    }

    const addMore = (e) => {
        e.preventDefault();
        let newState = [...lang];
        newState.push({ spokenLanguage: "", languageType: "native" })
        setLang(newState);
    }

    const removeField = (index) => {
        let newState = [...lang];
        newState = newState.filter((item, i) => {
            console.log(i, index)
            if (i !== index) {
                return item;
            }
        });
        setLang(newState);
    }

    const submitHandler = (e) => {
        e.preventDefault();
        let isValidate = true;
        let ids = [];
        // lang.forEach((item, i) => {
        //     let ele = document.getElementById(`lang_` + index + "_" + i);
        //     let eleError = document.getElementById(`lang_error` + index + "_" + i);
        //     let ele1 = document.getElementById(`langType_` + index + "_" + i);
        //     let eleError1 = document.getElementById(`langType_error` + index + "_" + i);

        //     let eleError2 = document.getElementById(`duplicateLang` + index + "_" + i);

        //     if (ele.value === "") {
        //         isValidate = false;
        //         ele.focus();
        //         eleError.style.display = "block";
        //     } else {
        //         eleError.style.display = "none";
        //     }
        //     if (ele1.value === "") {
        //         isValidate = false;
        //         ele1.focus();
        //         eleError1.style.display = "block";
        //     } else {
        //         eleError1.style.display = "none";
        //     }
        //     console.log('ids', ids, item.spokenLanguage)
        //     if (ids.includes(item.spokenLanguage)) {
        //         isValidate = false;
        //         ele.focus();
        //         console.log('eleError2', eleError2)
        //         eleError2.style.display = "block";
        //     } else {
        //         console.log('asdas')
        //         ids.push(item.spokenLanguage);
        //         eleError2.style.display = "none";
        //     }

        // })
        // console.log('isValidate', isValidate, lang)
        if (isValidate) {
            let payload = {
                sellerInformationId: item.sellerInformationId,
                subcategoryIds: state.subCategory.map(item => item.value),
                description: state.description,
                image: imageData?.image,
                languages: lang
            }
            // console.log('payload', payload)
            editSellerInfo(payload)(dispatch)
            setTimeout(() => history('/lab-information'), 1000)
        }
    }


    const closeAddressModel = (status) => {
        setShowAddAddress(status);
        getAddressList({ sellerInformationId: item.sellerInformationId }, callback => {
            setAddressList(callback)
        })(dispatch)
    }

    const deleteAddressHander = (id) => {
        deleteNewAddress({ addressId: id }, callback => {
            let newState = [...addressList];
            newState = newState.filter(item => item._id !== id);
            setAddressList(newState);
        })(dispatch)
    }

    const setAddressIdHandler = (id) => {
        setAddressId(id);
        setTimeout(() => setShowAddAddress(true), 500);
    }

    if (addressList.length !== 0) {
        // console.log('addressList', Math.ceil(addressList.length / 5))
    }

    const clickPagination = (index) => {
        setPage(index);
    }

    const clickPrev = (index) => {
        setPage(index - 1);
    }

    const clickNext = (index) => {
        setPage(index + 1);
    }


    const countryChange = (val) => {
        setCountryVal(val)
        setSearchVal("");
        setStateVal("")
        setCityVal("");
        setCountryId(val.value)
        stateList({ countryId: val.value })(dispatch);
        getAddressList({ sellerInformationId: item.sellerInformationId, countryId: val.value }, callback => {
            setAddressList(callback)
        })(dispatch)
    }

    const stateChange = (val) => {
        setStateVal(val)
        setStateId(val.value)
        setCityVal("");
        cityList({ countryId: countryId, stateId: val.value })(dispatch);
        getAddressList({ sellerInformationId: item.sellerInformationId, countryId: countryId, stateId: val.value }, callback => {
            setAddressList(callback)
        })(dispatch)
    }

    const cityChange = val => {
        setCityVal(val);
        getAddressList({ sellerInformationId: item.sellerInformationId, countryId: countryId, stateId: stateId, cityId: val.value }, callback => {
            setAddressList(callback)
        })(dispatch)
    }




    const handlerInputChange = (e) => {
        e.preventDefault();
        setCountryVal("")
        setStateVal("")
        setCityVal("");
        setCountryId("")

        getAddressList({ sellerInformationId: item.sellerInformationId, address: searchVal }, callback => {
            setAddressList(callback)
        })(dispatch)
    }

    const getCountryData = () => {
        let countrydata = getCountry?.map(item => ({ value: item._id, label: item.name }));
        countrydata.unshift({ value: "", label: "Please select country" });
        return countrydata;
    }

    const getStateData = () => {
        let countrydata = getState?.map(item => ({ value: item._id, label: item.name }));
        countrydata.unshift({ value: "", label: "Please select state" });
        return countrydata;
    }

    const getCityData = () => {
        let countrydata = getCity?.map(item => ({ value: item._id, label: item.name }));
        countrydata.unshift({ value: "", label: "Please select city" });
        return countrydata;
    }

    useEffect(() => {
        stateList({ countryId: "61c2fb83dc7c0d455ba5e68d" }, dispatch);
    }, [])
    // console.log('uniqueLang', uniqueLang)

    const closeManageDateModel = (status) => {
        setShowManageData(status)
    }

    const setManageDateHandler = (id) => {
        setAddressId(id);
        setTimeout(() => setShowManageData(true), 500);
    }

    console.log("3333333", state.imageSrc)

    return (
        <>

            {/* <Loader /> */}
            <div className="edit-info">
                <form eventKey={item.categoryName} >
                    <div className="card">
                        <div className="left-block inputs-wrapper">
                            {/* <div className="input-wrapper required">
                                <label className="input-label text-transform-initial">
                                    Sub-Categories of   <strong>{item.categoryName}</strong>
                                </label>
                                <div className="input-wrap">
                                    <div className="select-wrap multi-select">
                                        <Select
                                            classNamePrefix="react-select"
                                            className="react-select-container form-control"
                                            options={subCat?.map((newitem, i) => ({ value: newitem._id, label: newitem.name }))}
                                            onChange={getSubCategoryHandler}
                                            isMulti
                                            value={state.subCategory}
                                        />
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="input-wrapper">
                                <label className="input-label text-transform-initial">
                                    Languages Spoken
                                </label>
                                {
                                    lang.map((langitem, i) =>
                                        <div className="input-wrap">
                                            <div className="select-wrap multiple-selects">
                                                <div className="select-wrapper">
                                                    <select
                                                        className="form-control"
                                                        onChange={(e) => handlerChange(e, i, "spokenLanguage")}
                                                        value={langitem.spokenLanguage}
                                                        id={`lang_` + index + "_" + i}
                                                    >
                                                        <option value="">Please select language</option>
                                                        {
                                                            getLanguage?.map(item =>
                                                                <option value={item._id}>{item.name}</option>
                                                            )
                                                        }
                                                    </select>
                                                    <p class="error" style={{ display: "none" }} id={`lang_error` + index + "_" + i}>Please choose language</p>
                                                    <p class="error" style={{ display: "none" }} id={`duplicateLang` + index + "_" + i}>Languages should be unique</p>
                                                </div>
                                                <div className="select-wrapper">
                                                    <select
                                                        value={langitem.languageType}

                                                        className="form-control"
                                                        onChange={(e) => handlerChange(e, i, "languageType")}
                                                        // value={langitem.languageType}
                                                        id={`langType_` + index + "_" + i}
                                                    >
                                                        <option value="">Please select language type</option>
                                                        <option value={`Native`}>{`Native`}</option>
                                                        <option value={`Expert`}>{`Expert`}</option>
                                                        <option value={`Proficient`}>{`Proficient`}</option>
                                                        <option value={`Beginner`}>{`Beginner`}</option>
                                                    </select>
                                                    <p class="error" style={{ display: "none" }} id={`langType_error` + index + "_" + i}>Please choose language type</p>
                                                </div>
                                                {
                                                    i !== 0 &&
                                                    <button
                                                        onClick={() => removeField(i)}
                                                        type="button"
                                                        className="remove-btn"
                                                    ></button>
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                            <button onClick={addMore} type="button" className="add-language">
                                Add another language
                            </button> */}
                            <div className="input-wrapper">
                                <label className="input-label">Description</label>
                                <div className="input-wrap text-editor">
                                    {/* <Editor
                                        apiKey="snwo4qmxj9lypp9go8m4xpdmw6t0ekcg9sw5edqogeh4e73h"
                                        onInit={(evt, editor) =>
                                            (editorRef.current = editor)
                                        }
                                        onEditorChange={(newValue, editor) => setState({ ...state, description: newValue })}
                                        value={state.description}
                                        init={{
                                            menubar: false,
                                            height: 182,
                                            toolbar:
                                                "fontsizeselect | bold | forecolor | alignleft aligncenter alignright",
                                            content_style:
                                                "body { font-family: Lato; margin: 0;  font-size:16px; color: #9A9FA5; padding: 3px 11px;} p { margin: 0; letter-spacing: 0.4px }",
                                        }}
                                    /> */}
                                    <textarea
                                        onChange={(e) => setState({ ...state, description: e.target.value })}
                                        value={state.description}
                                        placeholder='Description'
                                        maxLength={500}
                                    />
                                    <label className="input-label required input-info position-static label-fix">Comment should not exceed 500 characters.</label>
                                </div>
                            </div>
                        </div>


                        <div className="right-block inputs-wrapper">
                            <div className="img-upload">
                                <label>Upload  Photo</label>
                                <div className="img-container">

                                    {/* <img src={state.imageSrc ? state.imageSrc : item.imagePath+ item.image} /> */}

                                    {/* {


                                        state.image === "" ? (
                                            <div className="img-placeholder">
                                                <img src={profileImg} />

                                            </div>
                                        ) :

                                            state.imageSrc ? <div className="img-placeholder gg">
                                                {console.log("item.image", state.imageSrc)}
                                                <img src={state.imageSrc || profileImg} />

                                            </div>
                                                :
                                                <div className="img-placeholder gg">
                                                    <img src={item.imagePath + item.image} />

                                                </div>


                                    } */}

                                    {
                                        !imageData?.image ? <div className="img-placeholder">
                                            <img src={profileImg} />

                                        </div>
                                            :
                                            <div className="img-placeholder">
                                                <img src={imageData?.image && imageData?.path + imageData?.image} />

                                            </div>
                                    }

                                </div>
                                <div className="upload-btn">
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={handlerImgChange}
                                    />
                                    <button type="button" className="btn w-100">
                                        Upload Photo
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </form>
            </div>
            <div className="slider-heading">
                <h2>Edit Addresses</h2>
                <button
                    className="btn text-transform-initial"
                    onClick={showAddAddressHandler}
                >
                    <i className="icon-plus"></i>
                    Add a New Address
                </button>
            </div>
            <div className="services-inputs">
                {/* {addressList.length !== 0 ? */}
                <form>
                    <div className="form-wrap">
                        <div className="inputs-wrap">
                            <div className="input-wrap">
                                <input
                                    type="text"
                                    className="input"
                                    placeholder="Search a specific address"
                                    onChange={(e) => setSearchVal(e.target.value)}
                                    value={searchVal}
                                />
                                <button className="search-btn" onClick={handlerInputChange}>
                                    <i className="icon-search"></i>
                                </button>
                            </div>
                        </div>
                        <div className="selects-wrap">
                            <div className="select-wrap bigger">
                                {/* <Select
                                    onChange={countryChange}
                                    classNamePrefix="react-select"
                                    className="react-select-container"
                                    value={countryVal}
                                    options={getCountryData()}
                                /> */}
                            </div>
                            <div className="select-wrap selecet-box-wrapper">
                                <Select
                                    onChange={stateChange}
                                    classNamePrefix="react-select"
                                    className="react-select-container"
                                    value={stateVal}
                                    options={getStateData()}
                                    placeholder="Select State"
                                />
                            </div>
                            <div className="select-wrap selecet-box-wrapper">
                                <Select
                                    onChange={cityChange}
                                    value={cityVal}
                                    classNamePrefix="react-select"
                                    className="react-select-container"
                                    options={getCityData()}
                                    placeholder="Select City"
                                />

                            </div>
                        </div>
                    </div>
                </form>
                {/* :
                    ''
                } */}
            </div>
            <div className="address-table">
                <div className="table-wrap">
                    {console.log("addressList", addressList)}
                    {
                        addressList && addressList?.slice(indexOfFirstTodo, indexOfLastTodo).map(item =>
                            <AddressList item={item}
                                deleteAddressHander={deleteAddressHander}
                                setAddressId={setAddressIdHandler}
                                setManageDateHandler={setManageDateHandler}
                                setDaysListShow={setDaysListShow}
                                daysListShow={daysListShow} />
                        )
                    }
                    {
                        addressList.length === 0 ?
                            <div>No address found</div>
                            : null
                    }
                </div>

                {
                    addressList.length > 0 &&
                    <nav
                        className="pagination-wrap"
                        aria-label="Page navigation example"
                    >
                        <ul className="pagination">
                            <li className={page === 1 ? "page-item previous disabled" : "page-item previous"}>
                                <a
                                    className="page-link"
                                    href="javascript:void(0)"
                                    tabIndex="-1"
                                    onClick={() => clickPrev(page)}
                                >
                                    <i className="icon-arrow"></i>
                                </a>
                            </li>
                            {
                                addressList.length > 0 ?
                                    [...Array(Math.ceil(addressList.length / 5))].map((item, i) =>
                                        <li className={i + 1 === page ? "page-item active" : "page-item"} aria-current="page">
                                            <a className="page-link" href="javascript:void(0)" onClick={() => clickPagination(i + 1)}>
                                                {i + 1}
                                            </a>
                                        </li>
                                    )
                                    : null
                            }


                            <li className={page === Math.ceil(addressList.length / 5) ? "page-item  disabled" : "page-item "}>
                                <a className="page-link" href="javascript:void(0)" onClick={() => clickNext(page)}>
                                    <i className="icon-arrow"></i>
                                </a>
                            </li>
                        </ul>
                    </nav>
                }
            </div>
            <div className="btn-wrap">
                <input type='cancel' className="secondary-btn cancel-btn"
                    // type='button'
                    onClick={() => history('/lab-information')} value="cancel" />
                <input type='submit' className="btn" onClick={submitHandler} value="save" />
            </div>
            {showAddAddress && <AddAddress
                sellerInformationId={item.sellerInformationId}
                onCloseModal={closeAddressModel}
                addressId={addressId}


            />}

            {showManageData && <ManageDatePopup
                sellerInformationId={item.sellerInformationId}
                onCloseModal={closeManageDateModel}
                addressId={addressId}
                cidSlug={cidSlug}
                setAddressList={setAddressList}
            />}

        </>
    )
}

export default SellerInfoEdit